import React from "react";
const { Modal } = require("reactstrap");

import { Container, CloseButton } from "./custommodal.styles";

type CustomModalPropsType = {
  children: React.ReactNode;
  isModalOpen: boolean;
  isModalCentered?: boolean;
  isModalSize?: "lg" | "sm";
  onRequestClose: () => void;
};

export const CustomModal = ({
  children,
  isModalOpen,
  isModalCentered,
  isModalSize,
  onRequestClose,
}: CustomModalPropsType) => {
  return (
    <Modal
      isOpen={isModalOpen}
      centered={isModalCentered}
      size={isModalSize || "lg"}
      fullscreen="sm"
    >
      <Container>
        <CloseButton onClick={onRequestClose}>X</CloseButton>
        {children}
      </Container>
    </Modal>
  );
};
