import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";
import {
  formatCurrency,
  formatFullDateAndTimeHelper,
  formatUsagePeriod,
  formatValuesByLanguage,
} from "../../helpers/Helpers";
import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../components/Displays/DisplayLoading/DisplayLoading";
import { Button } from "../../components/Button/Button";

import { AuthUserTypes } from "../../interfaces/AuthUser";
import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { SiteTransactionsTypes } from "../../interfaces/SiteTransactions";
import { RowTypes } from "../../interfaces/DataTable";

import { format, parse,subDays } from 'date-fns';

import {
  Component,
  Wrapper,
  Header,
  Content,
  DatePickerField,
  ButtonClearDate,
  ButtonPDF,
} from "./transactions.styles";
import { CSVLink } from "react-csv";

type TransactionsPropsTypes = {
  t: Function;
};

type PickerDateStateTypes = {
  start: string;
  end: string;
};

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

type DataRowTypes = {
  rowAmount: RowTypes;
  rowChargerPointId: RowTypes;
  rowCpCode: RowTypes;
  rowDate: RowTypes;
  rowDuration: RowTypes;
  rowKwh: RowTypes;
  rowName: RowTypes;
};


export const Transactions = ({ t: translation }: TransactionsPropsTypes) => {
  const currentUser: AuthUserTypes = JSON.parse(
    localStorage.getItem("authUser")!,
  );
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [siteTransactionsData, setSiteTransactionsData] = React.useState<
    SiteTransactionsTypes[]
  >([]);
  const [pickerDate, setPickerDate] = React.useState<PickerDateStateTypes>({
    start: "",
    end: "",
  });
  enum SystemLanguage {
    US = "en-US",
    BR = "pt-BR",
  }
  
  const [dataTableSettings, setDataTableSettings] =
    React.useState<DataTableTypes>({} as DataTableTypes);
  
  const datesUpdated = [new Date(state.inicialPickerData.start), new Date(state.inicialPickerData.end)];

  const customSortableKwhRow = (
    elementA: DataRowTypes,
    elementB: DataRowTypes,
  ) => {
    const _A = Number(elementA.rowKwh.toString().replace(",", "."));
    const _B = Number(elementB.rowKwh.toString().replace(",", "."));

    if (_A > _B) return 1;
    if (_A < _B) return -1;

    return 0;
  };

  const customSortDates = (elementA: DataRowTypes, elementB: DataRowTypes) => {
    const [month_dayA, day_monthA, year_timeA] = String(
      elementA.rowDate,
    ).split("/");
    const [yearA, timeA] = year_timeA.split("-");

    const [month_dayB, day_monthB, year_timeB] = String(
      elementB.rowDate,
    ).split("/");
    const [yearB, timeB] = year_timeB.split("-");

    const dateA_US = `${yearA}-${month_dayA}-${day_monthA}`; // 2023-05-22
    const dateB_US = `${yearB}-${month_dayB}-${day_monthB}`;

    const dateA_BR = `${yearA}-${day_monthA}-${month_dayA}`; // 2023-05-22 (in pt-BR day becomes month - month becomes day)
    const dateB_BR = `${yearB}-${day_monthB}-${month_dayB}`;

    if (state?.userData?.language === SystemLanguage.US) {
      if (dateA_US > dateB_US) return 1;
      if (dateA_US < dateB_US) return -1;
    }

    if (state?.userData?.language === SystemLanguage.BR) {
      if (dateA_BR > dateB_BR) return 1;
      if (dateA_BR < dateB_BR) return -1;
    }

    return 0;
  };
 

  const dataTableColumns: TableColumn<DataRowTypes>[] = [
    {
      name: translation("transactions_TableHeaderDate"),
      selector: (row: any) => row.rowDate,
      sortable: true,
      grow: 2,
      sortFunction: customSortDates,
    },
    {
      name: translation("transactions_TableHeaderCpCode"),
      selector: (row: any) => row.rowCpCode,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("transactions_TableHeaderChargerPointId"),
      selector: (row: any) => row.rowChargerPointId,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("transactions_TableHeaderName"),
      selector: (row: any) => row.rowName,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("transactions_TableHeaderkWh"),
      selector: (row: any) => row.rowKwh,
      sortable: true,
      sortFunction: customSortableKwhRow,
      width: "160px",
    },
    {
      name: translation("transactions_TableHeaderDuration"),
      selector: (row: any) => row.rowDuration,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("transactions_TableHeaderAmount"),
      selector: (row: any) => row.rowAmount,
      sortable: true,
      width: "160px",
    },
  ];
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };
  
  // Functions
  const buildDataTable = () => {
    const mutableTransactionsData: SiteTransactionsTypes[] =
      structuredClone(siteTransactionsData);

    const dataTableRows: DataRowTypes[] = mutableTransactionsData.map(
      (transaction: SiteTransactionsTypes) => {
        const amount = formatValuesByLanguage(
          transaction.amount,
          state?.userData?.language,
        );
        const chargerPointId = transaction.chargerId ?? emptyData;
        const cpCode =
          !transaction.cpCode || !transaction.connectorId
            ? emptyData
            : `${transaction.cpCode}-${transaction.connectorId}`;
        const date =
          formatFullDateAndTimeHelper(
            transaction.startTime,
            state?.userData?.language,
          ) ?? emptyData;
        const duration = formatUsagePeriod(transaction.duration) ?? emptyData;

        const calcKwh = transaction.meterTotal;
        const kwh = calcKwh.toLocaleString(state?.userData?.language, {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        });
        const name = transaction.userName ?? emptyData;

        return {
          rowAmount: amount,
          rowChargerPointId: chargerPointId,
          rowCpCode: cpCode,
          rowDate: date,
          rowDuration: duration,
          rowKwh: kwh ?? emptyData,
          rowName: name,
        };
      },
    );

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };
  const makeIsoTimestamp = (dates: Date[]) => {
    const startDate = dates[0] ? new Date(dates[0]) : "";
    const endDate = dates[1] ? new Date(dates[1]) : "";

    const makeDateTime = (date: Date, period: "start" | "end") => {
      const dateDay = String(date.getDate()).padStart(2, "0");
      const dateYear = date.getFullYear();
      const dateMonth = String(date.getMonth() + 1).padStart(2, "0");
      const isoDate = `${dateYear}-${dateMonth}-${dateDay}${
        period === "start" ? "T00:00:00Z" : "T23:59:59Z"
      }`;

      return isoDate;
    };

    setPickerDate(() => ({
      start:
        typeof startDate !== "string" ? makeDateTime(startDate, "start") : "",
      end: typeof endDate !== "string" ? makeDateTime(endDate, "end") : "",
    }));
  };

  const handleClearPeriod = () => {
    setPickerDate(() => ({
      start: "",
      end: "",
    }));
  };

  //API data
  const getSiteTransactionsData = async () => {
    try {
      setLoadingData(true);

      // DEFININDO E FORMATANDO A DATA ATUAL
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const todayFormatted = format(today, "yyyy-MM-dd'T'HH:mm:ss'Z'");

      const lastThirdDays = subDays(now, 31);
      const last30Days = new Date(lastThirdDays.getFullYear(), lastThirdDays.getMonth(), lastThirdDays.getDate());
      const lastThirdDaysFormatted = format(last30Days, "yyyy-MM-dd'T'HH:mm:ss'Z'");

      const { data } = await api.get<SiteTransactionsTypes[]>(
        `/SiteTransactions/${site_id}?from=${pickerDate.start !== "" ? pickerDate.start : lastThirdDaysFormatted }&to=${
          pickerDate.end ? pickerDate.end : todayFormatted
        }`,
      );

      const mutableData: SiteTransactionsTypes[] = structuredClone(data);
      const sortedData: SiteTransactionsTypes[] = mutableData.sort(
        (dataA, dataB) => {
          if (dataA.startTime > dataB.startTime) return -1;
          if (dataA.startTime < dataB.startTime) return 1;

          return 0;
        },
      );

      setSiteTransactionsData(sortedData);

    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };
  
  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getSiteTransactionsData();
  }, [state.sites, site_id, pickerDate]);

  React.useMemo(() => {
    buildDataTable();
  }, [siteTransactionsData, state?.userData?.language]);

  const dateRef = React.useRef<HTMLInputElement>(null);
  
  React.useEffect(() => {
    if (dateRef.current === null) return;
  }, [dateRef.current?.value]);

  const csvData = dataTableSettings?.tableRows?.map((res) => {
    return {
      rowDate: res.rowDate,
      rowCpCode: res.rowCpCode,
      rowChargerPointId: res.rowChargerPointId,
      rowName: res.rowName,
      rowKwh: res.rowKwh,
      rowDuration: res.rowDuration,
      rowAmount: res.rowAmount,
    };
  });

  const headers = [
    { label: translation("transactions_TableHeaderDate"),           key: "rowDate" },
    { label: translation("transactions_TableHeaderCpCode"),         key: "rowCpCode" },
    { label: translation("transactions_TableHeaderChargerPointId"), key: "rowChargerPointId" },
    { label: translation("transactions_TableHeaderName"),           key: "rowName" },
    { label: translation("transactions_TableHeaderkWh"),            key: "rowKwh" },
    { label: translation("transactions_TableHeaderDuration"),       key: "rowDuration" },
    { label: translation("transactions_TableHeaderAmount"),         key: "rowAmount" },
  ];


  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              {translation("transactions_PageTitle")}
            </h1>
          </Header>

          <Content>
            <div className="content-header">
              <DatePickerField
                onChange={(dateRange: Date[], dateStr, instance) => {
                  if (dateRange === undefined || !dateRange[1]) return;

                  makeIsoTimestamp(dateRange);
                }}
                options={{
                  wrap: true,
                  mode: "range",
                  defaultDate: datesUpdated,
                  maxDate: new Date(),
                  locale: state?.userData?.language === "pt-BR" ? "pt" : "en",
                  dateFormat:
                    state?.userData?.language === "pt-BR" ? "d-m-Y" : "m-d-Y",
                }}
              >
                <input
                  type="text"
                  ref={dateRef}
                  value={dateRef.current?.value}
                  data-input
                  placeholder={translation("global_DatePickerPlaceholder")}
                />

                <Button
                  buttonColor={"dark"}
                  type={"button"}
                  data-clear
                  disabled={!pickerDate.start}
                  onClick={handleClearPeriod}
                >
                  {translation("global_DatePickerButtonClear")}
                </Button>
                
                <CSVLink
                  data={csvData ? csvData : ""}
                  filename={translation("transactions_ExportFileCSV")}
                  headers={headers}
                  style={{
                    width: 2,
                  }}
                >
                  <Button buttonColor={"blue"} type={"button"}>
                    {translation("users_ButtonCSV")}
                  </Button>
                </CSVLink>
              </DatePickerField>

              {/* <ButtonPDF
                type="button"
                onClick={() => console.log("generate pdf")}
              >
                download pdf
              </ButtonPDF> */}
            </div>

            {dataTableSettings.tableRows &&
              <DataTable
                columns={dataTableSettings.tableColumns}
                data={dataTableSettings.tableRows}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                progressPending={loadingData} // Exibe um loading textual enquanto propriedade for verdadeira
                noDataComponent={<DisplayNoData />} // Exibe um component ou texto customizável quando não ha dados para exibir
                progressComponent={<DisplayLoading />} // Exibe um loading de um componente customizável próprio. Em alguns casos, é necessário envolve-lo em um <div /> com paginação
                selectableRows={false} // Exibe/oculta uma check box para cada linha
                selectableRowsVisibleOnly={false} //
                selectableRowsHighlight={false} // Adiciona um highlight marcando a linha que está com selecionada no checkbox
                dense={false} // Compacta o grid de linhas para um estilo menor
                responsive // Responsividade da tabela em resoluções menores
                striped // Marca/pinta cada linha ímpar da tabela para melhor vizualização
                highlightOnHover // Adiciona um highlight quando mouse estiver em cima de uma linha
                // pointerOnHover // Exibe cursor em formato de "pointer" quando este estiver em cima de uma linha
                fixedHeader // Fixa o cabeçalho da tabela enquanto estiver rolando o grid
                persistTableHead // Exibe o cabeçalho da tabela mesmo quando as informações estiverem sendo carregadas
                pagination // Adiciona paginação
                paginationDefaultPage={1} // Página a ser exibida quando componente for carregado
                paginationComponentOptions={paginationSettings}
              />
            }
          </Content>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Transactions);
