import React from 'react';
import './styles.scss';

const ProgressBar = ({ label, percent }) => {

  const setUsageLoadColor = (value) => {
    if (value >= 85) return "red";
    if (value >= 70) return "orange";
    if (value >= 50) return "yellow";
    if (value >= 0) return "light-green";
  }

  return (
    <div className="custom-progress-bar mb-3">
      <div className="custom-progress-bar_title">
        <p className="font-size-18 mb-0">{label}</p>
      </div>

      <div className="custom-progress-bar_base d-flex">
        <div className={'custom-progress-bar_percent-bar ' + setUsageLoadColor(percent)} style={{ width: `${percent}%` }}>
          {percent >= 10 ? <span className="percent">{percent + "%"}</span> : null}
        </div>
        {percent < 10 ? <span className="percent mt-1 ms-2 fw-bold">{percent + "%"}</span> : null}
      </div>
    </div>
  );
};

export default ProgressBar;
