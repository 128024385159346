export const decodeServerMessage = (serverMessage: string) => {
  let message: string = "";

  switch (serverMessage) {
    case "ERR_USER_NOT_FOUND":
      message =
        "This user is not registered in our CMS, please try a valid e-mail.";
      break;
    case "REQUEST_FAILURE":
      message = "The request failed, please try again.";
      break;
    default:
      message =
        "There was a connection error with our server, please try again.";
      break;
  }

  return message;
};
