import "./Footer.scss";
import { withTranslation } from "react-i18next";
import LanguageDropdownLogin from "../CommonForBoth/TopbarDropdown/LanguageDropdownLogin";

import { Container, Content } from "./footer.styles";

interface FooterProps {
  t: Function;
  loginFooter: boolean;
  tintText?: boolean;
  darkText?: boolean;
  hasBackground?: boolean;
  setLanguageByExternalResource?: string;
}

const Footer = ({
  t,
  loginFooter,
  tintText = false,
  darkText,
  hasBackground = false,
  setLanguageByExternalResource,
}: FooterProps) => {
  const attClass = loginFooter ? " footer__login" : "";
  const year = new Date().getFullYear();

  return (
    <>
      <Container className={"footer" + attClass}>
        <Content darkenTextColor={tintText} hasBackground={hasBackground}>
          <span style={{color: darkText ? '' : 'white'}}>
            {year} {t("Spott - The future is here!")}
          </span>
          {loginFooter && (
            <LanguageDropdownLogin
              incomingLanguage={setLanguageByExternalResource}
            />
          )}
        </Content>
      </Container>
    </>
  );
};

export default withTranslation()(Footer);
