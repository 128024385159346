import "./NewSite.scss";
import React, { useState } from "react";
import CustomBreadCrumb from "../../components/BreadCrumb";
import InputMask from "react-input-mask";
import DropdownMenuRight from "../../components/DropdownMenuRight";
import { AxiosApi } from "../../helpers/api";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withTranslation } from "react-i18next";
import { ModalComponent } from "../../components/Modal";
import { withRouter, useHistory } from "react-router-dom";
import { FormatDateHelper, validaCep } from "../../helpers/Helpers";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
} from "reactstrap";
import { BanksList } from "../../common/data/BanksList";
import { useStateContext } from "../../context/ContextProvider";

const SiteInformation = (props) => {
  const [modalConfig, setModalConfig] = useState({ open: false });
  const [siteData, setSiteData] = useState({});
  const history = useHistory();
  const { context, state } = useStateContext();

  const inputHandleChange = (e) => {
    setSiteData({ ...siteData, [e.target.name]: e.target.value });
  };

  const cepOnBlurHandler = (e) => {
    const cep = e.target.value;
    if (!validaCep(cep)) return;

    //https://cors-anywhere.herokuapp.com/
    const resp = AxiosApi.get(
      `https://viacep.com.br/ws/${cep.replace(/-/i, "")}/json/`,
      {},
    )
      .then((response) => {
        setSiteData({
          ...siteData,
          csAddress: response.data.logradouro,
          csCity: response.data.localidade,
          csState: response.data.uf,
          csCountry: "Brasil",
        });
        lockFields(true);
      })
      .catch((error) => {
        setSiteData({
          ...siteData,
        });
        lockFields(false);
      });
  };

  const lockFields = (v) => {
    document.querySelector('[name="csAddress"]').readOnly = v;
    document.querySelector('[name="csCity"]').readOnly = v;
    document.querySelector('[name="csState"]').readOnly = v;
    document.querySelector('[name="csCountry"]').readOnly = v;
  };

  const submitHandler = async (e, values) => {
    const resp = AxiosApi.post(`/ChargerSites`, values)
      .then((response) => {
        openModalSuccess();
        context.sitesUpdate();
      })
      .catch((error) => {
        error;
        openModalError();
      });
  };

  const redirectToDashboard = () => history.push("/dashboard");
  const modalCloseHandler = () => setModalConfig({ open: false });

  const openModalSuccess = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Site created successfully"),
      body: `${props.t("Site")} ${siteData.csName} ${props.t(
        "created successfully",
      )}`,
      btOkText: "Ok",
      btOkStyle: "success",
      btCancelText: "",
      functionHandler: redirectToDashboard,
      closeHandler: "",
    });
  };

  const openModalError = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Error creating site"),
      body: `${props.t("There was an error trying to create the site")} ${
        siteData.csName
      }, ${props.t("please try again")}.`,
      btOkText: "Ok",
      btOkStyle: "danger",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb
                siteName={props.t("Register a new site")}
                title={props.t("New site")}
                subtitle={`${props.t("Last update")}: ${FormatDateHelper(
                  Date.now(),
                )}`}
              />
            </Col>

            <Col md="4">
              <div className="float-end d-none d-md-block">
                <DropdownMenuRight
                  settings={{
                    active: false,
                    buttom: { label: "Settings", color: "primary" },
                    items: [
                      { label: "Action", link: "#" },
                      { label: "Another action", link: "#" },
                      { label: "Something else here", link: "#" },
                      { label: "", link: "" },
                      { label: "Separated link", link: "#" },
                    ],
                  }}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xl={12} className="p-3">
            <p>{props.t("Insert the site information below.")}</p>
            <Card>
              <CardBody className="">
                <AvForm
                  className=""
                  onValidSubmit={(e, v) => {
                    submitHandler(e, v);
                  }}
                >
                  <Row>
                    <Col xl={8} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csName"
                          label={props.t("Site name")}
                          className="form-control"
                          placeholder={props.t("Site name")}
                          mask="99999-999"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site name",
                              ),
                            },
                          }}
                          value={siteData.csName}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={4} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csPostalCode"
                          label={props.t("CEP")}
                          className="form-control"
                          placeholder="00000-000"
                          mask="99999-999"
                          tag={[Input, InputMask]}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t("Your must inform a CEP"),
                            },
                            pattern: {
                              value: "/^[0-9]{5}-[0-9]{3}$/",
                              errorMessage: props.t(
                                "Your must inform a valid CEP",
                              ),
                            },
                          }}
                          value={siteData.csPostalCode}
                          onChange={(e) => inputHandleChange(e)}
                          onBlur={(e) => cepOnBlurHandler(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={9} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csAddress"
                          label={props.t("Address")}
                          className="form-control"
                          placeholder={props.t("Address")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site adress",
                              ),
                            },
                          }}
                          value={siteData.csAddress}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csNumber"
                          label={props.t("Number")}
                          className="form-control"
                          placeholder={props.t("Number")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site adress number",
                              ),
                            },
                          }}
                          value={siteData.csNumber}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={4} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csComplement"
                          label={props.t("Complement")}
                          className="form-control"
                          placeholder={props.t("Complement")}
                          type="text"
                          value={siteData.csComplement}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csCity"
                          label={props.t("City")}
                          className="form-control"
                          placeholder={props.t("City")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site city",
                              ),
                            },
                          }}
                          value={siteData.csCity}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csState"
                          label={props.t("State")}
                          className="form-control"
                          placeholder={props.t("State")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site state",
                              ),
                            },
                          }}
                          value={siteData.csState}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={2} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csCountry"
                          label={props.t("Country")}
                          className="form-control"
                          placeholder={props.t("Country")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site country",
                              ),
                            },
                          }}
                          value={siteData.csCountry}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={4} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csDocument"
                          label={props.t("CNPJ")}
                          className="form-control"
                          placeholder="00.000.000/0000-00"
                          mask="99.999.999/9999-99"
                          tag={[Input, InputMask]}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site CNPJ",
                              ),
                            },
                            pattern: {
                              value:
                                "/^[0-9]{2}.[0-9]{3}.[0-9]{3}/[0-9]{4}-[0-9]{2}$/",
                              errorMessage: "Your must inform a valid CNPJ",
                            },
                          }}
                          value={siteData.csDocument}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={8} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csCompanyName"
                          label={props.t("Company name")}
                          className="form-control"
                          placeholder={props.t("Company name")}
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: props.t(
                                "Your must inform the site company name",
                              ),
                            },
                          }}
                          value={siteData.csCompanyName}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl={6} className="p-3">
                      <FormGroup>
                        <AvField
                          name="csObs"
                          label={props.t("Obs")}
                          className="form-control"
                          type="textarea"
                          value={siteData.csObs}
                          onChange={(e) => inputHandleChange(e)}
                          rows={8}
                          maxlength="1000"
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={6} className="p-3">
                      <Row>
                        <Col xl={12} className="p-3">
                          <h4>{props.t("Company Bank Account")}</h4>
                          <FormGroup>
                            {/* <AvField
                                                            name="csBankName"
                                                            label={props.t("Bank name")}
                                                            className="form-control"
                                                            placeholder={props.t("Bank name")}
                                                            type="text"
                                                            validate={{
                                                                required: { value: true, errorMessage: props.t('Your must inform the bank name') },
                                                            }}
                                                            value={siteData.csBankName}
                                                            onChange={e => inputHandleChange(e)}
                                                        /> */}

                            <AvField
                              name="csBankName"
                              label={props.t("Bank name")}
                              className="form-control"
                              placeholder={props.t("Bank name")}
                              type="select"
                              // helpMessage="Select..."
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform the bank name",
                                  ),
                                },
                              }}
                              value={siteData.csBankName}
                              onChange={(e) => inputHandleChange(e)}
                            >
                              {BanksList.map((bank, index) => {
                                return (
                                  <option key={index} value={bank.Codigo}>
                                    {bank.Banco}
                                  </option>
                                );
                              })}
                            </AvField>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl={5} className="p-3">
                          <FormGroup>
                            <AvField
                              name="csBankAgency"
                              label={props.t("Agency number")}
                              className="form-control"
                              placeholder={props.t("Agency number")}
                              type="number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform the agency number",
                                  ),
                                },
                              }}
                              value={siteData.csBankAgency}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl={5} className="p-3">
                          <FormGroup>
                            <AvField
                              name="csBankAccount"
                              label={props.t("Account number")}
                              className="form-control"
                              placeholder={props.t("Account number")}
                              type="number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform the account number",
                                  ),
                                },
                              }}
                              value={siteData.csBankAccount}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                        <Col xl={2} className="p-3">
                          <FormGroup>
                            <AvField
                              name="csBankDv"
                              label={props.t("Digit")}
                              className="form-control"
                              placeholder={props.t("Digit")}
                              type="text"
                              value={siteData.csBankDv}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <div className="text-end mt-4">
                    {/* <Button id="previous" onClick={e => previousHandler(e)} color="light">{props.t('PREVIOUS')}</Button> */}
                    <Button
                      type="submit"
                      id="save-profile"
                      color="primary"
                      className="ms-3"
                    >
                      {props.t("REGISTER SITE")}
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ModalComponent config={modalConfig} />
      </Container>
    </div>
  );
};

export default withRouter(withTranslation()(SiteInformation));
