import { ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { IoIosClose } from "react-icons/io";
const { Modal: TopModal } = require("reactstrap");

import { Container, Header, CloseButton } from "./globalmodal.styles";

interface GlobalModalProps {
  t: Function;
  children: ReactNode;
  isModalOpen: boolean;
  size: "lg" | "sm";
  centered?: boolean;
  modalTitle?: string;
  titleDivisor?: boolean;
  closeButton?: boolean;
  onRequestClose: () => void;
}

export const GlobalModal = ({
  t: translator,
  children,
  isModalOpen,
  size,
  centered,
  modalTitle,
  titleDivisor,
  closeButton,
  onRequestClose,
}: GlobalModalProps) => {
  return (
    <TopModal
      isOpen={isModalOpen}
      centered={centered}
      fullscreen="sm"
      size={size}
    >
      <Container>
        <Header titleDivisor={titleDivisor}>
          {modalTitle && <p>{modalTitle}</p>}
          <IoIosClose onClick={onRequestClose} />
        </Header>

        {children}

        {closeButton && (
          <CloseButton onClick={onRequestClose}>
            {translator("Close")}
          </CloseButton>
        )}
      </Container>
    </TopModal>
  );
};

export default withTranslation()(GlobalModal);
