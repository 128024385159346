import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";
import { BsExclamationDiamond } from "react-icons/bs";
const { Spinner } = require("reactstrap");

import * as Provider from "../../../../context/ContextProvider";
import { AxiosApi as api } from "../../../../helpers/api";

import AmountDisplayItem from "./components/AmountDisplayItem/AmountDisplayItem";
import AmountExtractModal from "./components/AmountExtractModal/AmountExtractModal";

import {
  PaymentTypes,
  OrderData,
  OrderItens,
} from "../../../../interfaces/Payment";
import { ExtractModalTypes } from "../../../../interfaces/Modal";

import {
  Container,
  Display,
  DisplayContentDataRefer,
  NoCardData,
} from "./amount.styles";
import { math } from "polished";

type AmountPropsType = {
  t: Function;
};

type LoadingApiDataStateType = {
  paymentOpenDataLoading: boolean;
  historyDataLoading: boolean;
};

enum OrderItemMode {
  SPOTT_EV_CHARGE_KWH = "SPOTT_EV_CHARGE_KWH",
  OPERATION_FEE = "OPERATION_FEE",
  SPOTT_FEE = "SPOTT_FEE",
}

export const Amount = ({ t: translation }: AmountPropsType) => {
  const { site_id }: any = RouterDom.useParams();
  const { state }: any = Provider.useStateContext();

  const [loadingData, setLoadingData] = React.useState<LoadingApiDataStateType>(
    {
      paymentOpenDataLoading: false,
      historyDataLoading: false,
    },
  );

  const [extractModalSettings, setExtractModalSettings] =
    React.useState<ExtractModalTypes>({
      modalIsOpen: false,
      extractDate: "",
      extractTotalTransacted: 0,
      extractTotalTransfers: 0,
      extractFeesAndCommissions: 0,
      extractSpottComission: 0,
      extractSpottTax: 0,
      extractTransactionFee: 0,
      extractTransactionFeeTax: 0,
      extractMonthlyPayment: 0,
      onRequestCloseModal: (): void => {},
    });

  const [paymentOpenApiData, setPaymentOpenApiData] =
    React.useState<PaymentTypes>({} as PaymentTypes);
  const [paymentHistoryApiData, setPaymentHistoryApiData] = React.useState<
    PaymentTypes[]
  >([]);
  // Functions
  const handleOpenExtractModal = () => {
    setExtractModalSettings((prev) => ({
      ...prev,
      modalIsOpen: true,
    }));
  };
  const handleCloseExtractModal = () => {
    setExtractModalSettings((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  const configureExtractModal = (orderId?: string) => {
    const mutablePaymentOpen: PaymentTypes =
      structuredClone(paymentOpenApiData);
    const mutablePaymentHistory: PaymentTypes[] = structuredClone(
      paymentHistoryApiData,
    );

    const payment = mutablePaymentHistory.find(
      (transaction) => transaction.orderId === orderId,
    );

    const modalSettings = structuredClone(payment ?? mutablePaymentOpen);
    const orderData: OrderData = JSON.parse(modalSettings.order?.orderData);
    const orderItens: OrderItens[] = modalSettings.order?.orderItems;

    const spottFeeTaxAmount = orderItens.find(
      (order) => order?.orderItemDesc === OrderItemMode.SPOTT_FEE,
    );
    const operationFeeTaxAmount = orderItens.find(
      (order) => order?.orderItemDesc === OrderItemMode.OPERATION_FEE,
    );
    const spottEvChargeTaxAmount = orderItens.find(
      (order) => order?.orderItemDesc === OrderItemMode.SPOTT_EV_CHARGE_KWH,
    );

    if (
      !spottFeeTaxAmount ||
      !operationFeeTaxAmount 
      // || !spottEvChargeTaxAmount
    ) {
      return;
    }

    setExtractModalSettings((prev) => ({
      ...prev,
      extractDate: modalSettings.period,
      extractTotalTransacted: modalSettings.totalAmount,
      extractTotalTransfers: modalSettings.netAmount,
      extractFeesAndCommissions: modalSettings.discounts,
      extractSpottComission: Math.abs(spottFeeTaxAmount.orderItemPrice),
      extractTransactionFee: Math.abs(operationFeeTaxAmount.orderItemPrice),
      extractSpottTax: orderData.csBillingPlan.BillingPlanSpottFeePercent,
      extractTransactionFeeTax:
        orderData.csBillingPlan.BillingPlanFinFeePercent,
      extractMonthlyPayment: orderData.csBillingPlan.BillingPlanMonthlyFee,
      onRequestCloseModal: handleCloseExtractModal,
    }));

    handleOpenExtractModal();
  };

  // API Callers
  const getOpenValuesApiData = async () => {
    try {
      setLoadingData((prev) => ({
        ...prev,
        paymentOpenDataLoading: true,
      }));

      const { data } = await api.get<PaymentTypes>(
        `payment/OpenSiteMontEndClose?siteId=${site_id}`,
      );
      setPaymentOpenApiData(data);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData((prev) => ({
        ...prev,
        paymentOpenDataLoading: false,
      }));
    }
  };

  const getHistoryApiData = async () => {
    try {
      setLoadingData((prev) => ({
        ...prev,
        historyDataLoading: true,
      }));

      const { data } = await api.get<PaymentTypes[]>(
        `payment/SiteMontEndClose?siteId=${site_id}`,
      );

      const mutableData: PaymentTypes[] = [...data];
      const sortedData: PaymentTypes[] = mutableData.sort((dataA, dataB) => {
        if (dataA.period < dataB.period) return 1;
        if (dataA.period > dataB.period) return -1;

        return 0;
      });
      setPaymentHistoryApiData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData((prev) => ({
        ...prev,
        historyDataLoading: false,
      }));
    }
  };

  React.useMemo(() => {
    if (!site_id || !state.sites) return;

    const pageSite = state.sites.find((site: any) => site.csId === site_id);

    if (!pageSite) return;

    getOpenValuesApiData();
    getHistoryApiData();
  }, [site_id, state.sites]);

  return (
    <>
      <Container>
        <Display>
          {loadingData.paymentOpenDataLoading &&
            loadingData.historyDataLoading && (
              <DisplayContentDataRefer>
                <Spinner />
              </DisplayContentDataRefer>
            )}

          {!loadingData.paymentOpenDataLoading &&
            !loadingData.historyDataLoading && (
              <>
                {!Object.keys(paymentOpenApiData).length &&
                paymentHistoryApiData.length === 0 ? (
                  <DisplayContentDataRefer>
                    <BsExclamationDiamond />
                    <p>{translation("global_NoDataToDisplay")}</p>
                  </DisplayContentDataRefer>
                ) : (
                  <>
                    {!Object.keys(paymentOpenApiData).length ? (
                      <NoCardData style={{ padding: "4rem 0" }}>
                        <p>{translation("finance_AmountNoPaymentOpen")}</p>
                      </NoCardData>
                    ) : (
                      <>
                        <AmountDisplayItem
                          isPaid={false}
                          onRequestOpenModal={configureExtractModal}
                          netAmount={paymentOpenApiData.netAmount}
                          totalAmount={paymentOpenApiData.totalAmount}
                          period={paymentOpenApiData.period}
                          scheduledPaymentDate={
                            paymentOpenApiData.scheduledPaymentDate
                          }
                        />
                      </>
                    )}

                    {paymentHistoryApiData.length === 0 ? (
                      <NoCardData>
                        <p>{translation("finance_AmountNoPaymentHistory")}</p>
                      </NoCardData>
                    ) : (
                      paymentHistoryApiData.map((item) => {
                        const displaySettings = {
                          key: item.orderId,
                          isPaid: true,
                          onRequestOpenModal: (): void =>
                            configureExtractModal(item.orderId),
                          netAmount: item.netAmount,
                          totalAmount: item.totalAmount,
                          orderInvoiceUrl: item.order.orderInvoiceUrl,
                          orderState: item.order.orderState,
                          orderUpdated: item.order.orderUpdated,
                          orderPaymentSchedule: item.order.orderPaymentSchedule,
                          scheduledPaymentDate: item.scheduledPaymentDate,
                          orderStateTimestamp: item.order.orderStateTimestamp,
                          period: item.period,
                          
                        };

                        return <AmountDisplayItem {...displaySettings} />;
                      })
                    )}
                  </>
                )}
              </>
            )}
        </Display>
      </Container>

      {/* Modals */}
      {extractModalSettings.modalIsOpen && (
        <AmountExtractModal {...extractModalSettings} />
      )}
    </>
  );
};
export default Translator.withTranslation()(Amount);
