import React from "react";
import logo from "../../../../assets/images/logo.png";
import { withTranslation } from "react-i18next";

const Header = (props) => (
  <>
    <div className="login-header d-sm-flex align-items-center justify-content-center bg-primary py-5">
      <img src={logo} alt="Spott" border="0" style={{ marginBottom: 35 }} />
    </div>
  </>
);

export default withTranslation()(Header);
