import PropTypes from "prop-types";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Container, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import InputMask from "react-input-mask";
import { Link, useHistory, Redirect } from "react-router-dom";
import Header from "./components/Header";
import Footer from "../../components/VerticalLayout/Footer";
import Progress from "./components/Progress";
import { validaCep } from "../../helpers/Helpers";
import { AxiosApi } from "../../helpers/api";
import { ModalComponent } from "../../components/Modal";
import { withTranslation } from "react-i18next";
//import { isPlainObject } from 'lodash';
//import { registerUser, apiError, registerUserFailed } from '../../store/actions';
//import { connect } from 'react-redux';

const Register = (props) => {
  const [step, setStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [modalConfig, setModalConfig] = useState({ open: false });
  const history = useHistory();

  const handleValidSubmit = (event, values) => {
    if (step === 3) {
      const navLang =
        navigator.language === "pt-BR" || navigator.language === "en-US"
          ? navigator.language
          : "en-US";
      userData.language = navLang;

      const resp = AxiosApi.post(`/userregister`, userData)
        .then((response) => {
          openModalSuccess();
        })
        .catch((error) => {
          openModalError();
        });
      return;
    }
    setStep(step + 1);
  };

  const inputHandleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const titles = [
    "Please insert your information",
    "Please insert your address",
    "Terms and Condition",
  ];

  const cepOnBlurHandler = (e) => {
    const cep = e.target.value;
    if (!validaCep(cep)) return;

    //https://cors-anywhere.herokuapp.com/
    const resp = AxiosApi.get(
      `https://viacep.com.br/ws/${cep.replace(/-/i, "")}/json/`,
      {},
    )
      .then((response) => {
        setUserData({
          ...userData,
          Address: response.data.logradouro,
          City: response.data.localidade,
          State: response.data.uf,
          Country: "Brasil",
        });
      })
      .catch((error) => {
        setUserData({
          ...userData,
          Address: "",
          City: "",
          State: "",
          Country: "",
        });
      });
  };

  const redirectToLogin = () => history.push("/login");
  const modalCloseHandler = () => setModalConfig({ open: false });

  const openModalSuccess = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("User created successfully"),
      body: `${props.t("User")} ${userData.Name} ${props.t(
        "created successfully, the instructions to set a password will be sent to",
      )} ${userData.Email}`,
      btOkText: "Ok",
      btOkStyle: "success",
      btCancelText: "",
      functionHandler: redirectToLogin,
      closeHandler: "",
    });
  };

  const openModalError = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Error creating user"),
      body: `${props.t("There was an error trying to create the user")} ${
        userData.Name
      }, ${props.t("please try again")}.`,
      btOkText: "Ok",
      btOkStyle: "danger",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  return (
    <>
      <MetaTags>
        <title>
          {props.t("Register | Spott | Manage your recharge points")}
        </title>
      </MetaTags>

      <Header />

      <div className="account-pages pt-0" style={{ marginTop: "-40px" }}>
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={6} xl={4} xxl={3}>
              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <Progress step={step} />
                  <div className="mt-4">
                    <p className="font-size-29 text-info mb-0">
                      {titles[step - 1]}
                    </p>
                  </div>

                  {step === 1 && (
                    <div>
                      <AvForm
                        className="mt-4"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        {/* {props.registrationError && props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null} */}

                        <div className="mb-3">
                          <AvField
                            name="Name"
                            label={props.t("Your name")}
                            className="form-control"
                            placeholder={props.t("Insert your full name")}
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your name",
                                ),
                              },
                            }}
                            value={userData.Name}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-4">
                          <AvField
                            name="Email"
                            label={props.t("Your e-mail")}
                            className="form-control"
                            placeholder={props.t("Insert your e-mail")}
                            type="email"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your e-mail",
                                ),
                              },
                            }}
                            value={userData.Email}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            id="reemail"
                            name="reemail"
                            label=""
                            className="form-control"
                            placeholder={props.t("Reenter your email")}
                            type="email"
                            validate={{ match: { value: "Email" } }}
                            value={userData.Reemail}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Cpf"
                            label={props.t("CPF")}
                            type="text"
                            placeholder={props.t("Insert your document")}
                            mask="999.999.999-99"
                            tag={[Input, InputMask]}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t("Your must inform a CPF"),
                              },
                              pattern: {
                                value:
                                  "/^([0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}|[0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2})$/",
                                errorMessage: props.t(
                                  "Your must inform a valid CPF",
                                ),
                              },
                            }}
                            value={userData.Cpf}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Birthdate"
                            label={props.t("Birth date")}
                            type="date"
                            placeholder={props.t("Insert your birth date")}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your birth date",
                                ),
                              },
                            }}
                            value={userData.Birthdate}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="PhoneNumber"
                            label={props.t("Mobile")}
                            type="text"
                            placeholder="(99) 99999-9999"
                            mask="(99) 99999-9999"
                            tag={[Input, InputMask]}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your mobile number",
                                ),
                              },
                            }}
                            value={userData.PhoneNumber}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-full waves-effect waves-light"
                              type="submit"
                            >
                              {props.t("Next")}
                            </button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  )}

                  {step === 2 && (
                    <div>
                      <AvForm
                        className="mt-4"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        {/* {props.registrationError && props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null} */}

                        <div className="d-flex justify-content-between">
                          <div className="mb-3 col-4">
                            <AvField
                              name="Cep"
                              label={props.t("CEP")}
                              type="text"
                              mask="99999-999"
                              placeholder="99999-999"
                              tag={[Input, InputMask]}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform a CEP",
                                  ),
                                },
                                pattern: {
                                  value: "/^[0-9]{5}-[0-9]{3}$/",
                                  errorMessage: props.t(
                                    "Your must inform a valid CEP",
                                  ),
                                },
                              }}
                              value={userData.Cep}
                              onChange={(e) => inputHandleChange(e)}
                              onBlur={(e) => cepOnBlurHandler(e)}
                            />
                          </div>

                          {/* <div className="col-8">
                            <Link to="/forgot-password" className="remember-cep mt-4 pt-2 mx-4 d-block">
                              I don’t remember my CEP
                            </Link>
                          </div> */}
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="Address"
                            label={props.t("Your Address")}
                            className="form-control"
                            placeholder={props.t("Insert your address")}
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform a address",
                                ),
                              },
                            }}
                            value={userData.Address}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="mb-3 col-6 px-1 pl-0">
                            <AvField
                              name="Number"
                              label={props.t("Number")}
                              className="form-control"
                              placeholder={props.t("Insert number")}
                              type="number"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform a address number",
                                  ),
                                },
                              }}
                              value={userData.Number}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </div>

                          <div className="mb-3 col-6 px-1 pr-0">
                            <AvField
                              name="complement"
                              label={props.t("Complement")}
                              type="text"
                              placeholder={props.t("Insert your complement")}
                              value={userData.Complement}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="mb-3 col-6 px-1 pl-0">
                            <AvField
                              name="Country"
                              label={props.t("Country")}
                              className="form-control"
                              placeholder={props.t("Country")}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform a country",
                                  ),
                                },
                              }}
                              value={userData.Country}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </div>
                          <div className="mb-3 col-6 px-1 pr-0">
                            <AvField
                              name="State"
                              label={props.t("State")}
                              className="form-control"
                              placeholder={props.t("State")}
                              type="text"
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Your must inform your state",
                                  ),
                                },
                              }}
                              value={userData.State}
                              onChange={(e) => inputHandleChange(e)}
                            />
                          </div>
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="City"
                            label={props.t("City")}
                            className="form-control"
                            placeholder={props.t("City")}
                            type="text"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your city",
                                ),
                              },
                            }}
                            value={userData.City}
                            onChange={(e) => inputHandleChange(e)}
                          />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-full waves-effect waves-light"
                              type="submit"
                            >
                              {props.t("Next")}
                            </button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  )}

                  {step === 3 && (
                    <div>
                      <AvForm
                        className="mt-4"
                        onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                      >
                        {/* {props.user && success ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null} */}

                        {/* {props.registrationError && props.registrationError ? (
                          <Alert color="danger">
                            {props.registrationError}
                          </Alert>
                        ) : null} */}

                        <div
                          className="mb-3"
                          style={{ height: "300px", background: "#d3d3d3" }}
                        ></div>

                        <div className="d-flex mb-3">
                          <AvField
                            name="TermAndConditions"
                            type="checkbox"
                            label={props.t(
                              "I have read and agree to the Spott Terms and Conditions",
                            )}
                            trueValue={true}
                            falseValue={false}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must agree with the Terms and Conditions to proceed",
                                ),
                              },
                            }}
                          />
                        </div>

                        <div className="mb-3 row">
                          <div className="col-12 text-end">
                            <button
                              className="btn btn-primary w-full waves-effect waves-light"
                              type="submit"
                            >
                              {props.t("Agree")}
                            </button>
                          </div>
                        </div>
                      </AvForm>
                    </div>
                  )}
                </CardBody>
              </Card>

              <div className="mt-5 text-center">
                <p>
                  {props.t("Already have an account ?")}{" "}
                  <Link to="/login" className="fw-medium text-primary">
                    {" "}
                    {props.t("Login")}
                  </Link>{" "}
                </p>
              </div>
            </Col>
          </Row>

          <ModalComponent config={modalConfig} />
        </Container>
      </div>
    </>
  );
};

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
};

// const mapStatetoProps = state => {
//   const { user, registrationError, loading } = state.Account;
//   return { user, registrationError, loading };
// };

export default withTranslation()(Register);
