import React from "react";
import { withTranslation } from "react-i18next";

import Switch from "react-switch";
import TogglerIcon from "../../Renders/TogglerIcon/TogglerIcon";

interface AutoUpdateFirmware {
  t: Function;
}

function AutoUpdateFirmware({ t }: AutoUpdateFirmware) {
  const [isAutoUpdate, setIsAutoUpdate] = React.useState<boolean>(false);

  const dispatchChangeSwitch = (value: boolean) => {
    setIsAutoUpdate(value);
  };

  return (
    <label
      htmlFor="update-switch"
      className="d-flex justify-content-center align-items-center mx-2 mx-md-0"
    >
      <Switch
        id="update-switch"
        width={52}
        onChange={dispatchChangeSwitch}
        checked={isAutoUpdate}
        className="react-switch"
        uncheckedIcon={<TogglerIcon icon={"Off"} />}
        checkedIcon={<TogglerIcon icon={"On"} />}
        onColor="#2FD475"
        
      />
      <span className="mx-2 font-size-16">{t("Auto Update Firmware")}</span>
    </label>
  );
}

export default withTranslation()(AutoUpdateFirmware);
