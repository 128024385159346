import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";

import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../components/Displays/DisplayLoading/DisplayLoading";
import ModalAddUser, {
  modalFormTypes,
} from "./components/ModalAddUser/ModalAddUser";
import { ModalAction } from "../../components/Modal/ModalAction/ModalAction";

import { AuthUserTypes } from "../../interfaces/AuthUser";
import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes, SiteUsersTypes } from "../../interfaces/SiteUsers";
import { ModaActionlTypes } from "../../interfaces/Modal";
import { RowTypes } from "../../interfaces/DataTable";

import {
  Component,
  Wrapper,
  Header,
  Content,
  ButtonAddUser,
} from "./permissions.styles";
import { Button } from "../../components/Button/Button";

type PermissionsPropsTypes = {
  t: Function;
};

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

type DataRowTypes = {
  rowEmail: RowTypes;
  rowIcon: RowTypes;
  rowName: RowTypes;
  rowStatus: RowTypes;
  rowRole: RowTypes;
};

export const Permissions = ({ t: translation }: PermissionsPropsTypes) => {
  const currentUser: AuthUserTypes = JSON.parse(
    localStorage.getItem("authUser")!,
  );
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");

  const dataTableColumns: TableColumn<DataRowTypes>[] = [
    {
      name: translation("permissions_TableHeaderName"),
      selector: (row: any) => row.rowName,
      sortable: true,
    },
    {
      name: translation("permissions_TableHeaderEmail"),
      selector: (row: any) => row.rowEmail,
      sortable: true,
    },
    {
      name: translation("permissions_TableHeaderRole"),
      selector: (row: any) => row.rowRole,
      sortable: true,
    },
    {
      name: translation("permissions_TableHeaderStatus"),
      selector: (row: any) => row.rowStatus,
      sortable: true,
    },
    {
      name: "",
      selector: (row: any) => row.rowIcon,
      sortable: false,
    },
  ];
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [usersAccessData, setUsersAccessData] = React.useState<
    SiteUsersTypes[]
  >([]);

  const [dataTableSettings, setDataTableSettings] =
    React.useState<DataTableTypes>({} as DataTableTypes);
  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => { },
    modalOnRequestClose: (): void => { },
  });
  const [addUserModal, setAddUserModal] = React.useState({
    modalIsOpen: false,
    modalHandleClose: (): void => { },
    modalFormSubmit: (payload: modalFormTypes): void => { },
  });

  // Functions
  const handleOpenActionModal = (userName: string, userId: number) => {
    const RenderStyledElement = (): JSX.Element => {
      return (
        <>
          {translation("permissions_ModalActionText")}{" "}
          <span
            className={"text-danger"}
            style={{
              textDecoration: "underline",
              textUnderlinePosition: "under",
            }}
          >
            {userName}
          </span>{" "}
          ?
        </>
      );
    };

    setModalAction((prev) => ({
      modalIsOpen: true,
      modalTitle: translation("permissions_ModalActionTitle"),
      modalInfo: <RenderStyledElement />,
      modalButtonConfirmText: translation("global_Yes"),
      modalButtonCancelText: translation("global_No"),
      modalOnConfirmAction: () => onDeleteUser(userId),
      modalOnRequestClose: handleCloseActionModal,
    }));
  };
  const handleCloseActionModal = () => {
    setModalAction((prev) => ({ ...prev, modalIsOpen: false }));
  };
  const handleOpenAddUserModal = () => {
    setAddUserModal((prev) => ({
      ...prev,
      modalIsOpen: true,
      modalFormSubmit: onAddUser,
      modalHandleClose: handleCloseAddUserModal,
    }));
  };
  const handleCloseAddUserModal = () => {
    setAddUserModal((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  const buildDataTable = () => {
    const mutablePermissionsData = structuredClone(usersAccessData);

    const dataTableRows = mutablePermissionsData.map((user: SiteUsersTypes) => {
      const loggedUserAccessing: boolean =
        currentUser.user?.id === user.uspAnuId;
      const checkUserConfirmedEmail = user.uspAnu.emailConfirmed
        ? translation("Email confirmed")
        : translation("Wait confirmation");


      let roleTranslateDict: { [key: string]: string; } = {
        ADMIN: 'permissions_ModalAddUserDroplistOptionAdmin',
        VIEWER: 'permissions_ModalAddUserDroplistOptionViewer',
        ENGINEER: 'permissions_ModalAddUserDroplistOptionEngineer',
        PARTNER: 'permissions_ModalAddUserDroplistOptionPartner',
      }

      const translatedRole = translation(roleTranslateDict[user.uspRole]);

      const RenderStyledElement = (key: string): JSX.Element => {
        return (
          <>
            <div
              {...(loggedUserAccessing && { className: "text-danger" })}
              {...(loggedUserAccessing && {
                style: {
                  textDecoration: "underline",
                  textTransform: "uppercase",
                  fontWeight: "600",
                },
              })}
            >
              {key}
            </div>
          </>
        );
      };
      const RenderDeleteIconTag = (): JSX.Element => {
        return (
          <img
            src={require("../../assets/images/delete-close.png")}
            className="remove-bt"
            alt="Remove"
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleOpenActionModal(user.uspAnu?.userName, user.uspId)
            }
          />
        );
      };

      // const email = user.uspAnu?.userName
      //   ? RenderStyledElement(user.uspAnu?.userName)
      //   : emptyData;
      // const icon = !loggedUserAccessing && user.uspAnu?.userName && (
      //   <RenderDeleteIconTag />
      // );
      // const name = user.uspAnu?.name
      //   ? RenderStyledElement(user.uspAnu?.name)
      //   : emptyData;
      // const status = RenderStyledElement(checkUserConfirmedEmail);
      const email = user.uspAnu?.email ?? emptyData;
      const icon = !loggedUserAccessing && user.uspAnu?.userName && (
        <RenderDeleteIconTag />
      );
      const name = user.uspAnu?.name ?? emptyData;
      const status = checkUserConfirmedEmail;
      const role = translatedRole;
      return {
        rowEmail: email,
        rowIcon: icon,
        rowName: name,
        rowStatus: status,
        rowRole: role,
      };
    });

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };

  // Api data
  const getSiteUsersAccess = async () => {
    try {
      setLoadingData(true);

      const { data } = await api.get<SiteUsersTypes[]>(`/SiteUsers/${site_id}`);

      const mutableData: SiteUsersTypes[] = structuredClone(data);
      const sortedData: SiteUsersTypes[] = mutableData.sort((dataA, dataB) => {
        if (dataA.uspAnu?.userName > dataB.uspAnu?.userName) return 1;
        if (dataA.uspAnu?.userName < dataB.uspAnu?.userName) return -1;

        return 0;
      });

      setUsersAccessData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };
  const onDeleteUser = async (userId: number) => {
    try {
      const { status } = await api.delete(`/UserSitePermissions/${userId}`);

      if (status === 200 || status === 204) console.log(status, "SUCCESS");
    } catch (error: unknown) {
      console.log(error);
    } finally {
      getSiteUsersAccess();
      handleCloseActionModal();
    }
  };
  const onAddUser = async (formValues: modalFormTypes) => {
    try {
      const { email, select } = formValues;
      const uppercaseValue = select ? String(select).toUpperCase() : "ADMIN";

      const payload = {
        CsId: siteAccess?.csId,
        UserName: email,
        Role: uppercaseValue,
      };

      const { status } = await api.post(`/UserSitePermissions/`, payload);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      getSiteUsersAccess();
      handleCloseAddUserModal();
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getSiteUsersAccess();
  }, [state.sites, site_id]);

  React.useMemo(() => {
    buildDataTable();
  }, [usersAccessData, state?.userData?.language]);

  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              {translation("permissions_PageTitle")}
            </h1>
          </Header>

          <Content>
            <DataTable
              columns={dataTableSettings.tableColumns}
              data={dataTableSettings.tableRows}
              progressPending={loadingData}
              noDataComponent={<DisplayNoData />}
              progressComponent={<DisplayLoading />}
              selectableRows={false}
              selectableRowsVisibleOnly={false}
              selectableRowsHighlight={false}
              dense={false}
              responsive
              striped
              highlightOnHover
              // pointerOnHover
              fixedHeader
              persistTableHead
              pagination
              paginationDefaultPage={1}
              paginationComponentOptions={paginationSettings}
            />

            <Button type={"button"} onClick={handleOpenAddUserModal}>
              {translation("permissions_ButtonAddUser")}{" "}
            </Button>
          </Content>
        </Wrapper>
      </Component>

      {/*Modals*/}
      {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      {addUserModal.modalIsOpen && <ModalAddUser {...addUserModal} />}
    </>
  );
};

export default Translator.withTranslation()(Permissions);
