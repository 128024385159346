import { withTranslation } from "react-i18next";

import Footer from "../../../components/VerticalLayout/Footer";
const logoImg = require("../../../assets/images/Logo_Spott_2colors.png");

import {
  Container,
  Header,
  Content,
  Display,
  SuccessBoundary,
  FloatingFooter,
} from "./confirmationemailapp.styles";

interface ConfirmationEmailAppProps {
  t: Function;
}

const ConfirmationEmailApp = ({ t: translator }: ConfirmationEmailAppProps) => {
  // const sitePath =
  //   "https://spott.com/?product=charger&model=EVC2&culture=pt-BR";
  const url = new URL(window.location.href);
  const hashParam = url.searchParams.get("culture");

  return (
    <>
      <Container>
        <Header>
          <img src={logoImg} alt="logo" />
        </Header>

        <Content>
          <Display>
            <SuccessBoundary>
              <h1>{translator("Ready!")}</h1>
              <h1>{translator("Your account has been created")}</h1>
              <p>{translator("You can go back to the Spott app and Log in")}</p>
            </SuccessBoundary>
            <FloatingFooter>
              <Footer
                darkText={true}
                loginFooter={true}
                tintText={true}
                setLanguageByExternalResource={hashParam || "pt-BR"}
              />
            </FloatingFooter>
          </Display>
        </Content>
      </Container>
    </>
  );
};

export default withTranslation()(ConfirmationEmailApp);
