import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

const background = require("../../assets/images/development/dev-wallpaper.png");

export const Wrapper = styled.div`
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: auto;
  width: 100%;
  height: 100vh;
  padding: 1rem;
`;

export const Display = styled.div`
  width: 100%;
  height: 100%;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.18);
  border-radius: 8px;
`;
