import styled from "styled-components";
import { transparentize, lighten, darken, rgb } from "polished";

type PriceAligmentPropsType = {
  tintItem?: boolean;
};
type ButtonsAligmentPropsType = {
  tintItem: string;
};

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  background-color: white;
  width: 100%;
  height: 8.8rem;
  margin: 0 auto;

  border: 0;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 1100px) {
    height: auto;
    padding: 15px 0px;
  }
`;

export const PriceAligment = styled.div<PriceAligmentPropsType>`
  flex: 1;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 1100px) {
    display: grid;
    grid-gap: 0;
    grid-template-columns: auto auto;
  }

  div {
    flex: 1;
    height: 100%;
    padding: 1rem;
    position: relative;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0.8rem;

    & + div {
      background-color: ${({ tintItem }) =>
        transparentize(0.88, tintItem ? "#34C05E" : "#565656")};

      border: 0;
      border-radius: 0.4rem;

      p {
        font-size: 1.16rem;
        line-height: 1.16rem;
        word-wrap: break-word;
        color: ${({ tintItem }) => tintItem && "#34C05E"};
      }

      h2 {
        font-size: 2.08rem;
        line-height: 2.08rem;
        word-wrap: break-word;
        color: ${({ tintItem }) => tintItem && "#34C05E"};

        @media (max-width: 1100px) {
          font-size: 25px;
        }
      }
    }

    p {
      color: #565656;
      margin: 0;

      font-style: normal;
      font-weight: 500;
      font-size: 1.08rem;
      line-height: 1.08rem;
      text-align: left;
      text-transform: none;
      word-wrap: break-word;
    }

    h2 {
      color: #565656;

      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 2rem;
      text-align: left;
      text-transform: capitalize;
      word-wrap: break-word;

      @media (max-width: 1100px) {
        font-size: 25px;
      }
    }

    small {
      color: #565656;

      position: absolute;
      top: 0.6rem;
      left: 1rem;

      font-style: normal;
      font-weight: 700;
      font-size: 1rem;
      line-height: 1rem;
      text-align: left;
      text-transform: none;
      word-wrap: break-word;

      @media (max-width: 1100px) {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }

`;

export const ButtonsAligment = styled.div<ButtonsAligmentPropsType>`
  flex: 1;
  height: 100%;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  /* gap: 1rem; */

  small {
    color: ${({ tintItem }) =>
      tintItem === "paid"
        ? "#34C05E"
        : tintItem === "created"
        ? lighten(0.15, "#0D5190")
        : lighten(0.15, "red")};

    position: absolute;
    bottom: 0.6rem;
    right: 1rem;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: right;
    text-transform: none;
    word-wrap: break-word;

    @media (max-width: 1100px) {
      position: relative;
      bottom: 0;
      right: 0;
      line-height: inherit;
      font-size: .9rem;
    }
  }

  button {
    margin: 0 0.5rem;
    width: 12rem;
  }

  @media (max-width: 1366px) {
    flex-direction: column;
    justify-content: center;
    gap: 0.6rem;

    button {
      margin: 0;
    }
  }
`;

export const BaseButton = styled.button`
  background-color: #666;
  color: white;
  width: 12rem;
  padding: 0.8rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.12);

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const NoteButton = styled(BaseButton)`
  background-color: #3970f3;
`;

export const ExtractButton = styled(BaseButton)`
  background-color: #6c6c6c;
`;

export const HRefButton = styled.a`
  background-color: #3970f3;
  color: white;
  width: 12rem;
  padding: 0.8rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.12);

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;

  &:hover {
    color: white;
    filter: brightness(0.9);
  }
`;
