import styled from "styled-components";
import { transparentize, rgba, darken, lighten } from "polished";

interface LabelProps {
  isFocusingField: boolean;
}

interface SubmitButtonProps {
  isSendingPayload: boolean;
}

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Header = styled.header`
  background-color: #2168d3;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;

  img {
    padding: 1rem;
    margin: 0 auto;
    display: block;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Display = styled.div`
  background-color: white;

  width: 32rem;
  height: 32rem;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.8rem;

  // * Activate here
  position: absolute;
  top: 8.8rem;
  left: 50%;
  transform: translate(-50%, 0);

  border: 1px solid rgba(0, 0, 0, 0.128);
  border-radius: 2rem;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.128);

  transition: all 300ms;
  &:hover {
    border-color: ${transparentize(0.6, "#2168d3")};
    box-shadow: 0 0 8px 0 ${transparentize(0.6, "#2168d3")};
  }

  h1 {
    color: #3970f3;
    margin-top: 2rem;

    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
  }

  @media (max-width: 480px) {
    width: 90%;

    h1 {
      color: #3970f3;
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: inherit;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: rgba(0, 0, 0, 0.428);
    margin: 0;
    padding: 0;

    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    word-wrap: break-word;
    line-height: 1.28rem;
    text-transform: none;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

export const Label = styled.label<LabelProps>`
  background-color: transparent;
  width: 100%;
  height: 3.28rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 0.6rem;

  /* border: 1px solid
    ${({ isFocusingField }) =>
    isFocusingField ? transparentize(0.38, "#2168d3") : rgba(0, 0, 0, 0.28)};
  border-radius: 0.25rem; */
  /* box-shadow: 0 0 8px 0
    ${({ isFocusingField }) =>
    isFocusingField ? transparentize(0.08, "#2168d3") : rgba(0, 0, 0, 0.28)}; */
  border: 0;
  overflow: hidden;

  transition: all 300ms;

  &:nth-child(3) {
    margin-top: 2rem;
  }

  input {
    background-color: none;
    color: rgba(0, 0, 0, 0.68);
    width: 100%;
    height: inherit;
    padding-left: 1rem;

    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 0.6rem;
    /* border-left: none;
    border-right: none; */

    text-align: left;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1rem;
    text-transform: none;
  }
  input::placeholder{
    color: #00000055;
  }

  svg {
    color: rgba(0, 0, 0, 0.48);
    width: 1.28rem;
    height: 1.28rem;
    /* margin-right: 1rem; */
    cursor: pointer;

    transition: all 300ms;
    &:hover {
      color: ${transparentize(0.06, "#2168d3")};
    }
  }
`;

export const SubmitButton = styled.button<SubmitButtonProps>`
  background-color: #3970f3;
  color: white;
  width: 100%;
  height: 3.28rem;
  margin-top: 2.8rem;

  border: 0;
  border-radius: 10px;

  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.28);
  pointer-events: ${({ isSendingPayload }) =>
    isSendingPayload ? "none" : "all"};

  transition: all 300ms;
  &:hover {
    filter: brightness(1.1);
    box-shadow: 0 0 8px 0 ${transparentize(0.08, "#2168d3")};
  }
`;

export const SuccessBoundary = styled.section`
  /* background: lightseagreen; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    color: ${lighten(0.128, "green")};

    margin-bottom: 2rem;
  }

  p {
    font-size: 1.28rem;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    line-height: 2rem;
  }
`;

export const ErrorBoundary = styled.section`
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  display: block;
`;

export const ErrorBoundaryContent = styled.div`
  background-color: ${transparentize(0.85, "red")};
  width: 100%;
  margin: 0 auto;
  margin-top: -0.6rem;
  padding: 1rem;
  border: 1px solid ${transparentize(0.78, "red")};
  border-radius: 0.25rem;

  p {
    color: ${darken(0.18, "red")};
    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    line-height: 1rem;
    letter-spacing: 1px;
  }
`;

export const FloatingFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  color: rgba(0,0,0,0.4);
  margin-top: 5.4rem;
`;
