import { useState } from "react";
import { BsLightningChargeFill } from "react-icons/bs";
import { withTranslation } from "react-i18next";

import { Card, Container } from "./registernewsite.styles";

interface RegisterNewSiteProps {
  t: Function;
}

export const RegisterNewSite = ({ t: translator }: RegisterNewSiteProps) => {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  return (
    <Container>
      <h1>{translator("Register a New Site")}</h1>
      <p>
        {translator(
          "To create a new site, please contact us.",
        )}
        &#58;
      </p>

      <Card
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        isHovering={isHovering}
      >
        <p>{translator("contactUs")}</p>

        <div>
          <h3>
              {translator("byEmail")}: <a href="mailto:spott@spott.eco">spott@spott.eco </a> <br />
              {translator("or")} whatsapp: <b> {translator("+55")} (11) 97556-8167 </b>

          </h3>
        </div>

        {/* <BsLightningChargeFill /> */}
      </Card>
    </Container>
  );
};

export default withTranslation()(RegisterNewSite);
