import React from "react";

const { AvForm, AvField } = require("availity-reactstrap-validation");
const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} = require("reactstrap");
import { withTranslation } from "react-i18next";

import Error from "../../../Alerts/Error";
import Success from "../../../Alerts/Success";

import { AxiosApi } from "../../../../helpers/api";
import { Charger } from "../../../../interfaces/Chargers";
import { Exception } from "../../../../interfaces/Exception";

import * as ContextProvider from "../../../../context/ContextProvider";

interface ChargerUpdateProps {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  charger: Charger;
  t: Function;
}

interface ChargerDataUpdate {
  name: string | null;
  comment: string | null;
  cpCode?: number | null;
}

function ChargerUpdate({
  t,
  isOpen,
  onClose,
  onRefresh,
  charger,
}: ChargerUpdateProps) {
  const [chargerInfo, setChargerInfo] = React.useState<ChargerDataUpdate>({
    name: charger.name,
    comment: charger.comment,
    cpCode: charger.cpCode,
  });

  const [loading, setLoading] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);
  const { context, state }: any = ContextProvider.useStateContext();

  const dispatchChargerUpdate = async () => {
    try {
      const {
        chargePointId,
        username,
        password,
        clientCertThumb,
        cpCsId,
        cpCmId,
        cpConfirmed,
        cpAddedDate,
        cpBootData,
        cpStatusDate,
        cpOwnerAnuId,
        cpOwnerDesc,
      } = charger;

      const { name, comment, cpCode } = chargerInfo;

      await AxiosApi.put(`/ChargePoints/${chargePointId}`, {
        chargePointId,
        username,
        password,
        clientCertThumb,
        cpCsId,
        cpCmId,
        cpConfirmed,
        cpAddedDate,
        cpBootData,
        cpStatusDate,
        cpCode,
        cpOwnerAnuId,
        cpOwnerDesc,
        name,
        comment,
      });

      setLoading(false);

      setHandleSuccess(true);
      
      onRefresh();
      context.getAuthorizationReload(true);

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err: any) {
      setHandleError({
        isOpen: true,
        message: err?.response?.data,
        status: err?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered fullscreen="sm">
      <ModalHeader toggle={onClose}>
        <span className="text-primary">{t("Charger Update")}</span>
      </ModalHeader>

      {handleError && <Error {...handleError} />}
      {handleSuccess && <Success message={t("Charger Update SuccessFully")} />}

      {!handleError && !handleSuccess && (
        <>
          <ModalBody>
            <span>{t("Update the charger information below.")}</span>

            <AvForm className="form-horizontal mt-4">
              <div className="mb-3">
                <AvField
                  name="name"
                  value={charger.name}
                  className="form-control"
                  label={t("Charger Name")}
                  placeholder={t("Charger Name")}
                  type="text"
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setChargerInfo((prev) => ({
                      ...prev,
                      name: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <AvField
                  name="CP-code"
                  value={chargerInfo.comment}
                  className="form-control"
                  label={t("Charger Comment")}
                  placeholder={t("Charger Comment")}
                  type="text"
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setChargerInfo((prev) => ({
                      ...prev,
                      comment: event.target.value,
                    }))
                  }
                />
              </div>
              <div className="mb-3">
                <AvField
                  name="name"
                  value={charger.cpCode}
                  className="form-control"
                  label={t("monitoring_CPCode")}
                  placeholder={t("monitoring_CPCode")}
                  type="text"
                  required
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setChargerInfo((prev) => ({
                      ...prev,
                      cpCode: Number(event.target.value),
                    }))
                  }
                />
              </div>
            </AvForm>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <Spinner size="sm" className="text-primary">
                {t("Loading...")}
              </Spinner>
            ) : (
              <>
                <Button color="primary" onClick={dispatchChargerUpdate}>
                  {t("Update Charger")}
                </Button>
                <Button onClick={onClose}>{t("Cancel")}</Button>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default withTranslation()(ChargerUpdate);
