import * as React from "react";
import * as Translator from "react-i18next";

import { Module } from "../Module/Module";
import { Button } from "../../../../../../components/Button";

import ModalUsersAccess from "./components/ModalUsersAccess/ModalUsersAccess";

import { ChargerPointTypes } from "../../../../../../interfaces/Chargers";

import { Component, Wrapper, Content } from "./chargerexclusive.styles";

type ChargerExclusivePropsTypes = {
  t: Function;
  selectedCharger: ChargerPointTypes;
  refreshChargersList: () => void;
};

const ChargerExclusive = ({
  t: translation,
  selectedCharger,
  refreshChargersList,
}: ChargerExclusivePropsTypes) => {
  const charger: ChargerPointTypes = selectedCharger;

  const [openModalUsersAccess, setOpenModalUsersAccess] =
    React.useState<boolean>(false);

  const [modalUsersAccess, setModalUsersAccess] = React.useState({
    modalIsOpen: false,
    chargePointId: "",
    refreshChargersList: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  // Functions
  const handleOpenModalUsersAccess = () => {
    setModalUsersAccess(() => ({
      modalIsOpen: true,
      chargePointId: charger.chargePointId,
      refreshChargersList: refreshChargersList,
      modalOnRequestClose: handleCloseModalUsersAccess,
    }));
  };
  const handleCloseModalUsersAccess = () => {
    setModalUsersAccess((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  // Render
  return (
    <>
      <Component>
        <Module>
          <Wrapper>
            <Content>
              <img
                src={require("../../../../../../assets/images/icon_owner_big.png")}
                alt=""
              />

              <div>
                <h2>{translation("chargers_ChargerExclusive")}</h2>
                <p>{translation("chargers_ChargerExclusiveText")}</p>
              </div>
            </Content>

            <Button buttonColor={"blue"} onClick={handleOpenModalUsersAccess}>
              {translation("chargers_ChargerExclusiveButton")}
            </Button>
          </Wrapper>
        </Module>

        {modalUsersAccess.modalIsOpen && (
          <ModalUsersAccess {...modalUsersAccess} />
        )}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerExclusive);
