import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";
import {
  formatCurrency,
  formatUsagePeriod,
  formatValuesByLanguage,
} from "../../helpers/Helpers";

const { Row, Col } = require("reactstrap");
import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../components/Displays/DisplayLoading/DisplayLoading";
import PizzaGraph from "../../components/Dashboard/SitesRevenueGraph";
import MotlyPreferencesUse from "../../components/Dashboard/MotlyPreferencesUse";

import { AuthUserTypes } from "../../interfaces/AuthUser";
import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { PerformanceDataTypes } from "../../interfaces/Performance";
import { SiteTransactionsTypes } from "../../interfaces/SiteTransactions";
import { RowTypes } from "../../interfaces/DataTable";

import { format, parse,subDays } from 'date-fns';

import {
  Component,
  Wrapper,
  Header,
  Content,
  DatePickerField,
  ButtonClearDate,
  Footer,
  WidgetButton,
} from "./performance.styles";
import { Button } from "../../components/Button/Button";

type PerformancePropsTypes = {
  t: Function;
};

type PickerDateStateTypes = {
  start: string;
  end: string;
};

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

type DataRowTypes = {
  rowAmount: RowTypes;
  rowChargePointId: RowTypes;
  rowComment: RowTypes;
  rowCpCode: RowTypes;
  rowDuration: RowTypes;
  rowKwh: RowTypes;
  rowChargerName: RowTypes;
  rowTransactions: RowTypes;
  rowUsers: RowTypes;
};

export const Performance = ({ t: translation }: PerformancePropsTypes) => {
  const currentUser: AuthUserTypes = JSON.parse(
    localStorage.getItem("authUser")!,
  );
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");
  const datesUpdated = [new Date(state.inicialPickerData.start), new Date(state.inicialPickerData.end)];

  const customSortableKwhRow = (
    elementA: DataRowTypes,
    elementB: DataRowTypes,
  ) => {
    const _A = Number(elementA.rowKwh.toString().replace(",", "."));
    const _B = Number(elementB.rowKwh.toString().replace(",", "."));

    if (_A > _B) return 1;
    if (_A < _B) return -1;

    return 0;
  };

  const dataTableColumns: TableColumn<DataRowTypes>[] = [
    {
      name: translation("performance_TableHeaderCpCode"),
      selector: (row: any) => row.rowCpCode,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("performance_TableHeaderName"),
      selector: (row: any) => row.rowChargerName,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("performance_TableHeaderChargerPointId"),
      selector: (row: any) => row.rowChargePointId,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("performance_TableHeaderUsers"),
      selector: (row: any) => row.rowUsers,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("performance_TableHeaderTransactions"),
      selector: (row: any) => row.rowTransactions,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("performance_TableHeaderKwh"),
      selector: (row: any) => row.rowKwh,
      sortable: true,
      sortFunction: customSortableKwhRow,
      width: "160px",
    },
    {
      name: translation("performance_TableHeaderDuration"),
      selector: (row: any) => row.rowDuration,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("performance_TableHeaderAmount"),
      selector: (row: any) => row.rowAmount,
      sortable: true,
      width: "160px",
    },
  ];
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [performanceData, setPerformanceData] = React.useState<
    PerformanceDataTypes[]
  >([]);
  const [siteTransactionsData, setSiteTransactionsData] = React.useState<
    SiteTransactionsTypes[]
  >([]);
  const [pickerDate, setPickerDate] = React.useState<PickerDateStateTypes>({
    start: "",
    end: "",
  });
  const [widgetDataMode, setWidgetDataMode] = React.useState<
    "amount" | "meter"
  >("amount");

  const [dataTableSettings, setDataTableSettings] =
    React.useState<DataTableTypes>({} as DataTableTypes);

  // Functions
  const buildDataTable = () => {
    const mutablePerformanceData: PerformanceDataTypes[] =
      structuredClone(performanceData);

    const dataTableRows: DataRowTypes[] = mutablePerformanceData.map(
      (transaction: PerformanceDataTypes) => {
        const amount = formatValuesByLanguage(
          transaction.amount,
          state?.userData?.language,
        );
        const chargePointId = transaction.chargePointId ?? emptyData;
        const comment = transaction.comment ?? emptyData;
        const cpCode = transaction.cpCode ?? emptyData;
        const duration = formatUsagePeriod(transaction.duration) ?? emptyData;
        const kwh = formatValuesByLanguage(
          transaction.meterTotal,
          state?.userData?.language,
        );
        const chargerName = transaction.name ?? emptyData;
        const transactions = transaction.transactions ?? emptyData;
        const users = transaction.users ?? emptyData;

        return {
          rowAmount: amount,
          rowChargePointId: chargePointId,
          rowComment: comment,
          rowCpCode: cpCode,
          rowDuration: duration,
          rowKwh: kwh,
          rowChargerName: chargerName,
          rowTransactions: transactions,
          rowUsers: users,
        };
      },
    );

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };

  const makeIsoTimestamp = (dates: Date[]) => {
    const startDate = dates[0] ? new Date(dates[0]) : "";
    const endDate = dates[1] ? new Date(dates[1]) : "";

    const makeDateTime = (date: Date, period: "start" | "end") => {
      const dateDay = String(date.getDate()).padStart(2, "0");
      const dateYear = date.getFullYear();
      const dateMonth = String(date.getMonth() + 1).padStart(2, "0");
      const isoDate = `${dateYear}-${dateMonth}-${dateDay}${
        period === "start" ? "T00:00:00Z" : "T23:59:59Z"
      }`;

      return isoDate;
    };

    setPickerDate((prev) => ({
      ...prev,
      start:
        typeof startDate !== "string" ? makeDateTime(startDate, "start") : "",
      end: typeof endDate !== "string" ? makeDateTime(endDate, "end") : "",
    }));
  };

  const handleClearPeriod = () => {
    setPickerDate((prev) => ({
      ...prev,
      start: "",
      end: "",
    }));
  };

  // Node graphs
  const calcPizzaGraphPeriod = (
    data: SiteTransactionsTypes[],
    periodStart: number,
    periodEnd: number,
    reduceEntry: "amount" | "meter",
  ): number => {
    const dataFilter = data.filter((item) => {
      const dateTime = new Date(item.startTime).getHours();
      return dateTime >= periodStart && dateTime < periodEnd;
    });

    const dataReduced = dataFilter
      .reduce((acc, item) => {
        return acc + (reduceEntry === "amount" ? item.amount : item.meterTotal);
      }, 0)
      .toFixed(2);

    return Number(dataReduced);
  };
  const pizzaGraphData = (propertyType: "amount" | "meter") => {
    if (!siteTransactionsData) return;

    const mutableTransactionsData: SiteTransactionsTypes[] =
      structuredClone(siteTransactionsData);

    const midnight = calcPizzaGraphPeriod(
      mutableTransactionsData,
      0,
      6,
      propertyType,
    );
    const morning = calcPizzaGraphPeriod(
      mutableTransactionsData,
      6,
      12,
      propertyType,
    );
    const afternoon = calcPizzaGraphPeriod(
      mutableTransactionsData,
      12,
      18,
      propertyType,
    );
    const night = calcPizzaGraphPeriod(
      mutableTransactionsData,
      18,
      24,
      propertyType,
    );

    const sumPeriods = Number(midnight + morning + afternoon + night).toFixed(
      2,
    );
    const summarizedPeriods = Math.ceil(Number(sumPeriods));

    const calcReturnValues = (entries: number, period: number) => {
      if (entries === 0) return 0;

      const calcs = Number(Number((entries * 100) / period).toFixed(2));
      return calcs;
    };

    const graphData = [
      {
        periodId: 0,
        periodName: "Midnight",
        timeStart: 0,
        timeEnd: 6,
        value: calcReturnValues(midnight, summarizedPeriods),
      },
      {
        periodId: 1,
        periodName: "Morning",
        timeStart: 6,
        timeEnd: 12,
        value: calcReturnValues(morning, summarizedPeriods),
      },
      {
        periodId: 2,
        periodName: "Afternoon",
        timeStart: 12,
        timeEnd: 18,
        value: calcReturnValues(afternoon, summarizedPeriods),
      },
      {
        periodId: 3,
        periodName: "Night",
        timeStart: 18,
        timeEnd: 24,
        value: calcReturnValues(night, summarizedPeriods),
      },
    ];

    const nonZeroValues = graphData.every((data) => data.value === 0);

    return nonZeroValues ? [] : graphData;
  };
  const calcBarsGraphPeriod = (
    data: SiteTransactionsTypes[],
    dayReference: number,
    reduceEntry: "amount" | "meter",
  ) => {
    const dataFilter = data.filter((item) => {
      const dateTime = new Date(item.startTime).getDay() === dayReference;
      return dateTime;
    });

    const dataReduced = dataFilter
      .reduce((acc, item) => {
        return acc + (reduceEntry === "amount" ? item.amount : item.meterTotal);
      }, 0)
      .toFixed(2);

    return Number(dataReduced);
  };
  const barsGraphData = (propertyType: "amount" | "meter") => {
    if (!siteTransactionsData) return;

    const mutableTransactionsData: SiteTransactionsTypes[] =
      structuredClone(siteTransactionsData);

    const weekInfo = [
      {
        weekDay: 0,
        value: calcBarsGraphPeriod(mutableTransactionsData, 0, propertyType),
      },
      {
        weekDay: 1,
        value: calcBarsGraphPeriod(mutableTransactionsData, 1, propertyType),
      },
      {
        weekDay: 2,
        value: calcBarsGraphPeriod(mutableTransactionsData, 2, propertyType),
      },
      {
        weekDay: 3,
        value: calcBarsGraphPeriod(mutableTransactionsData, 3, propertyType),
      },
      {
        weekDay: 4,
        value: calcBarsGraphPeriod(mutableTransactionsData, 4, propertyType),
      },
      {
        weekDay: 5,
        value: calcBarsGraphPeriod(mutableTransactionsData, 5, propertyType),
      },
      {
        weekDay: 6,
        value: calcBarsGraphPeriod(mutableTransactionsData, 6, propertyType),
      },
    ];

    return weekInfo;
  };

  //API data
  const getPerformanceData = async () => {
    try {
      setLoadingData(true);

      // DEFININDO E FORMATANDO A DATA ATUAL
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const todayFormatted = format(today, "yyyy-MM-dd'T'HH:mm:ss'Z'")

      const { data } = await api.get<PerformanceDataTypes[]>(
        `/ChargerTransactionsSummary/${site_id}?from=${
          pickerDate.start !== "" ? pickerDate.start : state.inicialPickerData.start
        }&to=${pickerDate.end ? pickerDate.end : todayFormatted}`,
      );

      const mutableApiData: PerformanceDataTypes[] = structuredClone(data);
      const sortedData: PerformanceDataTypes[] = mutableApiData.sort(
        (elementA, elementB) => {
          if (elementA.cpCode > elementB.cpCode) return -1;
          if (elementA.cpCode < elementB.cpCode) return 1;

          return 0;
        },
      );

      setPerformanceData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };
  const getSiteTransactionsData = async () => {
    try {

      // DEFININDO E FORMATANDO A DATA ATUAL
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const todayFormatted = format(today, "yyyy-MM-dd'T'HH:mm:ss'Z'")

      const lastThirdDays = subDays(now, 31);
      const last30Days = new Date(lastThirdDays.getFullYear(), lastThirdDays.getMonth(), lastThirdDays.getDate());
      const lastThirdDaysFormatted = format(last30Days, "yyyy-MM-dd'T'HH:mm:ss'Z'");

      const { data } = await api.get<SiteTransactionsTypes[]>(
        `/SiteTransactions/${site_id}?from=${
          pickerDate.start !== "" ? pickerDate.start : lastThirdDaysFormatted
        }&to=${pickerDate.end ? pickerDate.end : todayFormatted}`,
      );

      const mutableData = structuredClone(data);
      setSiteTransactionsData(mutableData);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getPerformanceData();
    getSiteTransactionsData();
  }, [state.sites, site_id, pickerDate]);

  React.useMemo(() => {
    buildDataTable();
  }, [performanceData, state?.userData?.language]);

  // const getLastTransactions = () => {
  //   // Current date
  //   const date = new Date();
  //   date.setDate(date.getDate() - 30);

  //   console.log(date);
  // };
  // getLastTransactions();

  // Render
  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              {translation("performance_PageTitle")}
            </h1>
          </Header>

          <Content>
            <p>{translation("performance_Info")}</p>

            <div className={"data-table"}>
              <DatePickerField
                onChange={(dateRange: Date[], dateStr, instance) => {
                  if (dateRange === undefined || !dateRange[1]) return;
                  makeIsoTimestamp(dateRange);
                }}
                // value={new Date()}
                options={{
                  wrap: true,
                  mode: "range",
                  defaultDate: datesUpdated,
                  maxDate: new Date(),
                  locale: state?.userData?.language === "pt-BR" ? "pt" : "en",
                  dateFormat:
                    state?.userData?.language === "pt-BR" ? "d-m-Y" : "m-d-Y",
                }}
              >
                <input
                  type="text"
                  data-input
                  placeholder={translation("global_DatePickerPlaceholder")}
                />

                <Button
                  buttonColor={"dark"}
                  type={"button"}
                  data-clear
                  disabled={!pickerDate.start}
                  onClick={handleClearPeriod}
                >
                  {translation("global_DatePickerButtonClear")}
                </Button>
              </DatePickerField>

              {dataTableSettings.tableRows &&
                <DataTable
                  columns={dataTableSettings.tableColumns}
                  data={dataTableSettings.tableRows}
                  defaultSortFieldId={5}
                  defaultSortAsc={false}
                  progressPending={loadingData}
                  noDataComponent={<DisplayNoData />}
                  progressComponent={<DisplayLoading />}
                  selectableRows={false}
                  selectableRowsVisibleOnly={false}
                  selectableRowsHighlight={false}
                  dense={false}
                  responsive
                  striped
                  highlightOnHover
                  // pointerOnHover
                  fixedHeader
                  persistTableHead
                  pagination
                  paginationDefaultPage={1}
                  paginationComponentOptions={paginationSettings}
                />
              }
            </div>
          </Content>

          <Footer>
            <div className="buttons-aligment">
              <WidgetButton
                type="button"
                isSelected={widgetDataMode === "amount"}
                onClick={() => setWidgetDataMode("amount")}
              >
                {translation("performance_Revenue")}
              </WidgetButton>
              <span>|</span>
              <WidgetButton
                type="button"
                isSelected={widgetDataMode !== "amount"}
                onClick={() => setWidgetDataMode("meter")}
              >
                {translation("performance_Consumption")}
              </WidgetButton>
            </div>

            <Row className="mb-4">
              <Col xl={4}>
                <PizzaGraph
                  data={
                    !!performanceData.length
                      ? pizzaGraphData(widgetDataMode)
                      : []
                  }
                  text={
                    widgetDataMode === "amount"
                      ? translation(`performance_Revenue`)
                      : translation(`performance_Consumption`)
                  }
                />
              </Col>

              <Col xl={8}>
                <MotlyPreferencesUse
                  data={barsGraphData(widgetDataMode)}
                  text={
                    widgetDataMode === "amount"
                      ? translation(`performance_Revenue`)
                      : translation(`performance_Consumption`)
                  }
                />
              </Col>
            </Row>
          </Footer>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Performance);
