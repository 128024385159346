import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  button {
    width: 10rem;
  }
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;

  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  small {
    color: ${darken(0.24, "#e7e8ea")};
    font-size: 1.16rem;
    line-height: 1.16rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }

  p {
    color: #3970f3;
    margin: 0;
    padding: 0;
    font-weight: 600;

    & + p {
      color: ${darken(0.32, "#e7e8ea")};
      font-weight: 500;
      font-style: normal;
      font-size: 0.88rem;
      line-height: 0.88rem;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;
    }
  }
`;

export const ModalContent = styled.div`
  background-color: white;
  width: 100%;
  margin: 0 auto;

  position: relative;
  overflow: hidden;
  z-index: 1;

  display: grid;
  grid-gap: 2rem;

  color: ${darken(0.32, "#e7e8ea")};
  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  h2 {
    color: ${darken(0.48, "#e7e8ea")};
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 1.24rem;
    line-height: 1.24rem;
  }
`;

const Aligment = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.32rem;
  }
`;
export const ModalQRCodeDispose = styled(Aligment)``;
export const ModalURLDispose = styled(Aligment)`
  a {
    color: #3970f3;
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;

  button {
    width: 4.88rem;
  }
`;

export const ModalQRCodeConfig = styled.div`
  width: 100%;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
  z-index: -1;

  h2 {
    color: ${darken(0.48, "#e7e8ea")};
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 2.24rem;
    line-height: 2.24rem;
    font-style: normal;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;
  }
`;
