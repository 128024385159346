import styled from "styled-components";
import { lighten, rgba } from "polished";

interface LabelProps {
  onInputFocus: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  margin: 0 auto -70px auto;
  position: relative;
`;

export const Display = styled.div`
  background: white;
  width: 29rem;

  margin: 0 auto;
  padding: 1.5rem;
  margin-top: -1rem;
  z-index: 1;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;

  p{
      font-family: "Gilroy", sans-serif;
      color: #3970F3;
      font-size: 29px;
      font-weight: 500;
      line-height: 42px;
      letter-spacing: 0px;
      text-align: left;

    a{
      font-family: "Gilroy", sans-serif;
      color: #3970F3;
      font-size: 22px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Form = styled.form``;

export const Label = styled.label<LabelProps>`
  width: 100%;
  margin-top: 1rem;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  span {
    margin: 0.5rem 0;
  }

  input {
    background: white;
    color: #6c757d !important;
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;

    border: 1px solid
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 0
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};

    transition: all 300ms;
  }
`;

export const Fields = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  button {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    width: 100%;
    padding: 0.6rem;

    border: 0;
    border-radius: 0.5rem;

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;

    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

    transition: all 300ms;
    &:hover {
      filter: brightness(1.1);
    }
  }

  button[type="submit"] {
    background-color: #3970f3;

    &:hover {
      box-shadow: 0 0 8px 0 #3970f3;
    }
    a{
      font-family: "Gilroy", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
  button[type="button"] {
    background-color: #6C6C6C;
    margin-top: 0.5rem;

    &:hover {
      box-shadow: 0 0 8px 0 #6C6C6C;
    }
    a{
      font-family: "Gilroy", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
`;
