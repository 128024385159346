import { useEffect, useState } from "react"
import { Container, Content, Display, Email, FloatingFooter, Header, SubmitButton, Text, Wrapper, WrapperError } from "./styles"
import { FaCheckCircle } from "react-icons/fa"
import { Loader } from "../../../components/Loader"
import Footer from "../../../components/VerticalLayout/Footer"
import { withTranslation } from "react-i18next"
import { AxiosApi } from "../../../helpers/api"
import { useParams } from "react-router-dom"
const logoImg = require("../../../assets/images/logo.png")

interface Props {
  t: Function;
}

interface OutputUser {
  name: string;
  email: string;
}

const ConfirmAccountDeletionApp = ({ t: translator }: Props) => {

  const url = new URL(window.location.href);
  const hashParam = url.searchParams.get("culture");
  const { t }: any = useParams();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<OutputUser>({
    name: '',
    email: ''
  });

  const hash = String(t);

  const fetchUserData = async () => {
    try {
      const response = await AxiosApi.get<OutputUser>(`/DataExclusion/${hash}`)
      if (response) {
        setUser(response.data)
        console.log(response.data)
      }
    } catch (err) {
      setError(true)
      console.log(err)
    }
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    try {
      const res = await AxiosApi.delete(`/DataExclusion/${hash}`)
      if (res.status === 200) {
        setSuccess(true)
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <Container>
      <Header>
        <img width={'200px'} style={{ marginBottom: '50px', marginTop: '-20px' }} src={logoImg} alt="logo" />
      </Header>

      <Content>
        <Display>
          {error ?
            <WrapperError>
              <h1>{translator('Oops! An error has occurred!')}</h1>
              <p>{translator('Token already used or expired')}</p>
            </WrapperError>
            :
            !success ?
              (<>
                <Wrapper>
                  <Text>{translator('Username')}: </Text>
                  <Email>{user.name}</Email>
                </Wrapper>
                <Wrapper style={{ marginTop: 0 }}>
                  <Text>{translator('Email')}: </Text>
                  <Email>{user.email}</Email>
                </Wrapper>
                <SubmitButton type="submit" onClick={(e) => handleSubmit(e as any)} disabled={loading}>
                  {loading ? <Loader spinnerColor="white" loaderSize="small" /> : <span>{translator('Delete')}</span>}
                </SubmitButton>
              </>) :
              (<>
                <h1>{translator('Successfully Deleted Account')}</h1>
                <FaCheckCircle color="#3970f3" size={100} style={{ marginTop: '1rem' }} />
              </>)}
        </Display>
        <FloatingFooter>
          <Footer
            loginFooter={true}
            darkText={true}
            tintText={true}
            setLanguageByExternalResource={hashParam || "pt-BR"}
          />
        </FloatingFooter>
      </Content>
    </Container>
  )
}

export default withTranslation()(ConfirmAccountDeletionApp);
