import * as React from "react";
import * as Translator from "react-i18next";
const { Modal } = require("reactstrap");

import * as Provider from "../../../../../../context/ContextProvider";
import {
  formatCurrency,
  formatValuesByLanguage,
  formatMonthYearHelper,
} from "../../../../../../helpers/Helpers";

import { ExtractModalTypes } from "../../../../../../interfaces/Modal";

import {
  ModalWrapper,
  ModalHeader,
  ModalBody,
} from "./amountextractmodal.styles";

interface AmountExtractModalPropsType extends ExtractModalTypes {
  t: Function;
}

const AmountExtractModal = ({
  t: translation,
  modalIsOpen,
  extractDate,
  extractTotalTransacted,
  extractTotalTransfers,
  extractFeesAndCommissions,
  extractSpottComission,
  extractSpottTax,
  extractTransactionFee,
  extractTransactionFeeTax,
  extractMonthlyPayment,
  onRequestCloseModal,
}: AmountExtractModalPropsType) => {
  const { state }: any = Provider.useStateContext();
  const [userLanguage, setUserLanguage] = React.useState<string>("");

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  return (
    <>
      <Modal isOpen={modalIsOpen} centered={false} size="lg" fullscreen="sm">
        <ModalWrapper>
          <ModalHeader>
            <h2>{translation("finance_AmountDetailsMonthDetails")}</h2>
            <small onClick={onRequestCloseModal}>x</small>
          </ModalHeader>

          <ModalBody>
            <ol>
              <li>
                {translation("finance_AmountDetailsPeriod")}:{" "}
                <span>{formatMonthYearHelper(extractDate)}</span>
              </li>
              <li>
                {translation("finance_AmountDetailsTotalTransacted")}:{" "}
                <span>
                  {formatCurrency(extractTotalTransacted, userLanguage)}
                </span>
              </li>
              <li>
                {translation("finance_AmountDetailsTotalTransfers")}:{" "}
                <span>
                  {formatCurrency(extractTotalTransfers, userLanguage)}
                </span>
              </li>
            </ol>

            <ol>
              <li>
                {translation("finance_AmountDetailsFeesAndCommissions")}:{" "}
                <span>
                  {formatCurrency(extractFeesAndCommissions, userLanguage)}
                </span>
              </li>
              <li>
                {translation("finance_AmountDetailsSpottCommission")}{" "}
                {formatValuesByLanguage(extractSpottTax, userLanguage)} %:{" "}
                <span>
                  {formatCurrency(extractSpottComission, userLanguage)}
                </span>
              </li>
              <li>
                {translation("finance_AmountDetailsTransactionFee")}{" "}
                {formatValuesByLanguage(extractTransactionFeeTax, userLanguage)}{" "}
                %:{" "}
                <span>
                  {formatCurrency(extractTransactionFee, userLanguage)}
                </span>
              </li>
              <li>
                {translation("finance_AmountDetailsMonthlyPayment")}:{" "}
                <span>
                  {formatCurrency(extractMonthlyPayment, userLanguage)}
                </span>
              </li>
            </ol>
          </ModalBody>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default Translator.withTranslation()(AmountExtractModal);
