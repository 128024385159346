import styled from "styled-components";
import { transparentize, rgba, darken, lighten } from "polished";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Header = styled.header`
  position: relative;
  background-color: #2168d3;
  width: 100%;
  height: 191px;
  margin: 0 auto;
  padding: 2rem 0;

  img {
    position: absolute;
    height: 94px;
    width: 206.63px;
    top: 37%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* height: 94px;
    width: 206.63px;
    margin: 0 auto;
    display: block; */
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Display = styled.div`
  background-color: white;

  width: 32rem;
  height: 32rem;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.8rem;

  // * Activate here
  position: absolute;
  top: 8.8rem;
  left: 50%;
  transform: translate(-50%, 0);

  border: 1px solid rgba(0, 0, 0, 0.128);
  border-radius: 2rem;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.128);

  transition: all 300ms;
  &:hover {
    border-color: ${transparentize(0.6, "#2168d3")};
    box-shadow: 0 0 8px 0 ${transparentize(0.6, "#2168d3")};
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

export const SuccessBoundary = styled.section`
  /* background: lightseagreen; */
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 30px 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  h1 {
    color: #34c05e;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2.25rem;
    text-align: center;
    text-transform: none;
  }

  p {
    font-weight: 500;
    font-style: normal;
    text-align: center;
    text-transform: none;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: #262729;
    margin: 22px 15px;
  }

  @media (max-width: 480px) {
    h1 {
      font-size: 1.25rem;
      line-height: 2rem;
    }

    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
`;

export const FloatingFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  position: absolute;
  left: 0;
  bottom: -5rem;
`;
