import './styles.scss';
import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from "react-router-dom";
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { AxiosApi } from '../../helpers/api';
import CustomBreadCrumb from '../../components/BreadCrumb';
import Tabs from '../../components/Tabs'
import DropdownMenuRight from '../../components/DropdownMenuRight';
import { FormatDateHelper } from '../../helpers/Helpers';
import ChargersUsageLoad from '../../components/Dashboard/ChargersUsageLoad';
import MotlyPreferencesUse from '../../components/Dashboard/MotlyPreferencesUse';
import { useStateContext } from "../../context/ContextProvider";
import EnergyIcon from './../../assets/images/energy.png';

const EnergyConsumption = props => {
  const { site_id, charger_id } = useParams();
  const { context, state } = useStateContext();
  const [siteInfo, setSiteInfo] = useState();
  const [tabs, setTabs] = useState([]);
  const [usageLoad, setUsageLoad] = useState([]);
  const [usePeference, setUsePeference] = useState([]);
  const [energyConsumptionTotal, setEnergyConsumptionTotal] = useState({ value: null, variation: null });

  const [userLanguage, setUserLanguage] = useState("");

  useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  if (!site_id) return <Redirect to='/dashboard' />;

  useEffect(() => {
    if (site_id && state.sites) {
      const siteInfoFilter = state.sites.filter(site => site.csId === site_id);
      setSiteInfo(siteInfoFilter[0]);
      getEnergyConsumptionTotal();
      getUsageLoad();
      getUserCharger();
      getMotlyPreferencesUse();
    }
  }, [site_id, charger_id, state.sites]);

  const getUserCharger = () => {
    const resp = AxiosApi.get(`/site/chargepoints/${site_id}`, {})
      .then((response) => {
        const tabsApi = response.data.map(charger => {
          return {
            'name': charger.chargePointId,
            'chargePointId': charger.chargePointId,
            'site_id': site_id,
            'siteInfo': siteInfo,
          }
        })
        setTabs(tabsApi);
      })
      .catch(error => error);
  };

  const getUsageLoad = () => {
    const parameters = charger_id ? site_id + "/" + charger_id : site_id;
    const resp = AxiosApi.get(`/metrics/ChargersUsageLoadPeriod/${parameters}`, {})
      .then((response) => {
        response.data.sort((x, y) => x.timeStart - y.timeStart);
        setUsageLoad(response.data);
      })
      .catch(error => {
        setUsageLoad([]);
      });
  };

  const getEnergyConsumptionTotal = () => {
    const parameters = charger_id ? site_id + "/" + charger_id : site_id;
    const resp = AxiosApi.get(`/metrics/EnergyConsumptionTotal/${parameters}`, {})
      .then(response => {
        setEnergyConsumptionTotal(response.data);
      })
      .catch(error => {
        setEnergyConsumptionTotal({ value: 0, variation: 0 });
      });
  }

  const getMotlyPreferencesUse = () => {
    const parameters = charger_id ? site_id + "/" + charger_id : site_id;
    const resp = AxiosApi.get(`/metrics/UsePeference/${parameters}`, {})
      .then(response => {
        setUsePeference(response.data);
      })
      .catch(error => error);
  }

  return (
    <div className="page-content">
      <Container fluid>

        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={12}>
              <CustomBreadCrumb
                siteName={siteInfo?.csName}
                title={props.t('Energy consumption')}
                itemTitle={siteInfo?.chargerActiveName}
                subtitle={`${props.t('Last update')}: ${FormatDateHelper(Date.now())}`}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Tabs
        tabs={tabs}
        image={EnergyIcon}
        mainValue={energyConsumptionTotal?.value !== null ? `${energyConsumptionTotal?.value.toLocaleString(userLanguage, { minimumFractionDigits: 1, maximumFractionDigits: 1 })} kWh` : null}
        period={props.t('Last 30 days')}
        percentage={energyConsumptionTotal?.variation !== null ? energyConsumptionTotal?.variation : null}
        siteInfoProps={siteInfo}
        pathname="energy-consumption"
      >

        <Row>
          <Col xl={8}>
            <MotlyPreferencesUse data={usePeference} />
          </Col>

          <Col xl={4}>
            <ChargersUsageLoad data={usageLoad} />
          </Col>
        </Row>

      </Tabs>
    </div>
  );
}

export default withTranslation()(EnergyConsumption);