import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";
import { BsCalendar2PlusFill } from "react-icons/bs";
import SuccessModal from "../../components/Alerts/SuccessModal";
const { Col, Row, InputGroup, Label } = require("reactstrap");
const {
  AvField,
  AvForm,
  AvCheckboxGroup,
  AvCheckbox,
} = require("availity-reactstrap-validation");
const moment = require("moment");
import Switch from "react-switch";
import Flatpickr from "react-flatpickr";

import { ModaActionlTypes } from "../../interfaces/Modal";
import { PricificationProfileTypes } from "../../interfaces/Pricification";
import { MouseCoordinatesTypes } from "../../interfaces/Mouse";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { ScheduleTypes } from "../../interfaces/Pricification";
import ErrorModal from "../../components/Alerts/ErrorModal";

import { Exception } from "../../interfaces/Exception";

import PricificationBar from "./components/PricificationBar/PricificationBar";
import { ModalAction } from "../../components/Modal/ModalAction/ModalAction";
import ProfileManagementModal from "./components/ProfileManagementModal/ProfileManagementModal";
import TogglerIcon from "../../components/Renders/TogglerIcon/TogglerIcon";
import Event from "./components/Renders/Event/Event";

import { AxiosApi } from "../../helpers/api";
import { formatCurrency, formatTimeHelper } from "../../helpers/Helpers";
import * as ContextProvider from "../../context/ContextProvider";

import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import BootstrapTheme from "@fullcalendar/bootstrap";
import allLocales from "@fullcalendar/core/locales-all";
import { preventContextMenu } from "@fullcalendar/core/internal";

import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import "flatpickr/dist/l10n/pt";

import {
  Container,
  Header,
  TabBar,
  TabButton,
  Content,
  NoContentDisplay,
  Calendar,
  FloatingDisplay,
} from "./pricification.styles";
import { AuthUserTypes } from "../../interfaces/AuthUser";
import { object } from "prop-types";

const numeral = require('numeral');

type PricePropsType = {
  t: Function;
};

type ScheduleStateTypes = {
  start?: string;
  end?: string;
};

type ProfileManagementModalStateTypes = {
  opening: boolean;
  modeType: string;
};

const Pricification = ({ t: translation }: PricePropsType) => {
  const { site_id }: any = RouterDom.useParams();
  const { state }: any = ContextProvider.useStateContext();

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );
  const [allEvents, setAllEvents] = React.useState<ScheduleTypes[]>([]);
  const [selectDate, setSelectDate] = React.useState<any>();
  const [isDelete, setIsDelete] = React.useState(false);
  const [dateField, setDateField] = React.useState<ScheduleStateTypes>(
    {} as ScheduleStateTypes,
  );

  const [hourError, setHourError] = React.useState(true);
  const [switch1, setswitch1] = React.useState(false);
  const [switch2, setswitch2] = React.useState(false);
  const [change, setChange] = React.useState(false);
  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: "",
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const [profilesData, setProfilesData] = React.useState<
    PricificationProfileTypes[]
  >([]);
  const [profileCurrentData, setProfileCurrentData] =
    React.useState<PricificationProfileTypes>({} as PricificationProfileTypes);

  const [profileManagementModal, setProfileManagementeModal] =
    React.useState<ProfileManagementModalStateTypes>({
      opening: false,
      modeType: "",
    });

  const [showFloatingDisplay, setShowFloatingDisplay] =
    React.useState<boolean>(false);
  const [mouseCoordinates, setMouseCoordinates] =
    React.useState<MouseCoordinatesTypes>({
      x: 0,
      y: 0,
    });

  // Functions
  const handleOpenActionModal = () => {
    setModalAction((prev) => ({
      ...prev,
      modalIsOpen: true,
      modalTitle: translation("pricing_ActionModalDeleteProfileTitle"),
      modalInfo: translation("pricing_ActionModalDeleteProfileText"),
      modalButtonConfirmText: translation("Yes"),
      modalButtonCancelText: translation("No"),
      modalOnConfirmAction: handleDeleteProfile,
      modalOnRequestClose: handleCloseActionModal,
    }));
  };

  const handleCloseActionModal = () => {
    setModalAction((prev) => ({ ...prev, modalIsOpen: false }));
  };

  const handleOpenManagementModal = (opening: boolean, modeType: string) => {
    setProfileManagementeModal({
      opening,
      modeType,
    });
  };

  const handleCloseManagementModal = () => {
    setChange(!change);
    setProfileManagementeModal((prev) => ({
      ...prev,
      opening: false,
    }));
  };

  const getMouseCoordinates = (event: any) => {
    if (showFloatingDisplay) return;

    const x = event.clientX - (event.clientX > 1000 ? 500 : 280);
    const y = event.clientY - (event.clientY > 700 ? 540 : 360);

    setMouseCoordinates({ x, y });
  };

  const convertPeekWeektoApiWeek = (week: any) => {
    let current = [false, false, false, false, false, false, false];
    let peekWeek = week.filter((e: any) => e !== false);
    if (peekWeek.length > 0) {
      peekWeek.forEach((key: any, index: any) => {
        // current[key] =
        current[key] = true;
      });
    }
    // console.log("Convert WEEKAPI:", peekWeek, "Converted Finish:", current)
    return current;
  };

  const convertEventApitoEvent = (event: any) => {
    let current: any[] = [];
    event.forEach((key: any, index: any) => {
      current.push({
        id: key.priceProfilePeriodId,
        startTime: key.priceProfilePeriodStartTime.slice(0, 8),
        endTime: key.priceProfilePeriodEndTime.slice(0, 8),
        daysOfWeek: convertApiWeektoPeekWeek(key.priceProfilePeriodWeekdays),
        kwh: key.priceProfilePeriodPriceKwh,
        pph: key.priceProfilePeriodPriceTimePlugged,
        disable: key.priceProfilePeriodChargerDisabled,
        free: key.priceProfilePeriodFreeCharge,
      });
    });

    setAllEvents(current);
  };

  const convertApiWeektoPeekWeek = (week: any) => {
    let current: any[] = [false, false, false, false, false, false, false];
    week.forEach((key: any, index: any) => {
      if (key == true) {
        current[index] = String(index);
      }
    });

    return current;
  };

  // API hanlder
  const handlePostProfilePeriods = async (values: any) => {
    const daystosend = convertPeekWeektoApiWeek(values.daysOfWeek);

    let send = {
      priceProfilePeriodName: values.id,
      priceProfilePeriodStartTime: values.startTime,
      priceProfilePeriodEndTime: values.endTime,
      priceProfilePeriodChargerDisabled: values.disable,
      priceProfilePeriodFreeCharge: values.free == null ? false : values.free,
      priceProfilePeriodPriceKwh: Number(values.kwh),
      priceProfilePeriodPriceTimeCharging: 0,
      priceProfilePeriodPriceTimePlugged: Number(values.pph),
      priceProfilePeriodProfileId: profileCurrentData?.priceProfileId,
      priceProfilePeriodWeekdays: daystosend,
      priceProfilePeriodTransactionPrice: 0,
      priceProfilePeriodOverstayPenalty: 0,
      priceProfilePeriodOverstayGracePeriod: 0,
      priceProfilePeriodReservationAllowed: false,
      priceProfilePeriodReservationPriceMinute: 0,
      priceProfilePeriodReservationLimitMinutes: 0,
      priceProfileOverstayPriceMinute: 0,
    };
    console.log("Post Profile", send);
    const resp: any = AxiosApi.post(`/PriceProfilePeriods`, send)
      .then((response) => {
        // console.log("response.status",response.status)
        // console.log("RESPONSE",response)
        return response;
      })
      .catch((err) => {
        // console.log("ERRO", err)
        return err;
      });
    return resp;
  };

  const handlePutProfilePeriods = async (values: any) => {
    const daystosend = convertPeekWeektoApiWeek(values.daysOfWeek);
    let send = {
      priceProfilePeriodId: values.id,
      priceProfilePeriodName: values.id,
      priceProfilePeriodStartTime: values.startTime,
      priceProfilePeriodEndTime: values.endTime,
      priceProfilePeriodChargerDisabled: values.disable,
      priceProfilePeriodFreeCharge: values.free,
      priceProfilePeriodPriceKwh: Number(values.kwh),
      priceProfilePeriodPriceTimeCharging: 0,
      priceProfilePeriodPriceTimePlugged: Number(values.pph),
      priceProfilePeriodProfileId: profileCurrentData?.priceProfileId,
      priceProfilePeriodWeekdays: daystosend,
      priceProfilePeriodTransactionPrice: 0,
      priceProfilePeriodOverstayPenalty: 0,
      priceProfilePeriodOverstayGracePeriod: 0,
      priceProfilePeriodReservationAllowed: false,
      priceProfilePeriodReservationPriceMinute: 0,
      priceProfilePeriodReservationLimitMinutes: 0,
      priceProfileOverstayPriceMinute: 0,
    };
    // console.log("SENDUPDATE", send)
    const resp: any = AxiosApi.put(`/PriceProfilePeriods/${values.id}`, send)
      .then((response) => {
        // console.log("response.status",response.status)
        // console.log("RESPONSE",response)
        return response;
      })
      .catch((err) => {
        // console.log("ERRO", err)
        return err;
      });
    return resp;
  };

  const handleDeleteProfile = () => {

    const resp: any = AxiosApi.delete(
      `/PriceProfiles/${profileCurrentData?.priceProfileId}`,
    )
      .then((response) => {
        console.log("response.status", response.status);
        console.log("RESPONSE", response);
        setChange(!change);

        setProfileCurrentData(profilesData[0]);
        localStorage.setItem('profileID', String(profilesData[0].priceProfileId))
        setHandleSuccess(true);
      })
      .catch((err) => {
        console.log("ERRO", err);
      })
      .finally(() => {
        console.log("FINALY");
        handleCloseActionModal();
      });
  };

  const handleDeleteProfilePeriods = async (values: any) => {
    console.log("DELETE", values);
    const resp: any = AxiosApi.delete(`/PriceProfilePeriods/${values.id}`)
      .then((response) => {
        // console.log("response.status",response.status)
        // console.log("RESPONSE",response)
        return response;
      })
      .catch((err) => {
        // console.log("ERRO", err)
        return err;
      });
    return resp;
  };

  // Validators
  const handleValidEventSubmit = async (e: any, values: any) => {
    const testes = allEvents.map((each) => {
      let sameDay = each.daysOfWeek.map((daytest: any) => {
        // console.log("values.peekWeek.indexOf(daytest)",daytest ,"INDEX:", values.peekWeek.indexOf(daytest))
        if (values.peekWeek.indexOf(daytest) == -1 || daytest == false) {
          // console.log("false sameDay" )
          return false;
        } else {
          // console.log("true sameDay : ", daytest , "is on", values.peekWeek.indexOf(daytest), (values.peekWeek))
          return true;
        }
      });
      let sameDayFilttter = sameDay.filter((e) => e == true);
      // console.log ("entrou no if", sameDayFilttter.length)
      if (
        (moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss").isBetween(
          moment(each.startTime, "hh:mm:ss"),
          moment(each.endTime, "hh:mm:ss"),
        ) ||
          moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss").isSame(
            moment(each.startTime, "hh:mm:ss"),
          ) ||
          moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss").isBetween(
            moment(each.startTime, "hh:mm:ss"),
            moment(each.endTime, "hh:mm:ss"),
          ) ||
          moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss").isSame(
            moment(each.endTime, "hh:mm:ss"),
          ) ||
          moment(each.startTime, "hh:mm:ss").isBetween(
            moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss"),
            moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss"),
          ) ||
          moment(each.endTime, "hh:mm:ss").isBetween(
            moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss"),
            moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss"),
          )) &&
        sameDayFilttter.length
      ) {
        // console.log ("entrou no if")
        if (each.id == selectDate.id) {
          // console.log("if id")
          return true;
        } else {
          // console.log("else do if")
          return false;
        }
      } else {
        // console.log("else")
        return true;
      }
    });

    if (testes.filter((e) => e == false).length != 0) {
      console.log("Horario conflitante");
      return;
    } else {
      let eventHourStart = moment(values.start).format("HH:mm:ss");
      let eventHourEnd = moment(values.end).format("HH:mm:ss");

      if (eventHourEnd == "00:00:00") {
        eventHourEnd = "23:59:59";
      }
      console.log(
        "RECEBIDO START:",
        `${moment(values.start).format("HH:mm:ss")}`,
      );
      console.log("RECEBIDO END:", `${moment(values.end).format("HH:mm:ss")}`);

      const kwhValue = values.kwh.replace(/,/g, '.');
      const hourValue = values.pph.replace(/,/g, '.');

      let eventToPost = {
        id: String(new Date()),
        startTime: eventHourStart,
        endTime: eventHourEnd,
        daysOfWeek: values.peekWeek,
        kwh: parseFloat(kwhValue),
        pph: parseFloat(hourValue),
        disable: values.disable,
        free: values.free,
      };
      const resp = await handlePostProfilePeriods(eventToPost);
      if (resp.status == 201) {
        eventToPost = {
          id: resp.data.priceProfilePeriodId,
          startTime: eventHourStart,
          endTime: eventHourEnd,
          daysOfWeek: values.peekWeek,
          kwh: values.kwh,
          pph: values.pph,
          disable: values.disable,
          free: values.free,
        };
        console.log("eventToadd", eventToPost);
        // setAllEvents([
        //   ...allEvents,
        //   eventToPost,
        // ]);
      }
      setChange(!change);
      setShowFloatingDisplay(false);
      setswitch1(false);
      setswitch2(false);
    }
  };
  const handleValidEventEdit = async (eventChanged: any) => {
    console.log(
      "handleValidEventEdit",
      "kwh:",
      eventChanged.event.extendedProps.kwh,
      "pph:",
      eventChanged.event.extendedProps.pph,
      "disable:",
      eventChanged.event.extendedProps.disable,
      "free:",
      eventChanged.event.extendedProps.free,
    );
    // console.log("iiiii",eventChanged.event.id);
    let peekWeek = allEvents.filter((e) => e.id == eventChanged.event.id);
    const testes = allEvents.map((each) => {
      let sameDay = each.daysOfWeek.map((daytest: any) => {
        // console.log("values.peekWeek.indexOf(daytest)", peekWeek[0].daysOfWeek)
        // console.log("values.peekWeek.indexOf(daytest)",daytest ,"INDEX:", peekWeek[0].daysOfWeek.indexOf(daytest))
        if (peekWeek[0].daysOfWeek.indexOf(daytest) == -1 || daytest == false) {
          // console.log("false sameDay" )
          return false;
        } else {
          // console.log("true sameDay : ", daytest , "is on", peekWeek.indexOf(daytest), (peekWeek))
          return true;
        }
      });
      let sameDayFilttter = sameDay.filter((e) => e == true);
      // console.log ("entrou no if", sameDayFilttter.length)
      if (
        (moment(
          moment(eventChanged.event.start).format("HH:mm:ss"),
          "hh:mm:ss",
        ).isBetween(
          moment(each.startTime, "hh:mm:ss"),
          moment(each.endTime, "hh:mm:ss"),
        ) ||
          moment(
            moment(eventChanged.event.start).format("HH:mm:ss"),
            "hh:mm:ss",
          ).isSame(moment(each.startTime, "hh:mm:ss")) ||
          moment(
            moment(eventChanged.event.end).format("HH:mm:ss"),
            "hh:mm:ss",
          ).isBetween(
            moment(each.startTime, "hh:mm:ss"),
            moment(each.endTime, "hh:mm:ss"),
          ) ||
          moment(
            moment(eventChanged.event.end).format("HH:mm:ss"),
            "hh:mm:ss",
          ).isSame(moment(each.endTime, "hh:mm:ss")) ||
          moment(each.startTime, "hh:mm:ss").isBetween(
            moment(
              moment(eventChanged.event.start).format("HH:mm:ss"),
              "hh:mm:ss",
            ),
            moment(
              moment(eventChanged.event.end).format("HH:mm:ss"),
              "hh:mm:ss",
            ),
          ) ||
          moment(each.endTime, "hh:mm:ss").isBetween(
            moment(
              moment(eventChanged.event.start).format("HH:mm:ss"),
              "hh:mm:ss",
            ),
            moment(
              moment(eventChanged.event.end).format("HH:mm:ss"),
              "hh:mm:ss",
            ),
          )) &&
        sameDayFilttter.length
      ) {
        // console.log ("entrou no if")
        if (each.id == eventChanged.event.id) {
          // console.log("if id")
          return true;
        } else {
          // console.log("else do if")
          return false;
        }
      } else {
        // console.log("else")
        return true;
      }
    });
    if (testes.filter((e) => e == false).length != 0) {
      console.log("Horario conflitante");
      // let excluir = allEvents;
      // let week = excluir.filter((e) => e.id === eventChanged.oldEvent.id);
      // excluir = excluir.filter((e) => e.id != eventChanged.oldEvent.id);
      // excluir = [
      //   ...excluir,
      //   {
      //     id: String(new Date()),
      //     // // title: eventChanged.oldEvent.title,
      //     startTime: moment(eventChanged.oldEvent.start).format("HH:mm:ss"),
      //     endTime: moment(eventChanged.oldEvent.end).format("HH:mm:ss"),
      //     daysOfWeek: week[0].daysOfWeek,
      //     kwh: eventChanged.event.extendedProps.kwh,
      //     pph: eventChanged.event.extendedProps.pph,
      //     disable: eventChanged.event.extendedProps.disable,
      //     free: eventChanged.event.extendedProps.free,
      //   },
      // ];
      // setAllEvents(excluir);
      // setChange(!change);
      return;
    }

    let startTime, endtime;
    let excluir = allEvents;
    let week = excluir.filter((e) => e.id == eventChanged.oldEvent.id);
    let eventHourStart = moment(
      moment(eventChanged.event.start).format("HH:mm:ss"),
      "hh:mm:ss",
    );
    let eventHourEnd = moment(
      moment(eventChanged.event.end).format("HH:mm:ss"),
      "hh:mm:ss",
    );
    let isChangeDay =
      eventChanged.oldEvent.start.getDay() == eventChanged.event.end.getDay() ||
      eventChanged.oldEvent.start.getDay() == eventChanged.event.start.getDay()
        ? false
        : true;
    excluir = excluir.filter((e) => e.id != eventChanged.oldEvent.id);
    if (eventHourStart.isAfter(eventHourEnd) && isChangeDay) {
      console.log("ERRO");
      // excluir = [
      //   ...excluir,
      //   {
      //     id: String(new Date()),
      //     // // title: eventChanged.oldEvent.title,
      //     startTime: moment(eventChanged.oldEvent.start).format("HH:mm:ss"),
      //     endTime: moment(eventChanged.oldEvent.end).format("HH:mm:ss"),
      //     daysOfWeek: week[0].daysOfWeek,
      //     kwh: eventChanged.event.extendedProps.kwh,
      //     pph: eventChanged.event.extendedProps.pph,
      //     disable: eventChanged.event.extendedProps.disable,
      //     free: eventChanged.event.extendedProps.free,
      //   },
      // ];
      // setAllEvents(excluir);
      // setChange(!change);
      return;
    }
    // console.log("weekRULE:", week[0].daysOfWeek.indexOf(String(eventChanged.event.start.getDay())))
    if (
      eventChanged.event.start.getDay() == eventChanged.event.end.getDay() &&
      week[0].daysOfWeek.indexOf(String(eventChanged.event.start.getDay())) ==
        -1
    ) {
      week[0].daysOfWeek.splice(
        week[0].daysOfWeek.indexOf(
          String(eventChanged.oldEvent.start.getDay()),
        ),
        1,
      );
      week[0].daysOfWeek.push(String(eventChanged.event.start.getDay()));
    }
    // console.log("week:", week[0].daysOfWeek)
    // console.log("RECEBIDO START:", `${moment(eventChanged.event.start)}`)
    // console.log("RECEBIDO END:",  `${moment(eventChanged.event.end)}`)
    if (
      moment(eventChanged.event.start).isBefore(
        moment(eventChanged.event.end),
        "day",
      ) &&
      moment(eventChanged.event.end).day() ==
        moment(eventChanged.oldEvent.end).day()
    ) {
      startTime = moment(eventChanged.event.end)
        .startOf("day")
        .format("HH:mm:ss");
      // console.log("isBefore is true")
    } else {
      startTime = moment(eventChanged.event.start).format("HH:mm:ss");
      // console.log("isBefore is false")
    }
    if (
      !moment(eventChanged.event.start).isSame(
        moment(eventChanged.event.end).subtract(1, "second"),
        "day",
      ) &&
      moment(eventChanged.event.start).day() ==
        moment(eventChanged.oldEvent.start).day()
    ) {
      console.log("isBefore is ");
      endtime = moment(eventChanged.event.start)
        .endOf("day")
        .format("HH:mm:ss");
    } else {
      endtime = moment(eventChanged.event.end).format("HH:mm:ss");
    }
    if (endtime == "00:00:00") {
      endtime = "23:59:59";
    }
    // console.log("eventChanged startTime edit:", startTime)
    // console.log("eventChanged endtime edit:",  endtime)
    // excluir = [
    //   ...excluir,
    //   {
    //     id: String(new Date()),
    //     // // title: eventChanged.event.title,
    //     startTime: startTime,
    //     endTime: endtime,
    //     daysOfWeek: week[0].daysOfWeek,
    //     kwh: eventChanged.event.extendedProps.kwh,
    //     pph: eventChanged.event.extendedProps.pph,
    //     disable: eventChanged.event.extendedProps.disable,
    //     free: eventChanged.event.extendedProps.free,
    //   },
    // ];
    // setAllEvents(excluir);
    let update = {
      id: eventChanged.event.id,
      startTime: startTime,
      endTime: endtime,
      daysOfWeek: week[0].daysOfWeek,
      kwh: eventChanged.event.extendedProps.kwh,
      pph: eventChanged.event.extendedProps.pph,
      disable: eventChanged.event.extendedProps.disable,
      free: eventChanged.event.extendedProps.free,
    };
    console.log("UPDATE EVENT", update);
    const resp = await handlePutProfilePeriods(update);
    if (resp.status == 204) {
      console.log("eventToUpdate");
    }
    setChange(!change);
  };
  const handleModalEventEdit = async (e: any, values: any) => {

    e.isDefaultPrevented;

    setHourError(true);

    const testes = allEvents.map((each) => {
      const sameDay = each.daysOfWeek.map((daytest: any) => {
        // console.log("values.peekWeek.indexOf(daytest)",daytest ,"INDEX:", values.peekWeek.indexOf(daytest))
        if (values.peekWeek.indexOf(daytest) == -1 || daytest == false) {
          // console.log("false sameDay" )
          return false;
        } else {
          // console.log("true sameDay : ", daytest , "is on", values.peekWeek.indexOf(daytest), (values.peekWeek))
          return true;
        }
      });

      const sameDayFilttter = sameDay.filter((e) => e == true);
      // console.log ("entrou no if", sameDayFilttter.length)
      if (
        (moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss").isBetween(
          moment(each.startTime, "hh:mm:ss"),
          moment(each.endTime, "hh:mm:ss"),
        ) ||
          moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss").isSame(
            moment(each.startTime, "hh:mm:ss"),
          ) ||
          moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss").isBetween(
            moment(each.startTime, "hh:mm:ss"),
            moment(each.endTime, "hh:mm:ss"),
          ) ||
          moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss").isSame(
            moment(each.endTime, "hh:mm:ss"),
          ) ||
          moment(each.startTime, "hh:mm:ss").isBetween(
            moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss"),
            moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss"),
          ) ||
          moment(each.endTime, "hh:mm:ss").isBetween(
            moment(moment(values.start).format("HH:mm:ss"), "hh:mm:ss"),
            moment(moment(values.end).format("HH:mm:ss"), "hh:mm:ss"),
          )) &&
        sameDayFilttter.length
      ) {
        // console.log ("entrou no if")
        if (each.id == selectDate.id) {
          // console.log("if id")
          return true;
        } else {
          // console.log("else do if")
          return false;
        }
      } else {
        // console.log("else")
        return true;
      }
    });

    if (testes.filter((e) => e == false).length != 0) {
      console.log("Horario conflitante");
      setHourError(false);
      return;
    } else {
      let eventHourStart = moment(values.start).format("HH:mm:ss");
      let eventHourEnd = moment(values.end).format("HH:mm:ss");

      console.log(
        "RECEBIDO START:",
        `${moment(values.start).format("HH:mm:ss")}`,
      );

      console.log("RECEBIDO END:", `${moment(values.end).format("HH:mm:ss")}`);
      // let excluir = allEvents;
      // excluir = excluir.filter((e) => e.id != selectDate.id);
      // excluir = [
      //   ...excluir,
      //   {
      //     id: String(new Date()),
      //     // // title: values.title,
      //     startTime: eventHourStart,
      //     endTime: eventHourEnd,
      //     daysOfWeek: values.peekWeek,
      //     kwh: values.kwh,
      //     pph: values.pph,
      //     disable: values.disable,
      //     free: values.free,
      //   },
      // ];
      // setAllEvents(excluir);
      setHourError(true);

      const kwhValue = values.kwh.replace(/,/g, '.');
      const hourValue = values.pph.replace(/,/g, '.');

      let update = {
        id: selectDate.id,
        startTime: eventHourStart,
        endTime: eventHourEnd,
        daysOfWeek: values.peekWeek,
        kwh: parseFloat(kwhValue),
        pph: parseFloat(hourValue),
        disable: values.disable,
        free: values.free,
      };

      const resp = await handlePutProfilePeriods(update);

      if (resp.status == 204) {
        console.log("eventToUpdate");
      }

      setChange(!change);
      setSelectDate(undefined);
      setShowFloatingDisplay(false);
    }
  };

  const handleModalEventDelete = async (e: any, eventChanged: any) => {

    setHourError(true);

    const resp: any = AxiosApi.delete(`/PriceProfilePeriods/${parseInt(selectDate.id)}`)
    .then(res => {
      console.log(res, 'DELETE IS OK')
    })
    setChange(!change);
    setSelectDate(undefined);
    setIsDelete(false);
    setShowFloatingDisplay(false);
  };

  const [values, setValues] = React.useState({
    kwh: '',
    hour: ''
  })

  // Calendar Event Emiter
  const handleEventClick = (event: any) => {
    console.log("CLICKED EVENT");

    setHourError(true);
    setDateField({
      start: event.event.startStr,
      end: event.event.endStr,
    });
    setValues({...values, kwh: event.event.extendedProps.kwh ? parseFloat(event.event.extendedProps.kwh).toFixed(2) : '', hour: event.event.extendedProps.pph ? parseFloat(event.event.extendedProps.pph).toFixed(2) : ''});
    setSelectDate(event.event);
    setswitch1(event.event.extendedProps.free);
    setswitch2(event.event.extendedProps.disable);
    setShowFloatingDisplay(true);

    console.log('aaaabbb', event.event)

    console.log(event.event.startStr, event.event.endStr);
  };
  const handleEventSelect = (info: any) => {
    setHourError(true);
    setDateField({
      start: info.startStr,
      end: info.endStr,
    });
    setswitch1(false);
    setswitch2(false);
    setSelectDate(info);
    console.log("selectDate", info.startStr, info.endStr);
    setShowFloatingDisplay(true);
  };
  const handleEventSelectAllow = (event: any): boolean => {
    return moment(event.start).isSame(
      moment(event.end).subtract(1, "second"),
      "day",
    );
  };

  // Callbacks
  const defaultValues = React.useCallback(() => {
    let eventFilter: string | any = [];

    if (selectDate && selectDate.id) {
      console.log("allEvents in defaultValues", allEvents);
      eventFilter = allEvents.find((e) => e.id == selectDate.id);

      if (!eventFilter) {
        return;
      } else {
        eventFilter = eventFilter.daysOfWeek;
      }
    }

    return {
      peekWeek: [
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("1") != -1
          ? "1"
          : selectDate && moment(selectDate?.startStr).day() === 1 && "1",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("2") != -1
          ? "2"
          : selectDate && moment(selectDate?.startStr).day() === 2 && "2",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("3") != -1
          ? "3"
          : selectDate && moment(selectDate?.startStr).day() === 3 && "3",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("4") != -1
          ? "4"
          : selectDate && moment(selectDate?.startStr).day() === 4 && "4",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("5") != -1
          ? "5"
          : selectDate && moment(selectDate?.startStr).day() === 5 && "5",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("6") != -1
          ? "6"
          : selectDate && moment(selectDate?.startStr).day() === 6 && "6",
        !selectDate
          ? undefined
          : eventFilter && eventFilter.indexOf("0") != -1
          ? "0"
          : selectDate && moment(selectDate?.startStr).day() === 0 && "0",
      ],
      kwh: !selectDate
        ? undefined
        : selectDate?.extendedProps?.kwh
        ? selectDate.extendedProps.kwh
        : undefined,
      pph: !selectDate
        ? undefined
        : selectDate?.extendedProps?.pph
        ? selectDate.extendedProps.pph
        : undefined,
    };
  }, [selectDate, allEvents, dateField, showFloatingDisplay]);

  const timeError = React.useCallback(
    async (value: any, ctx: any, input: any, cb: any) => {
      // console.log("value",value,"ctx", ctx, "input", input, "cb", cb)
      cb(hourError ? hourError : translation("horario conflitante"));
    },
    [hourError],
  );

  const profileID = localStorage.getItem('profileID');

  const [defaultProfile, setDefaultProfile] = React.useState<PricificationProfileTypes[]>()

  const getPriceProfilesData = async () => {
    try {
      const { data } = await AxiosApi.get<PricificationProfileTypes[]>(
        `/PriceProfiles?siteid=${site_id}`,
      );

      const mutableData: PricificationProfileTypes[] = structuredClone(data);

      const defaultProfile = mutableData.filter( profile => {
        return profile.priceProfileName === "DEFAULT_PRICE_PROFILE";
      });

      await setDefaultProfile(defaultProfile)

      const filteredData = mutableData.filter( profile => {
        return String(profile.priceProfileId) === profileID;
      });

      if (data.length > 0) {
        setProfileCurrentData(filteredData[0]);
      }

      const defaultProfileId = String(defaultProfile[0].priceProfileId)

      if(profileID === null){
        localStorage.setItem( 'profileID', defaultProfileId)
      }
      setProfilesData(mutableData);
    } catch (error: unknown) {
      console.log(error);
    }
  };




  const getPriceProfilesPeriodsData = async () => {
    try {

      if(!defaultProfile) return;
      
      const { data } = await AxiosApi.get(
        `/PriceProfilePeriods?profileid=${profileCurrentData?.priceProfileId === undefined ? defaultProfile[0].priceProfileId : profileCurrentData?.priceProfileId}`,
      );

      convertEventApitoEvent(data);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getPriceProfilesData();
  }, [site_id, state.sites, change]);

  React.useEffect(() => {
    getPriceProfilesPeriodsData();
  }, [profileCurrentData, site_id, state.sites, change]);

  // React.useEffect(() => {
  //   // key.priceProfileId = number;
  //   const mutableProfilesCurrentData: PricificationProfileTypes =
  //     structuredClone(profileCurrentData);

  //   if (!Object.keys(mutableProfilesCurrentData).length) return;

  //   console.log(mutableProfilesCurrentData, 'mutableprofile')

  //   localStorage.setItem(
  //     "profileID",
  //     String(mutableProfilesCurrentData.priceProfileId),
  //   );

  // }, [profileCurrentData]);
  
  const getStoredProfile = (profileId: number) => {
    const mutableProfilesData: PricificationProfileTypes[] =
      structuredClone(profilesData);

    if (!mutableProfilesData.length) return;

    const storedProfileId = mutableProfilesData.find(
      (profile: PricificationProfileTypes) =>
        profile.priceProfileId === profileId,
    );

    if (typeof storedProfileId === "undefined") return;

    setProfileCurrentData(storedProfileId);
  };

  const changeFunction = () => {
    const selectedProfile = profilesData.filter( prof => {
      return String(prof.priceProfileId) === profileID;
    });

    setProfileCurrentData(selectedProfile[0]);
    setChange(!change);
  }

  const language = localStorage.getItem('I18N_LANGUAGE');

  const handleInputValue = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {

    const value = event.target.value;
    const numericValue = await value.replace(/[^0-9.,]/g, '').replace(/(,.*?),(.*,)?/g, '$1');

    const inputValue = numericValue.toString()

    if(type === 'kwh'){
        return setValues((current: any) => ({
        ...current,
        kwh: inputValue.replace(/\.(?=.*\.)/g, ''),
        }))  
    } else {
        return setValues((current: any) => ({
        ...current,
        hour: inputValue.replace(/\.(?=.*\.)/g, ''),
        }))  
    }

  }

  const verifyInputValue = async (value: any, type: string) => {

    const inputValue = value.toString();
    const lastCharactere = inputValue.charAt(inputValue.length - 1);

    // Caso seja , ou . no final, retira
    const removeLastCharactere = inputValue.slice(0, -1);
    const inputNumber = parseFloat(removeLastCharactere);

    // Verifica se o usuário já fez separação decimal
    let decimalValue : any;
    if(language === 'pt'){
      decimalValue = await inputValue.split(',')  
    } else{
      decimalValue = await inputValue.split('.')
    }    

    if(type === 'kwh'){

        if(lastCharactere === '.' || lastCharactere === ','){
          const formattedValue = numeral(inputNumber).format('0,0.00');
          console.log(formattedValue, 'formatted')

            return setValues((current: any) => ({
                ...current,
                kwh: numeral(inputNumber).format('0,0.00'),
            })) 
        }

        if(lastCharactere === ''){
            return setValues((current: any) => ({
                ...current,
                kwh: numeral(0).format('0,0.00'),
            })) 
        }

        if(decimalValue.length === 1){
          console.log('entrou aqui é só 1', values)
          return setValues({...values, kwh: numeral(value).format('0,0.00')}) 
        }

        if(decimalValue[1].length === 1){

          return setValues((current: any) => ({
            ...current,
            kwh: inputValue.concat('0'),
          }))
        }

      } else {
          if(lastCharactere === '.' || lastCharactere === ','){
            return setValues((current: any) => ({
                ...current,
                hour: numeral(inputNumber).format('0,0.00'),
            })) 
          }

          if(lastCharactere === ''){
            return setValues((current: any) => ({
                ...current,
                hour: numeral(0).format('0,0.00'),
            })) 
          }

          if(decimalValue.length === 1){
            return setValues((current: any) => ({
              ...current,
              hour: numeral(parseInt(decimalValue[0])).format('0,0.00'),
            })) 
          }
  
          if(decimalValue[1].length === 1){
  
            return setValues((current: any) => ({
              ...current,
              hour: inputValue.concat('0'),
            }))
          }
      }

    }
    
  const returnValueLanguage = (event: any)=>{

    const returnValuePT = String(event).replace(/\./g, ',');
    const returnValueEN = String(event).replace(/\,/g, '.');


    if(language === 'pt'){
      return returnValuePT;
    }

    return returnValueEN;
  }

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  return (
    <>
      <Container>
        <Header>
          <h1>
            <span>
              {siteAccess?.csName} <span>/</span>
            </span>
            {translation("pricing_PricingPageTitle")}
          </h1>
        </Header>

        <TabBar>
          {profilesData.map((profile, index) => {
            return (
              <TabButton
                key={profile.priceProfileId}
                type="button"
                isSelected={
                  profileID ? profileID == String(profile.priceProfileId) ? true : false 
                  : 'DEFAULT_PRICE_PROFILE' == profile.priceProfileName ? true : false
                }
                onClick={() => {
                  setProfileCurrentData(profile);
                  localStorage.setItem('profileID', String(profile.priceProfileId))
                }}
              >
                {profile.priceProfileDefaultProfile === true
                  ? translation("pricing_DefaultProfile")
                  : profile.priceProfileName}
              </TabButton>
            );
          })}

          <TabButton
            type="button"
            isSelected={false}
            onClick={() => handleOpenManagementModal(true, "creation")}
          >
            {translation("pricing_NewProfileTabButton")}
          </TabButton>
        </TabBar>

        <Content>
          {!Object.keys(profilesData).length ? (
            <NoContentDisplay>
              <BsCalendar2PlusFill
                onClick={() => handleOpenManagementModal(true, "creation")}
              />
              <p>{translation("pricing_NoProfileSelected")}</p>
              <p>{translation("pricing_CreateOrAccessNewProfile")}</p>
            </NoContentDisplay>
          ) : (
            <>
              <PricificationBar
                handleEditProfile={() =>
                  handleOpenManagementModal(true, "edition")
                }
                handleDeleteProfile={handleOpenActionModal}
                change={() => changeFunction()}
                profileCurrent={profileCurrentData ? profileCurrentData : profilesData[0]}
              />

              <Calendar onMouseDown={getMouseCoordinates}>
                <p>{translation("pricing_DaysAndHoursCustomization")}</p>
                <span
                  id="span-calendar-background"
                  style={{ overflow: "hidden", display: "block" }}
                >
                  <FullCalendar
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    events={allEvents}
                    height={"80vh"}
                    initialView={"timeGridWeek"}
                    dayHeaderFormat={{ weekday: "long" }}
                    firstDay={1}
                    editable={true}
                    droppable={true}
                    selectable={true}
                    allDaySlot={false}
                    eventOverlap={false}
                    selectOverlap={false}
                    headerToolbar={false}
                    eventContent={(event) => <Event eventInfo={event} />}
                    locale={state?.userData?.language}
                    eventClick={handleEventClick}
                    select={handleEventSelect}
                    selectAllow={handleEventSelectAllow}
                    eventChange={(event) => handleValidEventEdit(event)}
                    scrollTime={"00:00:00"}
                  />
                </span>

                {showFloatingDisplay && (
                  <>
                    <div
                      style={{
                        position: "fixed",
                        zIndex: 1,
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        overflow: "auto",
                        // backgroundColor:"black"
                      }}
                      onClick={() => {
                        setShowFloatingDisplay(false);
                      }}
                    />
                    <FloatingDisplay
                      showContent={showFloatingDisplay}
                      CoordinatesX={mouseCoordinates.x}
                      CoordinatesY={mouseCoordinates.y}
                    >
                      <AvForm
                        name="formulario"
                        onValidSubmit={
                          selectDate?.id && !isDelete
                            ? handleModalEventEdit
                            : selectDate?.id && isDelete
                            ? handleModalEventDelete
                            : handleValidEventSubmit
                        }
                        model={defaultValues()}
                      >
                        <Row form>
                          <Row>
                            <Col className="col-6 mb-3">
                              <AvField
                                name="id"
                                type="text"
                                value={selectDate?.id ? selectDate.id : ""}
                                style={{ display: "none" }}
                              />
                              <AvField
                                id="AvFieldInput"
                                name="kwh"
                                label="kWh"
                                type="text"
                                min={0}
                                value={
                                  !values
                                    ? null
                                    : values &&
                                      values?.kwh
                                    ? returnValueLanguage(values.kwh)
                                    : undefined
                                }
                                onChange = {(event: any) => handleInputValue(event, 'kwh')}
                                onBlur={(event: any) => verifyInputValue(event.target.value, 'kwh')}
                                disabled={switch1 || switch2}
                              />
                            </Col>
                            <Col className="col-6 mb-3">
                              <AvField
                                id="AvFieldInput"
                                name="pph"
                                label={translation("pricing_LabelHourValue")}
                                type="text"
                                min={0}
                                // validate={{
                                //   number: true,
                                //   min: { value: 0 },
                                // }}
                                value={
                                  !values
                                    ? null
                                    : values &&
                                    values?.hour
                                    ? returnValueLanguage(values.hour)
                                    : undefined
                                }
                                onChange = {(event: any) => handleInputValue(event, 'hour')}
                                onBlur={(event: any) => verifyInputValue(event.target.value, 'hour')}
                                disabled={switch1 || switch2}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-6 mb-3">
                              <AvField
                                name="free"
                                label={translation("pricing_LabelSwitcherFree")}
                                type="switch"
                                style={{ display: "none" }}
                                value={switch1}
                              />
                              <Switch
                                uncheckedIcon={<TogglerIcon icon={"Off"} />}
                                checkedIcon={<TogglerIcon icon={"On"} />}
                                onColor="#2FD475"
                                onChange={() => {
                                  setswitch1(!switch1);
                                }}
                                checked={switch1}
                                disabled={switch2}
                              />
                            </Col>
                            <Col className="col-6 mb-3">
                              <AvField
                                name="disable"
                                label={translation(
                                  "pricing_LabelSwitcherDisabled",
                                )}
                                type="switch"
                                style={{ display: "none" }}
                                value={switch2}
                              />
                              <Switch
                                uncheckedIcon={<TogglerIcon icon={"Off"} />}
                                checkedIcon={<TogglerIcon icon={"On"} />}
                                onColor="#2FD475"
                                onChange={() => {
                                  setswitch2(!switch2);
                                }}
                                checked={switch2}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Col className="col-6 mb-3">
                              <div className="form-group mb-3">
                                <Label>
                                  {translation("pricing_LabelTimeStart")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i",
                                      time_24hr:
                                        state?.userData?.language == "pt-BR"
                                          ? true
                                          : false,
                                      minuteIncrement: 30,
                                      maxTime:
                                        dateField.end != undefined &&
                                        moment(dateField.end)
                                          .subtract(30, "m")
                                          .toISOString(),
                                    }}
                                    onChange={(e) => {
                                      console.log("onChange:", e);
                                      setHourError(true);
                                      setDateField({
                                        start: e[0].toISOString(),
                                        end: dateField.end,
                                      });
                                    }}
                                    defaultValue={
                                      !selectDate
                                        ? null
                                        : selectDate &&
                                          moment(selectDate?.startStr).format(
                                            "HH:mm",
                                          )
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                  <AvField
                                    name="start"
                                    value={
                                      dateField &&
                                      dateField?.start &&
                                      dateField?.start
                                    }
                                    style={{ display: "none" }}
                                    validate={{ async: timeError }}
                                  />
                                </InputGroup>
                              </div>
                            </Col>
                            <Col className="col-6 mb-3">
                              <div className="form-group mb-3">
                                <Label>
                                  {translation("pricing_LabelTimeEnd")}
                                </Label>
                                <InputGroup>
                                  <Flatpickr
                                    className="form-control d-block"
                                    placeholder="Select time"
                                    options={{
                                      enableTime: true,
                                      noCalendar: true,
                                      dateFormat: "H:i",
                                      time_24hr:
                                        state?.userData?.language == "pt-BR"
                                          ? true
                                          : false,
                                      minuteIncrement: 30,
                                      minTime:
                                        dateField.start != undefined &&
                                        moment(dateField.start)
                                          .add(30, "m")
                                          .toISOString(),
                                    }}
                                    form={"formulario"}
                                    onChange={(e) => {
                                      console.log("onChange:", e);
                                      setHourError(true);
                                      setDateField({
                                        start: dateField.start,
                                        end: e[0].toISOString(),
                                      });
                                    }}
                                    defaultValue={
                                      !selectDate
                                        ? null
                                        : selectDate &&
                                          moment(selectDate?.endStr).format(
                                            "HH:mm",
                                          ) === "00:00"
                                        ? moment(selectDate.startStr)
                                            .endOf("day")
                                            .format("HH:mm")
                                        : moment(selectDate.endStr).format(
                                            "HH:mm",
                                          )
                                    }
                                  />
                                  <div className="input-group-append">
                                    <span className="input-group-text">
                                      <i className="mdi mdi-clock-outline" />
                                    </span>
                                  </div>
                                  <AvField
                                    name="end"
                                    value={
                                      dateField &&
                                      dateField?.end &&
                                      dateField?.end
                                    }
                                    style={{ display: "none" }}
                                    validate={{ async: timeError }}
                                  />
                                </InputGroup>
                              </div>
                            </Col>
                          </Row>

                          <Col className="col-12 mb-3">
                            <AvCheckboxGroup
                              inline
                              name="peekWeek"
                              label={translation("pricing_LabelWeekDays")}
                              required
                            >
                              <Row>
                                <Col className="col-6 mb-3">
                                  <AvCheckbox
                                    customInput
                                    name="0"
                                    label={translation("pricing_LabelSunday")}
                                    value="0"
                                  />
                                  <AvCheckbox
                                    customInput
                                    name="1"
                                    label={translation("pricing_LabelMonday")}
                                    value="1"
                                  />
                                  <AvCheckbox
                                    customInput
                                    name="2"
                                    label={translation("pricing_LabelTuesday")}
                                    value="2"
                                  />
                                  <AvCheckbox
                                    customInput
                                    name="3"
                                    label={translation(
                                      "pricing_LabelWednesday",
                                    )}
                                    value="3"
                                  />
                                </Col>

                                <Col className="col-6 mb-3">
                                  <AvCheckbox
                                    customInput
                                    name="4"
                                    label={translation("pricing_LabelThursday")}
                                    value="4"
                                  />
                                  <AvCheckbox
                                    customInput
                                    name="5"
                                    label={translation("pricing_LabelFriday")}
                                    value="5"
                                  />
                                  <AvCheckbox
                                    customInput
                                    name="6"
                                    label={translation("pricing_LabelSaturday")}
                                    value="6"
                                  />
                                </Col>
                              </Row>
                            </AvCheckboxGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="button"
                                className="btn btn-light me-2"
                                onClick={() => setShowFloatingDisplay(false)}
                              >
                                {translation("pricing_ButtonClose")}
                              </button>

                              {selectDate?.id && (
                                <button
                                  type="submit"
                                  className="btn btn-danger me-2"
                                  onClick={() => setIsDelete(true)}
                                >
                                  {translation("pricing_ButtonDelete")}
                                </button>
                              )}

                              <button
                                type="submit"
                                className="btn btn-success save-event"
                              >
                                {translation("pricing_ButtonSave")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </FloatingDisplay>
                  </>
                )}
              </Calendar>
            </>
          )}
        </Content>
      </Container>

      {/*Modals*/}
      <>

        {handleError && <ErrorModal {...handleError} />}

        {handleSuccess && 
        <SuccessModal
          isOpen={handleSuccess}
          onClose={() => setHandleSuccess(false)}
          message={translation("pricing_ModalSuccessDeleteProfile")}
        />}

        {profileManagementModal.opening && (
          <ProfileManagementModal
            setHandleError={setHandleError}
            modalIsOpen={profileManagementModal.opening}
            modalClose={handleCloseManagementModal}
            modalType={profileManagementModal.modeType}
            csId={site_id}
            profileCurrent={profileCurrentData}
            setProfileCurrentData={setProfileCurrentData}
            setProfileManagementeModal={setProfileManagementeModal}
          />
        )}

        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      </>
    </>
  );
};

export default Translator.withTranslation()(Pricification);
