import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";

import DataTable from "./components/DataTable/DataTable";
import Display from "./components/Display/Display";
import ChargerGeneratorId from "./components/ChargerGeneratorId/ChargerGeneratorId";
import ChargerDetails from "./components/ChargerDetails/ChargerDetails";
import ModalDeletion from "./components/ModalDeletion/ModalDeletion";

import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { MouseCoordinatesTypes } from "../../interfaces/Mouse";
import { ChargerPointTypes, ChargerTypes } from "../../interfaces/Chargers";

import { Component, Wrapper, Header, Content, Panels } from "./chargers.styles";

type ChargersPropsTypes = {
  t: Function;
};

export type DisplaySettings = {
  displayInfo?: string;
  displayShow?: boolean;
  displayClipped?: boolean;
  displayCordinates?: MouseCoordinatesTypes;
};
type PrepareDisplayTypes = Omit<DisplaySettings, "displayCordinates">;

export type DataTableSettings = {
  data: ChargerTypes[];
  loadingData: boolean;
  configureDisplayElement: (payload: PrepareDisplayTypes) => void;
  handleSelectChargerId: (chargerId: string) => void;
  handleOpenModalDeletion: (chargerId: string) => void;
};

type ModalDeletionSettings = {
  modalIsOpen: boolean;
  modalChargerId: string;
  modalOnDeleteCharger: () => void;
  modalOnRequestClose: () => void;
};

type ChargerDetailsSettings = {
  selectedCharger: ChargerPointTypes;
  refreshChargersList: () => void;
};

export const Chargers = ({ t: translation }: ChargersPropsTypes) => {
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { context, state }: any = Provider.useStateContext();

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );

  const [mouseCoordinates, setMouseCoordinates] =
    React.useState<MouseCoordinatesTypes>({
      x: 0,
      y: 0,
    });

  const [modalDeletionSettings, setModalDeletionSettings] =
    React.useState<ModalDeletionSettings>({
      modalIsOpen: false,
      modalChargerId: "",
      modalOnDeleteCharger: (): void => {},
      modalOnRequestClose: (): void => {},
    });

  const [chargerPointsData, setChargerPointsData] = React.useState<
    ChargerTypes[]
  >([]);

  const [displaySettings, setDisplaySettings] = React.useState<DisplaySettings>(
    {
      displayInfo: "",
      displayShow: false,
      displayClipped: false,
      displayCordinates: { x: 0, y: 0 },
    },
  );

  const [dataTableSettings, setDataTableSettings] =
    React.useState<DataTableSettings>({
      data: [],
      loadingData: true,
      configureDisplayElement: (): void => {},
      handleSelectChargerId: (): void => {},
      handleOpenModalDeletion: (): void => {},
    });

  const [chargerDetailsSettings, setChargerDetailsSettings] =
    React.useState<ChargerDetailsSettings>({
      selectedCharger: {} as ChargerPointTypes,
      refreshChargersList: (): void => {},
    });

  // Functions
  const prepareDisplay = (payload: PrepareDisplayTypes) => {
    setDisplaySettings((prev) => ({
      ...prev,
      displayShow: payload.displayShow,
      displayInfo: payload.displayInfo,
      displayClipped: payload.displayClipped,
    }));
  };

  const prepareModalDeletion = (chargerId: string) => {
    setModalDeletionSettings(() => ({
      modalIsOpen: true,
      modalChargerId: chargerId,
      modalOnDeleteCharger: () => deleteChargePoint(chargerId),
      modalOnRequestClose: handleCloseModalDeletion,
    }));
  };

  const prepareChargerDetails = (settings: ChargerDetailsSettings) => {
    setChargerDetailsSettings({ ...settings });
  };

  const handleCloseModalDeletion = () => {
    setModalDeletionSettings((prev) => ({ ...prev, modalIsOpen: false }));
  };

  // API calls
  const getChargePoints = async () => {
    try {
      setDataTableSettings((prev) => ({
        ...prev,
        loadingData: true,
      }));

      const { data } = await api.get<ChargerTypes[]>(
        `/site/chargepoints/${site_id}`,
      );

      context.getAuthorizationReload(false);

      const mutableData: ChargerTypes[] = structuredClone(data);
      const sortedData: ChargerTypes[] = mutableData.sort((dataA, dataB) => {
        if (dataA.cpCode > dataB.cpCode) return 1;
        if (dataA.cpCode < dataB.cpCode) return -1;

        return 0;
      });

      setChargerPointsData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setDataTableSettings((prev) => ({
        ...prev,
        loadingData: false,
      }));
    }
  };

  const getSelectedChargerById = async (chargerId: string) => {

    console.log(chargerId, 'chargerId')
    try {
      const settings: ChargerDetailsSettings = {
        refreshChargersList: getChargePoints,
        selectedCharger: {} as ChargerPointTypes,
      };

      if (
        chargerId === null ||
        typeof chargerId === "undefined" ||
        !chargerId.length
      ) {
        prepareChargerDetails(settings);
        return;
      }

      const { data } = await api.get<ChargerPointTypes>(
        `/ChargePoints/${chargerId}`,
      );

      const mutableData = structuredClone(data);
      settings.selectedCharger = mutableData;

      prepareChargerDetails(settings);
    } catch (error: any) {
      console.log(error);
    }
  };

  const deleteChargePoint = async (chargerId: string) => {
    try {
      const response = await api.delete(`/ChargePoints/${chargerId}`);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setChargerDetailsSettings({} as ChargerDetailsSettings);
      getChargePoints();
      handleCloseModalDeletion();
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getChargePoints();
    setChargerDetailsSettings({} as ChargerDetailsSettings);
  }, [state.sites, site_id]);

  React.useMemo(() => {
    setDataTableSettings((prev) => ({
      ...prev,
      data: chargerPointsData,
      configureDisplayElement: prepareDisplay,
      handleSelectChargerId: (chargerId) => getSelectedChargerById(chargerId),
      handleOpenModalDeletion: (chargerId) => prepareModalDeletion(chargerId),
    }));
  }, [chargerPointsData]);

  React.useMemo( () => {
    getSelectedChargerById(chargerDetailsSettings.selectedCharger.chargePointId)
  }, [state.authorizationReload])

  React.useMemo(() => {
    if (!displaySettings.displayShow) return;

    const handleMouseMove = (event: any) => {
      setMouseCoordinates(() => ({
        x: event.clientX,
        y: event.clientY,
      }));
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [displaySettings.displayShow]);

  React.useEffect(() => {
    if (!displaySettings.displayClipped) return;

    const timer = setTimeout(() => {
      setDisplaySettings((prev: DisplaySettings) => ({
        ...prev,
        displayClipped: false,
      }));
    }, 800);

    return () => clearTimeout(timer);
  }, [displaySettings.displayClipped]);

  React.useEffect(() => {
    setDisplaySettings((prev) => ({
      ...prev,
      displayCordinates: mouseCoordinates,
    }));
  }, [mouseCoordinates, displaySettings.displayClipped]);

  // Render
  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              {translation("chargers_PageTitle")}
            </h1>
          </Header>

          <Content>
            <ChargerGeneratorId
              siteAccess={siteAccess}
              refreshChargersList={getChargePoints}
            />

            <Panels>
              <DataTable {...dataTableSettings} />
              <ChargerDetails {...chargerDetailsSettings} />
            </Panels>

            <Display {...displaySettings} />
          </Content>
        </Wrapper>

        {/*Modals*/}
        {modalDeletionSettings.modalIsOpen && (
          <ModalDeletion {...modalDeletionSettings} />
        )}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Chargers);
