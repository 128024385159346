import * as React from "react";
const { Spinner } = require("reactstrap");

import { Component, CustomButton } from "./button.styles";

type ButtonPropsTypes = {
  children?: React.ReactNode;
  buttonColor?: "blue" | "dark" | "red" | "green";
  fontColor?: "dark" | "light";
  loadingRequisiton?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  buttonColor = "dark",
  fontColor = "light",
  loadingRequisiton = false,
  ...rest
}: ButtonPropsTypes) => {
  return (
    <>
      <Component>
        <CustomButton buttonColor={buttonColor} fontColor={fontColor} {...rest}>
          {loadingRequisiton && <Spinner size="sm" />}
          {!loadingRequisiton && children}
        </CustomButton>
      </Component>
    </>
  );
};
