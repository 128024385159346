import React from 'react';
import logo from '../../../../assets/images/logo.png';
import { withTranslation } from 'react-i18next';

const Header = props => (
  // <div
  //   className="login-header d-none d-sm-flex align-items-center justify-content-center bg-primary py-5"
  //   style={{ marginLeft: '-360px' }}
  // >
  //   <p
  //     className="font-size-32 text-white mb-0 mx-2"
  //     style={{ marginTop: '-70px' }}
  //   >
  //     {props.t('Welcome to your')}
  //   </p>
  //   <div>
  //     <img src={logo} />
  //     <p className="font-size-32 text-white mx-5">
  //       {props.t('Manage your recharge points')}
  //     </p>
  //   </div>
  // </div>
  <>
    <div
      className="login-header d-none d-sm-flex align-items-center justify-content-center bg-primary py-5"
      style={{ marginLeft: '-360px' }}
    >
      <p
        className="font-size-32 text-white mb-0 mx-2"
        style={{ marginTop: '-70px' }}
      >
        {props.t('Welcome to your')}
      </p>
      <div>
        <img src={logo} />
        <p className="font-size-32 text-white mx-5">
          {props.t('Manage your recharge points')} 
        </p>
      </div>
    </div>

    <div className="login-header d-flex d-sm-none justify-content-center bg-primary py-5" style={{ paddingBottom: 50 }}>
      <img src={logo} className="pb-4" />
    </div>
  </>
);

export default withTranslation()(Header);