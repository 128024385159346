import React from "react";

const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} = require("reactstrap");

import Error from "./Error";

import { withTranslation } from "react-i18next";

interface ErrorProps {
  t: Function;
  status?: any;
  message: any;
  isOpen: boolean;
  onClose: () => void;
}

function ErrorModal({ t, status, message, isOpen, onClose }: ErrorProps) {
  return (
    <Modal isOpen={isOpen} centered fullscreen="sm">
      <ModalHeader>
        <span className="text-primary">{t("There was an Error")}</span>
      </ModalHeader>
      <ModalBody>
        <Error status={status} message={message} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>{t("Close")}</Button>
      </ModalFooter>
    </Modal>
  );
}

export default withTranslation()(ErrorModal);
