import { format } from "date-fns";

export const formatFullDateAndTimeHelper = (rawDateTime, systemLanguage) => {
  if (
    rawDateTime === null ||
    typeof rawDateTime === "undefined" ||
    rawDateTime === 0 ||
    !rawDateTime.length
  ) {
    return null;
  }

  const portuguese = "pt-BR";
  const english = "en-US";
  const locale = systemLanguage === portuguese ? portuguese : english;

  const date = new Date(rawDateTime);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const amORpm = hours >= 12 ? "pm" : "am";

  // Making en-US timestamp
  // Expected output => mm/dd/yyyy - hh:mm (am/pm)
  const enUSTimeHoursConvertion = String(hours % 12 || 12).padStart(2, "0");
  const enUSTimeHoursComplete = `${enUSTimeHoursConvertion}:${minutes} ${amORpm}`;
  const enUSDateFormat = `${month}/${day}/${year}`;
  const enUSTimestamp = `${enUSDateFormat} - ${enUSTimeHoursComplete}`;

  // Making pt-BR timestamp
  // Expected output => dd/mm/yyyy - hh:mm (24 hrs)
  const ptBRTimeHoursComplete = `${hours}:${minutes} hrs`;
  const ptBRDateFormat = `${day}/${month}/${year}`;
  const ptBRTimestamp = `${ptBRDateFormat} - ${ptBRTimeHoursComplete}`;

  return locale === english ? enUSTimestamp : ptBRTimestamp;
};

export const newFormatFullDateAndTimeHelper = (dataString, systemLanguage) => {

  const portuguese = "pt-BR";
  const english = "en-US";
  const locale = systemLanguage === portuguese ? portuguese : english;

  const data = new Date(dataString);
  const formattedDataPtBR = format(data, "dd/MM/yyyy - HH:mm 'hrs'");
  const formattedDataEnUS = format(data, "MM/dd/yyyy - hh:mm a");
  return locale === english ? formattedDataEnUS : formattedDataPtBR;
};

export const formatTimeHelper = (rawDateTime, systemLanguage) => {
  const portuguese = "pt-BR";
  const english = "en-US";
  const locale = systemLanguage === portuguese ? portuguese : english;

  const date = new Date(rawDateTime);

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const amORpm = hours >= 12 ? "pm" : "am";

  // Making en-US timestamp
  // Expected output => mm/dd/yyyy - hh:mm (am/pm)
  const enUSTimeHoursConvertion = String(hours % 12 || 12).padStart(2, "0");
  const enUSTimeHoursComplete = `${enUSTimeHoursConvertion}:${minutes} ${amORpm}`;
  const enUSTimestamp = `${enUSTimeHoursComplete}`;

  // Making pt-BR timestamp
  // Expected output => dd/mm/yyyy - hh:mm (24 hrs)
  const ptBRTimeHoursComplete = `${hours}:${minutes} hrs`;
  const ptBRTimestamp = `${ptBRTimeHoursComplete}`;

  return locale === english ? enUSTimestamp : ptBRTimestamp;
};

export const formatDateHelper = (rawDate, systemLanguage) => {
  const portuguese = "pt-BR";
  const english = "en-US";
  const locale = systemLanguage === portuguese ? portuguese : english;

  const date = new Date(rawDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  // Making en-US datestamp
  // Expected output => mm/dd/yyyy
  const enUSDatestamp = `${month}/${day}/${year}`;

  // Making pt-BR datestamp
  // Expected output => dd/mm/yyyy
  const ptBRDatestamp = `${day}/${month}/${year}`;

  return locale === english ? enUSDatestamp : ptBRDatestamp;
};

export const FormatDateHelper = (data) => {
  const date = new Date(data);
  const dia = date.getDate().toString().padStart(2, "0");
  const mes = (date.getMonth() + 1).toString().padStart(2, "0");
  const horario =
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0") +
    "h";

  return dia + "/" + mes + "/" + date.getFullYear() + " - " + horario;
  // return dia + "/" + mes + "/" + date.getFullYear() + " • " + horario;
};

export const formatUsagePeriod = (data) => {
  if (
    data === null ||
    typeof data === "undefined" ||
    data === 0 ||
    !data.length
  ) {
    return null;
  }

  if (data.split(":").length<4) {
    data = '0d:' + data;
  }

  const [days, hours, minutes, seconds] = data.split(":");

  const period = `${days.padStart(2, "0")}d`;
  const time = `${hours}:${minutes}:${seconds}`;

  return `${Number(days) ? period : ""} ${time}`;
};

export const getDayHelper = (data) => {
  const date = new Date(data);
  const dia = date.getDate().toString().padStart(2, "0");

  return dia;
};

export const getMonthNameHelper = (data) => {
  const date = new Date(data);
  const month = date.getMonth().toString();

  const months = Array.from({ length: 12 }, (e, i) => {
    return new Date(null, i + 1, null).toLocaleDateString("en", {
      month: "long",
    });
  });

  return months[month];
};
export const getYearHelper = (data) => {
  const year = new Date(data).getFullYear();

  return year;
};

export const validaEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validaCep = (cep) => {
  var re = /^[0-9]{5}\-[0-9]{3}$/;
  return re.test(cep);
};

// export const formatCurrency = (
//   incomingValue,
//   locale = "pt-BR",
//   currency = "BRL",
// ) => {
//   const incomings = Number(incomingValue);

//   const formattedCurrency = new Intl.NumberFormat(locale, {
//     style: "currency",
//     currency,
//   }).format(incomings);

//   return formattedCurrency;
// };

export const formatCurrency = (rawValue, language) => {
  if (rawValue === null || typeof rawValue === "undefined") {
    return null;
  }

  const portuguese = "pt-BR";
  const english = "en-US";
  const currency = "BRL";
  const value = Number(rawValue);

  const locale = language === portuguese ? portuguese : english;

  const formattedCurrency = new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
  }).format(value);

  if (locale === english) {
    const formattedCurrencySpaceStart = formattedCurrency.replace("R$", "R$ ");

    return formattedCurrencySpaceStart;
  }

  return formattedCurrency;
};

export const formatValuesByLanguage = (value, language) => {
  const converted = Number(value);
  const portuguese = "pt-BR";
  const english = "en-US";
  const locale = language === portuguese ? portuguese : english;

  const newValue = converted.toFixed(2);
  const USCurrency = String(newValue).replace(".", ",");
  const PTCurrency = String(newValue).replace(",", ".");

  return locale === english ? PTCurrency : USCurrency;
};

export const convertToMinutes = (chargeTime) => {
  const [hours, minutes, seconds] = chargeTime.split(":");
  return Number(hours) * 60 + Number(minutes);
};

export const formatMonthYearHelper = (rawDate) => {
  const date = String(rawDate);
  const [year, month] = date.split("-");

  const newDate = `${month}/${year}`;

  return newDate;
};
