import * as React from "react";
import * as Translator from "react-i18next";
import * as RouterDom from "react-router-dom";

import * as Provider from "../../../../../../context/ContextProvider";
import { AxiosApi as api } from "../../../../../../helpers/api";

import { Module } from "../Module/Module";
import { Button } from "../../../../../../components/Button";
import { ModalAction } from "../../../../../../components/Modal/ModalAction";
import ErrorModal from "../../../../../../components/Alerts/ErrorModal";
import SuccessModal from "../../../../../../components/Alerts/SuccessModal";

import { RouterDomTypes } from "../../../../../../interfaces/RouterDom";
import { ChargerPointTypes } from "../../../../../../interfaces/Chargers";
import { ModaActionlTypes } from "../../../../../../interfaces/Modal";
import { ChargerProfileTypes } from "../../../../../../interfaces/ChargerPricification";
import {
  Exception,
  ResponseSuccess,
} from "../../../../../../interfaces/Exception";

import {
  Component,
  Wrapper,
  Content,
  // DropList,
} from "./chargerpricification.styles";
import { Droplist } from "../../../../../../components/Droplists";
import { DroplistTypes } from "../../../../../../interfaces/Droplist";

type ChargerPricificationPropsTypes = {
  t: Function;
  selectedCharger: ChargerPointTypes;
  refreshChargersList: () => void;
};

type SelectProfile = {
  profileName: string;
};

enum ProfileName {
  DEFAULT = "DEFAULT_PRICE_PROFILE",
}

const ChargerPricification = ({
  t: translation,
  selectedCharger,
  refreshChargersList,
}: ChargerPricificationPropsTypes) => {
  const charger: ChargerPointTypes = selectedCharger;

  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<
    ResponseSuccess | undefined
  >();

  const [chargerProfilesData, setChargerProfilesData] = React.useState<
    ChargerProfileTypes[]
  >([]);
  const [chargerProfile, setChargerProfile] = React.useState<ChargerPointTypes>(
    {} as ChargerPointTypes,
  );

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalLoadingRequisition: false,
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const droplistOptions: DroplistTypes = chargerProfilesData.map((profile) => {
    return {
      name:
        profile.priceProfileName === ProfileName.DEFAULT
          ? translation("global_DefaultProfile")
          : profile.priceProfileName,
      value: profile.priceProfileId,
    };
  });

  // Functions
  const onChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const mutableProfileData: ChargerProfileTypes[] =
      structuredClone(chargerProfilesData);

    const profile = mutableProfileData.find(
      (profile) => profile.priceProfileId === Number(event.target.value),
    );

    if (profile === null || typeof profile === "undefined") return;

    const settings: ModaActionlTypes = {
      modalIsOpen: true,
      modalTitle: translation("chargers_ChargerPricificationModalActionTitle"),
      modalInfo: <RenderSelectProfile profileName={profile.priceProfileName} />,
      modalButtonConfirmText: translation("global_Yes"),
      modalButtonCancelText: translation("global_Cancel"),
      modalLoadingRequisition: false,
      modalOnConfirmAction: () => dispatchProfile(profile),
      modalOnRequestClose: onRequestCloseModalAction,
    };

    prepareModalAction(settings);
  };

  const prepareModalAction = (settings: ModaActionlTypes) => {
    setModalAction({ ...settings });
  };
  const onRequestCloseModalAction = () => {
    setModalAction((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  // Elements
  const RenderSelectProfile = ({ profileName }: SelectProfile): JSX.Element => {
    return (
      <>
        {translation("chargers_ChargerPricificationModalActionTextBefore")}{" "}
        <strong className={"text-danger"}>
          {profileName === ProfileName.DEFAULT
            ? translation("global_DefaultProfile")
            : profileName}
        </strong>{" "}
        {translation("chargers_ChargerPricificationModalActionTextAfter")}
      </>
    );
  };

  // API calls
  const getProfiles = async () => {
    try {
      const { data } = await api.get<ChargerProfileTypes[]>(
        `/PriceProfiles?siteid=${site_id}`,
      );

      const mutableData = structuredClone(data);
      const sortedData = mutableData.sort((_A, _B) => {
        // Ensures that default price profile is the first element
        if (_A.priceProfileName === ProfileName.DEFAULT) return 0;

        if (_A.priceProfileId > _B.priceProfileId) return 1;
        if (_A.priceProfileId < _B.priceProfileId) return -1;
        return 0;
      });

      setChargerProfilesData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const dispatchProfile = async (profile: ChargerProfileTypes) => {
    try {
      const mutableChargerData: ChargerPointTypes = structuredClone(charger);
      mutableChargerData.cpPriceProfileId = profile.priceProfileId;

      const payload: ChargerPointTypes = structuredClone(mutableChargerData);

      const response = await api.put<ChargerPointTypes>(
        `/ChargePoints/${charger.chargePointId}`,
        payload,
      );

      if (response.status === 204) {
        console.log("status", response.status);
        setHandleSuccess(() => ({
          isOpen: true,
          message: translation(
            "chargers_ChargerPricificationResponseSuccessModal",
          ),
          onClose: () => setHandleSuccess(undefined),
        }));
      }

      setChargerProfile(payload);
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: translation("global_ErrorModalMessage"),
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      refreshChargersList();
      onRequestCloseModalAction();
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    getProfiles();
  }, [state.sites, site_id]);

  React.useMemo(() => {
    if (charger === null || typeof charger === "undefined") return;
    if (!chargerProfilesData.length) return;

    const mutableChargerData: ChargerPointTypes = structuredClone(charger);
    const mutableChargerProfilesData: ChargerProfileTypes[] =
      structuredClone(chargerProfilesData);

    const profileDefault = mutableChargerProfilesData.find(
      (profile) => profile.priceProfileName === ProfileName.DEFAULT,
    );

    if (
      mutableChargerData.cpPriceProfileId === null ||
      typeof mutableChargerData.cpPriceProfileId === "undefined"
    ) {
      mutableChargerData.cpPriceProfileId = profileDefault?.priceProfileId;
    }

    setChargerProfile(mutableChargerData);
  }, [charger, chargerProfilesData]);

  const pageWidth = document.documentElement.scrollWidth;

  // Render
  return (
    <>
      <Component>
        <Module>
          <Wrapper>
            <Content>
              <div className="inner-content">
                <p>
                  <strong>
                    {translation("chargers_ChargerPricificationTitleStrong")}
                  </strong>{" "}
                  {translation("chargers_ChargerPricificationTitleInfo")}
                </p>
              </div>

              <div style={{display: pageWidth <= 1500 ? 'grid' : 'flex', gap: '15px'}}>
                {!!chargerProfilesData.length && (
                  <Droplist
                    className="droplist"
                    options={droplistOptions}
                    value={chargerProfile.cpPriceProfileId}
                    onChange={onChangeValue}
                    enablePrimaryOption={false}
                    lowerView={pageWidth >= 1500 ? false : true}
                  />
                )}

                <RouterDom.Link to={`/pricification/${site_id}`}>
                  <Button buttonColor={"blue"}>
                    {translation("chargers_ChargerPricificationButtonProfiles")}
                  </Button>
                </RouterDom.Link>
              </div>
            </Content>
          </Wrapper>
        </Module>

        {handleError && <ErrorModal {...handleError} />}
        {handleSuccess && <SuccessModal {...handleSuccess} />}
        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerPricification);
