import "./SidebarContent.scss";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import BookmarkIcone from "./../../assets/images/bookmark.png";
import ChargersIcone from "./../../assets/images/chargers.png";
import PriceTagIcon from "./../../assets/images/pricetag.png";
import ConsumerIcon from "./../../assets/images/consumers.png";
import EnergyIcon from "./../../assets/images/energyIcon.png";
import PieChartIcon from "./../../assets/images/pie-chart.png";
import UsersIcon from "./../../assets/images/users.png";
import AddNewIcon from "./../../assets/images/addNew.png";
import FinanceIcon from "./../../assets/images/icon_finance.png";
import SmartSpottIcon from "./../../assets/images/icon_Smart.png";
import SupportIcon from "./../../assets/images/support.png";
import QuestionIcon from "./../../assets/images/question.png";
import { useStateContext } from "../../context/ContextProvider";

import { useParams } from "react-router-dom";

const SidebarContent = (props) => {
  const ref = useRef();
  const [initMenuState, setInitMenuState] = useState(false);
  const [mockSites, setMockSites] = useState([]);
  const [alertsDashboardState, setAlertsDashboardState] = useState([]);
  const pathName = props.location.pathname;
  const { context, state } = useStateContext();

  const params = useParams();

  useEffect(() => {
    if (state.sites?.length !== 0 && state?.sites !== mockSites)
      setMockSites(state.sites);
  }, [state.sites]);

  useEffect(() => {
    if (state.alerts)
      setAlertsDashboardState(state.alerts.filter((alert) => alert.isNew));
  }, [state.alerts]);

  useEffect(() => {
    if (mockSites.length > 0 && !initMenuState) initMenu();
  }, [mockSites]);

  const initMenu = () => {
    if (!initMenuState) setInitMenuState(true);
    new MetisMenu("#side-menu");
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");

    const baseURL = (str) => {
      const posFim = str.substring(1, str.length).indexOf("/") + 1;
      return str.substring(1, posFim);
    };

    const finalURL = (str) => {
      const splitStr = str.split("/");
      return splitStr[splitStr.length - 1];
    };

    for (let i = 0; i < items.length; ++i) {
      if (
        baseURL(pathName) === baseURL(items[i].pathname) &&
        finalURL(pathName) === finalURL(items[i].pathname)
      ) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }

    ref.current.recalculate();
  };

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  const activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li item site
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); //ul sub site
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li sub site container
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; //ul 2

          if (parent4) {
            parent4.classList.add("mm-active");
            parent4.classList.add("mm-show");

          }
        }
      }
      return false;
    }
    return false;
  };

  const removeItemsFromLocalStorage = () => {
    localStorage.removeItem("profileID")
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu" onClick={() => removeItemsFromLocalStorage()}>
            <li>
              <Link
                to={{ pathname: `/alerts` }}
                className="waves-effect waves-ripple"
              >
                <i className="ti-info-alt"></i>
                {alertsDashboardState.length > 0 ? (
                  <span className="badge rounded-pill bg-danger float-end">
                    {alertsDashboardState.length}
                  </span>
                ) : null}
                <span>{props.t("Alerts")}</span>
              </Link>
            </li>

            <li>
              <Link to={{ pathname: `/dashboard` }} className="waves-effect">
                <i className="ti-home"></i>
                <span>{props.t("Home")}</span>
              </Link>
            </li>

            <li>
              <Link to={{ pathname: `/monitorings` }} className="waves-effect">
                <i>
                  <img src={EnergyIcon} />
                </i>
                <span>{props.t("Monitoring")}</span>
              </Link>
            </li>

            <li>
              <Link to={{ pathname: `/` }} className="has-arrow waves-effect">
                <i className="ti-package"></i>
                <span>{props.t("Sites")}</span>
              </Link>

              <ul className="sub-menu" aria-expanded="true">
                {mockSites?.length > 0
                  ? mockSites.map((site) => (
                      <li key={site.csId}>
                        <Link
                          to={{ pathname: `` }}
                          className="has-arrow waves-effect"
                        >
                          <i className="ti-location-pin"></i>
                          <span>{site.csName}</span>
                        </Link>

                        <ul
                          className="sub-menu mm-collapse"
                          aria-expanded="true"
                        >
                          {/* <li>
                            <Link to={{ pathname: `/dashboard/${site.csId}` }}>
                              <i className="ti-home"></i>
                              <span>{props.t("Dashboard")}</span>
                            </Link>
                          </li> */}

                          {/* <li>
                        <Link to={{ pathname: `/Subscription/${site.csId}` }}>
                          <img src={BookmarkIcon} />
                          <span className="mx-3">
                            {props.t('Subscription')}
                          </span>
                        </Link>
                      </li> */}

                          <li>
                            <Link to={{ pathname: `/dashboard/${site.csId}` }}>
                              <img src={BookmarkIcone} alt="smart-spott" />
                              <span className="mx-3">
                                {props.t("Dashboard")}
                              </span>
                            </Link>
                          </li>

                        {['ADMIN','ENGINEER','PARTNER'].includes(site.uspRole) ? (
                          <li>
                            <Link to={{ pathname: `/smartspott/${site.csId}` }}>
                              <img src={SmartSpottIcon} alt="smart-spott" />
                              <span className="mx-3">
                                {props.t("Smart Spott")}
                              </span>
                            </Link>
                          </li>
                        ) : null}
                        {['ADMIN', 'ENGINEER','PARTNER'].includes(site.uspRole) ? (
                          <li>
                            <Link
                              to={{
                                pathname: `/chargers/${site.csId}`,
                              }}
                            >
                              <img src={ChargersIcone} alt="smart-spott" />
                              <span className="mx-3">
                                {props.t("Chargers")}
                              </span>
                            </Link>
                          </li>
                        ) : null}
                          <li>
                            <Link
                              to={{
                                pathname: `/monitoring/${site.csId}`,
                              }}
                            >
                              <img src={EnergyIcon} />
                              <span className="mx-3">
                                {props.t("Monitoring")}
                              </span>
                            </Link>
                          </li>

                          <li>
                            <Link to={{ pathname: `/finance/${site.csId}` }}>
                              <img src={FinanceIcon} />
                              <span className="mx-3">
                                {" " + props.t("Finance")}
                              </span>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to={{ pathname: `/transactions/${site.csId}` }}
                            >
                              <i className="ti-support"></i>
                              <span>{props.t("Transactions")}</span>
                            </Link>
                          </li>

                          <li>
                            <Link to={{ pathname: `/users/${site.csId}` }}>
                              <img src={ConsumerIcon} />
                              <span className="mx-3">{props.t("Users")}</span>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to={{ pathname: `/performance/${site.csId}` }}
                            >
                              <img src={PieChartIcon} />
                              <span className="mx-3">
                                {" " + props.t("Performance")}
                              </span>
                            </Link>
                          </li>

                        {['ADMIN','PARTNER'].includes(site.uspRole) ? (
                          <li>
                            <Link
                              to={{ pathname: `/pricification/${site.csId}` }}
                            >
                              <img src={PriceTagIcon} />
                              <span className="mx-3">{props.t("Price")}</span>
                            </Link>
                          </li>
                        ) : null}
                        {['ADMIN'].includes(site.uspRole) ? (
                          <li>
                            <Link
                              to={{ pathname: `/permissions/${site.csId}` }}
                            >
                              <img src={UsersIcon} />
                              <span className="mx-3">
                                {props.t("permissions_PageTitle")}
                              </span>
                            </Link>
                          </li>
                        ) : null}

                        </ul>
                      </li>
                    ))
                  : null}
              </ul>
            </li>

            <li>
              <Link
                to={{ pathname: `/NewSite` }}
                className="waves-effect d-flex align-items-center"
              >
                <img src={AddNewIcon} />
                <p className="mx-3 mb-0">{props.t("Register a New Site")}</p>
              </Link>
            </li>
            {/* <li>
              <Link
                to={{ pathname: `/DisabledSites` }}
                className="waves-effect d-flex align-items-center"
              >
                <img src={SupportIcon} />
                <p className="mx-3 mb-0">{props.t("Disabled Sites")}</p>
              </Link>
            </li> */}
            <li>
              <Link
                to={{ pathname: `/Support` }}
                className="waves-effect d-flex align-items-center"
              >
                <img src={QuestionIcon} />
                <p className="mx-3 mb-0">{props.t("Support")}</p>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
