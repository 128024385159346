import React from 'react';
import { Col } from 'reactstrap';
import './SubscriptionBullets.scss';

export default function SubscriptionBullets(props) {
    return (
        <Col md={6} className="d-flex subscriptionBullet">
            <img src={props.icon} className="subscriptionBullet__icon" />
            <div>
                <span className="subscriptionBullet__title">
                    {props.title}
                </span>
                <p> {props.children} </p>
            </div>
        </Col>
    );
}