const SubscriptionSettings = {
    operationCost: "+ $25 per port per month",
    obs: [
        "¹ Transaction fee is the % of revenue collected from EV drivers that is retained by Sport. Transaction fees cover credit card processing costs and Spott services like our mobile apps, and more.",
        "² Spott O&M™ includes an annual on-site inspection and cleaning of EV chargers, 1 business day response time to charger failures, and parts & labor for repairs not covered under warranty. Spott O&M™ is not available in all geographies and pricing may vary by geography. In order to qualify, your chargers must be installed by an approved O&M partner."
    ],
    bulets: [
        {
            title: "EV driver interfaces",
            content: "All chargers networked by Spott are accessible to EV drivers through our mobile app any third-party app we integrate with."
        },
        {
            title: "Admin dashboard access",
            content: "Every ChargeLab plan comes with a configurable EV charging dashboard, plus access for an unlimited number of admin profile."
        },
        {
            title: "User access control",
            content: "Set EV chargers to public or private, create user groups, and set complex billing profiles. Full control over who uses your EV chargers, from the Essentials plan up."
        },
        {
            title: "24/7 call center",
            content: "We provide end-user support for EV drivers using any Spott-networked charger. Our human agents are available over SMS, chat, or toll-free phone number."
        }
    ]
}

const Cards = [
    {
        id: 1,
        active: false,
        title: "Basic",
        price: "R$ 0",
        priceType: "per month",
        description: "Connect any OCPP charger 5% transaction fee Enhanced latform access Maxi 9 ports",
        attributes: [
            ["Transaction fee¹", true],
            ["Connect any OCPP charger", true],
            ["Dashboard access", true],
            ["Bill usage fees to end-users", false],
            ["24/7 support", false],
            ["Power management features", false]
        ]
    },
    {
        id: 2,
        active: true,
        title: "Medium",
        price: "R$ 23",
        priceType: "per month",
        description: "Connect any OCPP charger 5% transaction fee Enhanced latform access Maxi 9 ports",
        attributes: [
            ["Transaction fee¹", true],
            ["Connect any OCPP charger", true],
            ["Dashboard access", true],
            ["Bill usage fees to end-users", true],
            ["24/7 support", false],
            ["Power management features", false]
        ]
    },
    {
        id: 3,
        active: false,
        title: "Super",
        price: "R$ 32",
        priceType: "per month",
        description: "Connect any OCPP charger 5% transaction fee Enhanced latform access Maxi 9 ports",
        attributes: [
            ["Transaction fee¹", true],
            ["Connect any OCPP charger", true],
            ["Dashboard access", true],
            ["Bill usage fees to end-users", true],
            ["24/7 support", true],
            ["Power management features", true]
        ]
    },
    {
        id: 4,
        active: false,
        title: "Premium",
        price: "R$ 43",
        priceType: "per month",
        description: "Connect any OCPP charger 5% transaction fee Enhanced latform access Maxi 9 ports",
        attributes: [
            ["Transaction fee¹", true],
            ["Connect any OCPP charger", true],
            ["Dashboard access", true],
            ["Bill usage fees to end-users", true],
            ["24/7 support", true],
            ["Power management features", true]
        ]
    }
]

export { SubscriptionSettings, Cards };