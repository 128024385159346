import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type BackgroundPropsTypes = {
  connectorStatus: "offline" | "available" | "using" | "unavailable";
};

const ConnectorStatusColor = {
  offline: "#c5c5cb",
  available: "#00da71",
  using: "#0083da",
  unavailable: "#da1600",
};

export const Background = styled.div<BackgroundPropsTypes>`
  background-color: ${({ connectorStatus }) =>
    ConnectorStatusColor[connectorStatus ?? "offline"]};
  border: 1px solid
    ${({ connectorStatus }) =>
      darken(0.088, ConnectorStatusColor[connectorStatus ?? "offline"])};
  color: white;
  width: 8rem;
  padding: 0.8rem;
  margin-bottom: 0.48rem;
  border-radius: 0.32rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.88rem;
  line-height: 0.88rem;
  text-align: center;
  text-transform: capitalize;

  user-select: none;
  transition: filter 300ms;
  &:hover {
    filter: brightness(0.9);
  }
`;
