import React from 'react';
import './SubscriptionCard.scss';
import { Card, CardBody, } from 'reactstrap';

export default function SubscriptionCard({ id, title, price, description, active, setActiveSubscription, plans }) {

    const handleSubcription = (idAtivo) => {
        const newPlan = plans.map(plan => (checkAtivo(plan, idAtivo)));
        setActiveSubscription(newPlan);
    };

    const checkAtivo = (card, id) => {
        (card.id == id) ? card.active = true : card.active = false;
        return card;
    };

    return (
        <div className="col-md-6 col-xl-3 subscriptionCard" id={`card${id}`}>
            <Card className={`m-0 d-flex justify-content-center align-items-center ${active ? 'subscriptionCard__active' : 'pb-5'}`}>
                <CardBody className="d-flex flex-column">

                    {active ? (
                        <span className="subscriptionCard__activeSub">
                            Your Subscription
                        </span>
                    ) : null}

                    <span className="subscriptionCard__title">{title}</span>
                    <span className="subscriptionCard__price">{price} / port</span>
                    <span>per month</span>
                    <p className="subscriptionCard__description">{description}</p>

                    {active ? (
                        <div className="d-flex flex-column mt-5 subscriptionCard__dates">
                            <span>Subscribed in 24/12/2021</span>
                            <span>Expire on 24/01/2022</span>
                        </div>
                    ) : null}

                </CardBody>

                {!active ? (
                    <div
                        className="btn d-flex justify-content-center align-items-center bg-primary text-white subscriptionCard__button"
                        onClick={() => handleSubcription(id)}
                    >
                        <span>UPGRADE</span>
                    </div>
                ) : null}

            </Card>
        </div>
    );
};



// <div class="col-md-6 col-xl-3">
//     <div class="mini-stat bg-primary text-white card">
//         <div class="card-body">
//             <div class="mb-4">
//                 <div class="float-start mini-stat-img me-4">ico</div>
//                 <p class="font-size-16 text-uppercase mt-0 mb-0 text-white-50">ENERGY CONSUMPTION</p>
//                 <h4 class="fw-medium font-size-24">15.8 <i class="mdi mdi-arrow-up text-success ms-2"></i></h4>
//                 <div class="mini-stat-label bg-info">
//                     <p class="mb-0"> 00%</p></div>
//             </div>
//             <div class="pt-2">
//                 <div class="float-end">
//                     <a class="text-white-50" href="/dashboard">
//                         <i class="mdi mdi-arrow-right h5"></i>
//                     </a>
//                 </div>
//                 <p class="text-white-50 mb-0 mt-1">Last 30 days</p>
//             </div>
//         </div>
//     </div>
// </div>