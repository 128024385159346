import {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from "react";
import { AxiosApi } from "../helpers/api";
import { useParams } from "react-router-dom";
import { format, subDays} from 'date-fns';

const StateContext = createContext({});
export const useStateContext = () => useContext(StateContext);

export default function ContextProvider(props) {

  const today = new Date();
  const todayFormatted =  format(today, "yyyy-MM-dd'T'HH:mm:ss'Z'")
  const lastThirdDays = subDays(today, 30);
  const last30Days = new Date(lastThirdDays.getFullYear(), lastThirdDays.getMonth(), lastThirdDays.getDate());
  const lastThirdDaysFormatted = format(last30Days, "yyyy-MM-dd'T'HH:mm:ss'Z'")

  const initialState = {
    sites: [],
    userData: null,
    avatar: null,
    alerts: null,
    lastUpdate: null,
    token: null,
    chargers:null,
    price_profile:null,
    authorizationReload:false,
    inicialPickerData:{
      start:lastThirdDaysFormatted,
      end: todayFormatted
    }
  };

  const [state, dispatch] = useReducer((prevState, action) => {
    switch (action.type) {
      case "LOAD_USEREDATA":
        return {
          ...prevState,
          sites: action.sites,
          avatar: action.avatar,
          userData: action.userData,
          alerts: action.alerts,
          lastUpdate: Date.now(),
        };
      case "UPDATE_AVATAR":
        return {
          ...prevState,
          avatar: action.avatar,
        };
      case "UPDATE_SITES":
        return {
          ...prevState,
          sites: action.sites,
        };
      case "CHANGE_LANGUAGE":
        return {
          ...prevState,
          userData: {
            ...prevState.userData,
            language: action.language,
          },
        };
      case "UPDATE_CHARGES":
        return {
          ...prevState,
          chargers: action.chargers,
        };
        case "PRICE_PROFILE":
          return {
            ...prevState,
            price_profile: action.price_profile,
          };
        case "UPDATE_AUTHORIZATION_RELOAD":
        return {
          ...prevState,
          authorizationReload: action.authorizationReload,
        };

       
    }
  }, initialState);

  const stateContext = useMemo(
    () => ({
      avatarUpdate: async () => {
        try {
          const avatarApi = await AxiosApi.get("Avatar");
          dispatch({ type: "UPDATE_AVATAR", avatar: avatarApi.data });
        } catch (error) {
          console.log(error);
        }
      },

      sitesUpdate: async () => {
        try {
          const userSitesApi = await AxiosApi.get("UserSites");
          dispatch({ type: "UPDATE_SITES", sites: userSitesApi.data });
        } catch (error) {
          console.log(error);
        }
      },

      getUserState: async () => {
        try {
          const userSitesApi = await AxiosApi.get("UserSites");
          const avatarApi = await AxiosApi.get("Avatar");
          const userDataApi = await AxiosApi.get("UserProfile");
          const alertsApi = await AxiosApi.get("UserAlerts");
          dispatch({
            type: "LOAD_USEREDATA",
            sites: userSitesApi.data,
            avatar: avatarApi.data,
            userData: userDataApi.data,
            alerts: alertsApi.data,
          });
        } catch (error) {
          console.log("ERRO > getUserState: " + error);
        }
      },

      changeLanguage: async (language) => {
        const langApi = await AxiosApi.put("UserLanguage", {
          language: language,
        }).catch((error) => console.log(error));
        dispatch({ type: "CHANGE_LANGUAGE", language });
      },

      getUserCharger: async (site_id) => {
        try {
          const chargersApi = await AxiosApi.get(`/site/chargepoints/${site_id}`, {})
          dispatch({ type: "UPDATE_CHARGES", chargers: chargersApi.data });
        } catch (error) {
          console.log(error);
        }
      },
      getPriceProfile: async (site_id) => {
        try {
          const { data } = await AxiosApi.get(
            `/PriceProfiles?siteid=${site_id}`,
          );
          const mutableData = [...data];
          const sortedData = mutableData.sort(  (dataA, dataB) => {
            if (dataA.priceProfileId > dataB.priceProfileId) return 1;
            if (dataA.priceProfileId < dataB.priceProfileId) return -1;
    
            return 0;
          });
          dispatch({ type: "PRICE_PROFILE", price_profile: sortedData });
          
        } catch (error) { 
          console.log(error);
        }
      },
      getAuthorizationReload: (valueUpdated) => {
        dispatch({ type: "UPDATE_AUTHORIZATION_RELOAD", authorizationReload: valueUpdated });
      },


    }),
    [],
  );

  return (
    <StateContext.Provider value={{ context: stateContext, state }}>
      {props.children}
    </StateContext.Provider>
  );
}
