import * as React from "react";
import * as Translator from "react-i18next";
import { Button } from "../../../../../../components/Button";

import * as Provider from "../../../../../../context/ContextProvider";

import {
  formatCurrency,
  formatDateHelper,
  getMonthNameHelper,
  getYearHelper,
} from "../../../../../../helpers/Helpers";

import {
  Wrapper,
  Content,
  PriceAligment,
  ButtonsAligment,
  ExtractButton,
  HRefButton,
} from "./amountdisplayitem.styles";

type AmountDisplayItemPropsType = {
  t: Function;
  isPaid: boolean;
  totalAmount: number;
  netAmount: number;
  orderInvoiceUrl?: any;
  orderPaymentSchedule?: any;
  orderState?: string;
  orderUpdated?: Date;
  onRequestOpenModal: () => void;
  orderSchedule?: any;
  period?: string;
  scheduledPaymentDate?: string;
  orderStateTimestamp?: Date;
};

export const AmountDisplayItem = ({
  t: translation,
  isPaid,
  onRequestOpenModal,
  totalAmount,
  netAmount,
  period,
  orderInvoiceUrl,
  orderUpdated,
  orderState,
  orderPaymentSchedule,
  scheduledPaymentDate,
  orderStateTimestamp,
}: AmountDisplayItemPropsType) => {
  const { context, state }: any = Provider.useStateContext();
  const [userLanguage, setUserLanguage] = React.useState<string>("");

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);
  return (
    <>
      <Wrapper>
        <Content style={{ opacity: !totalAmount && !netAmount ? "0.6" : "1" }}>
          <PriceAligment tintItem={isPaid}>
            <div>
              <small>
                {translation("global_" + getMonthNameHelper(period))}{" "}
                {translation("global_Of")} {getYearHelper(period)}
              </small>
              <p>{translation("finance_ItemTotalAmount")}</p>

              <h2>
                {totalAmount && formatCurrency(totalAmount, userLanguage)}
              </h2>
            </div>

            <div>
              {!isPaid && <p>{translation("finance_ItemToReceiveAmount")}</p>}
              {isPaid && <p>{translation("finance_ItemReceivedAmount")}</p>}

              <h2>{netAmount && formatCurrency(netAmount, userLanguage)}</h2>
            </div>
          </PriceAligment>

          <ButtonsAligment tintItem={String(orderState)}>
            {/* <ExtractButton type="button" onClick={onRequestOpenModal}>
              {translation("finance_ItemButtonShowDetails")}
            </ExtractButton> */}

            <Button
              type={"button"}
              buttonColor={"dark"}
              onClick={onRequestOpenModal}
            >
              {translation("finance_ItemButtonShowDetails")}
            </Button>

            {isPaid && orderInvoiceUrl !== null && (
              <HRefButton
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {translation("finance_ItemButtonShowNote")}
              </HRefButton>
            )}

            {isPaid && (
              <>
                {orderState && orderState === "paid" ? (
                  <small>
                    {translation("finance_ItemPayd", {
                      date: formatDateHelper(orderStateTimestamp, userLanguage),
                    })}
                  </small>
                ) : orderState && orderState === "created" ? (
                  <small>
                    {translation("finance_ItemScheduled", {
                      date: formatDateHelper(
                        orderPaymentSchedule,
                        userLanguage,
                      ),
                    })}
                  </small>
                ) : (
                  <small>
                    {translation("finance_ItemCanceled", {
                      date: formatDateHelper(orderUpdated, userLanguage),
                    })}
                  </small>
                )}
              </>
            )}
          </ButtonsAligment>
        </Content>
      </Wrapper>
    </>
  );
};

export default Translator.withTranslation()(AmountDisplayItem);
