import { darken, transparentize } from "polished";
import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

export const Header = styled.header`
  background-color: #2168d3;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;

  img {
    padding: 1rem;
    margin: 0 auto;
    display: block;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Display = styled.div`
  background-color: white;

  width: 32rem;
  height: 20rem;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 0.8rem;

  position: absolute;
  top: 8.8rem;
  left: 50%;
  transform: translate(-50%, 0);

  border: 1px solid rgba(0, 0, 0, 0.128);
  border-radius: 2rem;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.128);

  transition: all 300ms;
  &:hover {
    border-color: ${transparentize(0.6, "#2168d3")};
    box-shadow: 0 0 8px 0 ${transparentize(0.6, "#2168d3")};
  }

  h1 {
    color: #3970f3;
    margin-top: 2rem;

    text-align: center;
    font-size: 2rem;
    font-weight: 500;
    line-height: 2rem;
  }

  @media (max-width: 480px) {
    width: 90%;

    h1 {
      color: #3970f3;
      margin-top: 2rem;
      font-size: 1.5rem;
      line-height: 1.5rem;
      font-family: inherit;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  gap: 0.6rem;
  padding-left: 1rem;
  margin-top: 4rem;
`;

export const WrapperError = styled.div`
  h1 {
    color: #ff3333;
    margin-top: 2rem;
    font-size: 1.8rem;
    text-align: center;
  }

  p {
    color: #515050;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 2rem;
    text-align: center;
  }
`;

export const Text = styled.span`
  font-size: 1.5rem;
  font-weight: 500;
  color: #3970f3;
`;

export const Email = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  color: #515050;
`;

export const Form = styled.form`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: rgba(0, 0, 0, 0.428);
    margin: 0;
    padding: 0;

    text-align: left;
    font-size: 0.9rem;
    font-weight: 500;
    word-wrap: break-word;
    line-height: 1.28rem;
    text-transform: none;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
    line-height: 1rem;
  }
`;

export const Alert = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0 1rem;
  display: block;
`;

export const AlertContent = styled.div`
  background-color: ${transparentize(0.85, "red")};
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid ${transparentize(0.78, "red")};
  border-radius: 0.25rem;

  h1 {
    font-size: 1.1rem;
    font-weight: 600;
    color: red;
    text-align: left;
    margin: 0px;
  }

  p {
    color: ${darken(0.18, "red")};
    margin: 0;
    padding: 0;

    font-size: 0.8rem;
    font-weight: 500;
    text-align: center;
    text-transform: none;
    line-height: 1rem;
    letter-spacing: 1px;
    text-align: left;
  }
`;

export const SubmitButton = styled.button`
  position: absolute;
  bottom: 2.8rem;

  background-color: #3970f3;
  color: white;
  width: 86.5%;
  height: 3.28rem;
  margin-top: 2.8rem;

  border: 0;
  border-radius: 10px;

  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  text-transform: uppercase;
  letter-spacing: 1px;

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.28);

  transition: all 300ms;
  &:hover {
    filter: brightness(1.1);
    box-shadow: 0 0 8px 0 ${transparentize(0.08, "#2168d3")};
  }
`;

export const FloatingFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  color: rgba(0, 0, 0, 0.4);
  margin-top: 16.5rem;
`;
