import styled from "styled-components";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 32rem;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.32rem;
  display: flex;
  align-items: center;
`;
