import * as React from "react";
import { Loader } from "../../Loader";
import { Component, Wrapper } from "./displayloading.styles";

export const DisplayLoading = () => {
  return (
    <>
      <Component>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Component>
    </>
  );
};
