import React from 'react';
import './styles.scss';

const CustomTitlePage = ({ title, subtitle }) => {
  return (
    <div>
      <h2 className="font-size-35 custom-title">{title}</h2>
      <p className="font-size-15 custom-subtitle">{subtitle}</p>
    </div>
  );
};

export default CustomTitlePage;
