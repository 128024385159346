import styled, { keyframes } from "styled-components";

interface ContainerProps {
  loaderColor: "white" | "blue";
  loaderSize?: "large" | "small";
}

const spinnerColor = {
  white: "white",
  blue: "#3971f4",
};

const LoaderAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
`;

export const Container = styled.div<ContainerProps>`
  display: inline-block;
  position: relative;
  width: ${({ loaderSize }) => (loaderSize === "small" ? 28 : 64)}px;
  height: ${({ loaderSize }) => (loaderSize === "small" ? 28 : 64)}px;
  margin: 0 auto;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: ${({ loaderSize }) => (loaderSize === "small" ? 22 : 48)}px;
    height: ${({ loaderSize }) => (loaderSize === "small" ? 22 : 48)}px;
    margin: ${({ loaderSize }) => (loaderSize === "small" ? 3 : 6)}px;
    border: ${({ loaderSize }) => (loaderSize === "small" ? 3 : 6)}px solid
      ${({ loaderColor }) =>
        loaderColor ? spinnerColor[loaderColor] : "white"};
    border-radius: 50%;
    animation: ${LoaderAnimation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${({ loaderColor }) =>
        loaderColor ? spinnerColor[loaderColor] : "white"}
      transparent transparent transparent;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
`;
