import { Container } from "./termsandconditionscontentapp.styles";

export const TermsAndConditionsContentApp = () => {
  return (
    <>
      <Container>
        <p>Bem-vindo (a) ao Aplicativo SPOTT!</p>
        <p>
          O presente documento vista estabelecer os Termos e Condições (“Termo”)
          de Uso aplicáveis ao Aplicativo SPOTT (“App” ou “Aplicativo” ou
          “Plataforma”). Leia atentamente os termos antes de usar o Aplicativo,
          pois é importante que você (“Usuário”) saiba o que esperar ao usar os
          serviços do Aplicativo e o que a SPOTT TECNOLOGIA LTDA. (“SPOTT”)
          espera do Usuário.
        </p>
        <p>
          Por favor, revise os termos de uso cuidadosamente antes de utilizá-lo.
          Se você não aceitar estes termos e condições, não poderá utilizar os
          serviços. A aceitação por parte do Usuário gera uma relação contratual
          com a SPOTT. Você reconhece que é e será sempre livre de utilizar ou
          não os serviços. O Aplicativo SPOTT é uma ferramenta que traz uma
          experiência completa de infraestrutura de carregamento aos motoristas
          de veículos elétricos e foi desenvolvida pela empresa SPOTT TECNOLOGIA
          LTDA. - CNPJ nº 45.057.615/0001-92.
        </p>

        <h3>1. ELEGIBILIDADE</h3>
        <p>
          A APLICAÇÃO NÃO ESTÁ DISPONÍVEL PARA MENORES DE 18 ANOS. Caso você
          seja menor de 18 anos, por favor, não utilize o Aplicativo. Ao usar,
          você declara que tem capacidade jurídica plena para firmar contratos
          válidos sob a legislação aplicável.
        </p>

        <h3>2. CADASTRO, CONTA, SENHA</h3>
        <p>
          Inicialmente, para acessar o conteúdo do Aplicativo, como por exemplo,
          o localizador de carregadores, não é necessário realizar nenhum tipo
          de cadastro, apenas autorizar o fornecimento da sua localização ao
          Aplicativo. No entanto, caso você opte por utilizar todas as
          funcionalidades do Aplicativo, será necessário realizar o cadastro.
        </p>
        <p>
          O cadastro apenas será confirmado se você, Usuário, preencher todos os
          campos obrigatórios, com informações exatas, precisas e verdadeiras.
          Você declara e assume o compromisso de atualizar os dados inseridos em
          seu cadastro (“Dados Pessoais”) sempre que for necessário. O registro
          de uma Conta de Usuário exige, além da criação de uma senha, o
          fornecimento de poucos Dados Pessoais à SPOTT, tais como: nome
          completo e e-mail.
        </p>
        <p>
          Após a criação da conta, serão solicitados alguns outros dados cujo
          fornecimento é obrigatório, como cidade, país, marca, modelo e cor do
          veículo, número do celular e data de nascimento. Além dos dados
          destacados acima, você poderá incluir, opcionalmente, uma foto sua no
          avatar do seu cadastro. O Usuário garante e responde, em qualquer
          caso, civil e criminalmente, pela veracidade, exatidão e
          autenticidade, dos Dados Pessoais cadastrados.
        </p>
        <p>
          A SPOTT se reserva no direito de recusar qualquer solicitação de
          cadastro e de suspender um cadastro previamente aceito, que esteja ou
          venha a ficar em desacordo com as políticas e regras dos presentes
          Termos e Condições de Uso. A SPOTT, em razão de violação à legislação
          em vigor ou aos Termos e Condições de Uso, conforme cada situação,
          poderá, sem prejuízo de outras medidas, recusar qualquer solicitação
          de cadastro, advertir, suspender, temporária ou definitivamente, a
          conta de um Usuário.
        </p>
        <p>
          O Usuário acessará sua conta através de e-mail (login) e senha e
          compromete-se a não informar a terceiros esses dados,
          responsabilizando-se integralmente pelo uso que deles seja feito. Você
          não pode autorizar terceiros a utilizarem a sua Conta. Você não pode
          ceder ou por outra forma transferir a sua Conta para qualquer outra
          pessoa ou entidade. A SPOTT, nem quaisquer de seus empregados ou
          prepostos solicitará, por qualquer meio, físico ou eletrônico, que
          seja informada sua senha.
        </p>
        <p>
          É de responsabilidade do Usuário: (a) manter o sigilo de sua
          identificação de conta e senha; (b) atualizar e revisar sua conta
          frequentemente; (c) manter seus dispositivos eletrônicos seguros, por
          exemplo, com uso de ferramentas de antivírus e firewall; e (d) avisar
          prontamente a SPOTT caso ocorra qualquer utilização não autorizada de
          sua conta ou qualquer quebra de segurança. Você é responsável por
          todas as atividades que forem realizadas através de sua conta e a
          SPOTT não será responsável por qualquer perda ou dano resultante de
          sua falha em cumprir com as obrigações aqui previstas.
        </p>

        <h3>3. COMO UTILIZAR O APLICATIVO SPOTT</h3>
        <p>
          Ao realizar o login no Aplicativo, você terá acesso a todas as
          funcionalidades disponíveis, tais como: localizador de carregadores
          (com os filtros necessários para você fazer a escolha correta), tipos
          de carregadores, gerenciamento e pagamento das cargas realizadas no
          seu veículo. Após escolher um carregador compatível com o seu veículo,
          você deverá scanear o QR CODE constante no carregador, diretamente no
          campo indicado no App, para iniciar a carga. Todas as instruções de
          utilização estarão disponíveis nas telas do Aplicativo.
        </p>
        <p>
          Para realizar o pagamento das cargas, você poderá adicionar “cash”,
          que seria o valor do crédito que pretende utilizar no carregamento do
          veículo. Assim que você realizar a carga, o valor será descontado
          automaticamente do valor do crédito e você terá acesso ao painel de
          gerenciamento do seu veículo, onde será possível acompanhar o
          histórico de cargas, a porcentagem de carga em cada recarga e a
          performance do seu veículo.
        </p>

        <h3>4. PAGAMENTO</h3>
        <p>
          O usuário reconhece que a SPOTT não possui qualquer ingerência sobre
          os preços comercializados através dos carregadores. Como dito acima,
          para realizar o pagamento das cargas, o Usuário adicionará “cash”, que
          seria o valor do crédito que pretende utilizar no carregamento do
          veículo. Para adquirir “cash”, o pagamento é efetivado de acordo com a
          forma de pagamento selecionada pelo Usuário no Aplicativo.
        </p>
        <p>
          O preço total de cada recarga, ao final da recarga, será descontado do
          valor do crédito depositado como “cash”. é devido pelo Usuário quando
          a carga for concluída, o que será informado na tela do App, devendo
          ser pago ao final da transação de forma adiantada pelo Usuário, de
          acordo com a forma de pagamento selecionado pelo Usuário no
          Aplicativo.A transação pode ser interrompida em caso de saldo
          insuficiente em “cash”.
        </p>
        <p>
          A operação de compra da Plataforma funciona mediante o pagamento dos
          produtos pelo Usuário diretamente para a SPOTT, via XXXXempresas de
          Meio de Pagamentos parceiras. O Usuário terá a opção de cadastrar seus
          dados de cartão de crédito diretamente no Aplicativo, sendo que, após
          cadastrar um método de pagamento, o Usuário poderá identificar a
          cobrança de taxa simbólica para verificar se o método de pagamento é
          válido e está ativo. O valor será devidamente estornado em 30 (trinta)
          dias úteis no mesmo método de pagamento.
        </p>
        <p>
          A vinculação do método de pagamento, bem como o processamento das
          transações, está sujeita às disposições estabelecidas nos Termos e
          Condições de Uso e Política de Privacidade das Empresas de Meios de
          Pagamento responsáveis pela conta virtual do Usuário, bem como nas
          condições de segurança e mecanismos antifraude do Banco Emissor do
          cartão do Usuário, sem qualquer interferência da SPOTT.
        </p>
        <p>
          Se, por qualquer motivo, um pagamento do Usuário por meio do App não
          for autorizado pela empresa de Meio de Pagamento responsável pela
          captura, processamento e liquidação das transações financeiras, ou
          ainda pelo Banco Emissor do seu cartão de crédito, a transação poderá
          ser interrompida e o Usuário deverá dispor de outro meio de pagamento.
          Ocorrendo a hipótese descrita acima, independentemente do motivo,
          o Usuário não fará jus ao recebimento de qualquer reembolso ou
          indenização. A SPOTT não armazena quaisquer dados atrelados a
          pagamentos e, portanto, não possui qualquer responsabilidade por tais
          transações.
        </p>

        <h3>5. CAPTURA DE DADOS E PRIVACIDADE</h3>
        <p>
          A íntegra das Políticas de Privacidade da SPOTT pode ser acessada
          através do link “xxxxxPoliticas de privacidade” no site da Spott. Um
          resumo dos principais pontos será disponibilizado abaixo, porém esse
          resumo não exime o Usuário de realizar a leitura integral das
          Políticas de Privacidade.
        </p>
        <p>Principais pontos das Políticas de Privacidade:</p>
        <p>
          <b>- INFORMAÇÕES COLETADAS PELA SPOTT</b>: A fim de realizar o
          cadastro dos Usuários na plataforma, a SPOTT coleta dados de cadastro:
          nome completo, e-mail, cidade, país, marca, modelo e cor do veículo,
          número do celular e data de nascimento, além da localização e acesso à
          câmera ou biblioteca de fotos do dispositivo eletrônico, se autorizado
          pelo Usuário.
        </p>
        <p>
          <b>- COMPARTILHAMENTO DE DADOS COM TERCEIROS</b>: Exceto nas hipóteses
          especificadas na Política de PrivacidadePolitica de Privacidade, a
          SPOTT não realiza o compartilhamento de dados pessoais de seus
          usuários com terceiros. Nesses casos, sempre que a segurança das
          informações depender da SPOTT, a empresa se compromete a utilizar seus
          melhores esforços para manter seus dados em plena segurança.
        </p>
        <p>
          <b>- PROTEÇÃO E SEGURANÇA</b>: A SPOTT utiliza os mais modernos
          sistemas de segurança da informação em todos os nossos sistemas e
          dispositivos, o que inclui o uso de ferramentas tecnológicas como
          criptografias, controles de acesso, firewalls, registros de criação e
          acesso de contas, dentre outras. Caso ocorra qualquer incidente
          relacionados aos dados pessoais dos Usuários, a SPOTT enviará um
          comunicado aos usuários e às autoridades competentes dentro de um
          prazo razoável com as todas as informações necessárias.
        </p>
        <p>
          <b>- DIREITOS DOS TITULARES DOS DADOS PESSOAIS</b>: Nossas Políticas
          de Privacidade preveem todos os direitos legalmente garantidos aos
          Usuários cujos dados são tratados pela SPOTT. Acesse o documento para
          obter todas as informações a esse respeito.
        </p>
        <p>
          <b>- MANUTENÇÃO E EXCLUSÃO DOS DADOS PESSOAIS</b>: A SPOTT manterá as
          informações dos Usuários enquanto as respectivas contas forem mantidas
          na Plataforma. A qualquer tempo o Usuário poderá solicitar a exclusão
          da sua conta e dos seus dados pessoais diretamente pelos canais de
          comunicação da SPOTT. Mesmo diante de uma solicitação de exclusão dos
          dados, a SPOTT poderá manter algumas informações consigo, inclusive de
          forma anonimizada, nos termos da legislação em vigor.
        </p>
        <p>
          <b>- ATUALIZAÇÃO DAS POLÍTICAS DE PRIVACIDADE</b>: Publicaremos a
          versão atualizada em nosso Aplicativo sempre que realizarmos algum
          tipo de atualização e/ou alteração nessas Políticas de Privacidade e,
          caso seja necessário seu consentimento, solicitaremos imediatamente
          através do seu e-mail cadastrado conosco. Você reconhece que o aceite
          destes termos de uso será registrado nos nosso banco de dados e poderá
          ser utilizado como prova do consentimento, independentemente de outra
          formalidade.
        </p>

        <h3>6. LICENÇA E PROPRIEDADE INTELECTUAL</h3>
        <p>
          Todos os materiais exibidos ou disponibilizados no Aplicativo,
          incluindo, sem limitação, textos, gráficos, sons e áudios, documentos,
          vídeos, obras de arte, software, logos e código HTML (coletivamente, o
          &quot;Material&quot;) são de propriedade exclusiva da SPOTT ou de seus
          fornecedores de conteúdo, os quais reservam a si todos os seus
          direitos de propriedade intelectual. Os Materiais são protegidos pelas
          leis de direitos autorais, propriedade intelectual e outras regras,
          regulamentos e legislações brasileiras, americanas e internacionais de
          propriedade intelectual aplicáveis. 
        </p>
        <p>
          A SPOTT concede a você uma licença limitada, não-sublicenciável,
          não-exclusiva, revogável e inalienável que permite:
        </p>
        <p>
          <b>I</b> - o acesso e utilização do Aplicativo no dispositivo pessoal
          do Usuário unicamente em conexão com a utilização dos Serviços por
          parte do Usuário; e
        </p>
        <p>
          <b>II</b> - o acesso e utilização de quaisquer conteúdos, informações
          ou materiais relacionados que possam ser disponibilizados através dos
          Serviços, sempre unicamente para uso pessoal (enquanto consumidor) e
          não comercial.
        </p>
        <p>
          Eventuais marcas comerciais, marcas de serviços e logotipos (as
          &quot;Marcas&quot;) exibidas no Aplicativo são de propriedade
          exclusiva de seus respectivos titulares. Você não poderá usar as
          Marcas, de nenhuma forma, sem o consentimento prévio e por escrito de
          seus respectivos titulares.
        </p>

        <h3>7. DAS OBRIGAÇÕES E RESPONSABILIDADES DAS PARTES</h3>
        <p>
          <b>
            Constituem obrigações do Usuário, entre outras previstas neste
            instrumento:
          </b>
        </p>
        <p>
          <b>I</b> - Fornecer, no processo de cadastro, dados verdadeiros e
          completos sobre nome completo, data de nascimento, e-mail, cidade,
          país, número de telefone e modelo, marca e cor do veículo, mantendo-os
          sempre atualizados;
        </p>
        <p>
          <b>II</b> - Zelar pela segurança e confidencialidade do login e senha
          de acesso, de caráter pessoal e intransferível, sendo que você é o
          único e exclusivo responsável pela sua utilização e guarda, assumindo
          todos os ônus e responsabilidades decorrentes de seus atos e de sua
          conduta, respondendo, ainda, pelos atos que terceiros praticarem em
          seu nome, por meio do uso de seu login e da sua senha de acesso;
        </p>
        <p>
          <b>III</b> - Não violar direitos de propriedade intelectual da SPOTT
          ou de terceiros;
        </p>
        <p>
          <b>IV</b> - Não incorporar malwares (código ou programa de computador
          malicioso e mal- intencionado, que possa causar danos, alterações ou
          roubo de informações), não sendo permitida a utilização de nenhum
          dispositivo, software ou outro recurso que venha a interferir nas
          atividades e operações da SPOTT, bem como nas contas ou banco de
          dados. Qualquer intromissão, tentativa ou atividade que viole ou
          contrarie as leis de direito de propriedade intelectual e/ou as
          proibições estipuladas nestes Termos e Condições de Uso, tornarão o
          responsável passível das ações legais pertinentes, bem como das
          sanções aqui previstas, sendo ainda responsável pelas indenizações por
          eventuais danos causados;
        </p>
        <p>
          <b>V</b> - Não utilizar a Plataforma para prática de atividades
          ilícitas e envio de mensagens (texto, voz ou imagens) ilícitas, assim
          como ameaças, pornografia e pornografia infantil, violência, etc;
        </p>
        <p>
          <b>VI</b> - Não utilizar o Aplicativo para a prática de spam;
        </p>
        <p>
          <b>VII</b> - Arcar com gastos com ligações telefônicas, pacotes de
          dados, mensagens, correspondências ou qualquer outro valor despendido
          em razão da utilização da Plataforma, por qualquer motivo que o seja;
        </p>
        <p>
          <b>VIII</b> - Responder por atos irregulares ou ilícitos praticados na
          Plataforma;
        </p>

        <p>
          <b>
            Constituem obrigações da SPOTT, entre outras previstas neste
            instrumento:
          </b>
        </p>
        <p>
          <b>I</b> - Disponibilizar o acesso dos Usuários à Área Restrita,
          mediante o fornecimento de login e senha de acesso, caso os dados
          cadastrais e perfil do Usuário sejam aprovados;
        </p>
        <p>
          <b>II</b> - Disponibilizar todas as informações e ferramentas
          necessárias para que o Usuário possa acessar as funcionalidades do
          Aplicativo;
        </p>

        <p>
          <b>
            A SPOTT não se responsabiliza por quaisquer danos ou prejuízos
            decorrentes da indisponibilidade total ou parcial do Aplicativo, em
            decorrência de:
          </b>
        </p>
        <p>
          <b>I</b> - Manutenção técnica e/ou operacional;
        </p>
        <p>
          <b>II</b> - Interrupção ou suspensão dos serviços fornecidos por
          empresas que disponibilizam acesso à Internet;
        </p>
        <p>
          <b>III</b> - Caso fortuito ou eventos de força maior, incluindo, mas
          não se limitando a quedas de energia, ataques de malwares e ações de
          terceiros que impeçam a sua disponibilidade, a exemplo da retirada do
          Aplicativo do ar.
        </p>

        <p>
          <b>A SPOTT não se responsabiliza:</b>
        </p>
        <p>
          <b>I</b> - por nenhum problema existente nas estações de recarga
          física, incluindo, mas não se limitando a falhas elétricas,
          conservação do equipamento e outras condições que afetem o seu
          funcionamento, tragam dano ao veículo ou ofertem perigo ao Usuário;
        </p>
        <p>
          <b>II</b> - pela durabilidade da bateria do carro elétrico;
        </p>
        <p>
          <b>III</b> - por atos de má-fé de terceiros, tais como hackers, que
          acessem os dados cadastrais e pessoais do Usuário e se utilizem
          ilicitamente dos mesmos para quaisquer fins;
        </p>
        <p>
          <b>IV</b> - pela perda de informações e dados encaminhadas pelo
          Usuário através da Plataforma ou por outro meio; e
        </p>
        <p>
          <b>V</b> - pelo meio de pagamento escolhidoa pelo Usuário.
        </p>

        <h3>8. ACESSO À REDE E DISPOSITIVOS</h3>
        <p>
          Você é responsável pela obtenção do acesso à rede de dados necessária
          para utilizar os Serviços, assim como você é responsável pela
          aquisição e atualização de hardware ou dispositivos compatíveis
          necessários para utilizar do Aplicativo e quaisquer atualizações aos
          mesmos.
        </p>
        <p>
          A SPOTT apenas garante que os Serviços, ou qualquer parte deles,
          funcionarão num determinado hardware ou dispositivos caso haja a
          compatibilidade necessária. Para além disso, os Serviços podem sofrer
          de funcionamento deficiente ou atrasos inerentes à utilização da
          Internet e de comunicações eletrônicas.
        </p>
        <p>
          Para te manter informado sobre sua conta, produtos e serviços da
          SPOTT, além de informações sobre segurança, poderemos te mandar
          e-mail, mensagens SMS no número de celular cadastrado ou mensagens
          através do WhatsApp.
        </p>

        <h3>9. FORO E LEGISLAÇÃO APLICÁVEL</h3>
        <p>
          A SPOTT não economizará esforços para resolver qualquer problema que
          você, Usuário, tiver na nossa plataforma.
        </p>
        <p>
          No entanto, caso não haja solução amigável e extrajudicial, você
          concorda que o Foro competente para dirimir qualquer litígio relativo
          a estes Termos de Uso ou da Política de Privacidade, com exclusão de
          qualquer outro, será o Foro Central da Comarca de São Paulo/SP.
        </p>
      </Container>
    </>
  );
};
