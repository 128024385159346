import styled from "styled-components";
import { rgba, transparentize } from "polished";

type ModalInfoPropsType = {
  isElementChecked: boolean;
};

type CustomButtonPropsType = {
  buttonColor: "grey" | "red";
};

const CustomButtonColors = {
  grey: "#6C6C6C",
  red: "#DA0000",
};

export const ModalContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;

  h1 {
    color: #da0000;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const ModalContent = styled.div`
  color: #666666;
  width: 100%;
  margin: 0 auto;
  margin: 2rem 0;

  p {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4rem;
    text-align: left;
  }
`;

export const ModalInfo = styled.div<ModalInfoPropsType>`
  color: ${({ isElementChecked }) =>
    isElementChecked ? "#da0000" : rgba(0, 0, 0, 0.4)};
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;

  transition: all 300ms;

  span {
    font-size: 1rem;
    cursor: pointer;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  margin-top: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const CustomButton = styled.button<CustomButtonPropsType>`
  background-color: ${({ buttonColor }) =>
    buttonColor && CustomButtonColors[buttonColor]};
  color: white;
  width: 12rem;
  padding: 1rem;

  border: 0;
  border-radius: 0.5rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: uppercase;

  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  transition: all 300ms;

  &:disabled {
    opacity: 0.2;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;
