import * as React from "react";

import { DroplistTypes } from "../../../interfaces/Droplist";

import { Component } from "./droplist.styles";

type DroplistPropsTypes = {
  options: DroplistTypes;
  primaryOptionName?: string | number;
  enablePrimaryOption?: boolean;
  lowerView? : boolean;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const Droplist = ({
  options = [],
  primaryOptionName = "",
  enablePrimaryOption = false,
  lowerView,
  ...rest
}: DroplistPropsTypes) => {
  return (
    <>
      <Component>
        <select {...rest} style={{width: lowerView ? '95%' : ''}}>
          {enablePrimaryOption && <option value="">{primaryOptionName}</option>}
          {options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.name}
              </option>
            );
          })}
        </select>
      </Component>
    </>
  );
};
