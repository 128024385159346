import React from "react";
import { withTranslation } from "react-i18next";

import ViewChargerQRCode from "./components/ViewChargerQRCode/ViewChargerQRCode";

import { Charger, Connectors } from "../../../interfaces/Chargers";

import {
  ConectorCode,
  ConectorType,
  ConnectorInfo,
} from "./chargerqrcode.styles";

export interface ChargerQRCodeInterface {
  t: Function;
  charger: Charger;
}

interface ViewChargerQRCode {
  isOpen: boolean;
  onClose: () => void;
  charger: Charger;
  connector: Connectors;
}

function ChargerQRCode({ t, charger }: ChargerQRCodeInterface) {
  const [viewConnectorQRCode, setViewConnectorQRCode] = React.useState<
    ViewChargerQRCode | undefined
  >();

  const { cpCm, cpCode } = charger;
  const conectorType = charger.cpCm.chargerModelsConnectors[0].cct.cctName;

  const handleConnector = (connector: Connectors) => {
    setViewConnectorQRCode({
      charger,
      connector,
      isOpen: true,
      onClose: () => setViewConnectorQRCode(undefined),
    });
  };

  return (
    <>
      <div>
        <span className="tx-bold">{t("chargers_QRCodeGetConectorIDText")}</span>

        {cpCm.chargerModelsConnectors.length > 0 ? (
          <table className="table-charges mt-2">
            <tbody>
              {cpCm.chargerModelsConnectors.map(
                (connector: Connectors, key: number) => (
                  <tr
                    key={key}
                    className={`charger`}
                    onClick={() => handleConnector(connector)}
                  >
                    <td>
                      <ConnectorInfo>
                        <i className="mdi mdi-qrcode-scan font-size-35"></i>
                        <div>
                          <ConectorCode>
                            {`${cpCode}-${connector.connectorId}`}{" "}
                          </ConectorCode>
                          <ConectorType>
                            {t("chargers_QRCodeConectorType")}{" "}
                            {`${conectorType}`}
                          </ConectorType>
                        </div>
                      </ConnectorInfo>
                    </td>
                  </tr>
                ),
              )}
            </tbody>
          </table>
        ) : (
          <div className="d-flex justify-content-start align-items-center mt-2">
            <span className="font-size-10">
              {t("This charger does not have any connectors")}
            </span>
          </div>
        )}
      </div>

      <hr />

      {viewConnectorQRCode?.isOpen && (
        <ViewChargerQRCode {...viewConnectorQRCode} />
      )}
    </>
  );
}

export default withTranslation()(ChargerQRCode);
