import { withTranslation } from "react-i18next";

import Footer from "../../../components/VerticalLayout/Footer";
import { PrivacePolicyContentApp } from "./components/PrivacyPolicyContentApp/PrivacyPolicyContentApp";

const logoImg = require("../../../assets/images/logo.png");
import {
  Container,
  Display,
  Header,
  FooterPage,
} from "./privacypolicycontentapp.styles";

interface PrivacyPolicyAppProps {
  t: Function;
}

export const PrivacyPolicyApp = ({ t: translator }: PrivacyPolicyAppProps) => {
  return (
    <>
      <Container>
        <Header>
          <img width={'200px'} src={logoImg} alt="logo" />
        </Header>

        <Display>
          <h2>{translator("Privacy Policy")}</h2>
          <PrivacePolicyContentApp />
          <br />
        </Display>

        <FooterPage>
          <Footer darkText={true} loginFooter={true} tintText hasBackground />
        </FooterPage>
      </Container>
    </>
  );
};

export default withTranslation()(PrivacyPolicyApp);
