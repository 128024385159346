import { withTranslation } from "react-i18next";

const logoImg = require("../../../../assets/images/logo.png");

import { Container, Title } from "./header.styles";

interface HeaderProps {
  t: Function;
}

const Header = ({ t }: HeaderProps) => {
  return (
    <>
      <Container>
        <Title>
          <p>{t("Welcome to your")}</p>
          <span>
            <img src={logoImg} alt="spott-logo" />
          </span>
        </Title>

        <p>{t("Manage your recharge points")}</p>
      </Container>
    </>
  );
};

export default withTranslation()(Header);
