import styled from "styled-components";

export const Container = styled.div``;

export const ConnectorInfo = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.24rem;

    margin-left: 0.8rem;
  }
`;

export const ConectorCode = styled.p`
  color: #000000;
  width: 100%;
  padding: 0;
  margin: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 1.16rem;
  line-height: 1.16rem;
`;

export const ConectorType = styled(ConectorCode)`
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;

  margin: 0;
  padding: 0;
`;
