import styled from "styled-components";
import { rgba } from "polished";

interface ContentProps {
  darkenTextColor?: boolean;
  hasBackground?: boolean;
}

export const Container = styled.footer`;
  position: initial;
  margin-left: 250px;
  display: grid;

  @media (max-width: 1100px) {
    margin-left: 0px;
  }
`;

export const Content = styled.div<ContentProps>`
  background-color: ${({ hasBackground }) => hasBackground && "#f8f8fa"};
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  bottom: 0;

  span {
    color: ${({ darkenTextColor }) => darkenTextColor && rgba(0, 0, 0, 0.6)};
  }
  
`;
