import * as React from "react";
import * as RouterDom from "react-router-dom";
import { withTranslation } from "react-i18next";

import * as ContextProvider from "../../context/ContextProvider";

import Amount from "./components/Amount/Amount";
import BankInformation from "./components/BankInformation/BankInformation";

import {
  Container,
  Header,
  TabBar,
  TabButton,
  Content,
} from "./finance.styles";

type FinancePropsType = {
  t: Function;
};

type UserSiteType = {
  csAddress: string;
  csDescription: string;
  csDisabled: boolean;
  csId: string;
  csLocation: {
    coordinates: [x: number, y: number];
    type: string;
  };
  csMaxLoad: number;
  csName: string;
  siteConnectors: [];
  uspAnuId: string;
  uspId: number;
  uspRole: string;
};

export const Finance = ({ t: translation }: FinancePropsType) => {
  const { site_id }: any = RouterDom.useParams();
  const { state }: any = ContextProvider.useStateContext();
  const [accessSite, setAccessSite] = React.useState<UserSiteType>();

  React.useEffect(() => {
    if (!site_id || !state.sites) return;

    if (site_id && state.sites) {
      const pageSite = state?.sites.find((site: any) => site.csId === site_id);
      setAccessSite(pageSite);
    }
  }, [site_id, state.sites]);

  const [selectedTab, setSelectedTab] = React.useState<string>("amount");

  return (
    <>
      <Container>
        <Header>
          <h1>
            <span>
              {accessSite?.csName} <span>/</span>
            </span>
            {translation("finance_PageTitle")}
          </h1>
        </Header>

        <TabBar>
          <TabButton
            type="button"
            onClick={() => setSelectedTab("amount")}
            isSelected={selectedTab === "amount"}
          >
            {translation("finance_FinanceAmount")}
          </TabButton>
          <TabButton
            type="button"
            onClick={() => setSelectedTab("bank")}
            isSelected={selectedTab === "bank"}
          >
            {translation("finance_FinanceBankInfo")}
          </TabButton>
        </TabBar>

        <Content>
          {selectedTab === "amount" && <Amount />}
          {selectedTab === "bank" && <BankInformation />}
        </Content>
      </Container>
    </>
  );
};

export default withTranslation()(Finance);
