import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  button {
    width: 10rem;
  }
`;

export const Content = styled.div`
  width: fit-content;
  margin: 0 auto 0 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;
  gap: 0.64rem;

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    color: ${darken(0.48, "#e7e8ea")};
    font-weight: 500;
    font-style: normal;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;

    h2 {
      font-size: 1rem;
      line-height: 1rem;
    }

    p {
      color: ${darken(0.32, "#e7e8ea")};
      font-size: 0.88rem;
      line-height: 1rem;
    }
  }
`;
