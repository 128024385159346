import * as React from "react";
import * as Translator from "react-i18next";

import { AxiosApi as api } from "../../../../../../../../helpers/api";

const { Modal } = require("reactstrap");
const { AvForm } = require("availity-reactstrap-validation");
import { Button } from "../../../../../../../../components/Button";
import Error from "../../../../../../../../components/Alerts/Error";
import Success from "../../../../../../../../components/Alerts/Success";
import { ModalAction } from "../../../../../../../../components/Modal/ModalAction/ModalAction";
import * as yup from "yup";


import {
  Exception,
  ResponseSuccess,
} from "../../../../../../../../interfaces/Exception";
import { ModaActionlTypes } from "../../../../../../../../interfaces/Modal";
import { ChargerPoint } from "../../../../../../../../interfaces/Chargers";

import {
  Component,
  ModalWrapper,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalList,
  ModalEmptyList,
  ModalFormInput,
  ErrorMessage,
} from "./modalusersaccess.styles";
import ErrorModal from "../../../../../../../../components/Alerts/ErrorModal";

import { Droplist } from "../../../../../../../../components/Droplists";
import { DroplistTypes } from "../../../../../../../../interfaces/Droplist";
import { ChargerProfileTypes } from "../../../../../../../../interfaces/ChargerPricification";

import * as RouterDom from "react-router-dom";
import { RouterDomTypes } from "../../../../../../../../interfaces/RouterDom";

type ModalUsersAccessPropsTypes = {
  t: Function;
  chargePointId: string;
  modalIsOpen: boolean;
  refreshChargersList: () => void;
  modalOnRequestClose: () => void;
};

type FormSubmitValuesType = {
  email: string;
};

type ModalActionPayload = {
  userId: number;
  userName: string;
};

type IconProps = { handleClick: () => void };

enum ProfileName {
  DEFAULT = "DEFAULT_PRICE_PROFILE",
}

export const ModalUsersAccess = ({
  t: translation,
  chargePointId = "",
  modalIsOpen = false,
  refreshChargersList,
  modalOnRequestClose,
}: ModalUsersAccessPropsTypes) => {
  let schema = yup.object().shape({
    ChargepointId: yup.string(),
    UserName: yup.string().email().required(),
  });

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<
    ResponseSuccess | undefined
  >();

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const [usersAccessData, setUsersAccessData] = React.useState<ChargerPoint[]>(
    [],
  );

  const [showUserNotExist, setShowUserNotExist] = React.useState(false);
  const [showInvalidEmail, setShowInvalidEmail] = React.useState(false);

  const [showInputError, setShowInputError] = React.useState(false);
  const [inputErrorMessage, setInputErrorMessage] = React.useState("");

  const removeErrors = (e?: any) => {
    setShowInvalidEmail(false)
    return setShowInputError(false);
    // if (e.key === "Enter") {
    //   return;
    // } else {
    //   setShowInvalidEmail(false)
    //   return setShowInputError(false);
    // }
  };

  const [payload, setPayload] = React.useState({
    ChargepointId: chargePointId,
    UserName: "",
    UcpPriceProfileId: 0,
  });

  // Functions
  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      schema.isValid(payload).then(async (isValid) => {
        if (isValid) {
          await api
            .post("/UserChargePointPermissions", payload)
            .then(() => {

              getUsersAccessData();
              refreshChargersList();

              return setTimeout(() => {
                getUsersAccessData();
              }, 1500);
            })
            .catch((error) => {
              if (error.request.status === 409) {
                setInputErrorMessage(
                  translation(
                    "chargers_ModalExclusiveChargerValidationRegistered",
                  ),
                );
                return setShowInputError(true);
              }

              setInputErrorMessage(
                translation(
                  "chargers_ModalExclusiveChargerValidationNonexistent",
                ),
              );
              return setShowInputError(true);
            });
        } else {
          setInputErrorMessage(
            translation("chargers_ModalExclusiveChargerValidationInvalidEmail"),
          );
          return setShowInputError(true);
        }
      });
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: translation("chargers_ChargerExclusiveModalDeleteError"),
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
      // setInputErrorMessage(
      //   translation("chargers_ModalExclusiveChargerValidationNonexistent"),
      // );
      // return setShowInputError(true);
    } finally {
      // getUsersAccessData();
      // refreshChargersList();
    }
  };

  const prepareModalAction = (payload: ModalActionPayload) => {
    setModalAction((prev) => ({
      ...prev,
      modalIsOpen: true,
      modalTitle: translation(
        "chargers_ChargerExclusiveModalDeleteActionTitle",
      ),
      modalInfo: translation("chargers_ChargerExclusiveModalDeleteActionText", {
        userName: payload?.userName,
      }),
      modalButtonConfirmText: translation("global_Yes"),
      modalButtonCancelText: translation("global_Cancel"),
      modalOnConfirmAction: () => deleteUserAccess(payload?.userId),
      modalOnRequestClose: onRequestCloseActionModal,
    }));
  };
  const onRequestCloseActionModal = () => {
    setModalAction((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  // Api calls
  const getUsersAccessData = async () => {
    try {
      const { data } = await api.get<ChargerPoint[]>(
        `/UserChargePointPermissions?ChargePointId=${chargePointId}`,
      );

      const mutableData = structuredClone(data);
      const sortedData = mutableData.sort((_A, _B) => {
        if (_A.email > _B.email) return 1;
        if (_A.email < _B.email) return 1;
        return 0;
      });

      setUsersAccessData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const deleteUserAccess = async (userId: number) => {
    try {
      const response = await api.delete(
        `/UserChargePointPermissions/${userId}`,
      );

      // setHandleSuccess(() => ({
      //   isOpen: true,
      //   message: translation(
      //     "chargers_ChargerExclusiveModalDeleteSuccessDeletion",
      //   ),
      //   onClose: () => setHandleSuccess(undefined),
      // }));
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: translation("chargers_ChargerExclusiveModalDeleteError"),
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      getUsersAccessData();
      refreshChargersList();
      onRequestCloseActionModal();
    }
  };

  const { site_id } = RouterDom.useParams<RouterDomTypes>();

  const [chargerProfilesData, setChargerProfilesData] = React.useState<
    ChargerProfileTypes[]
  >([]);
  const [priceProfileId, setPriceProfileId] = React.useState<any>();
  console.log(priceProfileId, 'priceProfileId')

  const getProfiles = async () => {
    try{
      const response = await api.get(
        `/PriceProfiles?siteid=${site_id}`,
      );
      setChargerProfilesData(response.data);

      const {data} = await api.get(`/ChargePoints/${chargePointId}`);

      const defaultProfile = chargerProfilesData.filter( profile => profile.priceProfileDefaultProfile)

      setPriceProfileId(defaultProfile[0].priceProfileId);

    }catch(error: any){
      console.log(error, 'error')
    }
  }

  // Life cycle
  React.useEffect(() => {
    getUsersAccessData();
    getProfiles();
  }, []);

  const droplistOptions: DroplistTypes = chargerProfilesData.map((profile) => {
    return {
      name:
        profile.priceProfileName === ProfileName.DEFAULT
          ? translation("global_DefaultProfile")
          : profile.priceProfileName,
      value: profile.priceProfileId,
    };
  });

  const onChangeDroplist = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();

    const defaultProfileData = chargerProfilesData.filter( profile => profile.priceProfileDefaultProfile)

    const profileIdSelected = Number(event.target.value)
    const defaultProfileId = Number(defaultProfileData[0].priceProfileId);

    setPriceProfileId(profileIdSelected === defaultProfileId ? null : profileIdSelected);
    setPayload({...payload, UcpPriceProfileId: profileIdSelected})

  }

  const DeleteIcon = ({ handleClick }: IconProps): JSX.Element => {
    return (
      <img
        className="delete-icon"
        src={require("../../../../../../../../assets/images/delete-close.png")}
        alt="Remove charger"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
    );
  };

  // Render
  return (
    <>
      <Component>
        <Modal
          isOpen={modalIsOpen}
          fullscreen={"sm"}
          size={"lg"}
          centered={true}
        >
          <ModalWrapper>
            <ModalHeader>
              <p>{translation("chargers_ChargerExclusiveModalAddTitle")}</p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            <hr />

            {handleError && <ErrorModal {...handleError} />}
            {handleSuccess && <Success {...handleSuccess} />}

            {!handleError && !handleSuccess && (
              <>
                <ModalContent>
                  <p>
                    {translation("chargers_ChargerExclusiveModalAddText", {
                      charger: chargePointId,
                    })}
                  </p>

                  <AvForm onValidSubmit={handleSubmitForm}>
                    <div style={{display: 'grid', gap: '15px', gridTemplateColumns: 'auto max-content'}}>
                      <div>
                        <ModalFormInput
                          required
                          id={"email"}
                          name={"email"}
                          type={"email"}
                          placeholder={translation(
                            "chargers_ChargerExclusiveModalAddInputPlaceholder",
                          )}
                          onChange={(e) =>
                            setPayload({ ...payload, UserName: e.target.value, UcpPriceProfileId: Number(priceProfileId) })
                          }
                          onClick={() => removeErrors()}
                          onKeyUp={(e) => removeErrors(e)}
                        />

                        {showInputError && (
                          <ErrorMessage>{inputErrorMessage}</ErrorMessage>
                        )}

                        {showInvalidEmail && (
                          <ErrorMessage>
                            {translation(
                              "chargers_ModalExclusiveChargerValidationInvalidEmail",
                            )}
                          </ErrorMessage>
                        )}
                      </div>

                      <Droplist
                        className="droplist"
                        options={droplistOptions}
                        value={priceProfileId}
                        onChange={onChangeDroplist}
                        style={{width: '250px', height: '3.5rem'}}
                      />  
                    </div>
                    

                    <div className="button-aligment">
                      <Button buttonColor={"blue"} type={"submit"}>
                        {translation(
                          "chargers_ChargerExclusiveModalAddButtonAdd",
                        )}
                      </Button>
                    </div>
                  </AvForm>
                </ModalContent>

                <ModalFooter>
                  <p>
                    {translation(
                      "chargers_ChargerExclusiveModalAddRegisteredUsers",
                    )}
                  </p>
                  <hr />

                  {!usersAccessData.length && (
                    <ModalEmptyList>
                      <p>
                        {translation("chargers_ChargerExclusiveModalAddEmpty")}
                      </p>
                    </ModalEmptyList>
                  )}

                  {!!usersAccessData.length && (
                    <ModalList>
                      {usersAccessData.map((user, index) => {
                        const profileSelectedName = chargerProfilesData.filter( profile => {
                          return profile.priceProfileId === user.ucpPriceProfileId;
                        })

                        const profileName = profileSelectedName.length === 0 ? translation("pricing_DefaultProfile") : profileSelectedName[0].priceProfileName
                        
                        return (
                          <li key={index}>
                            <img
                              src={require("../../../../../../../../assets/images/icon_owner_big.png")}
                              alt={"user-icon"}
                            />
                            <p>{user.email}</p>
                            <span style={{marginRight: '20px'}}>{profileName === "DEFAULT_PRICE_PROFILE" ? translation("pricing_DefaultProfile") : profileName}</span>
                            <DeleteIcon
                              handleClick={() => {
                                const payload: ModalActionPayload = {
                                  userId: user.ucpId,
                                  userName: user.email,
                                };

                                prepareModalAction(payload);
                              }}
                            />
                          </li>
                        );
                      })}
                    </ModalList>
                  )}
                </ModalFooter>
              </>
            )}
          </ModalWrapper>
        </Modal>

        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalUsersAccess);
