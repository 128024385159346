import React, { useState, useEffect } from "react";
import { withRouter, Link, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  FormGroup,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

import { AxiosApi } from "../../helpers/api";
import {
  FormatDateHelper,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import * as Provider from "../../context/ContextProvider";

import CustomBreadCrumb from "../../components/BreadCrumb";
import { ModalComponent } from "../../components/Modal";
import ErrorModal from "../../components/Alerts/ErrorModal";
import ChargerView from "../../components/Chargers/ChargerView/ChargerView";
import AutoUpdateFirmware from "../../components/Chargers/ChargerFirmware/AutoUpdateFirmware";
import ModalDeleteCharger from "./components/ModalDeleteCharger/ModalDeleteCharger";

import ListCharger from "../../assets/images/list-charger.png";
import DeleteClose from "../../assets/images/delete-close.png";

import "./NewCharger.scss";

const Chargers = (props) => {
  const { site_id } = useParams();
  const { context, state } = Provider.useStateContext();
  const [userLanguage, setUserLanguage] = React.useState("");
  const [siteInfo, setSiteInfo] = useState();
  const [chargers, setChargers] = useState(null);
  const [generatedId, setGeneratedId] = useState("");
  const [modalConfig, setModalConfig] = useState({ open: false });
  const [chargerDetails, setChargerDetails] = useState(null);
  const [handleError, setHandleError] = useState();
  const [forceUpdateComponent, setForceUpdateComponent] = useState(0);
  const [chargerDetailsLoading, setChargerDetailsLoading] = useState(false);
  const [clipCopied, setClipCopied] = useState(false);

  React.useEffect(() => {
    if (!site_id || !state.sites) return;

    document.querySelector("#addCharger").readOnly = false;
    setGeneratedId("");

    const siteInfoFilter = state.sites.find((site) => site.csId === site_id);

    if (!siteInfoFilter) return;
    setSiteInfo(siteInfoFilter);

    if (state.authorizationReload === true)
      selectChargerHandler(chargerDetails.chargePointId);

    getUserCharger();
  }, [site_id, state.sites, state.authorizationReload, forceUpdateComponent]);

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  const getUserCharger = async () => {
    try {
      const { data } = await AxiosApi.get(`/site/chargepoints/${site_id}`, {});

      if (data && data !== null) {
        const mutableData = structuredClone(data);
        const chargerFiltered = mutableData.sort((dataA, dataB) => {
          if (dataA.cpAddedDate > dataB.cpAddedDate) return -1;
          if (dataA.cpAddedDate < dataB.cpAddedDate) return 1;
          return 0;
        });
        setChargers(chargerFiltered);

        context.getAuthorizationReload(false);
      }
    } catch (err) {
      setHandleError({
        isOpen: true,
        message: err?.response?.data,
        status: err?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    }
  };

  const refreshChargerList = () => {
    setForceUpdateComponent((prev) => prev + 1);
  };

  /// HANDLERS
  ////////////////////////////////////////////////////////////////////////////////

  const selectChargerHandler = async (chargePointId) => {
    try {
      // const mutableData = { ...chargerDetails };
      // if (mutableData.chargePointId === chargePointId) return;
      setChargerDetailsLoading(true);

      const { data } = await AxiosApi.get(`/ChargePoints/${chargePointId}`);
      setChargerDetails(data);
    } catch (err) {
      setHandleError({
        isOpen: true,
        message: err?.response?.data,
        status: err?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      setChargerDetailsLoading(false);
    }
  };

  const handleChargerUpdate = () => {
    selectChargerHandler(chargerDetails.chargePointId);
  };

  const generateHandler = (e) => {
    const resp = AxiosApi.get(`/genChargerId`, {})
      .then((response) => {
        setGeneratedId(response.data.generatedId);
        document.querySelector("#addCharger").readOnly = true;
      })
      .catch((err) =>
        setHandleError({
          isOpen: true,
          message: err?.response?.data,
          status: err?.response?.status,
          onClose: () => setHandleError(undefined),
        }),
      );
  };

  const copyHandler = async (chargerId, e) => {
    setClipCopied(true);
    setTimeout(() => {
      setClipCopied(false);
    }, 1000);
    e.stopPropagation();
    if (chargerId === "") return;
    await navigator.clipboard.writeText(chargerId);
  };

  const addChargerHandler = (e) => {
    if (generatedId === "") return;

    const resp = AxiosApi.post(`/ChargePoints`, {
      chargePointId: generatedId,
      cpCsId: siteInfo?.csId,
    })
      .then((response) => {
        getUserCharger();
        document.querySelector("#addCharger").value = "";
        document.querySelector("#addCharger").readOnly = false;
        setGeneratedId("");
      })
      .catch((err) => {
        setHandleError({
          isOpen: true,
          message: err?.response?.data,
          status: err?.response?.status,
          onClose: () => setHandleError(undefined),
        });
      });
  };

  const [modalDeletionConfig, setModalDeletionConfig] = React.useState({
    isOpen: false,
    chargerID: 0,
    onHandleDeleteCharger: (id) => {},
  });

  const handleDeleteTargetCharger = async (id) => {
    try {
      if (!id || id === null) return;

      if (chargerDetails && id === chargerDetails.chargePointId) {
        setChargerDetails(null);
      }

      const resp = await AxiosApi.delete(`/ChargePoints/${id}`);
    } catch (err) {
      console.log(err);
    } finally {
      getUserCharger();
      setModalDeletionConfig((prev) => ({
        ...prev,
        isOpen: false,
      }));
    }
  };

  const handleOpenDeleteModal = (clickEvent, chargerID) => {
    clickEvent.stopPropagation();
    setModalDeletionConfig({
      isOpen: true,
      chargerID: chargerID,
      onHandleDeleteCharger: handleDeleteTargetCharger,
    });
  };
  React.useEffect(() => {}, [state.authorizationReload]);

  const pageWidth = document.documentElement.scrollWidth;

  return (
    <>
      <div
        className="page-content"
        style={{ paddingTop: "1.9rem", paddingLeft: "1rem" }}
      >
        <Container fluid>
          <div className="page-title-box" style={{ paddingBottom: "20px" }}>
            <Row className="align-items-center">
              <Col md={12}>
                <CustomBreadCrumb
                  siteName={siteInfo?.csName}
                  title={props.t("Chargers")}
                  // subtitle={`${props.t(
                  //   "Last update",
                  // )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
                />
              </Col>
            </Row>
          </div>

          {/* <Row>
            <div className="w-100">
              <ul className="nav nav-tabs w-100">
                <li className="nav-item">
                  <span className={`nav-link active`}>
                    {props.t("New Charger")}
                  </span>
                </li>
                <li className="nav-item">
                  <Link
                    className={`nav-link`}
                    to={{ pathname: `/Chargers/AllChargers/${siteInfo?.csId}` }}
                  >
                    {props.t("All Chargers")}
                  </Link>
                </li>
              </ul>
            </div>
          </Row> */}

          <Row>
            <p
              className="font-size-20 w-500"
              style={{ padding: "0 12px", margin: "0", fontWeight: "500" }}
            >
              {props.t("Add new chargers to your account")}
            </p>
          </Row>

          <Row>
            <Col>
              <span style={{ color: "#a8a8a8" }}>
                {props.t("getChargerIdText")}
              </span>
              <div
                className="div-id-input-buttons"
                style={{
                  gridTemplateColumns:
                    pageWidth <= 1366 && pageWidth >= 769
                      ? "auto min-content min-content"
                      : "35% min-content min-content",
                }}
              >
                <Input
                  id="addCharger"
                  type="text"
                  className="me-2"
                  value={generatedId}
                  placeholder={props.t("Paste the ID number here")}
                  onChange={(e) => setGeneratedId(e.target.value)}
                />
                <Button
                  color="success"
                  className="bt-charger px-5 mx-0"
                  onClick={(e) => addChargerHandler(e)}
                >
                  {props.t("ADD")}
                </Button>
                <Button
                  color="primary"
                  className="bt-charger w-100"
                  onClick={(e) => generateHandler(e)}
                >
                  {props.t("GENERATE")}
                </Button>
              </div>
            </Col>
          </Row>

          <Row>
            <Row className="p-0 m-0">
              <Col xl={8} className="pl-3">
                <p
                  className="font-size-15 mb-1 mt-5"
                  style={{ fontWeight: "500", marginTop: "20px" }}
                >
                  {props.t("chargers_ChargerTableRegisteredChargers")}
                </p>
              </Col>
              {chargerDetails && (
                <Col
                  xl={4}
                  className="d-flex justify-content-start align-items-end m-0 p-0"
                >
                  <AutoUpdateFirmware />
                </Col>
              )}
            </Row>

            <Card>
              <CardBody className="p-0">
                <Row>
                  <Col xl={8} className="p-3 pe-0">
                    {!chargers ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <Spinner>{props.t("Loading...")}</Spinner>
                      </div>
                    ) : (
                      <div>
                        <table className="table-charges">
                          <thead>
                            <tr>
                              <th>CP-Code</th>
                              <th>
                                {props.t("chargers_ChargerTableChargerPointId")}
                              </th>
                              <th>{props.t("chargers_ChargerTableName")}</th>
                              <th>{props.t("chargers_ChargerStatus")}</th>
                              <th>{props.t("chargers_ChargerAddedDate")}</th>
                              <th>{props.t("chargers_ChargerPriceProfile")}</th>
                              <th>{props.t("chargers_ChargerExclusiveUse")}</th>
                              <th></th>
                            </tr>
                          </thead>

                          <tbody>
                            {chargers.length === 0 ? (
                              <tr>
                                <td colSpan={3}>
                                  Nenhum carregador encontrado
                                </td>
                              </tr>
                            ) : (
                              chargers.map((charger, index) => {
                                return (
                                  <tr
                                    key={index}
                                    className={`charger ${
                                      chargerDetails &&
                                      charger?.chargePointId ===
                                        chargerDetails.chargePointId
                                        ? "tr-selected"
                                        : ""
                                    } rows`}
                                    onClick={() => {
                                      selectChargerHandler(
                                        charger.chargePointId,
                                      );
                                    }}
                                  >
                                    <td>
                                      {charger?.cpCode}{" "}
                                      <img
                                        id={`CopyToClipboard_${charger?.cpCode}`}
                                        src={ListCharger}
                                        alt={charger?.cpCode}
                                        onClick={(e) =>
                                          copyHandler(charger?.cpCode, e)
                                        }
                                      />
                                      <UncontrolledTooltip
                                        placement="right"
                                        autohide={true}
                                        target={`CopyToClipboard_${charger?.cpCode}`}
                                        trigger="hover"
                                      >
                                        {clipCopied
                                          ? `${props.t(
                                              "chargers_QRCodeCopied",
                                            )}`
                                          : `
                                          ${props.t("Copy")}
                                          ${charger?.cpCode}
                                          ${props.t("to clipboard")}
                                        `}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td>
                                      {charger?.chargePointId}{" "}
                                      <img
                                        id={`CopyToClipboard_${charger?.chargePointId}`}
                                        src={ListCharger}
                                        alt={charger?.chargePointId}
                                        onClick={(e) =>
                                          copyHandler(charger?.chargePointId, e)
                                        }
                                      />
                                      <UncontrolledTooltip
                                        placement="right"
                                        autohide={true}
                                        target={`CopyToClipboard_${charger?.chargePointId}`}
                                        trigger="hover"
                                      >
                                        {clipCopied
                                          ? `${props.t(
                                              "chargers_QRCodeCopied",
                                            )}`
                                          : `
                                          ${props.t("Copy")}
                                          ${charger?.chargePointId}
                                          ${props.t("to clipboard")}
                                        `}
                                      </UncontrolledTooltip>
                                    </td>
                                    <td>{charger?.name}</td>
                                    <td>
                                      {charger?.cpStatus === "Online" ? (
                                        <span className="text-success">
                                          Online
                                        </span>
                                      ) : (
                                        <span className="text-danger">
                                          Offline
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {formatFullDateAndTimeHelper(
                                        charger?.cpAddedDate,
                                        userLanguage,
                                      )}
                                    </td>
                                    <td>
                                      {charger?.priceProfileName !==
                                        "DEFAULT_PRICE_PROFILE" &&
                                      charger?.priceProfileName !== null
                                        ? charger?.priceProfileName
                                        : props.t("pricing_DefaultProfile")}
                                    </td>
                                    <td className="d-flex justify-content-start align-items-center">
                                      {charger?.cpExclusive ? (
                                        <>
                                          <i className="mdi mdi-account-circle-outline font-size-24 color-black"></i>
                                          <span className="mx-1">
                                            {props.t(
                                              "chargers_ChargerExclusiveUse",
                                            )}
                                          </span>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>

                                    <td>
                                      <img
                                        src={DeleteClose}
                                        alt={props.t("Remove charger")}
                                        onClick={(e) => {
                                          handleOpenDeleteModal(
                                            e,
                                            charger?.chargePointId,
                                          );
                                        }}
                                        // onClick={(e) => {
                                        //   e.stopPropagation();
                                        //   setModalDeletionConfig({
                                        //     isOpen: true,
                                        //     chargerID: charger?.chargePointId,
                                        //     onHandleDeleteCharger:
                                        //       handleDeleteTargetCharger,
                                        //   });
                                        // }}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                          </tbody>
                        </table>

                        <ModalDeleteCharger
                          Charger={modalDeletionConfig}
                          onHandleCloseModal={() =>
                            setModalDeletionConfig((prev) => ({
                              ...prev,
                              isOpen: false,
                            }))
                          }
                        />
                      </div>
                    )}
                  </Col>

                  <Col xl={4} className="charger-details p-3">
                    {chargerDetails ? (
                      <ChargerView
                        charger={chargerDetails}
                        onChargerUpdate={() => handleChargerUpdate()}
                        refreshChargerList={() => refreshChargerList()}
                      />
                    ) : (
                      <div className="h-100 d-flex justify-content-center align-items-center text-center">
                        {chargerDetailsLoading ? (
                          <Spinner className="text-primary">
                            {props.t("Loading...")}
                          </Spinner>
                        ) : (
                          <p className="title mb-4">
                            {props.t(
                              "Click on a charger to see more details about it!",
                            )}
                          </p>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      {handleError && <ErrorModal {...handleError} />}
    </>
  );
};

export default withRouter(withTranslation()(Chargers));
