import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
  display: grid;
  grid-gap: 1.32rem;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  p {
    color: #484552;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }

  .data-table {
    background-color: white;
    width: 100%;
    margin: 0 auto;
    padding: 0.32rem;
    border: 1px solid #edeeef;
    border-radius: 0.32rem;
    overflow: hidden;
    display: grid;
    grid-gap: 0.32rem;
  }
`;

export const GreenBallDiv = styled.div`
  flex-direction: row;
  display: flex;
`;
export const GreenBall = styled.div`
  height: 17.91666603088379px;
  width: 18.839111328125px;
  left: 681.976318359375px;
  top: 13.138916015625px;
  border-radius: 100px;
  background-color: #00da71;
  margin-right: 2px;
`;
