import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: min-content;
  margin: 0;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  p {
    color: ${darken(0.64, "#e7e8ea")};
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    font-weight: 500;
    text-decoration: none;
  }

  .delete-icon {
    transition: transform 300ms;
    &:hover {
      transform: scale(0.9);
    }
  }
`;

export const RenderElement = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  span {
    font-style: normal;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    text-decoration: none;
  }

  svg {
    color: ${darken(0.16, "#e7e8ea")};
    width: 1.16rem;
    height: 1.16rem;
    margin-right: 0.4rem;
    cursor: pointer;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const TableHeader = styled.div`
  width: 100%;
  min-height: 52px;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: flex-end;
  border-bottom: 1px solid #0000001e;
  box-shadow: 2px 0px 0px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.87);

  & > p {
    font-size: 12px;
    padding-left: 16px;
    padding-right: 16px;
  }

  & > p:first-child {
    min-width: 102px;
    text-align: center;
  }

  & > p:nth-child(2) {
    min-width: 160px;
  }

  & > p:nth-child(3) {
    min-width: 164px;
  }

  & > p:nth-child(4) {
    min-width: 160px;
  }

  & > p:nth-child(5) {
    min-width: 152px;
  }

  & > p:nth-child(6) {
    min-width: 160px;
  }

  & > p:last-child {
    min-width: 171px;
  }
`;
