import "./styles.scss";
import React from "react";
import { withTranslation } from "react-i18next";

const CustomBreadCrumb = ({
  siteName,
  siteNameStyle,
  title,
  subtitle,
  itemTitle,
  t,
}) => {
  return (
    <div>
      <div className="custom-breadcrumb">
        <h2 className="custom-breadcrumb__site-name">
          <span style={siteNameStyle}>{siteName ? siteName : ""}</span>
        </h2>
        {title && title !== "" ? (
          <span className="spanustom-breadcrumb__site-name custom-breadcrumb__separator">
            /
          </span>
        ) : (
          ""
        )}
        <h3>{title ? title : ""}</h3>
        {itemTitle && itemTitle !== "" ? (
          <span className="spanustom-breadcrumb__site-name custom-breadcrumb__separator">
            /
          </span>
        ) : (
          ""
        )}
        <h4>{itemTitle ? itemTitle : ""}</h4>
      </div>
      <p className="font-size-14 mb-0 custom-subtitle">
        {subtitle ? subtitle : ""}
      </p>
    </div>
  );
};

export default withTranslation()(CustomBreadCrumb);
