import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";
const { AvField } = require("availity-reactstrap-validation");

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;

  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  small {
    color: ${darken(0.16, "#e7e8ea")};
    font-size: 1.16rem;
    line-height: 1.16rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }

  p {
    color: #3970f3;
    font-weight: 600;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin: 0 auto;

  .button-aligment {
    width: 100%;
    margin: 0 auto;
    margin-top: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }

  button {
    width: 10rem;
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;

  p {
    font-weight: 500;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-transform: none;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;

    &:first-child {
      font-weight: 600;
    }
  }
`;

export const ModalList = styled.ul`
  width: 100%;
  height: 16rem;
  overflow-y: scroll;
  margin: 0;
  padding: 0;
  border: 1px solid #e7e8ea;
  border-radius: 0.24rem;

  li {
    list-style-type: none;
    width: 100%;
    padding: 1rem;
    border: 1px solid transparent;
    border-bottom-color: #e7e8ea;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    margin-left: 0.88rem;
    flex: 1;
  }

  button {
    width: 8rem;
  }
`;

export const ModalFormInput = styled.input`
  width: 100%;
  height: 3.5rem;
  border-color: #e7e8ea;
  border-radius: 0.24rem;

  &:focus {
    transition: border-color 300ms;
  }

  &::placeholder {
    color: ${darken(0.32, "#e7e8ea")};

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
  }
`;

export const ModalFormInputField = styled(AvField)`
  width: 100%;
  height: 3.5rem;
  border-color: #e7e8ea;
  border-radius: 0.24rem;

  &:focus {
    transition: border-color 300ms;
  }

  &::placeholder {
    color: ${darken(0.32, "#e7e8ea")};

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
  }
`;

export const ModalEmptyList = styled.div`
  width: 100%;
  height: 16rem;
  margin: 0 auto;
  border: 1px solid #e7e8ea;
  border-radius: 0.24rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.88rem;

  p {
    font-weight: 500;
    font-style: normal;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: center;
    text-transform: none;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;
  }
`;

export const ErrorMessage = styled.span`
  width: fit-content;
  color: red;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 300;
`;
