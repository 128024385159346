import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { MetaTags } = require("react-meta-tags");

import { withTranslation } from "react-i18next";

import Header from "./components/Header/Header";
import Footer from "../../components/VerticalLayout/Footer";

import {
  Container,
  Display,
  Form,
  Label,
  Fields,
  AlignRadio,
  Aligninput,
} from "./signup.styles";
import UsagePolicy from "./components/UsagePolicy/UsagePolicy";

type Profile = {
  email: string;
  fullname: string;
  phone: string;
  contact: "email" | "phone" | "whatsapp";
};

interface SignUp {
  t: Function;
  profile: Profile;
}

function SignUp({ t }: SignUp) {
  const [profile, setProfile] = useState<Profile>();
  const [onFocusNameField, setOnFocusNameField] = useState<boolean>(false);
  const [onFocusEmailFieldm, setOnFocusEmailField] = useState<boolean>(false);
  const [onFocusPhoneFieldm, setOnFocusPhoneField] = useState<boolean>(false);
  const [onFocusContactFieldm, setOnFocusContactField] =
    useState<boolean>(false);

  return (
    <>
      <MetaTags>
        <title>{t("Register | Spott | Manage your recharge points")}</title>
      </MetaTags>

      <Container className="custom-bg">
        <Header />

        <Display>
          <h1>
            {t("Please leave your information, our team will talk to you.")}
          </h1>

          <Form
            action="https://evspott.us14.list-manage.com/subscribe/post?u=d8995e91772c7e43d111aef73&amp;id=25718be2d1&amp;f_id=0057f1e0f0"
            // action="https://hotmail.us13.list-manage.com/subscribe/post?u=28415e97a966d49031be98b31&amp;id=f031003721&amp;f_id=001da3e2f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate p-0 m-0"
            target="_self"
            // onSubmit={handleSubmit}
          >
            <Label htmlFor="full-name" onInputFocus={onFocusNameField}>
              <span>{t("Full name")}</span>
              <input
                name="FNAME"
                id="mce-FNAME"
                type="text"
                placeholder={t("Insert your full name")}
                value={profile?.fullname}
                onChange={(event) =>
                  setProfile((prevState: any) => ({
                    ...prevState,
                    fullname: event.target.value,
                  }))
                }
                onFocus={() => setOnFocusNameField(true)}
                onBlur={() => setOnFocusNameField(false)}
                required
              />
            </Label>

            <Label htmlFor="email" onInputFocus={onFocusEmailFieldm}>
              <span>E-mail</span>
              <input
                type="email"
                name="EMAIL"
                id="mce-EMAIL"
                placeholder={t("Insert your email")}
                value={profile?.email}
                onChange={(event) =>
                  setProfile((prevState: any) => ({
                    ...prevState,
                    email: event.target.value,
                  }))
                }
                onFocus={() => setOnFocusEmailField(true)}
                onBlur={() => setOnFocusEmailField(false)}
                required
              />
            </Label>

            <Label htmlFor="phone" onInputFocus={onFocusPhoneFieldm}>
              <span>{t("Phone")}</span>
              <input
                type="tel"
                name="PHONE"
                id="mce-PHONE"
                placeholder={t("(00) 00000-0000")}
                value={profile?.phone}
                onChange={(event) =>
                  setProfile((prevState: any) => ({
                    ...prevState,
                    phone: event.target.value,
                  }))
                }
                onFocus={() => setOnFocusContactField(true)}
                onBlur={() => setOnFocusContactField(false)}
                required
              />
            </Label>
            <Label htmlFor="contact" onInputFocus={onFocusContactFieldm}>
              <span>{t("Me contate por")}</span>
              <AlignRadio>
                <Aligninput>
                  {/* <input type="radio" value="E-mail" name="MMERGE2" id="mce-MMERGE2-0"/> */}
                  <input
                    type="radio"
                    value="E-mail"
                    name="MMERGE6"
                    id="mce-MMERGE6-0"
                    style={{ boxShadow: "none" }}
                  />
                  <label
                    style={{
                      marginTop: 8,
                      marginLeft: 10,
                      minWidth: 45,
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {"E-mail"}
                  </label>
                </Aligninput>
                <Aligninput>
                  {/* <input type="radio" value="Telefone" name="MMERGE2" id="mce-MMERGE2-1"/> */}
                  <input
                    type="radio"
                    value="Telefone"
                    name="MMERGE6"
                    id="mce-MMERGE6-1"
                    style={{ boxShadow: "none" }}
                  />
                  <label style={{ marginTop: 8, marginLeft: 10 }}>
                    {t("Phone")}
                  </label>
                </Aligninput>
                <Aligninput>
                  {/* <input type="radio" value="Telefone" name="MMERGE2" id="mce-MMERGE2-1"/> */}
                  <input
                    type="radio"
                    value="Whatsapp"
                    name="MMERGE6"
                    id="mce-MMERGE6-2"
                    style={{ boxShadow: "none" }}
                  />
                  <label style={{ marginTop: 8, marginLeft: 10 }}>
                    Whatsapp
                  </label>
                </Aligninput>
              </AlignRadio>
            </Label>
            <Fields>
              <button type="submit">{t("Send")}</button>

              <Link to="/login" style={{ width: "100%" }}>
                <button type="button">{t("Back to Login")}</button>
              </Link>
            </Fields>
          </Form>
        </Display>
      </Container>
      <Footer loginFooter={true} />

      {/* <section className="w-100 custom-bg">
        <div className="account-pages pt-5" style={{ marginTop: "-40px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col sm={9} md={7} lg={6} xl={4} xxl={3}>
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <div className="mt-4">
                      <p className="font-size-29 text-info mb-0">
                        {t(
                          "Please leave your information, our team will talk to you.",
                        )}
                      </p>
                    </div>

                    <div id="mc_embed_signup" className="w-100 mt-3 p-0">
                      <form
                        action="https://evspott.us14.list-manage.com/subscribe/post?u=d8995e91772c7e43d111aef73&amp;id=25718be2d1&amp;f_id=0057f1e0f0"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate p-0 m-0"
                        target="_self"
                      >
                        <div id="mc_embed_signup_scroll">
                          <div className="mc-field-group">
                            <label htmlFor="mce-FNAME">
                              {t("Name")} <span className="asterisk">*</span>
                            </label>
                            <input
                              type="text"
                              value={profile?.fullname}
                              name="FNAME"
                              className=""
                              id="mce-FNAME"
                              placeholder={t("Insert your full name")}
                              required
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                setProfile((prevState: any) => ({
                                  ...prevState,
                                  fullname: e.target.value,
                                }))
                              }
                            />
                            <span
                              id="mce-FNAME-HELPERTEXT"
                              className="helper_text"
                            ></span>
                          </div>

                          <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">
                              {t("Email")} <span className="asterisk">*</span>
                            </label>
                            <input
                              type="email"
                              value={profile?.email}
                              name="EMAIL"
                              className="required email"
                              id="mce-EMAIL"
                              placeholder={t("Insert your email")}
                              required
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                setProfile((prevState: any) => ({
                                  ...prevState,
                                  email: e.target.value,
                                }))
                              }
                            />
                            <span
                              id="mce-EMAIL-HELPERTEXT"
                              className="helper_text"
                            ></span>
                          </div>

                          <div className="mc-field-group">
                            <label htmlFor="mce-PHONE">
                              {t("Phone")} <span className="asterisk">*</span>{" "}
                            </label>
                            <input
                              type="text"
                              name="PHONE"
                              className=""
                              value={profile?.phone}
                              id="mce-PHONE"
                              required
                              placeholder={t("(00) 00000-0000")}
                              onChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) =>
                                setProfile((prevState: any) => ({
                                  ...prevState,
                                  phone: e.target.value,
                                }))
                              }
                            />
                            <span
                              id="mce-PHONE-HELPERTEXT"
                              className="helper_text"
                            ></span>
                          </div>
                          <div id="mce-responses" className="clear foot">
                            <div
                              className="response"
                              id="mce-error-response"
                              style={{ display: "none" }}
                            ></div>
                            <div
                              className="response"
                              id="mce-success-response"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_d8995e91772c7e43d111aef73_25718be2d1"
                              tabIndex={-1}
                              value=""
                            />
                          </div>
                          <div className="optionalParent">
                            <div className="clear foot">
                              <input
                                type="submit"
                                value={t("SUBSCRIBE")}
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className="btn btn-primary-gray w-full mt-2 waves-effect waves-light text-white bg-gray my-2"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="d-flex justify-content-center align-items-lg-center">
                      <BackToLogin />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Footer loginFooter={true} />
      </section> */}
    </>
  );
}

export default withTranslation()(SignUp);
