import "./Style.scss";
import React, { useState, useEffect } from "react";
import CustomBreadCrumb from "../../components/BreadCrumb";
import Tabs from "../../components/Tabs";
import {
  Container,
  Row,
  Col,
  Spinner,
  Card,
  CardBody,
  Button,
} from "reactstrap";
import {
  FormatDateHelper,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import { withTranslation } from "react-i18next";
import { ModalComponent } from "../../components/Modal";
import { useParams, useHistory } from "react-router-dom";
import { AxiosApi } from "../../helpers/api";
import { useStateContext } from "../../context/ContextProvider";

const DisabledSites = (props) => {
  const history = useHistory();
  const { site_id } = useParams();
  const { context, state } = useStateContext();

  const [userLanguage, setUserLanguage] = React.useState("");
  const [sitesList, setSitesList] = useState([]);
  const [confirmationConfig, setConfirmationConfig] = useState({ open: false });

  useEffect(() => {
    setSitesList([]);
    getDisabledSites();
    site_id ? disableSiteConfirm() : null;
  }, []);

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  const getDisabledSites = () => {
    const resp = AxiosApi.get(`UserSites/?disabled=true`, {})
      .then((response) => {
        setSitesList(response.data);
      })
      .catch((error) => {});
  };

  const activateHandler = (site) => {
    setConfirmationConfig({
      open: true,
      value: site.csId,
      title: props.t("Enable site"),
      body: props.t("Are you sure to enable " + site.csName + "?"),
      btOkText: props.t("Yes"),
      btOkStyle: "primary",
      btCancelText: props.t("No"),
      functionHandler: () => activateSite(site),
      closeHandler: modalCloseHandler,
    });
  };

  const activateSite = (site) => {
    const resp = AxiosApi.put(`SiteDisabledStatus/${site.csId}`, {
      isDisabled: false,
      siteId: site.csId,
    })
      .then((response) => {
        localStorage.removeItem("userSites");
        context.sitesUpdate();
        setTimeout(() => history.push(`dashboard/${site.csId}`), 1000);
      })
      .catch((error) => {});
    modalCloseHandler();
  };

  const modalCloseHandler = () => {
    setConfirmationConfig({ open: false });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb
                siteName={props.t("Disabled Sites")}
                title={""}
                // subtitle={`${props.t(
                //   "Last update",
                // )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Tabs tabs={[]} siteInfoProps={{}} pathname="Support">
        <Row>
          <Card className="card-users">
            <CardBody className="card-users-body">
              {sitesList === null ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner>{props.t("Loading...")}</Spinner>
                </div>
              ) : (
                <table className="table-users">
                  <thead>
                    <tr>
                      <th>{props.t("Site name")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesList.map((site, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            {site.csName}
                            <br />
                            <small className="font-size-11 text-muted">
                              {site.csId}
                            </small>
                          </td>
                          <td align="right">
                            <Button
                              id="bt-addUser"
                              color="primary"
                              className="bt-charger"
                              onClick={(e) => activateHandler(site)}
                            >
                              {props.t("ENABLE")}
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
              <ModalComponent config={confirmationConfig} />
            </CardBody>
          </Card>
        </Row>
      </Tabs>
    </div>
  );
};

export default withTranslation()(DisabledSites);
