import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.div`
  input {
    background-color: white;
    color: ${darken(0.48, "#edeeef")};
    width: 100%;
    height: 2.64rem;
    padding: 0.64rem;

    border: 1px solid #979797;
    border-radius: 0.24rem;

    font-weight: 500;
    font-style: normal;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    text-decoration: none;

    transition: all 300ms;

    &:focus {
      border-color: #3970f3;
    }

    &:disabled {
      background-color: ${darken(0.088, "#edeeef")};
      color: #979797;
      border-color: white;
      pointer-events: none;
      opacity: 0.48;
    }

    &::placeholder {
      color: ${darken(0.64, "#edeeef")};
    }
  }
`;
