import { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";

//i18n
import i18n from "../../../i18n";
import languages from "../../../common/languages";

const LanguageDropdownLogin = ({ incomingLanguage }) => {
  // const navLang =
  //   navigator.language === "pt-BR" || navigator.language === "en-US"
  //     ? navigator.language
  //     : "en-US";
  // const [selectedLang, setSelectedLang] = useState("");

  // useEffect(() => {
  //   const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
  //   setSelectedLang(currentLanguage);
  //   if (navLang) changeLanguageAction(navLang.substring(0, 2).toLowerCase());
  // }, []);

  // const changeLanguageAction = (lang) => {
  //   //set language as i18n
  //   i18n.changeLanguage(lang);
  //   localStorage.setItem("I18N_LANGUAGE", lang);
  //   setSelectedLang(lang);
  // };

  // -> New logic
  const DEFAULT_LANGUAGE = "pt";
  const [pageLanguage, setPageLanguage] = useState("");
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("I18N_LANGUAGE");
    //alert('storedLanguage: ' + storedLanguage + ', incomingLanguage:' + incomingLanguage);
    setPageLanguage(() => {
      if (incomingLanguage && incomingLanguage !== null) {
        return incomingLanguage.substring(0, 2).toLowerCase();
      }

      if (!storedLanguage) {
        if (navigator.language !== "pt-BR" && navigator.language !== "en-US") {
          return DEFAULT_LANGUAGE;
        }
        else return navigator.language;
      }
      else return storedLanguage;
    });
  }, []);

  useEffect(() => {
    if (!pageLanguage) return;
    
    i18n.changeLanguage(pageLanguage);
    //alert('pageLanguage: ' + pageLanguage)
    localStorage.setItem("I18N_LANGUAGE", pageLanguage);
  }, [pageLanguage]);

  return (
    <>
      <Dropdown
        isOpen={isLanguageMenuOpen}
        toggle={() => setIsLanguageMenuOpen((current) => !current)}
        direction="up"
        className="d-inline-block"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${pageLanguage}.flag`)}
            alt="Veltrix"
            height="16"
            className="me-2"
          />
          <span className="mdi mdi-chevron-down text-white"></span>
        </DropdownToggle>

        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => setPageLanguage(key)}
              className={`notify-item ${
                pageLanguage === key ? "active" : "none"
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Veltrix"
                className="me-2"
                height="12"
              />
              <span className="align-middle" style={{ color: "#5b626b" }}>
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdownLogin);
