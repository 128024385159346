import styled, { keyframes, css } from "styled-components";
import { lighten, darken, transparentize, rgba } from "polished";

interface AlertItemProps {
  isAlertRead?: boolean;
}

const scaleAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100%{
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 99.4%;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const AlertItem = styled.div<AlertItemProps>`
  width: 100%;
  margin: 1rem 0;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.2rem;
  transition: all 300ms;

  cursor: ${({ isAlertRead }) => (isAlertRead ? "default" : "pointer")};

  div {
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 1.4rem;

    svg {
      color: ${({ isAlertRead }) => (isAlertRead ? "#3970f3" : "#da0000")};

      ${({ isAlertRead }) =>
        !isAlertRead &&
        css`
          animation: ${scaleAnimation} 1.8s linear infinite;
        `};
    }
  }

  p {
    margin: 0;
    margin-left: 2.4rem;
    padding: 0;

    span {
      text-transform: uppercase;
    }
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.028);
    border-color: ${transparentize(0.8, "#3970f3")};
  }
`;
