import * as React from "react";
import * as Translator from "react-i18next";

const { Spinner } = require("reactstrap");
import Switch from "react-switch";
import TogglerIcon from "../../../../components/Renders/TogglerIcon/TogglerIcon";

import AutoUpdateFirmware from "../../../../components/Chargers/ChargerFirmware/AutoUpdateFirmware";
import ChargerPricification from "./components/ChargerPricification/ChargerPricification";
import ChargerExclusive from "./components/ChargerExclusive/ChargerExclusive";
import ChargerPoint from "./components/ChargerPoint/ChargerPoint";
import ChargerConnectors from "./components/ChargerConnectors/ChargerConnectors";
import ChargerCommands from "./components/ChargerCommands/ChargerCommands";

import { ChargerPointTypes } from "../../../../interfaces/Chargers";

import {
  Component,
  Wrapper,
  Header,
  Content,
  DisplayEmpty,
  TestAligment,
} from "./chargerdetails.styles";

type ChargerViewPropsTypes = {
  t: Function;
  selectedCharger: ChargerPointTypes;
  loadingDetails?: boolean;
  refreshChargersList: () => void;
};

const ChargerDetails = ({
  t: translation,
  loadingDetails = false,
  selectedCharger = {} as ChargerPointTypes,
  refreshChargersList,
}: ChargerViewPropsTypes) => {
  const charger: ChargerPointTypes = selectedCharger;

  const [autoUpdateFirmware, setAutoUpdateFirmware] =
    React.useState<boolean>(false);

  const pageWidth = document.documentElement.scrollWidth;

  return (
    <>
      <Component lowerView={pageWidth >= 1500 ? false : true}>
        <Wrapper>
          {/* {loadingDetails && <Spinner size="md" />}
          {!loadingDetails} */}

          {!Object.keys(charger).length && (
            <DisplayEmpty>
              <p>{translation("chargers_ChargerDetailsPanelInfo")}</p>
            </DisplayEmpty>
          )}

          {!!Object.keys(charger).length && (
            <>
              {/* <Header>
                <Switch
                  id="update-switch"
                  className="react-switch"
                  onChange={() => setAutoUpdateFirmware((prev) => !prev)}
                  checked={autoUpdateFirmware}
                  uncheckedIcon={<TogglerIcon icon={"Off"} />}
                  checkedIcon={<TogglerIcon icon={"On"} />}
                  onColor="#2FD475"
                />

                <p>
                  {translation(
                    "chargers_ChargerDetailsButtonAutoUpdateFirmware",
                  )}
                </p>
              </Header> */}

              <Content>
                <p>{translation("chargers_ChargerDetailsPanelTitle")}</p>

                <ChargerPricification
                  selectedCharger={charger}
                  refreshChargersList={refreshChargersList}
                />

                <ChargerExclusive
                  selectedCharger={charger}
                  refreshChargersList={refreshChargersList}
                />

                <ChargerPoint selectedCharger={charger} refreshChargersList={refreshChargersList}/>

                <ChargerConnectors selectedCharger={charger} />

                <ChargerCommands
                  selectedCharger={charger}
                  refreshChargersList={refreshChargersList}
                />
              </Content>
            </>
          )}
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerDetails);
