import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const ContentInformations = styled.span`
  display: grid;
  grid-row-gap: 10px;
  border: 1px solid #979797;
  padding: 15px 15px 30px 15px;
`;

export const SpanInformation = styled.span`
  display: flex;
  grid-column-gap: 5px;

  b {
    font-weight: 600;
  }
`;

export const ChangeChargerInput = styled.input`
  width: 100%;
  height: 3.5rem;
  border: 1px solid transparent;
  border-color: #edeeef;
  border-radius: 0.24rem;
  margin-bottom: 10px;
  padding: 0.375rem 0.75rem;

  &:focus {
    transition: border-color 300ms;
    border-color: #3970f3;
  }

  &::placeholder {
    color: ${darken(0.32, "#edeeef")};

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
  }
`;

export const DivButton = styled.div`
  display: grid;
  justify-content: end;
  margin-top: 10px;

  button {
    width: 10rem;
  }
`;
