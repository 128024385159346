import styled from "styled-components";

type TabButtonPropsType = {
  isSelected: boolean;
};

type FloatingDisplayProps = {
  showContent: boolean;
  CoordinatesX?: number;
  CoordinatesY?: number;
};

export const Container = styled.section`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const TabBar = styled.nav`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  z-index: 1000;

  border: 1px solid transparent;
  border-bottom-color: #ced4da;
`;

export const TabButton = styled.button<TabButtonPropsType>`
  background-color: transparent;
  color: #495057;
  margin: 0;
  padding: 8px 16px;
  border: 1px solid
    ${({ isSelected }) => (isSelected ? `#ced4da` : `transparent`)};
  border-bottom-color: transparent;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5rem;
  text-transform: capitalize;

  &:hover {
    border-color: ${({ isSelected }) => (isSelected ? `#ced4da` : `#e9ecef`)};
    border-bottom: none;
    color: ${({ isSelected }) => (isSelected ? `#495057` : `#2e5acf`)};
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  scrollbar-width: none;
  position: relative;
`;

export const NoContentDisplay = styled.div`
  width: 100%;
  height: 42rem;
  margin: 0 auto;
  margin-top: 2rem;

  border: 1px solid #979797;
  border-radius: 0.5rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    color: #8b8b8b;
    margin: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 1.24rem;
    line-height: 2rem;
  }

  svg {
    color: #565656;
    margin-bottom: 1rem;
    width: 4rem;
    height: 4rem;
    cursor: pointer;

    transition: all 300ms linear;
    &:hover {
      color: #3970f3;
    }
  }
`;

export const Calendar = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  position: relative;

  p {
    color: #353f47;
    font-style: normal;
    font-weight: 500;
    font-size: 1.16rem;
    line-height: 1.16rem;
    text-align: left;
  }

  .fc-timeGridWeek-view {
    margin-bottom: 100px;
  }

  .fc-scrollgrid {
    height: 80vh;
    margin-top: 0;
    margin-bottom: 0;
  }

  .fc-col-header {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 2px;
    text-align: center;
    color: #32353a;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    a {
      color: #32353a;
      opacity: 0.6;
      font-size: 10px;
      text-transform: uppercase;
      line-height: 12px;
      letter-spacing: 2px;
      text-align: center;
      font-weight: 500;
    }
    .fc-day-today {
      a {
        color: #0029ff;
        opacity: 0.6;
        font-size: 10px;
        text-transform: uppercase;
        line-height: 12px;
        letter-spacing: 2px;
        text-align: center;
        font-weight: 500;
      }
    }
  }

  tr.fc-scrollgrid-section:nth-child(1)
    > th:nth-child(1)
    > div:nth-child(1)
    > div:nth-child(1) {
    margin-top: -100px;
    overflow: hidden;
    scrollbar-width: none;
  }

  .fc-col-header > thead:nth-child(2) > tr:nth-child(1) {
    height: 30px;
  }

  tr.fc-scrollgrid-section:nth-child(2)
    > td:nth-child(1)
    > div:nth-child(1)
    > div:nth-child(1) {
    margin-top: -100px;
    overflow: hidden;
    scrollbar-width: none;
  }

  .fc-scroller-liquid-absolute {
    margin-top: -100px;
    overflow: hidden;
    scrollbar-width: none;
  }

  .fc-timegrid-cols > table:nth-child(1) {
    margin-top: 0px;
    overflow: hidden;
    scrollbar-width: none;
  }

  .fc-timegrid-slots {
    font-size: 12px;
    font-weight: 500;
    line-height: 10px;
    letter-spacing: 0.5px;
    text-align: right;
    color: #8b8b8b;
  }

  .fc-view-harness {
    background-color: #ffffff;
  }
`;

export const FloatingDisplay = styled.div<FloatingDisplayProps>`
  background-color: white;
  width: 17rem;
  margin: 0;
  padding: 1rem;

  position: absolute;
  top: ${({ CoordinatesY }) => CoordinatesY && CoordinatesY}px;
  left: ${({ CoordinatesX }) => CoordinatesX && CoordinatesX}px;

  opacity: ${({ showContent }) => (showContent ? 1 : 0)};
  pointer-events: ${({ showContent }) => (showContent ? "all" : "none")};

  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4rem;

  z-index: 1000;
`;
