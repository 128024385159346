import React from "react";
import { withTranslation } from "react-i18next";

const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} = require("reactstrap");

import Error from "../../Alerts/Error";
import Success from "../../Alerts/Success";

import { AxiosApi } from "../../../helpers/api";
import { Charger } from "../../../interfaces/Chargers";
import { Exception } from "../../../interfaces/Exception";
import { BasicModal } from "../../../interfaces/Modal";

interface ChargerResetProps extends BasicModal {
  t: Function;
  charger: Charger;
}

function ChargerFirmware({ t, charger, isOpen, onClose }: ChargerResetProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  const dispatchUpdateFirmware = async () => {
    try {
      setLoading(true);

      const { data } = await AxiosApi.get(
        `/UpdateFirmwareRequest/${charger.chargePointId}`,
      );

      if (data.status === "Accepted") {
        setHandleSuccess(true);

        setTimeout(() => {
          onClose();
        }, 2500);
      } else {
        setHandleError({
          isOpen: true,
          status: 400,
          message: { error: "REQUEST_FAILURE" },
          onClose: () => setHandleError(undefined),
        });
      }
    } catch (err: any) {
      setHandleError({
        isOpen: true,
        status: 400,
        message: { error: "REQUEST_FAILURE" },
        onClose: () => setHandleError(undefined),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered fullscreen="sm">
      <ModalHeader toggle={onClose}>
        <span className="text-primary">{t("Charger Update Firmware")}</span>
      </ModalHeader>

      {handleError && <Error {...handleError} />}
      {handleSuccess && (
        <Success message={t("Firmware updated successfully!")} />
      )}

      {!handleError && !handleSuccess && (
        <>
          <ModalBody>
            <span>
              {t(
                "Are you sure you want to update the firmware of this charger?",
              )}
            </span>
          </ModalBody>

          <ModalFooter>
            {loading ? (
              <Spinner size="sm" className="text-primary">
                {t("Loading...")}
              </Spinner>
            ) : (
              <>
                <Button color="danger" onClick={dispatchUpdateFirmware}>
                  {t("Update Firmware")}
                </Button>
                <Button onClick={onClose}>{t("Cancel")}</Button>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default withTranslation()(ChargerFirmware);
