import * as React from "react";
import * as Translator from "react-i18next";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";

const { Modal } = require("reactstrap");
import { Button } from "../../../../components/Button";

import {
  Component,
  ModalWrapper,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from "./modaldeletion.styles";

type ModalDeletionPropsTypes = {
  t: Function;
  modalIsOpen: boolean;
  modalChargerId: string;
  modalOnDeleteCharger: () => void;
  modalOnRequestClose: () => void;
};

export const ModalDeletion = ({
  t: translation,
  modalIsOpen = false,
  modalChargerId = "",
  modalOnDeleteCharger,
  modalOnRequestClose,
}: ModalDeletionPropsTypes) => {
  const [check, setCheck] = React.useState<boolean>(false);

  return (
    <>
      <Component>
        <Modal isOpen={modalIsOpen} fullscreen={"sm"} size={"md"} centered>
          <ModalWrapper>
            <ModalHeader>
              <p className="text-danger">
                {translation("chargers_ModalDeletionTitle")}{" "}
                <span>{modalChargerId}</span>
              </p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            <hr />

            <ModalContent>
              <p>{translation("chargers_ModalDeletionText")}</p>
              <p>{translation("chargers_ModalDeletionTextInfo")}</p>

              <div className={"text-danger"}>
                {!check && (
                  <ImCheckboxUnchecked onClick={() => setCheck(true)} />
                )}
                {check && <ImCheckboxChecked onClick={() => setCheck(false)} />}

                {translation("chargers_ModalDeletionTextConfirmation")}
              </div>
            </ModalContent>

            <hr />

            <ModalFooter>
              <Button
                type="button"
                buttonColor={"dark"}
                onClick={modalOnRequestClose}
              >
                {translation("chargers_ModalDeletionButtonCancel")}
              </Button>
              <Button
                type="button"
                buttonColor={"red"}
                disabled={!check}
                onClick={modalOnDeleteCharger}
              >
                {translation("chargers_ModalDeletionButtonDelete")}
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Modal>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalDeletion);
