import "./UserProfile.scss";
import React, { useState, useEffect, useRef } from "react";
import CustomBreadCrumb from "../../components/BreadCrumb";
import { Container, Row, Col, Card, CardBody, Form } from "reactstrap";
import { AxiosApi } from "../../helpers/api";
import { FormatDateHelper } from "../../helpers/Helpers";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useStateContext } from "../../context/ContextProvider";

const ProfileAvatar = (props) => {
  const [file, setFile] = useState({ selectedFile: "" });
  const [filet, setFilet] = useState();
  const [avatar, setAvatar] = useState({ file: "" });
  const { context, state } = useStateContext();
  const inputRef = useRef(null);

  useEffect(() => {
    if (state.avatar) setAvatar(state.avatar);
  }, [state.avatar]);

  const handleInputChange = (evt) => {
    const files = evt.target.files;
    const reader = new FileReader();
    setFilet(files);

    if (
      files.length !== 0 &&
      files[0].type.match("image.*") &&
      files[0].size !== undefined &&
      files[0].size < 1000000
    ) {
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => setFile({ selectedFile: e.target.result });
    } else {
      evt.target.value = null;
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (file.selectedFile === "") return;

    const formData = { file: file.selectedFile };
    AxiosApi.post(`/Avatar`, formData).then((res) => {
      context.avatarUpdate();
    });
    setFilet(null);
  };

  const removeAvatarHandler = (e) => {
    setFile({ selectedFile: "" });
    setFilet(null);
    AxiosApi.post(`/Avatar`, { file: "" }).then((res) => {
      context.avatarUpdate();
    });
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb
                siteName={props.t("Settings")}
                title={props.t("Add photo")}
                // subtitle={`${props.t("Last update")}: ${FormatDateHelper(
                //   Date.now(),
                // )}`}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <div className="w-100">
            <ul className="nav nav-tabs w-100">
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/UserProfile/`, state: {} }}
                >
                  {props.t("User Profile")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/Password/`, state: {} }}
                >
                  {props.t("Password")}
                </Link>
              </li>
              <li className="nav-item actived">
                <span className={`nav-link active`}>
                  {props.t("Add photo")}
                </span>
              </li>
            </ul>
          </div>
        </Row>
      </Container>
      <Row>
        <Col xl={12} className="p-3 pt-0">
          <Card>
            <CardBody className="">
              <Row>
                <div className="avatarAlign">
                  <div className="avatarTitle">
                    {props.t("Insira sua foto abaixo")}
                  </div>
                  <div className="rounded-circle avatar overflow-hidden">
                    {avatar.file === "" ? (
                      <img
                        src={require("../../assets/images/user_default.png")}
                        className="img-fluid h-100"
                        alt="Avatar"
                      />
                    ) : (
                      <img
                        src={avatar.file}
                        className="img-fluid"
                        alt="Avatar"
                      />
                    )}
                  </div>
                </div>
              </Row>
              <Row className="py-4">
                <Col md={{ offset: 0, size: 8 }} className="text-left">
                  {props.t("avatarInstructions")}
                </Col>
              </Row>

              <Form onSubmit={(e) => submitHandler(e)}>
                <div className="inputAlign">
                  <button onClick={handleClick} className="buttonChoose">
                    {props.t("CHOOSE FILE")}
                  </button>
                  <input
                    style={{ display: "none" }}
                    ref={inputRef}
                    type="file"
                    name="avatar"
                    accept="image/*"
                    onChange={(e) => handleInputChange(e)}
                  />
                  <div className="inputDiv">
                    {filet && filet[0]
                      ? filet[0].name
                      : props.t("no file selected")}
                  </div>

                  <button type="submit" className="changeButton">
                    {props.t("CHANGE")}
                  </button>

                  <button
                    type="submit"
                    className="removeButton"
                    onClick={(e) => removeAvatarHandler(e)}
                  >
                    {props.t("profile_AddPhotoButtonRemove")}
                  </button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withTranslation()(ProfileAvatar);
