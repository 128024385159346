import styled from "styled-components";

type ContentButtonsProps = {
  lowerView? : boolean;
}

export const Content = styled.span`
  display: flex;
  grid-column-gap: 7px;

  b {
    font-weight: 600;
  }
`;

export const ContentButtons = styled.span<ContentButtonsProps>`
  display: grid;
  grid-template-columns: ${({lowerView}) => lowerView ? "auto" : "repeat(2, max-content)"};
  gap: ${({lowerView}) => lowerView ? "5px" : ""};
  justify-content: space-between;
  margin-top: 10px;

  button {
    width: 10rem;
  }
`;
