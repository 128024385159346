import * as React from "react";
import * as Translator from "react-i18next";
const { Modal } = require("reactstrap");
import Switch from "react-switch";

import { AxiosApi } from "../../../../helpers/api";

import { PricificationProfileTypes } from "../../../../interfaces/Pricification";
import TogglerIcon from "../../../../components/Renders/TogglerIcon/TogglerIcon";

import {
  ModalFormField,
  ModalFormHeader,
  ModalFormTitleField,
  ModalFormContent,
  ModalFormInputField,
  ModalFormFooter,
  ModalFormButton,
} from "./profilemanagement.styles";
import { Button } from "../../../../components/Button/Button";
import { Exception } from "../../../../interfaces/Exception";
import ErrorModal from "../../../../components/Alerts/ErrorModal";

type ProfileManagementModalPropsType = {
  t: Function;
  modalIsOpen: boolean;
  modalType: string;
  modalClose: () => void;
  setHandleError: any;
  setProfileManagementeModal: any;
  setProfileCurrentData: any;
  csId: string;
  profileCurrent?: PricificationProfileTypes | undefined;
};

type FieldsValueStateType = {
  title: string;
  kwh: string;
  hour: string;
  free: boolean;
  disabled: boolean;
};

export const ProfileManagementModal = ({
  t: translation,
  modalIsOpen,
  modalType,
  modalClose,
  setHandleError,
  setProfileManagementeModal,
  setProfileCurrentData,
  csId,
  profileCurrent,
}: ProfileManagementModalPropsType) => {
  const inputRefTitle = React.useRef<HTMLInputElement>(null);
  const inputRefWattage = React.useRef<HTMLInputElement>(null);
  const inputRefHour = React.useRef<HTMLInputElement>(null);

  // console.log("modalType", modalType)
  const [fieldsValue, setFieldsValue] = React.useState<FieldsValueStateType>({
    title: "",
    kwh: "",
    hour: "",
    free: false,
    disabled: false,
  });
  const [loading, setLoading] = React.useState<any>(false);

  const closeErrorFunction = () => {
    setProfileManagementeModal({
      opening: true,
      modeType: "edition",
    });

    return setHandleError(undefined);
  }

  const handleSubmitModalForm = async (event: any, values: any) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();

    if (profileCurrent != undefined) {
      if (modalType === "edition") {
        profileCurrent = {
          priceProfileId: profileCurrent.priceProfileId,
          priceProfileName: values.title,
          priceProfileCsId: profileCurrent.priceProfileCsId,
          priceProfileDefaultProfile: profileCurrent.priceProfileDefaultProfile,
          priceProfileChargerDisabled:
            profileCurrent.priceProfileChargerDisabled,
          priceProfileFreeCharge: profileCurrent.priceProfileFreeCharge,
          priceProfilePriceKwh: profileCurrent.priceProfilePriceKwh,
          priceProfilePriceTimeCharging:
            profileCurrent.priceProfilePriceTimeCharging,
          priceProfileTransactionPrice:
            profileCurrent.priceProfileTransactionPrice,
          priceProfileOverstayPenalty:
            profileCurrent.priceProfileOverstayPenalty,
          priceProfileOverstayGracePeriod:
            profileCurrent.priceProfileOverstayGracePeriod,
          priceProfileReservationAllowed:
            profileCurrent.priceProfileReservationAllowed,
          priceProfileReservationPriceMinute:
            profileCurrent.priceProfileReservationPriceMinute,
          priceProfileReservationLimitMinutes:
            profileCurrent.priceProfileReservationLimitMinutes,
          priceProfileOverstayPriceMinute:
            profileCurrent.priceProfileOverstayPriceMinute,
          priceProfilePriceTimePlugged:
            profileCurrent.priceProfilePriceTimePlugged,
        };

        await AxiosApi.put(
          `/PriceProfiles/${profileCurrent.priceProfileId}`, profileCurrent,
        ).then( res => {
          setLoading(false);
          modalClose();
        }).catch((error) => {
          modalClose()
          return setHandleError({
            isOpen: true,
            message: translation("chargers_ChargerExclusiveModalDeleteError"),
            status: error?.response?.status,
            onClose: () => closeErrorFunction(),
          });
        })
      }
    }

    if (modalType === "creation") {
      const resp: any = AxiosApi.post(
        `/PriceProfiles`,
        {
          priceProfileName: values.title,
          priceProfileCsId: csId,
          priceProfileChargerDisabled: values.disabled,
          priceProfileFreeCharge: values.free,
          priceProfilePriceKwh: values.wattagePrice,
          priceProfilePriceTimeCharging: 0,

          priceProfileTransactionPrice: 0,
          priceProfileOverstayPenalty: 0,
          priceProfileOverstayGracePeriod: 0,
          priceProfileReservationAllowed: false,
          priceProfileReservationPriceMinute: 0,
          priceProfileReservationLimitMinutes: 0,
          priceProfileOverstayPriceMinute: 0,
          priceProfilePriceTimePlugged: values.hourPrice,
        },
        // console.log("RESP", resp)
      )
        .then((response) => {
          setProfileCurrentData(response.data);
          localStorage.setItem('profileID', String(response.data.priceProfileId))
        })
        .catch((err) => {
          console.log("ERRO", err);
        })
        .finally(() => {
          setLoading(false);
          modalClose();
        });
    }
  };

  React.useEffect(() => {
    setFieldsValue((prev) => ({
      ...prev,
      free: false,
    }));
  }, [fieldsValue.disabled]);

  // React.useEffect(() => {
  //   if (profileCurrent && profileCurrent?.priceProfileName && modalType === 'edition'){
  //   setFieldsValue((prev) => ({
  //     ...prev,
  //     title: profileCurrent?.priceProfileName
  //   }));
  // }
  // }, [modalType, profileCurrent]);

  React.useEffect(() => {
    setFieldsValue((prev) => ({
      ...prev,
      kwh: "0",
      hour: "0",
    }));
  }, [fieldsValue.free]);

  return (
    <>
      <Modal isOpen={modalIsOpen} size="lg" centered={false} fullscreen="sm">
        <ModalFormField onValidSubmit={handleSubmitModalForm}>
          <ModalFormHeader>
            <h1>
              {modalType === "creation" &&
                translation("pricing_ModalNewProfileTitle")}
              {modalType === "edition" &&
                translation("pricing_ModalEditProfileTitle")}
              {modalType === "deletion" &&
                translation("pricing_ModalDeleteProfileTitle")}
            </h1>

            <p>
              {modalType === "creation" &&
                translation("pricing_ModalNewProfileText")}
              {modalType === "edition" &&
                translation("pricing_ModalEditProfileText")}
              {modalType === "deletion" &&
                translation("pricing_ModalDeleteProfileText")}
            </p>

            {modalType === "deletion" && (
              <p>{translation("pricing_ModalDeleteProfileInfo")}</p>
            )}

            {modalType !== "deletion" && (
              <ModalFormTitleField
                value={fieldsValue.title}
                defaultValue={
                  profileCurrent &&
                  profileCurrent?.priceProfileName &&
                  modalType === "edition" &&
                  profileCurrent?.priceProfileName
                }
                name={"title"}
                type={"text"}
                placeholder={translation(
                  modalType === "creation"
                    ? "pricing_ModalNewProfileNamePlaceholder"
                    : "pricing_ModalEditProfileInputPlaceholder",
                )}
                validate={{
                  required: {
                    value: true,
                    errorMessage: translation('pricing_ModalNewProfileTitleInputError'),
                  },
                }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldsValue((current) => ({
                    ...current,
                    title: event.target.value,
                  }))
                }
              />
            )}
          </ModalFormHeader>

          {modalType && modalType === "creation" && (
            <>
              <ModalFormContent>
                <p>{translation("pricing_ModalNewProfileWeekInfo")}</p>

                <div className="aligment-content">
                  <span>R$</span>
                  <ModalFormInputField
                    value={fieldsValue.kwh}
                    name={"wattagePrice"}
                    type={"number"}
                    step={".50"}
                    min={0}
                    // validate={{
                    //   number: true,
                    //   min: { value: 0 },
                    // }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldsValue((current) => ({
                        ...current,
                        kwh: event.target.value,
                      }))
                    }
                    disabled={fieldsValue.disabled || fieldsValue.free}
                    required
                  />

                  <span>kWh</span>
                </div>

                <div className="aligment-content">
                  <span>R$</span>
                  <ModalFormInputField
                    value={fieldsValue.hour}
                    name={"hourPrice"}
                    type={"number"}
                    step={".50"}
                    min={0}
                    // validate={{
                    //   number: true,
                    //   min: { value: 0 },
                    // }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldsValue((current) => ({
                        ...current,
                        hour: event.target.value,
                      }))
                    }
                    disabled={fieldsValue.disabled || fieldsValue.free}
                    required
                  />

                  <span>/h</span>
                </div>

                <div className="aligment-content">
                  <ModalFormInputField
                    name={"free"}
                    type={"switch"}
                    value={fieldsValue.free}
                    style={{ display: "none" }}
                  />
                  <Switch
                    uncheckedIcon={<TogglerIcon icon={"Off"} />}
                    checkedIcon={<TogglerIcon icon={"On"} />}
                    onColor="#2FD475"
                    onChange={() =>
                      setFieldsValue((current) => ({
                        ...current,
                        free: !current.free,
                      }))
                    }
                    checked={fieldsValue.free}
                  />

                  <span>
                    {translation("pricing_ModalNewProfileSwitcherFree")}
                  </span>
                </div>

                <div className="aligment-content">
                  <ModalFormInputField
                    name={"disabled"}
                    type={"switch"}
                    value={fieldsValue.disabled}
                    style={{ display: "none" }}
                  />
                  <Switch
                    uncheckedIcon={<TogglerIcon icon={"Off"} />}
                    checkedIcon={<TogglerIcon icon={"On"} />}
                    onColor="#2FD475"
                    onChange={() =>
                      setFieldsValue((current) => ({
                        ...current,
                        disabled: !current.disabled,
                      }))
                    }
                    checked={fieldsValue.disabled}
                  />

                  <span>
                    {translation("pricing_ModalNewProfileSwitcherDisabeld")}
                  </span>
                </div>
              </ModalFormContent>
            </>
          )}

          <ModalFormFooter>
            <Button type={"button"} onClick={modalClose}>
              {translation("pricing_ProfileManagementModalButtonCancel")}
            </Button>

            <Button
              type={"submit"}
              buttonColor={modalType === "deletion" ? "red" : "blue"}
            >
            
            {modalType === "creation" &&
              translation("pricing_ProfileManagementModalButtonCreate")}
            {modalType === "edition" &&
              translation("pricing_ProfileManagementModalButtonUpdate")}
            {modalType === "deletion" &&
              translation("pricing_ProfileManagementModalButtonDelete")}
            </Button>
            {/* <ModalFormButton
              type={"button"}
              activeColor={"grey"}
              onClick={modalClose}
            >
              {translation("pricing_ProfileManagementModalButtonCancel")}
            </ModalFormButton> */}

            {/* <ModalFormButton
              type={"submit"}
              activeColor={modalType === "deletion" ? "red" : "blue"}
              disabled={loading}
            >
              {modalType === "creation" &&
                translation("pricing_ProfileManagementModalButtonCreate")}
              {modalType === "edition" &&
                translation("pricing_ProfileManagementModalButtonUpdate")}
              {modalType === "deletion" &&
                translation("pricing_ProfileManagementModalButtonDelete")}
            </ModalFormButton> */}
          </ModalFormFooter>
        </ModalFormField>
      </Modal>
    </>
  );
};

export default Translator.withTranslation()(ProfileManagementModal);
