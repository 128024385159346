import "./styles.scss";
import React, { useState, useEffect } from "react";
import { Link, useParams, Redirect } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";
import ArrowUp from "./../../assets/images/arrow-up.png";
import ArrowDown from "./../../assets/images/arrow-down.png";
import { withTranslation } from "react-i18next";

// tabs = {
//   'name': charger.name,
//   'chargePointId': charger.chargePointId,
//   'site_id': site_id,
//   'siteInfo': siteInfo,
// }

const MenuChargers = ({ tabs, siteInfo, pathname, t }) => {
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };
  const { site_id, charger_id } = useParams();
  const [visibleTabs, setVisibleTabs] = useState([]);
  const selectedCharger = charger_id
    ? tabs.find((tab) => tab.chargePointId === charger_id)
    : null;
  let q = 3; //visible tabs

  useEffect(() => {
    if (
      visibleTabs &&
      !tabs.find((tab) => tab.chargePointId === visibleTabs[0].chargePointId)
    )
      setVisibleTabs([]);
  }, [tabs]);

  const listTabs = () => {
    let arr = visibleTabs;

    //nao ha tabs visiveis, adiciona algumas
    if (arr.length === 0) {
      tabs.map((tab, index) => {
        if (index < q) return arr.push(tab);
      });
      setVisibleTabs(arr);
    }

    //checando se a tab selecionada esta entre as visiveis, se nao, coloca ela em primeiro.
    if (
      selectedCharger &&
      !arr.find((tab) => tab.chargePointId === selectedCharger.chargePointId)
    ) {
      arr.unshift(selectedCharger);
      arr.pop();
      setVisibleTabs(arr);
    }

    //prepara a lista de tabs pronta
    const result = arr.map((tab, index) => {
      if (index < q) {
        return (
          <li key={index} className="nav-item">
            <Link
              className={`nav-link ${
                selectedCharger &&
                tab.chargePointId === selectedCharger.chargePointId
                  ? "active"
                  : ""
              }`}
              to={{
                pathname: `/${pathname}/${tab.site_id}/${tab.chargePointId}`,
                state: {
                  site: { ...tab.siteInfo, chargerActiveName: tab.name },
                },
              }}
            >
              {tab.name}
            </Link>
          </li>
        );
      }
    });

    return result;
  };

  const listMore = () => {
    let arr = visibleTabs;
    if (tabs.length <= arr.length) return; //se ha menos que tabs visiveis ele para aqui

    return (
      //retorna a aba More preenchida
      <li className="nav-item dropdown">
        <Dropdown tag="div" isOpen={menu} toggle={toggle} nav>
          <DropdownToggle caret nav className="nav-link dropdown-toggle">
            {t("More...")}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            {tabs.map((tab, index) => {
              if (
                arr.find(
                  (tabVisible) =>
                    tabVisible.chargePointId === tab.chargePointId,
                )
              )
                return;
              return (
                <DropdownItem key={index}>
                  <Link
                    to={{
                      pathname: `/${pathname}/${tab.site_id}/${tab.chargePointId}`,
                      state: {
                        site: { ...tab.siteInfo, chargerActiveName: tab.name },
                      },
                    }}
                  >
                    {tab.name}
                  </Link>
                </DropdownItem>
              );
            })}
          </DropdownMenu>
        </Dropdown>
      </li>
    );
  };

  return (
    <div className="w-100">
      <ul className="nav nav-tabs w-100">
        {/* ALL TAB */}
        <li className="nav-item">
          <Link
            className={`nav-link ${selectedCharger ? "" : "active"}`}
            to={{
              pathname: `/${pathname}/${site_id}`,
              state: { site: { ...siteInfo, chargerActiveName: "" } },
            }}
          >
            {t("All chargers")}
          </Link>
        </li>

        {/* 3 itens */}
        {listTabs()}

        {/* MORE TAB */}
        {listMore()}
      </ul>
    </div>
  );
};

const MenuChargersTranslation = withTranslation()(MenuChargers);

export { MenuChargersTranslation };

const Tabs = ({
  tabs,
  image,
  mainValue,
  percentage,
  period,
  children,
  siteInfoProps,
  pathname,
  t,
}) => {
  const [siteInfo, setSiteInfo] = useState(siteInfoProps);
  const { site_id } = useParams();

  useEffect(() => {
    setSiteInfo(siteInfoProps);
  }, [siteInfoProps]);

  const headerLoaded =
    image && mainValue ? (
      <div className="tabsContent__header p-4">
        <div className="d-flex">
          <div className="tabsContent__header--image">
            <img src={image} className="flex-1" />
          </div>

          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex align-items-center mb-2">
              <div
                className={`d-flex ${
                  percentage < 0 ? "bg-danger" : "bg-success"
                } text-white px-4 py-1`}
              >
                <span>
                  {percentage > 0 ? "+ " : ""}
                  {t(percentage)}
                  {isNaN(percentage) ? "" : "%"}
                </span>
              </div>
              {isNaN(percentage) ? (
                <></>
              ) : (
                <img
                  className="tabsContent__header--arrowUp"
                  src={percentage >= 0 ? ArrowUp : ArrowDown}
                />
              )}
            </div>
            <span className="text-white display-4">{mainValue}</span>
          </div>
        </div>
        <span className="tabsContent__header--info">{period}</span>
      </div>
    ) : null;

  const loader = (
    <div className="tabsContent__header p-4">
      <div className="d-flex text-white align-items-center justify-content-center">
        <Spinner>Loading...</Spinner>
      </div>
    </div>
  );

  return (
    <div className="container-fluid">
      {tabs.length > 0 ? (
        <div className="tabs align-itens-center d-flex pb-2">
          <MenuChargersTranslation
            tabs={tabs}
            siteInfo={siteInfo}
            pathname={pathname}
          />
        </div>
      ) : (
        ""
      )}

      <div className="tabsContent">
        {percentage === null ? loader : headerLoaded}
        {children}
      </div>
    </div>
  );
};

export default withTranslation()(Tabs);
