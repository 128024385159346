import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  button {
    width: 10rem;
  }

  div {
    display: flex;
    justify-content: space-between;
    margin: 0.24rem 0;
  }

  button:last-child {
    display: block;
    margin-left: auto;
    margin-right: 0;
  }
`;
