import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, LOGIN_GOOGLE, LOGIN_FACEBOOK } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
  postGoogleAuth,
  postFacebookAuth
} from "../../../helpers/fakebackend_helper";

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {

      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));

    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {

      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });

      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");

  } catch (error) {
    
    try {
      const { response } = error;

      if (response.status === 404) {
        yield put(apiError("Erro: usuário ou senha inválido."));
      } else if (response.status === 401) {
        yield put(apiError("Erro: usuário não possui acesso."));
      } else {
        yield put(apiError("Erro interno do servidor."));
      }
    } catch (e) {
      yield put(apiError("Erro desconhecido."));
    }
  }
}

function* googleAuth({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      console.log("googleAuth_CALL")
      const response = yield call(postGoogleAuth, {
        access_token: user.access_token
      });
      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postGoogleAuth, {
        access_token: user.access_token
      });
      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");

  } catch (error) {
    yield put(apiError("Erro: Erro ao usar Google."));
  }
}

function* facebookAuth({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      console.log("googleAuth_CALL")
      const response = yield call(postFacebookAuth, {
        tokenId: user.access_token
      });
      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFacebookAuth, {
        tokenId: user.access_token
      });
      localStorage.removeItem("authUser");
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history.push("/dashboard");

  } catch (error) {
    yield put(apiError("Erro: Erro ao usar facebook."));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("avatar");
    localStorage.removeItem("userSites");
    localStorage.removeItem("alerts");

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/login");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(LOGIN_GOOGLE, googleAuth);
  yield takeEvery(LOGIN_FACEBOOK, facebookAuth);
}

export default authSaga;
