import { Container, Wrapper } from "./loader.styles";

interface LoaderProps {
  spinnerColor?: "white" | "blue";
  loaderSize?: "large" | "small";
}

export const Loader = ({
  spinnerColor = "blue",
  loaderSize = "large",
}: LoaderProps) => {
  return (
    <Wrapper>
      <Container loaderColor={spinnerColor} loaderSize={loaderSize}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Container>
    </Wrapper>
  );
};
