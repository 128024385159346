import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";
import { CSVLink, CSVDownload } from "react-csv";
import { format, parse,subDays } from 'date-fns';

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";
import {
  formatCurrency,
  formatUsagePeriod,
  formatValuesByLanguage,
} from "../../helpers/Helpers";

import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../components/Displays/DisplayLoading/DisplayLoading";

import { AuthUserTypes } from "../../interfaces/AuthUser";
import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { UsersTransactionsSummaryTypes } from "../../interfaces/SiteTransactions";
import { RowTypes } from "../../interfaces/DataTable";
import { usersPdfReport } from "../../utils/reports/users-reports";

import {
  Component,
  Wrapper,
  Header,
  Content,
  DatePickerField,
  ButtonClearDate,
  ButtonExport,
} from "./users.styles";
import { Button } from '../../components/Button/Button';

type PerformancePropsTypes = {
  t: Function;
};

type PickerDateStateTypes = {
  start: string;
  end: string;
};

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

type DataRowTypes = {
  rowAmount: RowTypes;
  rowChargers: RowTypes;
  rowComplement: RowTypes;
  rowDuration: RowTypes;
  rowUserName: RowTypes;
  rowKwh: RowTypes;
  rowTransactions: RowTypes;
  rowName: RowTypes;
};

export const Users = ({ t: translation }: PerformancePropsTypes) => {
  const currentUser: AuthUserTypes = JSON.parse(
    localStorage.getItem("authUser")!,
  );
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );
  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [usersTransactionsData, setUsersTransactionsData] = React.useState<
    UsersTransactionsSummaryTypes[]
  >([]);

  const [pickerDate, setPickerDate] = React.useState<PickerDateStateTypes>({
    start: "",
    end: "",
  });
  const [dataTableSettings, setDataTableSettings] =
    React.useState<DataTableTypes>({} as DataTableTypes);
  
  const datesUpdated = [new Date(state.inicialPickerData.start), new Date(state.inicialPickerData.end)];

  const customSortableKwhRow = (
    elementA: DataRowTypes,
    elementB: DataRowTypes,
  ) => {
    const _A = Number(elementA.rowKwh.toString().replace(",", "."));
    const _B = Number(elementB.rowKwh.toString().replace(",", "."));

    if (_A > _B) return 1;
    if (_A < _B) return -1;

    return 0;
  };
  const titlePdf = translation("users_TitlePdf");

  const customSortName = (
    elementA: DataRowTypes,
    elementB: DataRowTypes,
  ) => {

    const nameElementA = String(elementA.rowName).toUpperCase();
    const nameElementB = String(elementB.rowName).toUpperCase();

    return nameElementA.localeCompare(nameElementB);
  };

  const dataTableColumns: TableColumn<DataRowTypes>[] = [
    {
      name: translation("users_TableHeaderName"),
      selector: (row: any) => row.rowName,
      sortable: true,
      grow: 2,
      sortFunction: customSortName,
    },
    {
      name: translation("users_TableHeaderUserName"),
      selector: (row: any) => row.rowUserName,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("users_TableHeaderComplement"),
      selector: (row: any) => row.rowComplement,
      sortable: true,
      grow: 2,
    },
    {
      name: translation("users_TableHeaderChargers"),
      selector: (row: any) => row.rowChargers,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("users_TableHeaderTransaction"),
      selector: (row: any) => row.rowTransactions,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("users_TableHeaderKwh"),
      selector: (row: any) => row.rowKwh,
      sortable: true,
      sortFunction: customSortableKwhRow,
      width: "160px",
    },
    {
      name: translation("users_TableHeaderDuration"),
      selector: (row: any) => row.rowDuration,
      sortable: true,
      width: "160px",
    },
    {
      name: translation("users_TableHeaderAmount"),
      selector: (row: any) => row.rowAmount,
      sortable: true,
      width: "160px",
    },
  ];
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };

  // Functions

  const buildDataTable = () => {
    const mutableUsersTransactionsData: UsersTransactionsSummaryTypes[] =
      structuredClone(usersTransactionsData);

    const dataTableRows: DataRowTypes[] = mutableUsersTransactionsData.map(
      (user: UsersTransactionsSummaryTypes) => {
        const amount = formatValuesByLanguage(
          user.amount,
          state?.userData?.language,
        );
        const chargers = user.chargers ?? emptyData;
        const complement = user.complement ?? "";
        const duration = formatUsagePeriod(user.duration) ?? emptyData;
        const userName = user.email ?? emptyData;
        const meterTotal =
          formatValuesByLanguage(user.meterTotal, state?.userData?.language) ??
          emptyData;
        const transactions = user.transactions ?? emptyData;
        const name = user.userName ?? emptyData;

        return {
          rowAmount: amount,
          rowChargers: chargers,
          rowComplement: complement,
          rowDuration: duration,
          rowUserName: userName,
          rowKwh: meterTotal,
          rowTransactions: transactions,
          rowName: name,
        };
      },
    );

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };

  const makeIsoTimestamp = (dates: Date[]) => {
    const startDate = dates[0] ? new Date(dates[0]) : "";
    const endDate = dates[1] ? new Date(dates[1]) : "";

    const makeDateTime = (date: Date, period: "start" | "end") => {
      const dateDay = String(date.getDate()).padStart(2, "0");
      const dateYear = date.getFullYear();
      const dateMonth = String(date.getMonth() + 1).padStart(2, "0");
      const isoDate = `${dateYear}-${dateMonth}-${dateDay}${
        period === "start" ? "T00:00:00Z" : "T23:59:59Z"
      }`;

      return isoDate;
    };

    setPickerDate((prev) => ({
      ...prev,
      start:
        typeof startDate !== "string" ? makeDateTime(startDate, "start") : "",
      end: typeof endDate !== "string" ? makeDateTime(endDate, "end") : "",
    }));

  };

  const dateRef = React.useRef<HTMLInputElement>(null);

  const handleClearPeriod = () => {
    setPickerDate((prev) => ({
      ...prev,
      start: "",
      end: "",
    }));
  };

  //API data
  const getUsersTransactionsData = async () => {
    try {
      setLoadingData(true);

      // DEFININDO E FORMATANDO A DATA ATUAL
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);
      const todayFormatted = format(today, "yyyy-MM-dd'T'HH:mm:ss'Z'")

      const lastThirdDays = subDays(now, 31);
      const last30Days = new Date(lastThirdDays.getFullYear(), lastThirdDays.getMonth(), lastThirdDays.getDate());
      const lastThirdDaysFormatted = format(last30Days, "yyyy-MM-dd'T'HH:mm:ss'Z'");

      const { data } = await api.get<UsersTransactionsSummaryTypes[]>(
       
        `/UserTransactionsSummary/${site_id}?from=${pickerDate.start !== ""? pickerDate.start : lastThirdDaysFormatted
        }&to=${pickerDate.end !== "" ? pickerDate.end : todayFormatted}`,
      );

      const mutableApiData: UsersTransactionsSummaryTypes[] =
        structuredClone(data);
      const sortedData: UsersTransactionsSummaryTypes[] = mutableApiData.sort(
        (elementA, elementB) => {
          if (elementA.meterTotal > elementB.meterTotal) return -1;
          if (elementA.meterTotal < elementB.meterTotal) return 1;

          return 0;
        },
      );

      buildDataTable();
      
      setUsersTransactionsData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };

  
  // Life cycle
  React.useMemo(() => {
    
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;
    


    setSiteAccess(pageSite);
    getUsersTransactionsData();
    // getSiteTransactionsData();
  }, [state.sites, site_id, pickerDate]);

  React.useMemo(() => {
    buildDataTable();
  }, [usersTransactionsData, state?.userData?.language]);


  const csvData = dataTableSettings?.tableRows?.map((res) => {
    return {
      rowName: res.rowName,
      rowUserName: res.rowUserName,
      rowComplement: res.rowComplement,
      rowChargers: res.rowChargers,
      rowTransactions: res.rowTransactions,
      rowKwh: res.rowKwh,
      rowDuration: res.rowDuration,
      rowAmount: res.rowAmount,
    };
  });
  const headers = [
    { label: translation("users_TableHeaderName"), key: "rowName" },
    { label: translation("users_TableHeaderUserName"), key: "rowUserName" },
    { label: translation("users_TableHeaderComplement"), key: "rowComplement" },
    { label: translation("users_TableHeaderChargers"), key: "rowChargers" },
    {
      label: translation("users_TableHeaderTransaction"),
      key: "rowTransactions",
    },
    { label: translation("users_TableHeaderKwh"), key: "rowKwh" },
    { label: translation("users_TableHeaderDuration"), key: "rowDuration" },
    { label: translation("users_TableHeaderAmount"), key: "rowAmount" },
  ];

  // Render
  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              {translation("Users")}
            </h1>
          </Header>

          <Content>
            <p>{translation("users_Info")}</p>

            <div className={"data-table"}>
              <DatePickerField
                onChange={(dateRange: Date[]) => {
                  if (dateRange === undefined || !dateRange[1]) return;
                  makeIsoTimestamp(dateRange);
                }}
                options={{
                  wrap: true,
                  mode: "range",
                  defaultDate: datesUpdated,
                  maxDate: new Date(),
                  locale: state?.userData?.language === "pt-BR" ? "pt" : "en",
                  dateFormat:
                    state?.userData?.language === "pt-BR" ? "d-m-Y" : "m-d-Y",
                }}
              >
                <input
                  ref={dateRef}
                  value={dateRef.current?.value}
                  type="text"
                  data-input
                  placeholder={translation("global_DatePickerPlaceholder")}
                />

                <Button
                  buttonColor={"dark"}
                  type={"button"}
                  data-clear
                  disabled={!pickerDate.start}
                  onClick={handleClearPeriod}
                >
                  {translation("global_DatePickerButtonClear")}
                </Button>

                <Button
                  buttonColor={"blue"}
                  type={"button"}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                  ) =>
                    usersPdfReport(
                      titlePdf,
                      dataTableSettings.tableColumns,
                      dataTableSettings.tableRows,
                      translation("users_ExportFilePDF")
                    )
                  }
                >
                  {translation("users_ButtonPdf")}
                </Button>

                <CSVLink
                  data={csvData ? csvData : ""}
                  filename={translation("users_ExportFileCSV")}
                  headers={headers}
                  style={{
                    width: 2,
                  }}
                >
                  <Button buttonColor={"blue"} type={"button"}>
                    {translation("users_ButtonCSV")}
                  </Button>
                </CSVLink>
              </DatePickerField>

              {dataTableSettings.tableRows &&
                <DataTable
                  columns={dataTableSettings.tableColumns}
                  data={dataTableSettings.tableRows}
                  defaultSortFieldId={1}
                  defaultSortAsc={true}
                  progressPending={loadingData}
                  noDataComponent={<DisplayNoData />}
                  progressComponent={<DisplayLoading />}
                  selectableRows={false}
                  selectableRowsVisibleOnly={false}
                  selectableRowsHighlight={false}
                  dense={false}
                  responsive
                  striped
                  highlightOnHover
                  // pointerOnHover
                  fixedHeader
                  persistTableHead
                  pagination
                  paginationDefaultPage={1}
                  paginationComponentOptions={paginationSettings}
                />
              }
            </div>
          </Content>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Users);
