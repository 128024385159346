import * as React from "react";

import { Droplist } from "../../Droplists";

import { DroplistTypes } from "../../../interfaces/Droplist";

import { Component } from "./droplistlabel.styles";

type DroplistLabelPropsTypes = {
  label: string;
  options: DroplistTypes;
  enablePrimaryOption?: boolean;
  primaryOptionName?: string | number;
} & React.SelectHTMLAttributes<HTMLSelectElement>;

export const DroplistLabel = ({
  label = "",
  options = [],
  primaryOptionName = "",
  enablePrimaryOption = false,
  ...rest
}: DroplistLabelPropsTypes) => {
  return (
    <>
      <Component>
        <label htmlFor={label}>
          <p>{label}</p>

          <Droplist
            options={options}
            primaryOptionName={primaryOptionName}
            enablePrimaryOption={enablePrimaryOption}
            {...rest}
          />
        </label>
      </Component>
    </>
  );
};
