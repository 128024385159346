import styled from "styled-components";
import { lighten, rgba } from "polished";

interface LabelProps {
  onInputFocus: boolean;
}

export const Container = styled.section`
  width: 100%;
  height: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? 'calc(100% + 90px)' : '100vh'};
  margin: 0 auto;
  margin-bottom: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '' : '-70px'};
`;

export const Display = styled.div`
  background: white;
  width: 28rem;

  margin: 0 auto;
  padding: 1.5rem;
  margin-top: -1rem;
  z-index: 1;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  h1 {
    font-size: 25px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0px;
    text-align: left;
    color: #3970F3;
  }
  h2{
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.20000000298023224px;
    text-align: left;
    color: #484552;
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Form = styled.form`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Label = styled.label<LabelProps>`
  /* background: lightsalmon; */
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  &:first-child {
    margin-top: 1rem;
  }

  span {
    margin: 0.5rem 0;
  }

  input {
    background: white;
    color: #6c757d !important;
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;

    border: 1px solid
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 0
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};

    transition: all 300ms;
  }
`;

export const AlignRadio = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 0;
`;
export const Aligninput = styled.li`
  display: flex;
  flex-direction: row;
  height: 24px;
  align-items: center;
  min-width: 30px;
`;

export const Fields = styled.div`
  width: 100%;
  margin: 0 auto;
  /* margin-top: 1rem; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  /* margin-top: 2rem; */
  button {
    background-color: #6C6C6C;
    color: white;
    width: 100%;
    padding: 0.6rem;
    height: 42px;
    margin-top: 10px;
    border: 0;
    border-radius: 0.5rem;

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;

    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

    transition: all 300ms;
    &:hover {
      filter: brightness(1.1);
    }
  }

  button[type="submit"] {
    background-color: #3970f3;
    height: 42px;
    &:hover {
      box-shadow: 0 0 8px 0 #3970f3;
    }
  }
`;
