import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;

  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  small {
    color: ${darken(0.24, "#e7e8ea")};
    font-size: 1.16rem;
    line-height: 1.16rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }

  p {
    color: #3970f3;
    font-weight: 600;
  }

  span {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin: 0 auto;

  div {
    display: flex;
    align-items: center;
    gap: 0.88rem;

    p {
      margin: 0;
    }
  }

  svg {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 1rem;

  Button {
    width: 10rem;
  }
`;
