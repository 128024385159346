import * as React from "react";
import * as Translator from "react-i18next";

import { Module } from "../Module/Module";
import { Content, ContentButtons } from "./components/ChargePoint.style";
import { Button } from "../../../../../../components/Button";
import { ChargerTypes } from "../../../../../../interfaces/Chargers";
import { AxiosApi } from "../../../../../../helpers/api";
import { Component } from "../ChargerExclusive/chargerexclusive.styles";
import ModalChargeDetails from "./components/Modal/ModalChargeDetails";
import ModalChargeChange from "./components/Modal/ModalChargeChange";

import { Exception } from "../../../../../../interfaces/Exception";
import ErrorModal from "../../../../../../components/Alerts/ErrorModal";
import SuccessModal from "../../../../../../components/Alerts/SuccessModal";

type ChargerPointPropsType = {
  t: Function;
  refreshChargersList: () => void;
  selectedCharger: ChargerTypes;
};

const ChargerPoint = ({
  t: translation,
  selectedCharger,
  refreshChargersList,
}: ChargerPointPropsType) => {
  const charger: ChargerTypes = selectedCharger;

  const [cpCmData, setCpCmData] = React.useState<any>();

  const setChargerData = async () => {
    AxiosApi.get(`/ChargePoints/${charger.chargePointId}`).then((res) => {
      setCpCmData(res.data.cpCm);
    });
  };

  React.useEffect(() => {
    setChargerData();
  }, [charger]);

  const [modalChargeDetails, setModalChargeDetails] = React.useState({
    modalIsOpen: false,
    chargePointId: "",
    modalOnRequestClose: (): void => {},
    refreshChargersList: (): void => {},
  });

  const handleOpenChargeDetailsModal = () => {
    setModalChargeDetails(() => ({
      modalIsOpen: true,
      chargePointId: charger.chargePointId,
      modalOnRequestClose: handleCloseChargeDetailsModal,
      refreshChargersList: refreshChargersList,
    }));
  };
  const handleCloseChargeDetailsModal = () => {
    setModalChargeDetails((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  const [modalChangeCharge, setModalChangeCharge] = React.useState({
    modalIsOpen: false,
    chargePointId: "",
    modalOnRequestClose: (): void => {},
    refreshChargersList: (): void => {},
  });

  const handleOpenChangeChargeModal = () => {
    setModalChangeCharge(() => ({
      modalIsOpen: true,
      chargePointId: charger.chargePointId,
      modalOnRequestClose: handleCloseChangeChargeModal,
      refreshChargersList: refreshChargersList,
    }));
  };

  const handleCloseChangeChargeModal = () => {
    setModalChangeCharge((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  const closeAllModals = () => {
    setHandleSuccess(false)
    handleCloseChangeChargeModal()
  }

  const pageWidth = document.documentElement.scrollWidth;

  return (
    <>
      <Component>
        <Module>
          {pageWidth <= 1500 &&
            <Content>
              <b>{translation("chargers_ChargerPointChargerId")}: </b>
              {charger.chargePointId ? charger.chargePointId : translation("Uninformed")}
            </Content>
          }
          <Content>
            <b>{translation("chargers_ChargerPointName")}: </b>
            {charger.name ? charger.name : translation("Uninformed")}
          </Content>
          <Content>
            <b>{translation("chargers_ChargerPointComment")}: </b>
            {charger.comment ? charger.comment : translation("Uninformed")}
          </Content>
          <Content>
            <b>{translation("chargers_ChargerPointModel")}: </b>
            {cpCmData?.cmName ? cpCmData?.cmName : translation("Uninformed")}
          </Content>
          <Content>
            <b>{translation("chargers_ChargerPointPower")}: </b>
            {cpCmData?.cmMaxPower
              ? cpCmData?.cmMaxPower + "kW"
              : translation("Uninformed")}
          </Content>

          <ContentButtons lowerView={pageWidth <= 1500 ? true : false}>
            <Button buttonColor={"blue"} onClick={handleOpenChargeDetailsModal}>
              {translation("chargers_ChargerPointButtonMoreDetails")}
            </Button>

            <Button buttonColor={"blue"} onClick={handleOpenChangeChargeModal}>
              {translation("chargers_ChargerPointButtonChange")}
            </Button>
          </ContentButtons>
        </Module>

        {handleError && <ErrorModal {...handleError} />}
        
        {handleSuccess && 
        <SuccessModal
          isOpen={handleSuccess}
          onClose={() => closeAllModals()}
          message={translation("global_SuccessModalResponse")}
        />}

        {modalChargeDetails.modalIsOpen && (
          <ModalChargeDetails
            {...modalChargeDetails}
            cpCmData={cpCmData}
            charger={charger}
          />
        )}

        {modalChangeCharge.modalIsOpen && (
          <ModalChargeChange {...modalChangeCharge} charger={charger} handleSuccess={handleSuccess} setHandleSuccess={setHandleSuccess} handleError={handleError}/>
        )}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerPoint);
