import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import Header from "./components/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { withTranslation } from "react-i18next";

const Confirmation = (props) => {
  return (
    <>
      <MetaTags>
        <title>{props.t("Login | Spott | Manage your recharge points")}</title>
      </MetaTags>
      <Header />
      <div className="account-pages pt-5" style={{ marginTop: "-40px" }}>
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={6} xl={4} xxl={3}>
              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <p className="font-size-29 text-info mb-0 mb-3">
                    {props.t("appConfirmationTitle")}
                  </p>
                  <div>{props.t("appConfirmationMsg")}</div>
                  {/* <Row className="mb-1">
                    <Col sm={12} className="text-end">
                      <button className="btn mt-4 btn-primary w-full waves-effect waves-light">
                        {props.t('NEXT')}
                      </button>
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer loginFooter={true} />
    </>
  );
};

export default withRouter(withTranslation()(Confirmation));
