import React from 'react';
import './CardTableHeader.scss';

export default function CardTableHeader(props) {
    return (
        <div className="tableCardHeader text-center" key={props.id}>
            <span className="tableCardHeader__title">{props.title}</span>
            <br />
            <span className="tableCardHeader__price">{props.price} / port</span>
            <br />
            <p>{props.priceType}</p>
        </div>
    );
}
