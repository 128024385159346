import styled, { css } from "styled-components";
import { lighten, darken, transparentize, rgba } from "polished";

interface HeaderProps {
  titleDivisor?: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

export const Display = styled.div`
  width: 100%;
  height: 16rem;
`;

export const Header = styled.header<HeaderProps>`
  width: 100%;
  height: 3rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  ${({ titleDivisor }) =>
    titleDivisor &&
    css`
      border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    `};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  p {
    color: #3970f3;
    font-size: 1rem;
    font-weight: 600;
    text-transform: none;
    line-height: 2rem;
    text-align: left;
  }

  svg {
    color: rgba(0, 0, 0, 0.28);
    width: 2rem;
    height: 2rem;

    position: absolute;
    top: 0;
    right: 0;

    cursor: pointer;

    transition: all 300ms;
    &:hover {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const CloseButton = styled.button`
  background-color: #3970f3;
  color: white;
  width: 12rem;
  margin: 0.6rem auto;
  padding: 0.8rem;
  display: block;

  border: 0;
  border-radius: 0.4rem;

  transition: 300ms all;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  text-transform: uppercase;

  &:hover {
    filter: brightness(1.1);
    box-shadow: 0 0 8px 0 ${lighten(0.18, "#3970f3")};
  }
`;
