import React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button, Spinner } from 'reactstrap';
import { BodyDiv, ButtonDiv, Titlediv } from './styles';

export const ModalComponent = ({ config }) => {

    return (
        <Modal isOpen={config.open} centered fullscreen="sm">
            <ModalBody>
              <Titlediv>
                <h1>
                  {config.title}
                </h1>
                <a onClick={config.bt2Function}>
                 X
                </a>
              </Titlediv>
              <BodyDiv>
                {config.body && config.emailField &&
                <p>
                {config.body.msg1+" "}
                  <a>{config.emailField}</a>
                {config.body.msg2}
                </p>
                }
              </BodyDiv>
              <ButtonDiv>
                <Button type="submit" onClick={config.bt1Function}>
                  {
                  config.loading ?
                  <Spinner className="ms-2" color="primary" />
                  :
                  <a>{config.bt1}</a>
                  }
                </Button>
                <Button type="back" onClick={config.bt2Function}>
                  <a>{config.bt2}</a>
                </Button>
              </ButtonDiv>
            </ModalBody>
        </Modal >
    );
};
