import { useState } from "react";
import {
  Container,
  Content,
  Display,
  FloatingFooter,
  Form,
  Header,
  Label,
  SubmitButton,
  TextError
} from "./styles"
import { Loader } from "../../../components/Loader";
import { withTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";
import Footer from "../../../components/VerticalLayout/Footer";
const logoImg = require("../../../assets/images/logo.png");
import { AxiosApi } from "../../../helpers/api";

interface Props {
  t: Function;
}

const DeleteAccountApp = ({ t: translator }: Props) => {

  const url = new URL(window.location.href);
  const hashParam = url.searchParams.get("culture");

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<null | string>(null)
  const [emailNotFound, setEmailNotFound] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleOnChangeInputEmail = (e: any) => {
    setEmail(e.target.value)
    setEmailError(null)
    setEmailNotFound(false)
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setLoading(true);

    if (email === '') {
      setEmailError('Por favor, preencha o campo de e-mail.');
      setLoading(false);
      return;
    }

    try {
      const response = await AxiosApi.post(`/DataExclusion/${email}`);
      if (response.status === 200) {
        setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 2000)
      }
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        setEmailNotFound(true);
      } else {
        console.error('Erro inesperado:', err);
      }
      setLoading(false);
    }
  };

  return (
    <Container>
      <Header>
        <img width={'200px'} style={{ marginBottom: '50px', marginTop: '-20px' }} src={logoImg} alt="logo" />
      </Header>

      <Content>
        <Display>
          {!success ?
            (
              <>
                <h1>{translator('Delete account')}</h1>
                <Form onSubmit={handleSubmit} method="GET">
                  <Label
                    htmlFor="email"
                  >
                    <input
                      id="email"
                      required
                      placeholder={translator("Type your e-mail")}
                      value={email}
                      onChange={handleOnChangeInputEmail}
                    />
                  </Label>
                  <SubmitButton type="submit" onClick={(e) => handleSubmit(e as any)} disabled={loading}>
                    {loading ?
                      <Loader spinnerColor="white" loaderSize="small" /> :
                      <span>{translator('Send')}</span>}
                  </SubmitButton>
                </Form>
                {emailError && <TextError>{emailError}</TextError>}
                {emailNotFound && <TextError>O email informado não existe.</TextError>}
              </>
            ) : (
              <>
                <h1>Um link de confirmação foi enviado para seu email</h1>
                <FaCheckCircle color="#3970f3" size={100} style={{ marginTop: '1rem' }} />
              </>
            )}
        </Display>
        <FloatingFooter>
          <Footer
            loginFooter={true}
            darkText={true}
            tintText={true}
            setLanguageByExternalResource={hashParam || "pt-BR"}
          />
        </FloatingFooter>
      </Content>
    </Container>
  )
}

export default withTranslation()(DeleteAccountApp);
