import * as React from "react";
import * as Translator from "react-i18next";

import { AxiosApi as api } from "../../../../../../helpers/api";

import { Module } from "../Module/Module";
import { Button } from "../../../../../../components/Button";
import { ModalAction } from "../../../../../../components/Modal/ModalAction/ModalAction";
import ModalLogInformation from "./components/ModalLogInformation/ModalLogInformation";

import ErrorModal from "../../../../../../components/Alerts/ErrorModal";

import { ModaActionlTypes } from "../../../../../../interfaces/Modal";
import { ChargerPointTypes } from "../../../../../../interfaces/Chargers";
import { Exception } from "../../../../../../interfaces/Exception";

import { Component, Wrapper } from "./chargercommands.styles";
import SucessModal from "../../../../../../components/Alerts/SuccessModal";

type ChargerCommandsPropsTypes = {
  t: Function;
  selectedCharger: ChargerPointTypes;
  refreshChargersList: () => void;
};

export const ChargerCommands = ({
  t: translation,
  selectedCharger,
  refreshChargersList,
}: ChargerCommandsPropsTypes) => {
  const charger: ChargerPointTypes = selectedCharger;

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalLoadingRequisition: false,
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const [openModalLogInformation, setOpenModalLogInformation] =
    React.useState<boolean>(false);

  // Functions
  const handleOpenModalLogInformation = () => {
    setOpenModalLogInformation(true);
  };
  const handleCloseModalLogInformation = () => {
    setOpenModalLogInformation(false);
  };

  const handleOpenModalAction = (settings: ModaActionlTypes) => {
    setModalAction({ ...settings });
  };
  const handleCloseModalAction = () => {
    setModalAction((prev) => ({
      ...prev,
      modalIsOpen: false,
      modalLoadingRequisition: false,
    }));
  };
  const loadingRequisition = (transfering: boolean) => {
    setModalAction((prev) => ({
      ...prev,
      modalLoadingRequisition: transfering,
    }));
  };

  const dispatchRestartCharger = async () => {
    try {
      loadingRequisition(true);

      const { data } = await api.post(`/ChargerReset/${charger.chargePointId}`);

      if (data.status === "Accepted") {
        setHandleSuccess(true);
        refreshChargersList();

        setModalAction((prev) => ({
          ...prev,
          modalIsOpen: false,
        }));

      } else {
        setHandleError({
          isOpen: true,
          status: 400,
          message: { error: "REQUEST_FAILURE" },
          onClose: () => setHandleError(undefined),
        });

      }
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: error?.response?.data,
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      // handleCloseModalAction();
      loadingRequisition(false);
    }
  };
  const dispatchUpdateChargerFirmware = async () => {
    try {
      loadingRequisition(true);

      const { data } = await api.get(
        `/UpdateFirmwareRequest/${charger.chargePointId}`,
      );

      if (data.status === "Accepted") {
        setHandleSuccess(true);
        refreshChargersList();
      } else {
        setHandleError({
          isOpen: true,
          status: 400,
          message: { error: "REQUEST_FAILURE" },
          onClose: () => setHandleError(undefined),
        });
      }
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: error?.response?.data,
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      // handleCloseModalAction();
      loadingRequisition(false);
    }
  };

  const pageWidth = document.documentElement.scrollWidth;

  return (
    <>
      <Component>
        <Module>
          <Wrapper>
            <div style={{display: pageWidth >= 1500 ? 'flex' : "grid", gap: pageWidth >= 1500 ? '' : "5px"}}>
              <Button
                buttonColor={"blue"}
                onClick={() => {
                  const settings: ModaActionlTypes = {
                    modalIsOpen: true,
                    modalTitle: translation(
                      "charger_ChargerCommandsModalActionTitleRestart",
                    ),
                    modalInfo: translation(
                      "charger_ChargerCommandsModalActionTextRestart",
                    ),
                    modalButtonConfirmText: translation(
                      "charger_ChargerCommandsModalActionButtonRestart",
                    ),
                    modalButtonCancelText: translation("global_Cancel"),
                    modalOnConfirmAction: dispatchRestartCharger,
                    modalOnRequestClose: handleCloseModalAction,
                    modalLoadingRequisition: false,
                  };

                  handleOpenModalAction(settings);
                }}
              >
                {translation("charger_ChargerCommandsButtonRestart")}
              </Button>

              {/* <Button
                buttonColor={"blue"}
                onClick={() => {
                  const settings: ModaActionlTypes = {
                    modalIsOpen: true,
                    modalTitle: translation(
                      "charger_ChargerCommandsModalActionTitleFirmware",
                    ),
                    modalInfo: translation(
                      "charger_ChargerCommandsModalActionTextFirmware",
                    ),
                    modalButtonConfirmText: translation(
                      "charger_ChargerCommandsModalActionButtonFirmware",
                    ),
                    modalButtonCancelText: translation("global_Cancel"),
                    modalOnConfirmAction: dispatchUpdateChargerFirmware,
                    modalOnRequestClose: handleCloseModalAction,
                    modalLoadingRequisition: false,
                  };

                  handleOpenModalAction(settings);
                }}
              >
                {translation("charger_ChargerCommandsButtonUpdateFirmware")}
              </Button> */}
              <Button
              buttonColor={"dark"}
              onClick={handleOpenModalLogInformation}
            >
              {translation("charger_ChargerCommandsButtonLogInfo")}
            </Button>
            </div>
          </Wrapper>
        </Module>

        {/* Modals */}
        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}

        {openModalLogInformation && (
          <ModalLogInformation
            modalIsOpen={openModalLogInformation}
            modalOnRequestClose={handleCloseModalLogInformation}
          />
        )}

        {handleError && <ErrorModal {...handleError} />}

        {handleSuccess && (
          <SucessModal
            isOpen={handleSuccess}
            onClose={() => setHandleSuccess(false)}
            message={translation("charger_ChargerCommandsModalRestartSuccess")}
          />
        )}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerCommands);
