import { useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, Alert } from "reactstrap";
// import { AvForm, AvField } from "availity-reactstrap-validation";
import { ModalComponent } from "../../components/ForgotPassModal";
import { AxiosApi } from "../../helpers/api";
import Header from "./components/Header/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { connect } from "react-redux";
import { withRouter, useHistory, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Container, Display, Form, Label, Fields } from "./forgetpass.styles";
import UsagePolicy from "./components/UsagePolicy/UsagePolicy";

const ForgetPass = (props) => {
  const [onFocusEmailField, setOnFocusEmailField] = useState(false);
  const [confirmationConfig, setConfirmationConfig] = useState({ open: false });
  const [erro, setErro] = useState({ erro: false, message: "" });
  const history = useHistory();

  const [emailField, setEmailField] = useState("");

  const submitForm = async (event) => {
    event.preventDefault();
    setConfirmationConfig({
      open: true,
      value: "",
      title: props.t("Confirmation sent"),
      body: {
        msg1: props.t("ForgotPassMessage"),
        msg2: props.t("ForgotPassMessage2"),
      },
      bt1: props.t("Enviar Novamente"),
      bt2: props.t("Back to Login"),
      bt1Function: submitForm,
      bt2Function: redirectToLogin,
      emailField: emailField,
      loading: true,
    });


    await AxiosApi.get(`/ResetPassword/${emailField}`, {})
      .then((response) => {
        setErro({ erro: false, message: "" });
        passCreatedSuccess();
      })
      .catch((error) => {

        const msg = JSON.parse(error.request.response).message;
        if (error.response.status === 500) {
          setErro({ erro: true, message: "Internal server error" });
        } else {
          setErro({ erro: true, message: msg });
        }

      });
      
    passCreatedSuccess();
  };

  const passCreatedSuccess = () => {
    setConfirmationConfig({
      open: true,
      value: "",
      title: props.t("Confirmation sent"),
      body: {
        msg1: props.t("ForgotPassMessage"),
        msg2: props.t("ForgotPassMessage2"),
      },
      bt1: props.t("Enviar Novamente"),
      bt2: props.t("Back to Login"),
      bt1Function: submitForm,
      bt2Function: redirectToLogin,
      emailField: emailField,
      loading: false,
    });
  };

  const redirectToLogin = () => history.push("/login");

  return (
    <>
      <MetaTags>
        <title>{props.t("Login | Spott | Manage your recharge points")}</title>
      </MetaTags>

      <Container className="custom-bg">
        <Header />

        <Display>
          <p>
            {props.t("Esqueci minha senha")} <br />
            <a>{props.t("To recover your password, enter your email.")}</a>
          </p>

          {erro.erro && (
            <Row className="mt-3">
              <Col sm={12} className="text-start">
                <Alert
                  isOpen={erro.message !== null}
                  color="danger"
                  className="mb-0"
                >
                  {erro.message}
                </Alert>
              </Col>
            </Row>
          )}

          <Form onSubmit={submitForm}>
            <Label htmlFor="email-field" onInputFocus={onFocusEmailField}>
              <span>E-mail</span>
              <input
                type="email"
                value={emailField}
                placeholder={props.t("Enter email")}
                required
                onChange={(event) => setEmailField(event.target.value)}
                onFocus={() => setOnFocusEmailField(true)}
                onBlur={() => setOnFocusEmailField(false)}
              />
            </Label>

            {/* <UsagePolicy /> */}

            <Fields>
              <button type="submit">
                <a>{props.t("Send")}</a>
              </button>

              <Link to="/login" style={{ width: "100%" }}>
                <button type="button">
                  <a>{props.t("Back to Login")}</a>
                </button>
              </Link>
            </Fields>
          </Form>
        </Display>

        <ModalComponent config={confirmationConfig} />
      </Container>

      <Footer loginFooter={true} />
    </>
  );
};

export default withRouter(connect(null, {})(withTranslation()(ForgetPass)));
