import styled from "styled-components";
import { lighten, transparentize } from "polished";

type ClickableButtonPropsType = {
  buttonDefaultColor?: "grey" | "blue";
  buttonWidth?: number;
};

const ClickableButtonColors = {
  grey: "#6C6C6C",
  blue: "#3970F3",
};

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  div {
    width: 100%;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
  }
`;

export const BaseButton = styled.button<ClickableButtonPropsType>`
  background-color: ${({ buttonDefaultColor }) =>
    buttonDefaultColor && ClickableButtonColors[buttonDefaultColor]};
  color: white;
  width: ${({ buttonWidth }) => buttonWidth || 8}rem;
  padding: 0.6rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.125);

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
`;

export const CustomButton = styled(BaseButton)``;
