import "./SpottTips.scss";
import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const SpottTips = ({ data, t }) => {
  return (
    <div className="pb-4 h-100">
      <Card className="spott-tips">
        <CardBody>
          <h1 className="tips-title">{data.title}</h1>

          <p className="tips-subtitle">{t("Did you know?")}</p>
          <p className="text-muted mb-3 pb-4 font-size-14">
            {t("Recharge an eletric car is a quite simple process")}
            <span>&#58; </span>
            {t(
              "Just connect the car using a specific cable to a charger connected to the power grid and that's it.",
            )}
          </p>
          {/* <div className="float-end mt-2">
                        <Link to="#" className="text-primary">
                            <i className="mdi mdi-arrow-right h5"></i>
                        </Link>
                    </div> */}
          {/* <p className="mb-1 spott-team"> {t("Spott Team")}</p> */}
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(SpottTips);
