import styled from "styled-components";

export const Container = styled.div`
  background-color: #3970f3;
  width: 100%;
  padding: 2rem 0;

  /* position: absolute;
  top: 0; */
  /* z-index: 1; */

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  color: white;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
  text-decoration: none;

  @media (max-width: 1440px) {
    padding: 0;

    p {
      margin-top: 0;
      margin-right: 0;
      font-size: 1.25rem;
      margin-top: -0.8rem;
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  p {
    margin-top: 1rem;
    margin-right: 1rem;
  }

  span {
    margin-right: 17rem;
  }

  @media (max-width: 1440px) {
    margin-left: 0;
    flex-direction: column;

    p {
      margin-top: 0;
      margin-right: 0;
      margin-bottom: -0.25rem;
      font-size: 1.25rem;
    }

    span {
      margin-right: 0;

      img {
        width: 50%;
      }
    }
  }
`;
