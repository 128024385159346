import { FaCircle } from "react-icons/fa";
import { withTranslation } from "react-i18next";

import { Loader } from "../../Loader";

import React from "react";

import { Container, AlertItem } from "./alertsdashboard.styles";

interface AlertsDashboardProps {
  t: Function;
  title?: string;
  data?: any;
  isLoadingData?: boolean;
}

export const AlertsDashboard = ({
  t: translator,
  data,
  isLoadingData,
}: AlertsDashboardProps) => {
  const [isAlertRead, setIsAlertRead] = React.useState<boolean>(false);

  return (
    <>
      <Container>
        {isLoadingData && <Loader loaderSize={"large"} spinnerColor={"blue"} />}

        {!isLoadingData && data.length === 0 && (
          <div>{translator("No data to display")}</div>
        )}

        {!isLoadingData && data.length !== 0 && (
          <>
            <AlertItem
              isAlertRead={isAlertRead}
              onClick={() => setIsAlertRead(true)}
            >
              <div>
                <FaCircle onClick={() => setIsAlertRead(true)} />
                <span>10/22 &#8226; 14:30H</span>
              </div>
              <p>
                <span>Site 01</span>: Money credited in your account. Thanks for
                your purchase.
              </p>
            </AlertItem>
          </>
        )}
      </Container>
    </>
  );
};

export default withTranslation()(AlertsDashboard);
