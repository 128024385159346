import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationEN from "./locales/en/translation.json";
import translationPT from "./locales/pt/translation.json";

// the translations
const resources = {
  en: { translation: translationEN },
  pt: { translation: translationPT },
};

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) localStorage.setItem("I18N_LANGUAGE", "pt");

export const getLanguage = () => {
  if (language === "en") return "en-US";
  if (language === "pt") return "pt-BR";
  return null;
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("I18N_LANGUAGE") || "pt",
    fallbackLng: "pt", // use en if detected lng is not available
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
