const BanksList = [
    {
        "Codigo": 246,
        "Banco": "Banco ABC Brasil S.A.",
    },
    {
        "Codigo": 748,
        "Banco": "Banco Cooperativo Sicredi S.A.",
    },
    {
        "Codigo": 117,
        "Banco": "Advanced Cc Ltda",
    },
    {
        "Codigo": 121,
        "Banco": "Banco Agibank S.A.",
    },
    {
        "Codigo": 172,
        "Banco": "Albatross Ccv S.A",
    },
    {
        "Codigo": 188,
        "Banco": "Ativa Investimentos S.A",
    },
    {
        "Codigo": 280,
        "Banco": "Avista S.A. Crédito Financiamento e Investimento",
    },
    {
        "Codigo": 80,
        "Banco": "B&T Cc Ltda",
    },
    {
        "Codigo": 654,
        "Banco": "Banco A.J.Renner S.A.",
    },
    {
        "Codigo": 246,
        "Banco": "Banco ABC Brasil S.A.",
    },
    {
        "Codigo": 75,
        "Banco": "Banco ABN AMRO S.A",
    },
    {
        "Codigo": 121,
        "Banco": "Banco Agibank S.A.",
    },
    {
        "Codigo": 25,
        "Banco": "Banco Alfa S.A.",
    },
    {
        "Codigo": 641,
        "Banco": "Banco Alvorada S.A.",
    },
    {
        "Codigo": 65,
        "Banco": "Banco Andbank (Brasil) S.A.",
    },
    {
        "Codigo": 213,
        "Banco": "Banco Arbi S.A.",
    },
    {
        "Codigo": 96,
        "Banco": "Banco B3 S.A.",
    },
    {
        "Codigo": 24,
        "Banco": "Banco BANDEPE S.A.",
    },
    {
        "Codigo": 318,
        "Banco": "Banco BMG S.A.",
    },
    {
        "Codigo": 752,
        "Banco": "Banco BNP Paribas Brasil S.A.",
    },
    {
        "Codigo": 107,
        "Banco": "Banco BOCOM BBM S.A.",
    },
    {
        "Codigo": 63,
        "Banco": "Banco Bradescard S.A.",
    },
    {
        "Codigo": 36,
        "Banco": "Banco Bradesco BBI S.A.",
    },
    {
        "Codigo": 122,
        "Banco": "Banco Bradesco BERJ S.A.",
    },
    {
        "Codigo": 204,
        "Banco": "Banco Bradesco Cartões S.A.",
    },
    {
        "Codigo": 394,
        "Banco": "Banco Bradesco Financiamentos S.A.",
    },
    {
        "Codigo": 237,
        "Banco": "Banco Bradesco S.A.",
    },
    {
        "Codigo": 218,
        "Banco": "Banco BS2 S.A.",
    },
    {
        "Codigo": 208,
        "Banco": "Banco BTG Pactual S.A.",
    },
    {
        "Codigo": 336,
        "Banco": "Banco C6 S.A – C6 Bank",
    },
    {
        "Codigo": 473,
        "Banco": "Banco Caixa Geral – Brasil S.A.",
    },
    {
        "Codigo": 412,
        "Banco": "Banco Capital S.A.",
    },
    {
        "Codigo": 40,
        "Banco": "Banco Cargill S.A.",
    },
    {
        "Codigo": 368,
        "Banco": "Banco Carrefour",
    },
    {
        "Codigo": 266,
        "Banco": "Banco Cédula S.A.",
    },
    {
        "Codigo": 739,
        "Banco": "Banco Cetelem S.A.",
    },
    {
        "Codigo": 233,
        "Banco": "Banco Cifra S.A.",
    },
    {
        "Codigo": 745,
        "Banco": "Banco Citibank S.A.",
    },
    {
        "Codigo": 241,
        "Banco": "Banco Clássico S.A.",
    },
    {
        "Codigo": 756,
        "Banco": "Banco Cooperativo do Brasil S.A. – BANCOOB",
    },
    {
        "Codigo": 748,
        "Banco": "Banco Cooperativo Sicredi S.A.",
    },
    {
        "Codigo": 222,
        "Banco": "Banco Credit Agricole Brasil S.A.",
    },
    {
        "Codigo": 505,
        "Banco": "Banco Credit Suisse (Brasil) S.A.",
    },
    {
        "Codigo": 69,
        "Banco": "Banco Crefisa S.A.",
    },
    {
        "Codigo": 3,
        "Banco": "Banco da Amazônia S.A.",
    },
    {
        "Codigo": 83,
        "Banco": "Banco da China Brasil S.A.",
    },
    {
        "Codigo": 707,
        "Banco": "Banco Daycoval S.A.",
    },
    {
        "Codigo": 51,
        "Banco": "Banco de Desenvolvimento do Espírito Santo S.A.",
    },
    {
        "Codigo": 300,
        "Banco": "Banco de La Nacion Argentina",
    },
    {
        "Codigo": 495,
        "Banco": "Banco de La Provincia de Buenos Aires",
    },
    {
        "Codigo": 494,
        "Banco": "Banco de La Republica Oriental del Uruguay",
    },
    {
        "Codigo": 335,
        "Banco": "Banco Digio S.A",
    },
    {
        "Codigo": 1,
        "Banco": "Banco do Brasil S.A.",
    },
    {
        "Codigo": 47,
        "Banco": "Banco do Estado de Sergipe S.A.",
    },
    {
        "Codigo": 37,
        "Banco": "Banco do Estado do Pará S.A.",
    },
    {
        "Codigo": 41,
        "Banco": "Banco do Estado do Rio Grande do Sul S.A.",
    },
    {
        "Codigo": 4,
        "Banco": "Banco do Nordeste do Brasil S.A.",
    },
    {
        "Codigo": 196,
        "Banco": "Banco Fair Corretora de Câmbio S.A",
    },
    {
        "Codigo": 265,
        "Banco": "Banco Fator S.A.",
    },
    {
        "Codigo": 224,
        "Banco": "Banco Fibra S.A.",
    },
    {
        "Codigo": 626,
        "Banco": "Banco Ficsa S.A.",
    },
    {
        "Codigo": 94,
        "Banco": "Banco Finaxis S.A.",
    },
    {
        "Codigo": 612,
        "Banco": "Banco Guanabara S.A.",
    },
    {
        "Codigo": 12,
        "Banco": "Banco Inbursa S.A.",
    },
    {
        "Codigo": 604,
        "Banco": "Banco Industrial do Brasil S.A.",
    },
    {
        "Codigo": 653,
        "Banco": "Banco Indusval S.A.",
    },
    {
        "Codigo": 77,
        "Banco": "Banco Inter S.A.",
    },
    {
        "Codigo": 249,
        "Banco": "Banco Investcred Unibanco S.A.",
    },
    {
        "Codigo": 184,
        "Banco": "Banco Itaú BBA S.A.",
    },
    {
        "Codigo": 29,
        "Banco": "Banco Itaú Consignado S.A.",
    },
    {
        "Codigo": 479,
        "Banco": "Banco ItauBank S.A",
    },
    {
        "Codigo": 376,
        "Banco": "Banco J. P. Morgan S.A.",
    },
    {
        "Codigo": 74,
        "Banco": "Banco J. Safra S.A.",
    },
    {
        "Codigo": 217,
        "Banco": "Banco John Deere S.A.",
    },
    {
        "Codigo": 76,
        "Banco": "Banco KDB S.A.",
    },
    {
        "Codigo": 757,
        "Banco": "Banco KEB HANA do Brasil S.A.",
    },
    {
        "Codigo": 600,
        "Banco": "Banco Luso Brasileiro S.A.",
    },
    {
        "Codigo": 243,
        "Banco": "Banco Máxima S.A.",
    },
    {
        "Codigo": 720,
        "Banco": "Banco Maxinvest S.A.",
    },
    {
        "Codigo": 389,
        "Banco": "Banco Mercantil de Investimentos S.A.",
    },
    {
        "Codigo": 389,
        "Banco": "Banco Mercantil do Brasil S.A.",
    },
    {
        "Codigo": 370,
        "Banco": "Banco Mizuho do Brasil S.A.",
    },
    {
        "Codigo": 746,
        "Banco": "Banco Modal S.A.",
    },
    {
        "Codigo": 66,
        "Banco": "Banco Morgan Stanley S.A.",
    },
    {
        "Codigo": 456,
        "Banco": "Banco MUFG Brasil S.A.",
    },
    {
        "Codigo": 7,
        "Banco": "Banco Nacional de Desenvolvimento Econômico e Social – BNDES",
    },
    {
        "Codigo": 169,
        "Banco": "Banco Olé Bonsucesso Consignado S.A.",
    },
    {
        "Codigo": 111,
        "Banco": "Banco Oliveira Trust Dtvm S.A",
    },
    {
        "Codigo": 79,
        "Banco": "Banco Original do Agronegócio S.A.",
    },
    {
        "Codigo": 212,
        "Banco": "Banco Original S.A.",
    },
    {
        "Codigo": 712,
        "Banco": "Banco Ourinvest S.A.",
    },
    {
        "Codigo": 623,
        "Banco": "Banco PAN S.A.",
    },
    {
        "Codigo": 611,
        "Banco": "Banco Paulista S.A.",
    },
    {
        "Codigo": 643,
        "Banco": "Banco Pine S.A.",
    },
    {
        "Codigo": 658,
        "Banco": "Banco Porto Real de Investimentos S.A.",
    },
    {
        "Codigo": 747,
        "Banco": "Banco Rabobank International Brasil S.A.",
    },
    {
        "Codigo": 633,
        "Banco": "Banco Rendimento S.A.",
    },
    {
        "Codigo": 741,
        "Banco": "Banco Ribeirão Preto S.A.",
    },
    {
        "Codigo": 120,
        "Banco": "Banco Rodobens S.A.",
    },
    {
        "Codigo": 422,
        "Banco": "Banco Safra S.A.",
    },
    {
        "Codigo": 33,
        "Banco": "Banco Santander (Brasil) S.A.",
    },
    {
        "Codigo": 743,
        "Banco": "Banco Semear S.A.",
    },
    {
        "Codigo": 754,
        "Banco": "Banco Sistema S.A.",
    },
    {
        "Codigo": 630,
        "Banco": "Banco Smartbank S.A.",
    },
    {
        "Codigo": 366,
        "Banco": "Banco Société Générale Brasil S.A.",
    },
    {
        "Codigo": 637,
        "Banco": "Banco Sofisa S.A.",
    },
    {
        "Codigo": 464,
        "Banco": "Banco Sumitomo Mitsui Brasileiro S.A.",
    },
    {
        "Codigo": 82,
        "Banco": "Banco Topázio S.A.",
    },
    {
        "Codigo": 634,
        "Banco": "Banco Triângulo S.A.",
    },
    {
        "Codigo": 18,
        "Banco": "Banco Tricury S.A.",
    },
    {
        "Codigo": 655,
        "Banco": "Banco Votorantim S.A.",
    },
    {
        "Codigo": 610,
        "Banco": "Banco VR S.A.",
    },
    {
        "Codigo": 119,
        "Banco": "Banco Western Union do Brasil S.A.",
    },
    {
        "Codigo": 124,
        "Banco": "Banco Woori Bank do Brasil S.A.",
    },
    {
        "Codigo": 348,
        "Banco": "Banco Xp S/A",
    },
    {
        "Codigo": 81,
        "Banco": "BancoSeguro S.A.",
    },
    {
        "Codigo": 21,
        "Banco": "BANESTES S.A. Banco do Estado do Espírito Santo",
    },
    {
        "Codigo": 755,
        "Banco": "Bank of America Merrill Lynch Banco Múltiplo S.A.",
    },
    {
        "Codigo": 268,
        "Banco": "Barigui Companhia Hipotecária",
    },
    {
        "Codigo": 250,
        "Banco": "BCV – Banco de Crédito e Varejo S.A.",
    },
    {
        "Codigo": 144,
        "Banco": "BEXS Banco de Câmbio S.A.",
    },
    {
        "Codigo": 253,
        "Banco": "Bexs Corretora de Câmbio S/A",
    },
    {
        "Codigo": 134,
        "Banco": "Bgc Liquidez Dtvm Ltda",
    },
    {
        "Codigo": 17,
        "Banco": "BNY Mellon Banco S.A.",
    },
    {
        "Codigo": 301,
        "Banco": "Bpp Instituição De Pagamentos S.A",
    },
    {
        "Codigo": 126,
        "Banco": "BR Partners Banco de Investimento S.A.",
    },
    {
        "Codigo": 70,
        "Banco": "BRB – Banco de Brasília S.A.",
    },
    {
        "Codigo": 92,
        "Banco": "Brickell S.A. Crédito, Financiamento e Investimento",
    },
    {
        "Codigo": 173,
        "Banco": "BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.",
    },
    {
        "Codigo": 142,
        "Banco": "Broker Brasil Cc Ltda",
    },
    {
        "Codigo": 292,
        "Banco": "BS2 Distribuidora de Títulos e Valores Mobiliários S.A.",
    },
    {
        "Codigo": 11,
        "Banco": "C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)",
    },
    {
        "Codigo": 104,
        "Banco": "Caixa Econômica Federal",
    },
    {
        "Codigo": 288,
        "Banco": "Carol Distribuidora de Títulos e Valor Mobiliários Ltda",
    },
    {
        "Codigo": 130,
        "Banco": "Caruana Scfi",
    },
    {
        "Codigo": 159,
        "Banco": "Casa Credito S.A",
    },
    {
        "Codigo": 16,
        "Banco": "Ccm Desp Trâns Sc E Rs",
    },
    {
        "Codigo": 89,
        "Banco": "Ccr Reg Mogiana",
    },
    {
        "Codigo": 114,
        "Banco": "Central Cooperativa De Crédito No Estado Do Espírito Santo",
    },
    {
        "Codigo": "114-7",
        "Banco": "Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.",
    },
    {
        "Codigo": 320,
        "Banco": "China Construction Bank (Brasil) Banco Múltiplo S.A.",
    },
    {
        "Codigo": 477,
        "Banco": "Citibank N.A.",
    },
    {
        "Codigo": 180,
        "Banco": "Cm Capital Markets Cctvm Ltda",
    },
    {
        "Codigo": 127,
        "Banco": "Codepe Cvc S.A",
    },
    {
        "Codigo": 163,
        "Banco": "Commerzbank Brasil S.A. – Banco Múltiplo",
    },
    {
        "Codigo": 60,
        "Banco": "Confidence Cc S.A",
    },
    {
        "Codigo": 85,
        "Banco": "Coop Central Ailos",
    },
    {
        "Codigo": 97,
        "Banco": "Cooperativa Central de Crédito Noroeste Brasileiro Ltda.",
    },
    {
        "Codigo": "085-x",
        "Banco": "Cooperativa Central de Crédito Urbano-CECRED",
    },
    {
        "Codigo": "090-2",
        "Banco": "Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS",
    },
    {
        "Codigo": "087-6",
        "Banco": "Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná",
    },
    {
        "Codigo": "089-2",
        "Banco": "Cooperativa de Crédito Rural da Região da Mogiana",
    },
    {
        "Codigo": 286,
        "Banco": "Cooperativa de Crédito Rural De Ouro",
    },
    {
        "Codigo": 279,
        "Banco": "Cooperativa de Crédito Rural de Primavera Do Leste",
    },
    {
        "Codigo": 273,
        "Banco": "Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel",
    },
    {
        "Codigo": 98,
        "Banco": "Credialiança Ccr",
    },
    {
        "Codigo": "098-1",
        "Banco": "CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL",
    },
    {
        "Codigo": 10,
        "Banco": "Credicoamo",
    },
    {
        "Codigo": 133,
        "Banco": "Cresol Confederação",
    },
    {
        "Codigo": 182,
        "Banco": "Dacasa Financeira S/A",
    },
    {
        "Codigo": 707,
        "Banco": "Banco Daycoval S.A.",
    },
    {
        "Codigo": 487,
        "Banco": "Deutsche Bank S.A. – Banco Alemão",
    },
    {
        "Codigo": 140,
        "Banco": "Easynvest – Título Cv S.A",
    },
    {
        "Codigo": 149,
        "Banco": "Facta S.A. Cfi",
    },
    {
        "Codigo": 285,
        "Banco": "Frente Corretora de Câmbio Ltda.",
    },
    {
        "Codigo": 278,
        "Banco": "Genial Investimentos Corretora de Valores Mobiliários S.A.",
    },
    {
        "Codigo": 138,
        "Banco": "Get Money Cc Ltda",
    },
    {
        "Codigo": 64,
        "Banco": "Goldman Sachs do Brasil Banco Múltiplo S.A.",
    },
    {
        "Codigo": 177,
        "Banco": "Guide Investimentos S.A. Corretora de Valores",
    },
    {
        "Codigo": 146,
        "Banco": "Guitta Corretora de Câmbio Ltda",
    },
    {
        "Codigo": 78,
        "Banco": "Haitong Banco de Investimento do Brasil S.A.",
    },
    {
        "Codigo": 62,
        "Banco": "Hipercard Banco Múltiplo S.A.",
    },
    {
        "Codigo": 189,
        "Banco": "HS Financeira S/A Crédito , Financiamento e Investimentos",
    },
    {
        "Codigo": 269,
        "Banco": "HSBC Brasil S.A. – Banco de Investimento",
    },
    {
        "Codigo": 271,
        "Banco": "IB Corretora de Câmbio , Títulos e Valores Mobiliários S.A.",
    },
    {
        "Codigo": 157,
        "Banco": "Icap Do Brasil Ctvm Ltda",
    },
    {
        "Codigo": 132,
        "Banco": "ICBC do Brasil Banco Múltiplo S.A.",
    },
    {
        "Codigo": 492,
        "Banco": "ING Bank N.V.",
    },
    {
        "Codigo": 139,
        "Banco": "Intesa Sanpaolo Brasil S.A. – Banco Múltiplo",
    },
    {
        "Codigo": 652,
        "Banco": "Itaú Unibanco Holding S.A.",
    },
    {
        "Codigo": 341,
        "Banco": "Itaú Unibanco S.A.",
    },
    {
        "Codigo": 488,
        "Banco": "JPMorgan Chase Bank , National Association",
    },
    {
        "Codigo": 399,
        "Banco": "Kirton Bank S.A. – Banco Múltiplo",
    },
    {
        "Codigo": 293,
        "Banco": "Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.",
    },
    {
        "Codigo": 105,
        "Banco": "Lecca Crédito , Financiamento e Investimento S/ A",
    },
    {
        "Codigo": 145,
        "Banco": "Levycam Ccv Ltda",
    },
    {
        "Codigo": 113,
        "Banco": "Magliano S.A",
    },
    {
        "Codigo": 323,
        "Banco": "Mercado Pago – Conta Do Mercado Livre",
    },
    {
        "Codigo": 128,
        "Banco": "MS Bank S.A. Banco de Câmbio",
    },
    {
        "Codigo": 137,
        "Banco": "Multimoney Cc Ltda",
    },
    {
        "Codigo": 14,
        "Banco": "Natixis Brasil S.A. Banco Múltiplo",
    },
    {
        "Codigo": 191,
        "Banco": "Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.",
    },
    {
        "Codigo": 753,
        "Banco": "Novo Banco Continental S.A. – Banco Múltiplo",
    },
    {
        "Codigo": 260,
        "Banco": "Nu Pagamentos S.A (Nubank)",
    },
    {
        "Codigo": 613,
        "Banco": "Omni Banco S.A.",
    },
    {
        "Codigo": 613,
        "Banco": "Omni Banco S.A.",
    },
    {
        "Codigo": 290,
        "Banco": "Pagseguro Internet S.A",
    },
    {
        "Codigo": 254,
        "Banco": "Paraná Banco S.A.",
    },
    {
        "Codigo": 326,
        "Banco": "Parati – Crédito Financiamento e Investimento S.A.",
    },
    {
        "Codigo": 194,
        "Banco": "Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda",
    },
    {
        "Codigo": 174,
        "Banco": "Pernambucanas Financ S.A",
    },
    {
        "Codigo": 100,
        "Banco": "Planner Corretora De Valores S.A",
    },
    {
        "Codigo": 125,
        "Banco": "Plural S.A. – Banco Múltiplo",
    },
    {
        "Codigo": 93,
        "Banco": "Pólocred Scmepp Ltda",
    },
    {
        "Codigo": 108,
        "Banco": "Portocred S.A",
    },
    {
        "Codigo": 283,
        "Banco": "Rb Capital Investimentos Dtvm Ltda",
    },
    {
        "Codigo": 101,
        "Banco": "Renascenca Dtvm Ltda",
    },
    {
        "Codigo": 270,
        "Banco": "Sagitur Corretora de Câmbio Ltda.",
    },
    {
        "Codigo": 751,
        "Banco": "Scotiabank Brasil S.A. Banco Múltiplo",
    },
    {
        "Codigo": 276,
        "Banco": "Senff S.A. – Crédito , Financiamento e Investimento",
    },
    {
        "Codigo": 545,
        "Banco": "Senso Ccvm S.A",
    },
    {
        "Codigo": 190,
        "Banco": "Servicoop",
    },
    {
        "Codigo": 183,
        "Banco": "Socred S.A",
    },
    {
        "Codigo": 299,
        "Banco": "Sorocred Crédito , Financiamento e Investimento S.A.",
    },
    {
        "Codigo": 118,
        "Banco": "Standard Chartered Bank (Brasil) S/A–Bco Invest.",
    },
    {
        "Codigo": 197,
        "Banco": "Stone Pagamentos S.A",
    },
    {
        "Codigo": 340,
        "Banco": "Super Pagamentos e Administração de Meios Eletrônicos S.A.",
    },
    {
        "Codigo": 95,
        "Banco": "Travelex Banco de Câmbio S.A.",
    },
    {
        "Codigo": 143,
        "Banco": "Treviso Corretora de Câmbio S.A.",
    },
    {
        "Codigo": 131,
        "Banco": "Tullett Prebon Brasil Cvc Ltda",
    },
    {
        "Codigo": 129,
        "Banco": "UBS Brasil Banco de Investimento S.A.",
    },
    {
        "Codigo": "091-4",
        "Banco": "Unicred Central do Rio Grande do Sul",
    },
    {
        "Codigo": 91,
        "Banco": "Unicred Central Rs",
    },
    {
        "Codigo": 136,
        "Banco": "Unicred Cooperativa",
    },
    {
        "Codigo": 99,
        "Banco": "UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.",
    },
    {
        "Codigo": 84,
        "Banco": "Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos , Profissionais das Ciências",
    },
    {
        "Codigo": 298,
        "Banco": "Vips Cc Ltda",
    },
    {
        "Codigo": 310,
        "Banco": "Vortx Distribuidora de Títulos e Valores Mobiliários Ltda",
    },
    {
        "Codigo": 102,
        "Banco": "Xp Investimentos S.A",
    }
]

export { BanksList };