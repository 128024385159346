import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1rem;
  position: relative;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  color: #666666;
  margin: 1rem;
  padding: 0;
  border: 0;

  position: absolute;
  top: 0;
  right: 0;

  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
`;
