import axios from 'axios';

const API_TOKEN = localStorage.getItem("authUser") ? JSON.parse(localStorage.getItem("authUser")).token : "";

const API_URL = process.env.REACT_APP_APIURL;


export const URL_USERSITES = "/UserSites";

export const AxiosApi = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

AxiosApi.defaults.headers.common["Authorization"] = 'Bearer ' + API_TOKEN;
AxiosApi.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);