import * as React from "react";
import * as Translator from "react-i18next";
import { Link, useParams } from "react-router-dom";
const { Button } = require("reactstrap");

import { AxiosApi } from "../../../helpers/api";
import * as ContextProvider from "../../../context/ContextProvider";

import { ModalAction } from "../../../components/Modal/ModalAction/ModalAction";

import { Charger } from "../../../interfaces/Chargers";
import { ModaActionlTypes } from "../../../interfaces/Modal";
import { ChargerProfileTypes } from "../../../interfaces/ChargerPricification";

import { Wrapper, Content, DropList } from "./chargerpricification.styles";

type ChargerPricificationPropsType = {
  t: Function;
  charger: Charger;
};

type DroplistStateType = {
  selectedOptionValue: string;
  settedOptionValue: string;
  updatingChargerProfile: boolean;
};

type ResponseStatusStateType = {
  success: boolean;
  error: boolean;
  message: string;
};

export const ChargerPricification = ({
  t: translation,
  charger,
}: ChargerPricificationPropsType) => {
  const { chargePointId }: Charger = charger;
  const { site_id }: any = useParams();
  const { context, state }: any = ContextProvider.useStateContext();

  const [isError, setIsError] = React.useState<boolean>(false);
  const [firstRender, setFirstRender] = React.useState<boolean>(true);

  const [responseStatus, setResponseStatus] =
    React.useState<ResponseStatusStateType>({
      success: false,
      error: false,
      message: "",
    });

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const [optionSettings, setOptionSettings] = React.useState<DroplistStateType>(
    {
      selectedOptionValue: "",
      settedOptionValue: "",
      updatingChargerProfile: false,
    },
  );
  const [profileData, setProfileData] = React.useState<ChargerProfileTypes[]>(
    [],
  );

  const handleCloseActionModal = () => {
    setModalAction((prev) => ({ ...prev, modalIsOpen: false }));
  };

  const handleSetSelectedProfile = () => {
    setOptionSettings((prev) => ({
      ...prev,
      settedOptionValue: prev.selectedOptionValue,
      updatingChargerProfile: true,
    }));
    context.getAuthorizationReload(true);
  };

  const renderInfoElement = (value: string): JSX.Element => {
    if (value === "" || Number(value) === 0) {
      return (
        <>
          {translation("chargers_PricificationActionModalRemoveText1")}{" "}
          <strong className={"text-danger"}>
            {translation("chargers_PricificationActionModalRemoveText2")}
          </strong>
          {". "}
          {translation("chargers_PricificationActionModalRemoveText3")}
        </>
      );
    }

    return (
      <>
        {translation("chargers_PricificationActionModalText1")}{" "}
        <strong className={"text-danger"}>{value}</strong>{" "}
        {translation("chargers_PricificationActionModalText2")}
      </>
    );
  };

  const onChangeDropListValue = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    event.preventDefault();
    const droplistValue = event.target.value;
    const mutableProfileData: ChargerProfileTypes[] = [...profileData];
    const mutableOptionsData: DroplistStateType = { ...optionSettings };
    const mutableChargerData: Charger = { ...charger };

    const findProfileById = mutableProfileData.find(
      (profile) => profile.priceProfileId === Number(droplistValue),
    );

    if (
      droplistValue !== "0" &&
      (!findProfileById || findProfileById === null)
    ) {
      return;
    }

    const selectValue = findProfileById?.priceProfileName ?? "0";

    setOptionSettings((prev) => ({
      ...prev,
      selectedOptionValue: droplistValue,
    }));

    setModalAction(() => ({
      modalIsOpen: true,
      modalTitle: translation("chargers_PricificationActionModalTitle"),
      // modalInfo: renderInfoElement(
      //   selectValue === "DEFAULT_PRICE_PROFILE"
      //     ? translation("pricing_DefaultProfile")
      //     : selectValue,
      // ),
      modalInfo: "",
      modalButtonConfirmText: translation("global_Yes"),
      modalButtonCancelText: translation("global_No"),
      modalOnConfirmAction: handleSetSelectedProfile,
      modalOnRequestClose: handleCloseActionModal,
    }));
  };

  const getApiData = async () => {
    try {
      const { data } = await AxiosApi.get<ChargerProfileTypes[]>(
        `/PriceProfiles?siteid=${site_id}`,
      );

      const mutableData = [...data];
      const sortedData = mutableData.sort((dataA, dataB) => {
        if (dataA.priceProfileId > dataB.priceProfileId) return 1;
        if (dataA.priceProfileId < dataB.priceProfileId) return -1;

        return 0;
      });

      setProfileData(sortedData);
      console.log("profileData", profileData);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    const setProfilePrice = async () => {
      try {
        if (firstRender) return;

        const mutableProfileData: ChargerProfileTypes[] = [...profileData];
        const mutableOptionsData: DroplistStateType = { ...optionSettings };
        const mutableChargerData: Charger = { ...charger };

        if (!mutableOptionsData.updatingChargerProfile) return;

        // if (
        //   mutableChargerData.cpPriceProfileId === null &&
        //   !handleUpdateChargerProfile
        // )
        //   return;

        // if (
        //   String(mutableChargerData.cpPriceProfileId) ===
        //   mutableOptionsData.settedOptionValue
        // ) {
        //   return;
        // }

        console.log("SETTING PROFILE PRICE");

        const catchValue =
          mutableOptionsData.settedOptionValue === "0"
            ? null
            : Number(mutableOptionsData.settedOptionValue);
        mutableChargerData.cpPriceProfileId = catchValue;

        const payload: Charger = { ...mutableChargerData };

        console.log("payload", payload);

        const { status } = await AxiosApi.put<Charger>(
          `/ChargePoints/${chargePointId}`,
          payload,
        );

        if (status === 204) {
          console.log("status", status);
        }
      } catch (error: unknown) {
        console.log(error);
        getApiData();
      } finally {
        setFirstRender(false);
        setOptionSettings((prev) => ({
          ...prev,
          updatingChargerProfile: false,
        }));
        handleCloseActionModal();
      }
    };

    setProfilePrice();
  }, [optionSettings.settedOptionValue]);

  React.useEffect(() => {
    const mutableProfileData: ChargerProfileTypes[] = [...profileData];
    const mutableOptionsData: DroplistStateType = { ...optionSettings };
    const mutableChargerData: Charger = { ...charger };

    const checkDataValue =
      mutableChargerData.cpPriceProfileId === null
        ? "0"
        : mutableChargerData.cpPriceProfileId;

    setOptionSettings((prev) => ({
      ...prev,
      settedOptionValue: String(checkDataValue),
    }));
  }, [charger]);

  React.useEffect(() => {
    if (!responseStatus.error) return;

    setModalAction((prev) => ({
      modalIsOpen: true,
      modalTitle: translation("chargers_PricificationActionModalErrorTitle"),
      modalInfo: translation("chargers_PricificationActionModalErrorText"),
      modalButtonConfirmText: translation(
        "chargers_PricificationActionModalErrorButtonConfirm",
      ),
      modalButtonCancelText: translation(
        "chargers_PricificationActionModalErrorButtonClose",
      ),
      modalOnConfirmAction: handleCloseActionModal,
      modalOnRequestClose: handleCloseActionModal,
    }));

    // getApiData();
  }, [responseStatus.error]);

  React.useEffect(() => {
    getApiData();
  }, [site_id, state.sites]);

  return (
    <>
      <Wrapper>
        <Content>
          <p>
            <strong>{translation("chargers_PricificationTitle")}</strong>{" "}
            {translation("chargers_PricificationText")}
          </p>

          <div>
            <DropList dropListWidth={20}>
              <select
                id="select"
                name="select"
                disabled={!profileData.length}
                style={!profileData.length ? { cursor: "default" } : {}}
                value={optionSettings.settedOptionValue}
                onChange={onChangeDropListValue}
              >
                {/* <option value="0" selected>
                  {translation("chargers_PricificationSelectAProfileValue")}
                </option> */}

                {profileData.length > 0 && (
                  <>
                    {profileData
                      .filter((info) => info.priceProfileDefaultProfile)
                      .map((defaultProfile) => (
                        <option
                          value={defaultProfile.priceProfileId}
                          key={defaultProfile.priceProfileId}
                          selected
                        >
                          {translation("pricing_DefaultProfile")}
                        </option>
                      ))}
                    {profileData
                      .filter((info) => !info.priceProfileDefaultProfile)
                      .map((info) => (
                        <option
                          value={info.priceProfileId}
                          key={info.priceProfileId}
                        >
                          {info.priceProfileName}
                        </option>
                      ))}
                  </>
                )}
              </select>
            </DropList>

            <Link
              to={`/pricification/${site_id}`}
              style={{ pointerEvents: !profileData.length ? "none" : "all" }}
            >
              <Button
                color="primary"
                className="mt-0 px-4 btn-sm rounded-button"
                disabled={!profileData.length}
              >
                <span style={{ color: "white" }}>
                  {translation("chargers_PricificationButtonShowProfiles")}
                </span>
              </Button>
            </Link>
          </div>

          <hr />
        </Content>
      </Wrapper>

      {/*Modals*/}
      {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
    </>
  );
};

export default Translator.withTranslation()(ChargerPricification);
