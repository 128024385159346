import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 8rem;

  h3 {
    margin-top: 2rem;
    text-transform: uppercase;
    font-size: 1.08rem;
    font-weight: 600;
  }

  h4 {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
  }

  p {
    font-size: 0.88rem;
    font-weight: 400;
    line-height: 1.4rem;
    text-align: left;
    text-decoration: none;
    word-break: break-word;

    b {
      font-weight: 600;
    }
  }
`;
