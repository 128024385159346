import styled, { css } from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1.8rem;

  display: grid;
  grid-gap: 1rem;
  position: relative;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;

  h2 {
    color: #3970f3;

    font-style: normal;
    font-weight: 700;
    font-size: 1.16rem;
    line-height: 1.16rem;
    text-align: left;
  }

  small {
    color: ${lighten(0.24, "#666666")};

    position: absolute;
    top: 1rem;
    right: 1.48rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1.16rem;
    line-height: 1.16rem;
    text-align: right;
    text-decoration: none;
    text-transform: lowercase;

    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: #666666;
    }
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  margin: 0 auto;

  ol {
    color: #666666;
    font-weight: 700;

    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-decoration: none;
    text-transform: none;

    margin: 0;
    padding: 0;
    list-style-type: none;

    & + ol {
      margin-top: 2.8rem;
    }

    li + li {
      margin: 1rem 0;
    }

    span {
      font-weight: 500;
    }
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
`;
