import * as React from "react";
import { QRCode as QRCodeChunck } from "react-qrcode-logo";
import { darken } from "polished";

import { Component, Wrapper, Content } from "./qrcode.styles";

type QRCodePropsTypes = {
  cpCode: string;
};

export const QRCode = ({ cpCode = "" }: QRCodePropsTypes) => {
  return (
    <>
      <Component>
        <Wrapper>
          <Content>
            <QRCodeChunck
              size={250}
              logoWidth={120}
              logoHeight={120}
              logoOpacity={1}
              fgColor={darken(0.48, "#e7e8ea")}
              logoImage={require("../../../../../../../../../../assets/images/logo-sm.png")}
              value={`https://app.spott.eco/ch/${cpCode}`}
            />
          </Content>
        </Wrapper>
      </Component>
    </>
  );
};
