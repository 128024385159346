import styled from "styled-components";
import { lighten, darken, transparentize, rgb } from "polished";

export const Container = styled.div`
  /* background-color: lightskyblue; */
  width: 100%;
  margin: 1rem 0;
  padding: 0 0.2rem;

  p {
    text-align: center;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 1.4rem;
  }
`;

export const ClickableText = styled.span`
  background-color: transparent;
  color: #3970f3;
  border: 0;
  cursor: pointer;

  transition: all 300ms;
  &:hover {
    filter: brightness(1.1);
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 32rem;
  padding: 1rem;

  flex: 1;
  overflow: hidden;
  overflow-y: scroll;
  user-select: none;
`;
