import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  .inner-content {
    p {
      color: ${darken(0.32, "#e7e8ea")};
      font-size: 0.88rem;
      line-height: 0.88rem;
      font-weight: 500;
      font-style: normal;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;

      strong {
        font-weight: 600;
      }
    }
  }

  div {
    display: flex;
    justify-content: space-between;
  }

  .droplist {
    width: 10rem;
  }

  button {
    width: 10rem;
  }
`;

export const DropList = styled.label`
  width: 12rem;
  color: ${darken(0.32, "#e7e8ea")};

  select {
    background-color: #e7e8ea;
    width: 100%;
    height: 2.64rem;
    padding: 0.24rem;

    border: 1px solid ${darken(0.08, "#e7e8ea")};
    border-radius: 0.24rem;
    cursor: pointer;

    option {
      color: ${darken(0.48, "#e7e8ea")};
      cursor: pointer;
    }
  }
`;
