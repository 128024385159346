import "./NewCharger.scss";
import React, { useState, useEffect } from "react";
import CustomBreadCrumb from "../../components/BreadCrumb";
import { Container, Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { AxiosApi } from "../../helpers/api";
import {
  FormatDateHelper,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import { withRouter, Link, useParams } from "react-router-dom";
import DropdownMenuRight from "../../components/DropdownMenuRight";
import { withTranslation } from "react-i18next";
import ListCharger from "../../assets/images/list-charger.png";
import { useStateContext } from "../../context/ContextProvider";

const AllChargers = (props) => {
  const [chargers, setChargers] = useState(null);
  const [siteInfo, setSiteInfo] = useState();
  const { site_id } = useParams();
  const { context, state } = useStateContext();

  const [userLanguage, setUserLanguage] = React.useState("");
  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  useEffect(() => {
    if (site_id && state.sites) {
      const siteInfoFilter = state.sites.filter(
        (site) => site.csId === site_id,
      );
      setSiteInfo(siteInfoFilter[0]);
      getUserCharger(site_id);
    }
  }, [site_id, state.sites]);

  const getUserCharger = (siteId) => {
    const resp = AxiosApi.get(`/site/chargepoints/${siteId}`, {})
      .then((response) => {
        setChargers(response.data);
      })
      .catch((error) => error);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={12}>
              <CustomBreadCrumb
                siteName={siteInfo?.csName}
                title={props.t("Chargers")}
                subtitle={`${props.t(
                  "Last update",
                )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <div className="w-100">
            <ul className="nav nav-tabs w-100">
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Chargers/NewCharger/${siteInfo?.csId}` }}
                >
                  {props.t("New Charger")}
                </Link>
              </li>
              <li className="nav-item">
                <span className={`nav-link active`}>
                  {props.t("All Chargers")}
                </span>
              </li>
            </ul>
          </div>
        </Row>
      </Container>

      <Row>
        <Col xl={12} className="p-3">
          <Card>
            <CardBody className="">
              {!chargers ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner>Loading...</Spinner>
                </div>
              ) : (
                <div>
                  <table className="table-charges">
                    <thead>
                      <tr>
                        <th>{props.t("Charger ID")}</th>
                        <th>{props.t("Status")}</th>
                        <th>{props.t("Action date")}</th>
                      </tr>
                    </thead>

                    <tbody>
                      {chargers.length === 0 ? (
                        <tr>
                          <td colSpan={3}>Nenhum carregador encontrado</td>
                        </tr>
                      ) : (
                        chargers.map((charger, index) => {
                          return (
                            <tr
                              key={charger.chargePointId}
                              className={`charger`}
                            >
                              <td>
                                {charger.chargePointId}{" "}
                                <img
                                  src={ListCharger}
                                  alt={charger.chargePointId}
                                />
                              </td>
                              <td>
                                {charger.cpConfirmed ? (
                                  <span className="text-success">
                                    {props.t("Completed successfully")}
                                  </span>
                                ) : (
                                  <span className="text-danger">
                                    {props.t("Waiting for validating 24hs")}
                                  </span>
                                )}
                              </td>
                              <td>
                                {formatFullDateAndTimeHelper(
                                  charger.cpAddedDate,
                                  userLanguage,
                                )}
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default withRouter(withTranslation()(AllChargers));
