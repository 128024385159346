import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { withTranslation } from "react-i18next";

import "./WidgetSupport.scss";

const WidgetSupport = ({ t }) => {
  // const tipId = Math.round(Math.random() * (data.tips.length - 1) + 0);

  return (
    <div className="pb-4 h-100">
      <Card className="bg-primary widget-suport">
        <CardBody>
          <div className="text-white">
            {/*
                <h5 className="mt-0 mb-4 text-white-50 font-size-20">
                {t(data.title)}
                </h5>
                <p className="text-white-50 font-size-14">
                    {data.tips[tipId].text}
                </p>
                <Link to={data.tips[tipId].linkURL} className="text-white font-size-14">
                    {t(data.tips[tipId].linkText)}
                </Link>
            */}
            <h1 className="support-title">{t("Precisa de ajuda?")}</h1>

            <p>
              {t(
                `If you have questions about management your chargers or how to our system, talk to us!`,
              )}
            </p>

            <div className="support-contact">
              <IoIosMail />
              <a href="mailto:spott@spott.eco" className="text-white">
                spott@spott.eco
              </a>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(WidgetSupport);
