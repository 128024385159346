import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Header from "./components/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { withTranslation } from "react-i18next";

const Help = (props) => {
  return (
    <>
      <MetaTags>
        <title>{props.t("Login | Spott | Manage your recharge points")}</title>
      </MetaTags>
      <Header />

      <section className="w-100 custom-bg">
        <div className="account-pages pt-5" style={{ marginTop: "-40px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col sm={9} md={7} lg={6} xl={4} xxl={3}>
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <p className="font-size-29 text-info mb-0">
                      {props.t("Need a help to continue")}
                    </p>
                    <div>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed vitae varius nunc. Quisque eget dolor purus. Maecenas
                      purus felis, aliquam vitae efficitur sit amet, ultricies
                      eget lectus. Sed pretium, velit sit amet gravida ultrices,
                      nulla lectus consectetur sem, gravida dictum diam nulla a
                      erat.
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed vitae varius nunc. Quisque eget dolor purus. Maecenas
                      purus felis, aliquam vitae efficitur sit amet, ultricies
                      eget lectus. Sed pretium, velit sit amet gravida ultrices,
                      nulla lectus consectetur sem, gravida dictum diam nulla a
                      erat.
                      <br />
                      <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed vitae varius nunc. Quisque eget dolor purus. Maecenas
                      purus felis, aliquam vitae efficitur sit amet, ultricies
                      eget lectus. Sed pretium, velit sit amet gravida ultrices,
                      nulla lectus consectetur sem, gravida dictum diam nulla a
                      erat.
                    </div>
                    <Row className="mb-1">
                      <Col sm={12} className="text-end">
                        <button className="btn mt-4 btn-primary w-full waves-effect waves-light">
                          {props.t("NEXT")}
                        </button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer loginFooter={true} />
      </section>
     
    </>
  );
};

export default withRouter(connect(null, {})(withTranslation()(Help)));
