import React from "react";
import { withTranslation } from "react-i18next";
import { decodeServerMessage } from "../../utils/exceptions";

interface ErrorProps {
  t: Function;
  status?: any;
  message: any;
  onClose?: () => void;
}

function Error({ t, status, message, onClose }: ErrorProps) {
  const [exceptionMessage, setExceptionMessage] = React.useState<string>();

  React.useEffect(() => {
    if (status && status === 500) {
      if(message?.error){
        return setExceptionMessage(decodeServerMessage(message.error));
      }

      return setExceptionMessage(decodeServerMessage(message));
    }

    if (message && message?.error && status !== 500) {
      setExceptionMessage(decodeServerMessage(message.error));
    }

    if(message && !message?.error){
      setExceptionMessage("global_ErrorModalMessage");
    }

    if (onClose)
      setTimeout(() => {
        onClose();
      }, 2500);
  }, []);

  return (
    <div className="mt-3 mb-3">
      <div
        className="swal2-icon swal2-error swal2-animate-error-icon"
        style={{ display: "flex" }}
      >
        <span className="swal2-x-mark">
          <span className="swal2-x-mark-line-left"></span>
          <span className="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <p className="text-center text-grey">{t(exceptionMessage)}</p>
    </div>
  );
}

export default withTranslation()(Error);
