import styled from "styled-components";
import { lighten, darken, rgba } from "polished";

type DropListPropsType = {
  dropListWidth: number;
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const AlignContainer = styled.div`
  width: 80%;

  @media (max-width: 1100px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  width: 100%;

  p {
    padding: 0;
    margin: 1rem 0;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const Display = styled.div`
  background-color: white;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  padding: 1rem;
  position: relative;

  border: 0;
  border-radius: 0.24rem;
`;

export const Title = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 0;
  padding-bottom: 1rem;

  border: 1px solid transparent;
  border-bottom-color: #979797;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  p {
    padding: 0;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 1.24rem;
    line-height: 1.5rem;
  }
`;

export const EnterprisePlan = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-bottom: 1rem;

  border: 1px solid transparent;
  border-bottom-color: #979797;

  h2 {
    color: #34c05e;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;

    ul {
      color: #484552;
      margin: 0;
      padding: 0;
      list-style: none;

      font-style: normal;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.5rem;
      text-align: left;
    }

    @media (max-width: 1100px) {
      display: grid;

      div{
        margin-top: 15px;
      }
    }
  }
`;

export const EnterprisePlanTax = styled.div`
  width: 100%;
  margin: 1rem 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;

  @media (max-width: 1100px) {
    display: grid;
    grid-row-gap: 10px;
  }

  div {
    flex: 1;
    height: 6rem;

    @media (max-width: 1100px) {
      height: fit-content;
    }

    h2 {
      color: #262729;
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    ul {
      color: #8699ad;
      padding: 0;
      margin: 0;
      list-style: none;

      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5rem;

      span {
        color: #484552;
      }
    }
  }
`;

export const CustomTextButton = styled.button`
  background-color: transparent;
  color: #3970f3;
  border: 0;
  padding: 0;
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
`;

export const Description = styled.p`
  color: #8b8b8b;
  padding: 0;
  margin: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
`;

export const BankData = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding-top: 1rem;
`;

export const Form = styled.form`
  width: 88%;
  margin: 0 auto 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 1100px) {
    width: 100%;
  }

  

  .inputs-aligment {
    width: 100%;
    display: grid;
    grid-template-columns: 200px auto 100px;
    align-items: flex-end;
    gap: 15px;

    label, input{
      width: 100%
    }

    @media (max-width: 1100px) {
      grid-template-columns: auto auto;
      gap: 15px;
    }

  }

  .buttons-aligment {
    position: absolute;
    top: 0.8rem;
    right: 1rem;

    @media (max-width: 1100px) {
      position: inherit;
    }

    button {
      width: 12rem;
    }
  }

  button[type="submit"] {
    &:disabled {
      background-color: rgba(0, 0, 0, 0.28);
    }
  }
`;

export const EstablishmentData = styled.div`
  width: 88%;
  height: 100%;
  margin: 0 auto 0 0;
  margin-top: 1.5rem;
  padding: 0;

  div {
    width: 100%;
    display: flex;
    gap: 15px;

    label{
      width: 100%
    }
  }

  .div-more-informations{
    display: grid;
    grid-template-columns: repeat(2, auto);

    @media (max-width: 1100px) {
      grid-template-columns: auto;
    }
  }
`;

export const ResponsibleData = styled(EstablishmentData)``;

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;

  h2 {
    color: #3970f3;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
  }

  p {
    margin: 1rem 0.6rem;
    color: #565656;
    font-style: normal;
    font-weight: 500;
    font-size: 1.24rem;
    line-height: 1.8rem;
    text-align: left;
  }

  div {
    background-color: rgba(0, 0, 0, 0.124);
    color: #262729;
    width: 100%;
    height: 16rem;
    padding: 1rem 2rem;

    border: 0;
    border-radius: 0.4rem;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 1.24rem;
      line-height: 1.5rem;
    }

    ul {
      margin: 0;
      padding: 0;
      margin-top: 1rem;
      list-style-type: none;

      li {
        font-weight: 500;
        font-size: 2rem;
        line-height: 2.5rem;
      }
    }
  }
`;
