import * as React from "react";
import * as Translator from "react-i18next";
import { AxiosApi } from "../../../../../../../../helpers/api";

const { Modal } = require("reactstrap");
import Error from "../../../../../../../../components/Alerts/Error";
import Success from "../../../../../../../../components/Alerts/Success";
import { ModalAction } from "../../../../../../../../components/Modal/ModalAction/ModalAction";
import { Button } from "../../../../../../../../components/Button";
const { AvForm, AvField } = require("availity-reactstrap-validation");
import { ChangeChargerInput, DivButton } from "./Modal.style";
import * as ContextProvider from "../../../../../../../../context/ContextProvider";

import {
  Exception,
  ResponseSuccess,
} from "../../../../../../../../interfaces/Exception";
import { ModaActionlTypes } from "../../../../../../../../interfaces/Modal";

import {
  Component,
  ModalWrapper,
  ModalHeader,
  ModalFormInputField,
} from "../../../ChargerExclusive/components/ModalUsersAccess/modalusersaccess.styles";

import { Charger } from "../../../../../../../../interfaces/Chargers";
import SuccessModal from "../../../../../../../../components/Alerts/SuccessModal";

type ModalChargeChangeType = {
  modalIsOpen: boolean;
  t: Function;
  chargePointId: string;
  handleError: any;
  handleSuccess: any;
  setHandleSuccess: any;
  modalOnRequestClose: () => void;
  refreshChargersList: () => void;
  charger: Charger;
};

export const ModalChargeChange = ({
  t: translation,
  modalIsOpen,
  handleSuccess,
  setHandleSuccess,
  handleError,
  modalOnRequestClose,
  refreshChargersList,
  charger,
}: ModalChargeChangeType) => {
  const [chargerInfo, setChargerInfo] = React.useState({
    name: charger.name,
    comment: charger.comment,
  });

  const { context, state }: any = ContextProvider.useStateContext();

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  const changeChargerInfo = async () => {
    try {
      const { chargePointId, cpOwnerDesc } = charger;
      const { name, comment } = chargerInfo;

      await AxiosApi.put(`/ChargePoints/${chargePointId}`, {
        ...charger,
        name,
        comment,
      });

      context.getAuthorizationReload(true);
      await setHandleSuccess(true)
      modalOnRequestClose()

      refreshChargersList();
    } catch (err: unknown) {
      console.log(err);
    }
  };

  // Render
  return (
    <>
      <Component>
        <Modal
          isOpen={modalIsOpen}
          fullscreen={"sm"}
          size={"md"}
          centered={true}
        >
          <ModalWrapper>
            <ModalHeader>
              <p>
                {translation("chargers_ChargerPointModalChangeTitle")}{" "}
                {charger.name}
              </p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            <hr />

            {!handleError && !handleSuccess && (
              <>
                <AvForm>
                  <>
                    <label>
                      {translation(
                        "chargers_ChargerPointModalChangeChargerName",
                      )}
                    </label>
                    <AvField
                      id={"name"}
                      name={"name"}
                      type={"text"}
                      placeholder={translation(
                        "chargers_ChargerPointModalChangeChargerNamePlaceholder",
                      )}
                      value={charger.name}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setChargerInfo((prev) => ({
                          ...prev,
                          name: String(event.target.value),
                        }))
                      }
                      style={{ marginBottom: "10px" }}
                    />
                  </>
                  <>
                    <label>
                      {translation("chargers_ChargerPointModalChangeComment")}
                    </label>
                    <AvField
                      id={"comment"}
                      name={"comment"}
                      type={"text"}
                      placeholder={translation(
                        "chargers_ChargerPointModalChangeCommentPlaceholder",
                      )}
                      value={charger.comment}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setChargerInfo((prev) => ({
                          ...prev,
                          comment: String(event.target.value),
                        }))
                      }
                    />
                  </>
                </AvForm>

                <DivButton>
                  <Button
                    buttonColor={"blue"}
                    type={"button"}
                    onClick={changeChargerInfo}
                    // onClick={() => {
                    //   const payload: ModalActionPayload = {
                    //     userId: user.ucpId,
                    //     userName: user.email,
                    //   };

                    //   prepareModalAction(payload);
                    // }}
                  >
                    {translation(
                      "chargers_ChargerPointModalChangeButtonChange",
                    )}
                  </Button>
                </DivButton>
              </>
            )}
          </ModalWrapper>
        </Modal>

        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalChargeChange);
