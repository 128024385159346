import { FormEvent, useEffect, useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { useParams, withRouter } from "react-router-dom";
import { AxiosApi } from "../../../helpers/api";
import { withTranslation, WithTranslation } from "react-i18next";

import Footer from "../../../components/VerticalLayout/Footer";
import { Loader } from "../../../components/Loader";

const logoImg = require("../../../assets/images/logo.png");

import {
  Container,
  Header,
  Content,
  Display,
  Form,
  Label,
  SubmitButton,
  ErrorBoundary,
  ErrorBoundaryContent,
  SuccessBoundary,
  FloatingFooter,
} from "./forgotpasswordapp.styles";

interface ForgotPasswordAppProps {
  t: Function; // as translator prop name
}

const ForgotPasswordApp = ({ t: translator }: ForgotPasswordAppProps) => {
  const { t }: any = useParams();
  const url = new URL(window.location.href);
  const hashParam = url.searchParams.get("culture");

  // Variables
  const [isRequestingServerStatus, setIsRequestingServerStatus] =
    useState(false);
  const [error, setError] = useState({ erro: false, errorMessage: "" });
  const [success, setSuccess] = useState(false);

  const [passwordFieldValue, setPasswordFieldValue] = useState("");
  const [passwordConfirmFieldValue, setPasswordConfirmFieldValue] =
    useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showPasswordConfirmField, setShowPasswordConfirmField] =
    useState(false);

  const [isPasswordFieldFocused, setIsPasswordFieldFocused] = useState(false);
  const [isPasswordConfirmFieldFocused, setIsPasswordConfirmFieldFocused] =
    useState(false);

  // Functions
  const enabledPasswordVisibility = () =>
    setShowPasswordField((current) => !current);

  const enabledPasswordConfirmVisibility = () =>
    setShowPasswordConfirmField((current) => !current);

  const createNewPasswordAPI = async (payload: any) => {
    setIsRequestingServerStatus(true);

    try {
      const { status } = await AxiosApi.post(`/SetPassword`, payload);

      setSuccess(true);
    } catch (err: any) {
      const errorMessageServerStatus = JSON.parse(err.request.response).message;

      if (err.response.status === 500) {
        setError({
          erro: true,
          errorMessage: translator("appForgotPasswordError3"),
        });
        // setError({ erro: true, errorMessage: "Internal Server Error" });
      } else {
        setError({ erro: true, errorMessage: errorMessageServerStatus });
        // setError({ erro: true, errorMessage: "Handle Error" });
      }
    } finally {
      setIsRequestingServerStatus(false);
    }
  };

  const handleSubmitForm = (event: FormEvent) => {
    event.preventDefault();

    if (passwordFieldValue !== passwordConfirmFieldValue) {
      setError({
        erro: true,
        errorMessage: translator("Passwords don't match"),
      });
      return;
    }

    if (!t) {
      setError({
        erro: true,
        errorMessage: translator("appForgotPasswordError2"),
      });
      return;
    }

    const payload = {
      Password: passwordFieldValue,
      Token: t,
    };

    createNewPasswordAPI(payload);
  };

  return (
    <>
      <Container>
        <Header>
          <img width={'200px'} style={{ marginBottom: '50px', marginTop: '-20px' }} src={logoImg} alt="logo" />
        </Header>

        <Content>
          <Display>
            {success && (
              <>
                <SuccessBoundary>
                  {/* <h1>Password Changed!</h1>
                  <p>You can now login to Spott App.</p> */}
                  <h1>{translator("Password Changed!")}</h1>
                  <p>{translator("You can now login to Spott App.")}</p>
                </SuccessBoundary>
              </>
            )}

            {!success && (
              <>
                <h1>{translator("Create New Password")}</h1>
                {/* <h1>Create New Password</h1> */}

                <Form onSubmit={handleSubmitForm}>
                  <Label
                    htmlFor="new-password"
                    isFocusingField={isPasswordFieldFocused}
                  >
                    <input
                      required
                      type={showPasswordField ? "text" : "password"}
                      placeholder={translator("New Password")}
                      onMouseOver={() => setIsPasswordFieldFocused(true)}
                      onMouseOut={() => setIsPasswordFieldFocused(false)}
                      onFocus={() => {
                        setIsPasswordFieldFocused(true);
                        setError({
                          erro: false,
                          errorMessage: "",
                        });
                      }}
                      onBlur={() => setIsPasswordFieldFocused(false)}
                      value={passwordFieldValue}
                      onChange={(event) =>
                        setPasswordFieldValue(event.target.value)
                      }
                    />
                    {showPasswordField ? (
                      <BsEyeFill onClick={enabledPasswordVisibility} />
                    ) : (
                      <BsEyeSlashFill onClick={enabledPasswordVisibility} />
                    )}
                  </Label>

                  <p>
                    {translator(
                      "Use 8 or more characters with a mix of letters, numbers and symbols",
                    )}
                  </p>

                  <Label
                    htmlFor="confirm-password"
                    isFocusingField={isPasswordConfirmFieldFocused}
                  >
                    <input
                      required
                      type={showPasswordConfirmField ? "text" : "password"}
                      placeholder={translator("Confirm Password")}
                      onMouseOver={() => setIsPasswordConfirmFieldFocused(true)}
                      onMouseOut={() => setIsPasswordConfirmFieldFocused(false)}
                      onFocus={() => {
                        setIsPasswordConfirmFieldFocused(true);
                        setError({
                          erro: false,
                          errorMessage: "",
                        });
                      }}
                      onBlur={() => setIsPasswordConfirmFieldFocused(false)}
                      value={passwordConfirmFieldValue}
                      onChange={(event) =>
                        setPasswordConfirmFieldValue(event.target.value)
                      }
                    />
                    {showPasswordConfirmField ? (
                      <BsEyeFill onClick={enabledPasswordConfirmVisibility} />
                    ) : (
                      <BsEyeSlashFill
                        onClick={enabledPasswordConfirmVisibility}
                      />
                    )}
                  </Label>

                  <SubmitButton
                    type="submit"
                    isSendingPayload={isRequestingServerStatus}
                  >
                    {!isRequestingServerStatus && <span>{translator("Change Password")}</span>}
                    {isRequestingServerStatus && (
                      <Loader spinnerColor="white" loaderSize="small" />
                    )}
                  </SubmitButton>
                </Form>
              </>
            )}

            {error.erro && (
              <ErrorBoundary>
                <ErrorBoundaryContent>
                  <p>{error.errorMessage}</p>
                </ErrorBoundaryContent>
              </ErrorBoundary>
            )}

            <FloatingFooter>
              <Footer
                loginFooter={true}
                darkText={true}
                tintText={true}
                setLanguageByExternalResource={hashParam || "pt-BR"}
              />
            </FloatingFooter>
          </Display>
        </Content>
      </Container>
    </>
  );
};

export default withTranslation()(ForgotPasswordApp);
