import { useState } from "react";
import { withTranslation } from "react-i18next";

import Modal from "../../../../components/GlobalModal/GlobalModal";
import { PrivacePolicyContent } from "./components/PrivacyPolicyContent/PrivacyPolicyContent";
import { TermsAndConditionsContent } from "./components/TermsAndConditionsContent/TermsAndConditionsContent";

import { ClickableText, Container, Wrapper } from "./usagepolicy.styles";

interface UsagePolicyProps {
  t: Function;
}

type PolicyModalType = {
  isOpen: boolean;
  modalName: string;
};

export const UsagePolicy = ({ t: translator }: UsagePolicyProps) => {
  const [policyModal, setPolicyModal] = useState<PolicyModalType>(
    {} as PolicyModalType,
  );

  const handleOpenPolicyModal = (isOpen: boolean, modalName: string) => {
    setPolicyModal({ isOpen, modalName });
  };
  const handleClosePolicyModal = () => {
    setPolicyModal((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <Container>
        <p>
          {translator(
            "By continuing, you confirm that you have read and agreed to our",
          )}{" "}
          <ClickableText
            onClick={() => handleOpenPolicyModal(true, "Terms and Conditions")}
          >
            {translator("Terms and Conditions")}
          </ClickableText>{" "}
          {translator("And")}{" "}
          <ClickableText
            onClick={() => handleOpenPolicyModal(true, "Privacy Policy")}
          >
            {translator("Privacy Policy")}
          </ClickableText>
          .
        </p>

        <Modal
          centered
          size="lg"
          closeButton
          titleDivisor
          modalTitle={translator(policyModal.modalName)}
          isModalOpen={policyModal.isOpen}
          onRequestClose={handleClosePolicyModal}
        >
          <Wrapper>
            {policyModal.modalName === "Terms and Conditions" && (
              <TermsAndConditionsContent />
            )}
            {policyModal.modalName === "Privacy Policy" && (
              <PrivacePolicyContent />
            )}
          </Wrapper>
        </Modal>
      </Container>
    </>
  );
};

export default withTranslation()(UsagePolicy);
