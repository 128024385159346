import "./header.scss";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import LogoutIcon from "./../../assets/images/logout_icon.png";
import { connect } from "react-redux";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import { Link } from "react-router-dom";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import logodarkImg from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";
import Logo from "./../../assets/images/Logo_Spott.png";
import NewSpottLogo from "./../../assets/images/Logo_Spott_2colors.png";
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import { withTranslation } from "react-i18next";
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import { useStateContext } from "../../context/ContextProvider";
//import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

const Header = (props) => {
  const { context, state } = useStateContext(); // ! HERE - Re-rendering component
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const [userName, setUserName] = useState(() => {
    const storedData = localStorage.getItem("authUser");
    const data = JSON.parse(storedData);

    if (data !== null && data.user?.name) return data.user.name;

    return "";
  });
  const [avatar, setAvatar] = useState({ file: "" });
  const [alertsDashboardState, setAlertsDashboardState] = useState([]);
  const [menuUser, setMenuUser] = useState(false);

  useEffect(() => {
    if (state.lastUpdate === null) {
      context.getUserState();
    }
    if (state.userData) {
      setUserName(state.userData.name);
    }
    if (state.alerts) {
      setAlertsDashboardState(state.alerts.filter((alert) => alert.isNew));
    }
    if (state.avatar) {
      setAvatar(state.avatar);
    }
  }, [state]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodarkImg} alt="" height="17" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logosmImg} alt="" height="20" />
                </span>
                <span className="logo-lg">
                  <img src={NewSpottLogo} alt="" height="60" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => props.toggleMenuCallback()}
              className="d-md-block d-lg-none btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            <div className="d-none d-sm-flex ms-2 align-items-center">
              <div className="img-thumbnail rounded-circle avatar-header">
                {avatar.file === "" ? (
                  <img
                    src={require("../../assets/images/user_default.png")}
                    className="img-fluid h-100"
                    alt="Avatar"
                  />
                ) : (
                  <img src={avatar.file} className="img-fluid" alt="Avatar" />
                )}
              </div>

              <span className="d-block header-profile-user-name ms-3">
                <strong>{userName.split(" ")[0]}</strong>
                <span className="welcome">{props.t("Welcome to Spott")}</span>
              </span>
            </div>
          </div>

          <div className="d-flex">
            <NotificationDropdown data={alertsDashboardState} />

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="fa fa-search"></span>
              </div>
            </form> */}

            <LanguageDropdown />

            <div className="dropdown d-inline-block">
              <Dropdown
                isOpen={menuUser}
                toggle={() => setMenuUser((state) => !state)}
              >
                <DropdownToggle className="btn header-item noti-icon right-bar-toggle waves-effect">
                  <i className="mdi mdi-cog-outline"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <Link
                      to={{ pathname: `/Settings/UserProfile/`, state: {} }}
                    >
                      {props.t("User profile")}
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={{ pathname: `/Settings/Password/`, state: {} }}>
                      {props.t("Password")}
                    </Link>
                  </DropdownItem>
                  <DropdownItem>
                    <Link to={{ pathname: `/Settings/Avatar/`, state: {} }}>
                      {props.t("Add Photo")}
                    </Link>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>

            <div className="d-flex align-items-center btn">
              <Link to="/logout" className="d-flex">
                <img
                  src={LogoutIcon}
                  style={{ width: "18px", height: "18px" }}
                />
                <span className="header-profile-logout">
                  {props.t("Logout")}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
