import * as React from "react";
import * as Translator from "react-i18next";
import Switch from "react-switch";
import { TbEdit as EditIcon } from "react-icons/tb";
import { IoMdTrash as DeleteIcon } from "react-icons/io";

import { AxiosApi } from "../../../../helpers/api";

import { PricificationProfileTypes } from "../../../../interfaces/Pricification";

import TogglerIcon from "../../../../components/Renders/TogglerIcon/TogglerIcon";

import {
  Container,
  InputsAligment,
  ButtonsAligment,
  FormInputField,
  FormField,
  InputField
} from "./pricificationbar.styles";
import { Button } from "../../../../components/Button/Button";
import SuccessModal from "../../../../components/Alerts/SuccessModal";

const numeral = require('numeral');

type PricificationBarPropsType = {
  t: Function;
  handleEditProfile: () => void;
  handleDeleteProfile: () => void;
  change: () => void;
  profileCurrent?: PricificationProfileTypes;
};

type FieldsValueStateType = {
  kwh: string;
  hour: string;
  free: boolean;
  disabled: boolean;
};

export const PricificationBar = ({
  t: translation,
  handleEditProfile,
  handleDeleteProfile,
  profileCurrent,
  change,
}: PricificationBarPropsType) => {
  const inputRefWattage = React.useRef<HTMLInputElement>(null);
  const inputRefHour = React.useRef<HTMLInputElement>(null);
  
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  const [fieldsValue, setFieldsValue] = React.useState<FieldsValueStateType>(
    profileCurrent != undefined
      ? {
          kwh: String(profileCurrent.priceProfilePriceKwh),
          hour: String(profileCurrent.priceProfilePriceTimePlugged),
          free: profileCurrent.priceProfileFreeCharge,
          disabled: profileCurrent.priceProfileChargerDisabled,
        }
      : {
          kwh: "",
          hour: "",
          free: false,
          disabled: false,
        },
  );

  const handleSubmitForm = (event: any, values: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (profileCurrent != undefined) {
      profileCurrent = {
        priceProfileId: profileCurrent.priceProfileId,
        priceProfileName: profileCurrent.priceProfileName,
        priceProfileCsId: profileCurrent.priceProfileCsId,
        priceProfileDefaultProfile: profileCurrent.priceProfileDefaultProfile,
        priceProfileChargerDisabled: fieldsValue.disabled,
        priceProfileFreeCharge: fieldsValue.free,
        priceProfilePriceKwh: parseFloat((fieldsValue.kwh).replace(/,/g, '.')),
        priceProfilePriceTimeCharging:
          profileCurrent.priceProfilePriceTimeCharging,

        priceProfileTransactionPrice:
          profileCurrent.priceProfileTransactionPrice,
        priceProfileOverstayPenalty: profileCurrent.priceProfileOverstayPenalty,
        priceProfileOverstayGracePeriod:
          profileCurrent.priceProfileOverstayGracePeriod,
        priceProfileReservationAllowed:
          profileCurrent.priceProfileReservationAllowed,
        priceProfileReservationPriceMinute:
          profileCurrent.priceProfileReservationPriceMinute,
        priceProfileReservationLimitMinutes:
          profileCurrent.priceProfileReservationLimitMinutes,
        priceProfileOverstayPriceMinute:
          profileCurrent.priceProfileOverstayPriceMinute,
        priceProfilePriceTimePlugged: parseFloat((fieldsValue.hour).replace(/,/g, '.')),
      };
      const resp: any = AxiosApi.put(
        `/PriceProfiles/${profileCurrent?.priceProfileId}`,
        profileCurrent,
      )
        .then((response) => {
          if(response.status === 204){
            setHandleSuccess(true)
          }
         
        })
        .catch((err) => {
          console.log("ERRO", err);
        })
        .finally(() => {
          change();
        });
      
    } else {
      console.log("Selecione um perfil");
    }
  };

  const clearFormFields = () => {
    setFieldsValue({
      kwh: "0,00",
      hour: "0,00",
      free: false,
      disabled: false,
    });
  };
  React.useEffect(() => {
    setFieldsValue(
      profileCurrent != undefined
        ? {
            kwh: numeral(profileCurrent.priceProfilePriceKwh).format('0,0.00'),
            hour: numeral(profileCurrent.priceProfilePriceTimePlugged).format('0,0.00'),
            free: profileCurrent.priceProfileFreeCharge,
            disabled: profileCurrent.priceProfileChargerDisabled,
          }
        : {
            kwh: "",
            hour: "",
            free: false,
            disabled: false,
          },
    );
    if (profileCurrent == undefined) {
      setFieldsValue({
        kwh: "",
        hour: "",
        free: false,
        disabled: false,
      });
    }
  }, [profileCurrent]);

  const language = localStorage.getItem('I18N_LANGUAGE');

  const returnValueLanguage = (event: any)=>{

    const returnValuePT = event.replace(/\./g, ',');
    const returnValueEN = event.replace(/\,/g, '.');


    if(language === 'pt'){
      return returnValuePT;
    }

    return returnValueEN;
  }

  const handleInputValue = async (event: React.ChangeEvent<HTMLInputElement>, type: string) => {

    const value = event.target.value;
    const numericValue = await value.replace(/[^0-9.,]/g, '').replace(/(,.*?),(.*,)?/g, '$1');

    const inputValue = numericValue.toString()

    if(type === 'kwh'){
        return setFieldsValue((current: any) => ({
        ...current,
        kwh: inputValue.replace(/\.(?=.*\.)/g, ''),
        }))  
    } else {
        return setFieldsValue((current: any) => ({
        ...current,
        hour: inputValue.replace(/\.(?=.*\.)/g, ''),
        }))  
    }

}

  const verifyInputValue = async (value: any, type: string) => {

    const inputValue = value.toString();
    const lastCharactere = inputValue.charAt(inputValue.length - 1);

    // Caso seja , ou . no final, retira
    const removeLastCharactere = inputValue.slice(0, -1);
    const inputNumber = parseFloat(removeLastCharactere);

    // Verifica se o usuário já fez separação decimal
    let decimalValue : any;
    if(language === 'pt'){
      decimalValue = await inputValue.split(',')  
    } else{
      decimalValue = await inputValue.split('.')
    }    

    if(type === 'kwh'){

        if(lastCharactere === '.' || lastCharactere === ','){
          const formattedValue = numeral(inputNumber).format('0,0.00');
          console.log(formattedValue, 'formatted')

            return setFieldsValue((current: any) => ({
                ...current,
                kwh: numeral(inputNumber).format('0,0.00'),
            })) 
        }

        if(lastCharactere === ''){
            return setFieldsValue((current: any) => ({
                ...current,
                kwh: numeral(0).format('0,0.00'),
            })) 
        }

        if(decimalValue.length === 1){
          return setFieldsValue((current: any) => ({
            ...current,
            kwh: numeral(parseInt(decimalValue[0])).format('0,0.00'),
          })) 
        }

        if(decimalValue[1].length === 1){

          return setFieldsValue((current: any) => ({
            ...current,
            kwh: inputValue.concat('0'),
          }))
        }

      } else {
          if(lastCharactere === '.' || lastCharactere === ','){
            return setFieldsValue((current: any) => ({
                ...current,
                hour: numeral(inputNumber).format('0,0.00'),
            })) 
          }

          if(lastCharactere === ''){
            return setFieldsValue((current: any) => ({
                ...current,
                hour: numeral(0).format('0,0.00'),
            })) 
          }

          if(decimalValue.length === 1){
            return setFieldsValue((current: any) => ({
              ...current,
              hour: numeral(parseInt(decimalValue[0])).format('0,0.00'),
            })) 
          }
  
          if(decimalValue[1].length === 1){
  
            return setFieldsValue((current: any) => ({
              ...current,
              hour: inputValue.concat('0'),
            }))
          }
      }

    }
  return (
    <>
      <Container>
        <FormField onValidSubmit={handleSubmitForm}>
          <p>{translation("pricing_WeekInfo")}</p>

          <InputsAligment>
            <span>R$</span>
            <InputField
              value={returnValueLanguage(fieldsValue.kwh)}
              type={'text'}
              name={"kwh"}
              disabled={
                fieldsValue.disabled || fieldsValue.free || !profileCurrent
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputValue(event, 'kwh')
              }
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => verifyInputValue(event.target.value, 'kwh')}
              required
            />    

            <span>kWh</span>

            <span>R$</span>
            <InputField
              value={returnValueLanguage(fieldsValue.hour)}
              type={'hour'}
              name={"kwh"}
              disabled={
                fieldsValue.disabled || fieldsValue.free || !profileCurrent
              }
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleInputValue(event, 'hour')
              }
              onBlur={(event: React.ChangeEvent<HTMLInputElement>) => verifyInputValue(event.target.value, 'hour')}
              required
            />  
            

            <span>/h</span>

            <FormInputField
              name={"free"}
              type={"switch"}
              value={fieldsValue.free}
              style={{ display: "none" }}
            />
            <Switch
              uncheckedIcon={<TogglerIcon icon={"Off"} />}
              checkedIcon={<TogglerIcon icon={"On"} />}
              onColor="#2FD475"
              onChange={() =>
                setFieldsValue((current) => ({
                  ...current,
                  free: !current.free,
                }))
              }
              checked={fieldsValue.free}
              disabled={fieldsValue.disabled || !profileCurrent}
            />

            <span>{translation("pricing_LabelSwitcherFree")}</span>

            <FormInputField
              name={"disabled"}
              type={"switch"}
              value={fieldsValue.disabled}
              style={{ display: "none" }}
            />
            <Switch
              uncheckedIcon={<TogglerIcon icon={"Off"} />}
              checkedIcon={<TogglerIcon icon={"On"} />}
              onColor="#2FD475"
              onChange={() =>
                setFieldsValue((current) => ({
                  ...current,
                  disabled: !current.disabled,
                }))
              }
              checked={fieldsValue.disabled}
              disabled={!profileCurrent}
            />

            <span>{translation("pricing_LabelSwitcherDisabled")}</span>
          </InputsAligment>

          <ButtonsAligment>
            <Button
              fontColor={"dark"}
              type={"button"}
              onClick={clearFormFields}
              disabled={!profileCurrent ? true : false}
            >
              {translation("pricing_ButtonClear")}
            </Button>

            <Button
              buttonColor={"blue"}
              type={"submit"}
              disabled={!profileCurrent ? true : false}
            >
              {translation("pricing_ButtonSave")}
            </Button>
            
            {/* <button
              type={"button"}
              onClick={clearFormFields}
              disabled={!profileCurrent ? true : false}
            >
              {translation("pricing_ButtonClear")}
            </button> */}
            {/* <button type={"submit"} disabled={!profileCurrent ? true : false}>
              {translation("pricing_ButtonSave")}
            </button> */}
          </ButtonsAligment>
          
          {handleSuccess && (
            <SuccessModal
              isOpen={handleSuccess}
              onClose={() => setHandleSuccess(false)}
              message={translation("global_SuccessModalResponse")}
            />
          )}
          {profileCurrent?.priceProfileDefaultProfile === true ? (
            ""
          ) : (
            <div>
              <EditIcon
                onClick={() => profileCurrent && handleEditProfile()}
                style={!profileCurrent ? { opacity: 0.5 } : { opacity: 1 }}
              />
              <DeleteIcon
                onClick={() => profileCurrent && handleDeleteProfile()}
                style={!profileCurrent ? { opacity: 0.5 } : { opacity: 1 }}
              />
            </div>
          )}
        </FormField>
      </Container>
    </>
  );
};

export default Translator.withTranslation()(PricificationBar);
