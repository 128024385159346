import * as React from "react";
import * as Translator from "react-i18next";

import { ChargerTypes } from "../../../../../../../../interfaces/Chargers";
const { Modal } = require("reactstrap");
import Error from "../../../../../../../../components/Alerts/Error";
import Success from "../../../../../../../../components/Alerts/Success";
import { ModalAction } from "../../../../../../../../components/Modal/ModalAction/ModalAction";
import { ContentInformations, SpanInformation } from "./Modal.style";

import {
  Exception,
  ResponseSuccess,
} from "../../../../../../../../interfaces/Exception";
import { ModaActionlTypes } from "../../../../../../../../interfaces/Modal";

import {
  Component,
  ModalWrapper,
  ModalHeader,
} from "../../../ChargerExclusive/components/ModalUsersAccess/modalusersaccess.styles";

type ModalChargeDetailsType = {
  t: Function;
  chargePointId: string;
  modalIsOpen: boolean;
  modalOnRequestClose: () => void;
  cpCmData: any;
  charger: ChargerTypes;
};

export const ModalChargeDetails = ({
  t: translation,
  chargePointId,
  modalIsOpen,
  cpCmData,
  modalOnRequestClose,
  charger,
}: ModalChargeDetailsType) => {
  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<
    ResponseSuccess | undefined
  >();

  const [modalAction, setModalAction] = React.useState<ModaActionlTypes>({
    modalIsOpen: false,
    modalTitle: "",
    modalInfo: <></>,
    modalButtonConfirmText: "",
    modalButtonCancelText: "",
    modalOnConfirmAction: (): void => {},
    modalOnRequestClose: (): void => {},
  });

  // Render
  return (
    <>
      <Component>
        <Modal
          isOpen={modalIsOpen}
          fullscreen={"sm"}
          size={"md"}
          centered={true}
        >
          <ModalWrapper>
            <ModalHeader>
              <p>
                {translation("chargers_ChargerPointModalDetailsTitle")}{" "}
                {charger.name}
              </p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            {handleError && <Error {...handleError} />}
            {handleSuccess && <Success {...handleSuccess} />}

            {!handleError && !handleSuccess && (
              <>
                <ContentInformations>
                  <SpanInformation>
                    <b>{translation("chargers_ChargerPointModalDetailsID")}</b>
                    {chargePointId ? chargePointId : translation("Uninformed")}
                  </SpanInformation>

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargerPointModalDetailsName")}:
                    </b>
                    {charger.name ? charger.name : translation("Uninformed")}
                  </SpanInformation>

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargerPointModalDetailsComment")}:
                    </b>
                    {charger.comment
                      ? charger.comment
                      : translation("Uninformed")}
                  </SpanInformation>
                  {/*
                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalVendor")}:
                    </b>

                  </SpanInformation>  */}

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargerPointModalDetailsModel")}:
                    </b>
                    {cpCmData.cmName
                      ? cpCmData.cmName
                      : translation("Uninformed")}
                  </SpanInformation>
                  {/*
                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalSerialNumber")}:
                    </b>

                  </SpanInformation>   */}
                  {/*
                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalChargeBoxSerialNumber")}:
                    </b>

                  </SpanInformation>   */}

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargerPointModalDetailsFirmware")}
                      :
                    </b>
                    {cpCmData.cmFirmwareCurrent
                      ? cpCmData.cmFirmwareCurrent
                      : translation("Uninformed")}
                  </SpanInformation>
                  {/*
                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalIccid")}:
                    </b>

                  </SpanInformation>

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalImsi")}:
                    </b>

                  </SpanInformation>

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalMeterType")}:
                    </b>

                  </SpanInformation>

                  <SpanInformation>
                    <b>
                      {translation("chargers_ChargeDetailsModalMeterSerialNumber")}:
                    </b>

                  </SpanInformation>  */}
                </ContentInformations>
              </>
            )}
          </ModalWrapper>
        </Modal>

        {modalAction.modalIsOpen && <ModalAction {...modalAction} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalChargeDetails);
