import * as React from "react";
import * as Translator from "react-i18next";
import * as ContextProvider from "../../../../../context/ContextProvider";

import {
  formatCurrency,
  formatTimeHelper,
} from "../../../../../helpers/Helpers";

type EventPropsTypes = {
  t: Function;
  eventInfo: any;
};

const Event = ({ t: translation, eventInfo }: EventPropsTypes) => {
  const { state }: any = ContextProvider.useStateContext();

  return (
    <>
      <div>
        <b>
          {`${formatTimeHelper(
            eventInfo.event.start,
            state?.userData?.language,
          )} - ${formatTimeHelper(
            eventInfo.event.end,
            state?.userData?.language,
          )}`}
        </b>

        <br />

        {eventInfo.event.extendedProps.disable ? (
          <b>{translation("Desativado")}</b>
        ) : !eventInfo.event.extendedProps.disable &&
          eventInfo.event.extendedProps.free ? (
          <b>{translation("Free")}</b>
        ) : eventInfo.event.extendedProps.kwh &&
          eventInfo.event.extendedProps.pph ? (
          <b>{`${formatCurrency(
            eventInfo.event.extendedProps.kwh,
            state?.userData?.language,
          )} kWh + ${formatCurrency(
            eventInfo.event.extendedProps.pph,
            state?.userData?.language,
          )}/h`}</b>
        ) : eventInfo.event.extendedProps.kwh &&
          !eventInfo.event.extendedProps.pph ? (
          <b>{`${formatCurrency(
            eventInfo.event.extendedProps.kwh,
            state?.userData?.language,
          )} kWh`}</b>
        ) : (
          <b>{`${formatCurrency(
            eventInfo.event.extendedProps.pph,
            state?.userData?.language,
          )}/h`}</b>
        )}
      </div>
    </>
  );
};

export default Translator.withTranslation()(Event);
