import React, { useState } from 'react';
import CustomBreadCrumb from '../../components/BreadCrumb';
import Tabs from '../../components/Tabs';
import BoltIcon from './../../assets/images/bolt_icon.png';
import './styles.scss';
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, } from 'reactstrap';

import SubsctiptionBullets from '../../components/Subscription/SubsctiptionBullets';
import SubscriptionCard from '../../components/Subscription/SubscriptionCard';
import { SubscriptionSettings, Cards } from '../../common/data/Subscription';
import CardTableHeader from '../../components/Subscription/CardTableHeader';

import CheckIcon from '../../assets/images/ckeck_icon.png';
import { forEach } from 'lodash';

export default function Subscription() {
  const [menu, setMenu] = useState(false);
  const [SubscriptionCards, setSubscriptionCards] = useState(Cards);
  const toggle = () => { setMenu(!menu); };

  let tableContent = Cards[0].attributes;
  if (tableContent[0].length < Cards.length + 1) {
    for (let i = 1; i < Cards.length; i++) {
      for (let z = 0; z < Cards[i].attributes.length; z++) {
        tableContent[z].push(Cards[i].attributes[z][1]);
      }
    }
  }

  const drawTr = (tr, index) => {
    return (
      <tr key={index}>{tr.map(drawTd)}</tr>
    )
  }

  const drawTd = (td, index) => {
    if (index === 0) return <td key={Math.random() * Math.random()} className="label">{td}</td>
    return <td key={index}>{td ? <img src={CheckIcon} alt="Yes" /> : ""}</td>
  }

  return (
    <div className="page-content">
      <Container fluid>

        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb siteName={'Site 01'} title={'Subscription'} subtitle={'Last update 21/02.21 - 22:30h'} />
            </Col>

            <Col md="4">
              <div className="float-end d-none d-md-block">
                <Dropdown isOpen={menu} toggle={toggle}>
                  <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
                    <i className="mdi mdi-cog me-2"></i> Settings
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="#">Action</DropdownItem>
                    <DropdownItem tag="a" href="#">Another action</DropdownItem>
                    <DropdownItem tag="a" href="#">Something else here</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" href="#">Separated link</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <div className="tabs align-itens-center d-flex mb-3">
            <span className="tabs__defaultItem btn active">Our Plan</span>
            <div className="d-flex">
              <span className="tabs__separator"></span>
              <span className="tabs__name btn">Your Card</span>
            </div>
          </div>
        </Row>

        <Row>
          <p className="font-size-15 py-3">Pricing built for any smart EV charging deployment. The plans below are for Spott Network Services.</p>
        </Row>

        <Row className="align-items-center">
          {SubscriptionCards.map(subCards => (
            <SubscriptionCard
              key={subCards.id}
              id={subCards.id}
              active={subCards.active}
              title={subCards.title}
              price={subCards.price}
              description={subCards.description}
              setActiveSubscription={setSubscriptionCards}
              plans={SubscriptionCards}
            />
          ))}
        </Row>

        <Row>
          <Col md={12}>
            <table className="subscribeComparsion">
              <thead>
                <tr>
                  <th>Plan details</th>
                  {SubscriptionCards.map(card => (
                    <th className={card.active ? "CardActive" : ""} key={card.id}>
                      <CardTableHeader
                        title={card.title}
                        price={card.price}
                        priceType={card.priceType}
                      />
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {tableContent.map(drawTr)}
                <tr>
                  <td className="label">Operations & maintenance²</td>
                  <td colSpan={Cards.length}>{SubscriptionSettings.operationCost}</td>
                </tr>
                <tr>
                  <td colSpan={Cards.length + 1}></td>
                </tr>
              </tbody>
            </table>
            <div className="obs">
              <p>{SubscriptionSettings.obs}</p>
            </div>
          </Col>
        </Row>

        <Row className="mt-5">
          <h3>Included in all plans</h3>
          <h5 className="m-0">
            All Spott software plans include the key features needed to get any EV charging service up and running.
          </h5>

          <Row className="includedPlan">

            <SubsctiptionBullets title="EV driver interfaces" icon={BoltIcon}>
              All chargers networked by Spott are accessible to EV drivers through our mobile app any third-party app we integrate with
            </SubsctiptionBullets>

            <SubsctiptionBullets title="Admin dashboard access" icon={BoltIcon}>
              Every ChargeLab plan comes with a configurable EV charging dashboard, plus access for an unlimited number of admin profile
            </SubsctiptionBullets>

          </Row>

          <Row className="includedPlan mb-4">

            <SubsctiptionBullets title="Admin dashboard access" icon={BoltIcon}>
              Every ChargeLab plan comes with a configurable EV charging dashboard, plus access for an unlimited number of admin profile
            </SubsctiptionBullets>

            <SubsctiptionBullets title="24/7 call center" icon={BoltIcon}>
              We provide end-user support for EV drivers using any Spott-networked charger. Our human agents are available over SMS, chat, or toll-free phone number.
            </SubsctiptionBullets>

          </Row>

        </Row>

      </Container>

    </div>
  );
}
