import styled from "styled-components";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  background-color: white;
  width: 100%;
  height: 32rem;
  margin: 0 auto;
  padding: 1rem;
  border: 1px solid transparent;
  border-radius: 0.32rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg {
    color: #666666;
    width: 2rem;
    height: 2rem;
  }

  p {
    margin: 1rem;
    color: #666666;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: none;
  }
`;
