import * as React from "react";
import * as Translator from "react-i18next";

const { Modal } = require("reactstrap");

import {
  ModalCloseButton,
  ModalFormContent,
  ModalFormField,
  ModalFormFooter,
  ModalFormHeader,
  ModalFormInputField,
  ModalSubmitButton,
} from "./modaladduser.styles";

export type modalFormTypes = {
  email: string;
  select: string;
};

type ModalAddUserPropsTypes = {
  t: Function;
  modalIsOpen: boolean;
  modalFormSubmit: (payload: modalFormTypes) => void;
  modalHandleClose: () => void;
};

export const ModalAddUser = ({
  t: translation,
  modalIsOpen,
  modalFormSubmit,
  modalHandleClose,
}: ModalAddUserPropsTypes) => {
  const handleSubmitModalForm = (
    event: React.FormEvent<HTMLFormElement>,
    fieldValues: modalFormTypes,
  ) => {
    event.preventDefault();
    modalFormSubmit(fieldValues);
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} size="lg" centered={false} fullscreen="sm">
        <ModalFormField onValidSubmit={handleSubmitModalForm}>
          <ModalFormHeader>
            <h1>{translation("permissions_ModalAddUserTitle")}</h1>
            <p>{translation("permissions_ModalAddUserText")}</p>
          </ModalFormHeader>

          <ModalFormContent>
            <ModalFormInputField
              required
              id={"email"}
              name={"email"}
              type={"email"}
              placeholder={translation(
                "permissions_ModalAddUserPlaceholderInput",
              )}
              validate={{
                required: {
                  value: true,
                  errorMessage: translation(
                    "permissions_ModalAddUserErrorInput",
                  ),
                },
              }}
            />

            <ModalFormInputField
              required
              id={"select"}
              name={"select"}
              type={"select"}
              validate={{
                required: {
                  value: true,
                  errorMessage: translation('permissions_ModalAddUserErrorInputType'),
                },
              }}
            >
              <option value="">
                {translation("permissions_ModalAddUserDroplistOptionDefault")}
              </option>
              <option value="admin">
                {translation("permissions_ModalAddUserDroplistOptionAdmin")}
              </option>
              <option value="viewer">
                {translation("permissions_ModalAddUserDroplistOptionViewer")}
              </option>
              <option value="engineer">
                {translation("permissions_ModalAddUserDroplistOptionEngineer")}
              </option>
              <option value="partner">
                {translation("permissions_ModalAddUserDroplistOptionPartner")}
              </option>
            </ModalFormInputField>
          </ModalFormContent>

          <ModalFormFooter>
            <ModalCloseButton
              type={"button"}
              activeColor={"grey"}
              onClick={modalHandleClose}
            >
              {translation("permissions_ModalAddUserButtonCancel")}
            </ModalCloseButton>

            <ModalSubmitButton type={"submit"} activeColor={"blue"}>
              {translation("permissions_ModalAddUserButtonAddUser")}
            </ModalSubmitButton>
          </ModalFormFooter>
        </ModalFormField>
      </Modal>
    </>
  );
};

export default Translator.withTranslation()(ModalAddUser);
