import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  color: #000000;
  width: 100%;
  margin: 0 auto;
  padding: 0.6rem;
  padding-bottom: 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.4rem;

  div {
    width: 70%;

    h4 {
      margin-bottom: 0.2rem;
      font-style: normal;
      font-weight: 700;
      font-size: 0.88rem;
      line-height: 1rem;
      text-align: left;
    }

    p {
      padding: 0;
      margin: 0;
      margin-bottom: 1rem;

      font-style: normal;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1.16rem;
      text-align: left;
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 0.88rem;
      line-height: 1rem;
      text-transform: uppercase;
    }
  }
`;
