import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import CreatePass from "../pages/Authentication/CreatePass";
import ForgetPass from "../pages/Authentication/ForgetPass";
import Help from "../pages/Authentication/Help";

//App
import ConfirmationEmailApp from "../pages/App/ConfirmationEmailApp/ConfirmationEmailApp";
import ForgotPasswordApp from "../pages/App/ForgotPasswordApp/ForgotPasswordApp";
import ConfirmationEmailAppOld from "../pages/App/Confirmation";
import ForgotPasswordAppOld from "../pages/App/ForgotPassAppOld";
import PrivacyPolicyApp from "../pages/App/PrivacyPolicyApp/PrivacyPolicyApp";
import TermsAndConditionsApp from "../pages/App/TermsAndConditionsApp/TermsAndConditionsApp";
import DeleteAccountApp from "../pages/App/DeleteAccountApp/deleteAccountApp";
import ConfirmAccountDeletionApp from "../pages/App/ConfirmAccountDeletionApp/confirmAccountDeletionApp";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import Welcome from "../pages/Welcome";
import Users from "../pages/Users/Users";
import Alerts from "../pages/Alerts";
import SettingsUserProfile from "../pages/Settings/UserProfile";
import SettingsPassword from "../pages/Settings/Password";
import SettingsAvatar from "../pages/Settings/Avatar";
import Support from "../pages/Support";

//Site
import EnergyConsumption from "../pages/EnergyConsumption";
import Finance from "../pages/Finance/Finance";
import Pricification from "../pages/Pricification/Pricification";
import Monitoring from "../pages/Monitoring/Monitoring";

import Performance from "../pages/Performance/Performance";
import Subscription from "../pages/Subscription";
import Payment from "../pages/Subscription/Payment";
import NewCharger from "../pages/Chargers/NewCharger";
import AllChargers from "../pages/Chargers/AllChargers";
import Chargers from "../pages/ChargersNew/Chargers";
import Transactions from "../pages/Transactions/Transactions";
import Permissions from "../pages/Permissions/Permissions";
import DisabledSites from "../pages/DisabledSites";
import EditSite from "../pages/EditSite";
// import SmartSpott from "../pages/SmartSpott/SmartSpott";
import SmartSpott from "../pages/SmartSpottNew/SmartSpott";

//New Site
import SiteInformation from "../pages/Newsite/SiteInformation";
import PaymentInformation from "../pages/Newsite/PaymentInformation";
import RegisterNewSite from "../pages/RegisterNewSite/RegisterNewSite";

// Others
import TempComponent from "../pages/temp/forgotPasswordApp";
import SignUp from "../pages/Authentication/SignUp";
import { Development } from "../pages/Development";
import SignUpSucess from "../pages/Authentication/SignUpSucess";

const userRoutes = [
  { path: "/dashboard/:site_id?", component: Dashboard },
  { path: "/smartspott/:site_id?", component: SmartSpott },
  { path: "/NewSite", component: RegisterNewSite },
  { path: "/EditSite/:site_id?", component: EditSite },
  { path: "/NewSite/PaymentInformation", component: PaymentInformation },
  {
    path: "/energy-consumption/:site_id?/:charger_id?",
    component: EnergyConsumption,
  },
  {
    path: "/monitoring/:site_id?/:charger_id?",
    component: Monitoring,
  },
  {
    path: "/monitorings",
    component: Monitoring,
  },
  { path: "/welcome", component: Welcome },
  { path: "/finance/:site_id", component: Finance },
  { path: "/pricification/:site_id", component: Pricification },
  { path: "/performance/:site_id/:charger_id?", component: Performance },
  { path: "/subscription/:site_id?", component: Subscription },
  { path: "/subscription/Payment/:site_id?", component: Payment },
  { path: "/Chargers/NewCharger/:site_id?", component: NewCharger },
  { path: "/Chargers/AllChargers/:site_id?", component: AllChargers },
  { path: "/chargers/:site_id?", component: Chargers },
  // { path: "/Users/UsersList/:site_id?", component: UsersList },
  { path: "/permissions/:site_id?", component: Permissions },
  { path: "/transactions/:site_id?", component: Transactions },
  { path: "/Alerts/:site_id?", component: Alerts },
  { path: "/DisabledSites/:site_id?", component: DisabledSites },

  { path: "/Settings/UserProfile/", component: SettingsUserProfile },
  { path: "/Settings/Password/", component: SettingsPassword },
  { path: "/Settings/Avatar/", component: SettingsAvatar },
  { path: "/Support/", component: Support },

  { path: "/users/:site_id", component: Users },

  { path: "/temp", component: TempComponent },
  { path: "/register-site-old", component: SiteInformation },

  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  // CMS auth routes
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/Help", component: Help },
  { path: "/create-password/:t?", component: CreatePass },
  { path: "/forgot-password", component: ForgetPass },
  { path: "/register", component: SignUp },
  { path: "/registerSucess", component: SignUpSucess },
  { path: "/register-user-old", component: Register },
  { path: "/development", component: Development },

  // App auth routes
  { path: "/confirmation-app", component: ConfirmationEmailApp },
  { path: "/confirmation-app-old", component: ConfirmationEmailAppOld },
  { path: "/forgot-password-app/:t?", component: ForgotPasswordApp },
  { path: "/forgot-password-app-old/:t?", component: ForgotPasswordAppOld },
  { path: "/conditions-app", component: TermsAndConditionsApp },
  { path: "/privacy-policy-app", component: PrivacyPolicyApp },
  { path: "/delete-account-app", component: DeleteAccountApp },
  { path: "/confirm-account-deletion-app/:t?", component: ConfirmAccountDeletionApp },
];

export { userRoutes, authRoutes };
