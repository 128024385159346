import * as React from "react";
import * as Translator from "react-i18next";
import { IoCopy } from "react-icons/io5";
import { transparentize, lighten } from "polished";

import { DataTableSettings } from "../../Chargers";
import * as Provider from "../../../../context/ContextProvider";
import { formatFullDateAndTimeHelper, } from "../../../../helpers/Helpers";
import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../../../components/Displays/DisplayLoading/DisplayLoading";
import ChargerStatus from "./components/ChargerStatus/ChargerStatus";
import { RowTypes } from "../../../../interfaces/DataTable";
import { ChargerTypes } from "../../../../interfaces/Chargers";
import { Component, Wrapper, Content, RenderElement, TableHeader } from "./datatable.styles";
import { customNumberFilterDataTable, customSortDatesChargers, customSortExclusiveUseChargers, customSortIdChargers, customSortStatusChargers } from "../../../../utils/filters-datatable";

type DatatablePropsTypes = {
  t: Function;
} & DataTableSettings;

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

export type DataRowTypes = {
  rowCpCode: RowTypes;
  rowChargerId: RowTypes;
  rowChargerName: RowTypes;
  rowStatus: RowTypes;
  rowIncludedDate: any;
  rowProfilePrice: RowTypes;
  rowExclusiveAccess: RowTypes;
  rowIcon: RowTypes;
  rowHided: RowTypes;
};

type Elements = { data: string, onClick: () => void };

type IconProps = { handleClick: () => void };

enum PriceProfile {
  DEFAULT = "DEFAULT_PRICE_PROFILE",
}

enum SelectedRow {
  NONE = "",
}

export const DataTableCustom = ({
  t: translation,
  data = [],
  loadingData = false,
  configureDisplayElement,
  handleSelectChargerId,
  handleOpenModalDeletion,
}: DatatablePropsTypes) => {

  const pageWidth = document.documentElement.scrollWidth;

  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");

  const [selectedElement, setSelectedElement] = React.useState<string>("");

  const [dataTableSettings, setDataTableSettings] = React.useState<DataTableTypes>({} as DataTableTypes);


  const customRowStyles = [
    {
      when: (row: DataRowTypes) => row.rowHided === selectedElement,
      style: {
        backgroundColor: `${transparentize(0.88, "#028b82")}`,
      },
    },
  ];

  const dataFaker = [
    data
  ]

  const dataTableColumns: TableColumn<DataRowTypes>[] =
    pageWidth >= 1500 ?
      [
        {
          name: translation("chargers_TabbleHeaderCPCode"),
          selector: (row: any) => row.rowCpCode,
          sortable: true,
          width: "102px",
          sortFunction: customNumberFilterDataTable,
        },
        {
          name: translation("chargers_TabbleHeaderChargerId"),
          selector: (row: any) => row.rowChargerId,
          sortable: true,
          width: "160px",
          sortFunction: customSortIdChargers,
        },
        {
          name: translation("chargers_TabbleHeaderChargerName"),
          selector: (row: any) => row.rowChargerName,
          sortable: true,
          width: "164px",
        },
        {
          name: translation("chargers_TabbleHeaderStatus"),
          selector: (row: any) => row.rowStatus,
          sortable: true,
          width: "160px",
          sortFunction: customSortStatusChargers,
        },
        {
          name: translation("chargers_TabbleHeaderIncludedDate"),
          selector: (row: any) => pageWidth >= 1500 ? row.rowIncludedDate.date : row.rowIncludedDate.dateToLower
          ,
          sortable: true,
          // width: "180px",
          sortFunction: customSortDatesChargers,
        },
        {
          name: translation("chargers_TabbleHeaderPriceProfile"),
          selector: (row: any) => row.rowProfilePrice,
          sortable: true,
          width: "160px",
        },
        {
          name: translation("chargers_TabbleHeaderExclusiveUse"),
          selector: (row: any) => row.rowExclusiveAccess,
          sortable: true,
          width: "141px",
          sortFunction: customSortExclusiveUseChargers,
        },
        {
          name: "",
          selector: (row: any) => row.rowIcon,
          sortable: false,
          width: "60px",
        },
        {
          name: "",
          selector: (row: any) => row.rowHided,
          sortable: false,
          width: "0px",
        },
      ] :
      [
        {
          name: translation("chargers_TabbleHeaderCPCode"),
          selector: (row: any) => row.rowCpCode,
          sortable: true,
          width: "102px",
          sortFunction: customNumberFilterDataTable,
        },
        {
          name: translation("chargers_TabbleHeaderChargerNameLower"),
          selector: (row: any) => row.rowChargerName,
          sortable: true,
          width: "130px",
        },
        {
          name: translation("chargers_TabbleHeaderStatus"),
          selector: (row: any) => row.rowStatus,
          sortable: true,
          width: "130px",
          sortFunction: customSortStatusChargers,
        },
        {
          name: translation("chargers_TabbleHeaderIncludedDateLower"),
          selector: (row: any) => pageWidth >= 1500 ? row.rowIncludedDate.date : row.rowIncludedDate.dateToLower
          ,
          sortable: true,
          width: "110px",
          sortFunction: customSortDatesChargers,
        },
        {
          name: translation("chargers_TabbleHeaderPriceProfileLower"),
          selector: (row: any) => row.rowProfilePrice,
          sortable: true,
          width: "120px",
        },
        {
          name: translation("chargers_TabbleHeaderExclusiveUseLower"),
          selector: (row: any) => row.rowExclusiveAccess,
          sortable: true,
          width: "100px",
          sortFunction: customSortExclusiveUseChargers,
        },
        {
          name: "",
          selector: (row: any) => row.rowIcon,
          sortable: false,
          width: "55px",
        },
      ]
    ;
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };

  // Functions
  const buildDataTable = async () => {
    const mutableChargerPointsData: ChargerTypes[] = structuredClone(data);

    if (!mutableChargerPointsData.length) return;

    const dataTableRows: DataRowTypes[] = mutableChargerPointsData.map(
      (charger: ChargerTypes) => {

        const dateFormatFull = formatFullDateAndTimeHelper(
          charger.cpAddedDate,
          state?.userData?.language,
        ) ?? emptyData;

        const [data, rest] = charger.cpAddedDate.split('T');
        const [year, month, day] = data.split('-');

        const datePTBR = `${day}/${month}/${year}`
        const dateENG = `${year}/${month}/${day}`

        const dateToLower = state?.userData?.language === 'pt-BR' ? datePTBR : dateENG;

        const date =
          formatFullDateAndTimeHelper(
            charger.cpAddedDate,
            state?.userData?.language,
          ) ?? emptyData;

        const fullDate = { dateToLower, date }

        const priceProfile =
          charger.priceProfileName === null ||
            charger.priceProfileName === PriceProfile.DEFAULT
            ? pageWidth >= 1500 ? translation("chargers_TabbleInfoDefaultPriceProfile") : translation("chargers_TabbleInfoDefaultPriceProfileLower")
            : charger.priceProfileName;

        return {
          rowCpCode: <Element data={String(charger.cpCode)}
            onClick={() => handleElementClick(charger.chargePointId)} />,
          rowChargerId: <Element data={charger.chargePointId}
            onClick={() => handleElementClick(charger.chargePointId)} />,
          rowChargerName: charger.name,
          rowStatus: <ChargerStatus chargerStatus={charger.cpStatus} />,
          rowIncludedDate: fullDate,
          rowProfilePrice: priceProfile,
          rowExclusiveAccess: charger.cpExclusive && <ExclusiveElement />,
          rowIcon: (
            <DeleteIcon
              handleClick={() => handleOpenModalDeletion(charger.chargePointId)}
            />
          ),
          rowHided: charger.chargePointId,
        };
      },
    );

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };

  const handleCopyElementData = (data: string) => {
    const settings = {
      displayShow: true,
      displayInfo: data,
      displayClipped: true,
    };

    navigator.clipboard.writeText(data);
    configureDisplayElement(settings);
  };

  const getRowInformation = (row: any) => {
    const rowChargerId = row.rowHided;

    rowChargerId.length > 7 ?
      setSelectedElement(
        selectedElement !== rowChargerId ? rowChargerId : {},
      ) : ""
    rowChargerId.length > 7 ?
      handleSelectChargerId(
        selectedElement !== rowChargerId ? rowChargerId : {},
      ) : ""

  };

  const handleElementClick = (row: any) => {
    const rowChargerId = row;

    rowChargerId.length > 7 ?
      setSelectedElement(
        selectedElement !== rowChargerId ? rowChargerId : {},
      ) : ""
    rowChargerId.length > 7 ?
      handleSelectChargerId(
        selectedElement !== rowChargerId ? rowChargerId : {},
      ) : ""
  };


  // Renders
  const Element = ({ data, onClick }: Elements): JSX.Element => {

    return (
      <>
        <RenderElement onClick={onClick}>
          <IoCopy
            onClick={() => handleCopyElementData(data)}
            onMouseOver={() => {
              const settings = {
                displayInfo: data,
                displayShow: true,
              };
              configureDisplayElement(settings);
            }}
            onMouseOut={() => {
              const settings = {
                displayInfo: data,
                displayShow: false,
              };
              configureDisplayElement(settings);
            }}
          />

          <span>{data}</span>
        </RenderElement>
      </>
    );
  };

  const ExclusiveElement = (): JSX.Element => {
    return (
      <>
        <div style={{ display: "flex", alignItems: "center" }}>
          <i
            className="mdi mdi-account-circle-outline font-size-24 color-black"
            style={{ marginRight: "0.4rem" }}
          ></i>
          {pageWidth >= 1500 && translation("chargers_TabbleInfoExclusiveUse")}
        </div>
      </>
    );
  };

  const DeleteIcon = ({ handleClick }: IconProps): JSX.Element => {
    return (
      <img
        className="delete-icon"
        src={require("../../../../assets/images/delete-close.png")}
        alt="Remove charger"
        style={{ cursor: "pointer" }}
        onClick={handleClick}
      />
    );
  };

  // Life cycle
  React.useMemo(() => {
    buildDataTable();
  }, [data, state?.userData?.language]);

  return (
    <>
      <Component>
        <Wrapper>
          <Content>
            <p>{translation("chargers_RegisteredChargers")}</p>

            {dataTableSettings.tableRows ?
              <DataTable
                columns={dataTableSettings.tableColumns}
                data={dataTableSettings.tableRows}
                defaultSortFieldId={5}
                defaultSortAsc={false}
                progressPending={loadingData}
                noDataComponent={<DisplayNoData />}
                progressComponent={<DisplayLoading />}
                selectableRows={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                dense={false}
                responsive
                striped
                highlightOnHover
                pointerOnHover
                fixedHeader
                persistTableHead
                pagination
                paginationDefaultPage={1}
                paginationComponentOptions={paginationSettings}
                onRowClicked={getRowInformation}
                conditionalRowStyles={customRowStyles}
              /> :
              <TableHeader>
                <p>{translation("chargers_TabbleHeaderCPCode")}</p>
                <p>{translation("chargers_TabbleHeaderChargerId")}</p>
                <p>{translation("chargers_TabbleHeaderChargerName")}</p>
                <p>{translation("chargers_TabbleHeaderStatus")}</p>
                <p>{translation("chargers_TabbleHeaderIncludedDate")}</p>
                <p>{translation("chargers_TabbleHeaderPriceProfile")}</p>
                <p>{translation("chargers_TabbleHeaderExclusiveUse")}</p>
              </TableHeader>
            }
          </Content>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(DataTableCustom);
