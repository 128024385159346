import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type CustomButtonPropsTypes = {
  buttonColor: "blue" | "dark" | "red" | "green";
  fontColor?: "dark" | "light";
};

const buttonColors = {
  blue: "#3970F3",
  dark: `${darken(0.48, "#edeeef")}`,
  red: "#ec4561",
  green: "#028b82",
};

const fontColors = {
  dark: `${darken(0.48, "#edeeef")}`,
  light: "#edeeef",
};

export const Component = styled.div``;

export const CustomButton = styled.button<CustomButtonPropsTypes>`
  background-color: ${({ buttonColor }) =>
    buttonColor ? buttonColors[buttonColor] : buttonColors["dark"]};
  color: ${({ fontColor }) =>
    fontColor ? fontColors[fontColor] : fontColors["dark"]};
  width: 16rem;
  height: 2.64rem;
  padding: 0.24rem;
  border: 1px solid transparent;
  border-radius: 0.24rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 0.88rem;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  transition: all 300ms;
  &:hover {
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.2;
  }
`;
