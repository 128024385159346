import styled from "styled-components";
import { lighten, transparentize } from "polished";

type FloatingDisplayPropsType = {
  showFloatingDisplay: boolean;
};

type ClickableButtonPropsType = {
  buttonDefaultColor?: "grey" | "blue";
  buttonWidth?: number;
};

type ClipboardPropsType = {
  isContentCopied?: boolean;
};

const ClickableButtonColors = {
  grey: "#6C6C6C",
  blue: "#3970F3",
};

const ClipboardButtonColors = {
  green: "#008000",
};

export const ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 1.8rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;

  h1 {
    color: #3970f3;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }

  p {
    color: #666666;
    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin: 1rem auto;
`;

export const QRCodeSection = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const QRCodeImage = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  p {
    color: #666666;
    margin: 0;
    padding: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    text-align: center;
  }
`;

export const QRCodeButtons = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.24rem;
`;

export const ModalLink = styled.div`
  color: #666666;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;

  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: row;

  position: relative;

  div {
    padding-bottom: 0.4rem;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h3 {
      width: 100%;
      font-style: normal;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 2rem;
      text-align: center;
    }

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
`;

export const ModalFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const FloatingDisplay = styled.div<FloatingDisplayPropsType>`
  background-color: ${transparentize(0.88, "#008000")};
  color: #666666;
  width: 100%;
  margin-top: 0.8rem;
  padding: 0.24rem;
  padding-left: 1rem;

  border: 1px solid ${transparentize(0.7, "#008000")};
  border-radius: 0.2rem;

  transition: all 300ms linear;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  opacity: ${({ showFloatingDisplay }) => (showFloatingDisplay ? 1 : 0)};
  /* display: ${({ showFloatingDisplay }) =>
    showFloatingDisplay ? "block" : "none"}; */

  svg {
    color: ${transparentize(0.32, "#008000")};
    margin-right: 0.2rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  span {
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1.24rem;
    text-align: left;
  }
`;

export const BaseButton = styled.button<ClickableButtonPropsType>`
  background-color: ${({ buttonDefaultColor }) =>
    buttonDefaultColor && ClickableButtonColors[buttonDefaultColor]};
  color: white;
  width: ${({ buttonWidth }) => buttonWidth || 8}rem;
  padding: 0.6rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.125);

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;
`;

export const CustomButton = styled(BaseButton)``;

export const CloseButton = styled(BaseButton)``;

export const ClipboardButton = styled(BaseButton)<ClipboardPropsType>`
  background-color: ${({ isContentCopied }) =>
    isContentCopied && transparentize(0.32, ClipboardButtonColors.green)};

  transition: all 300ms linear;
`;

export const QRCodePDFToPrint = styled(QRCodeImage)`
  position: absolute;
  inset: -100;

  p {
    color: black;
    font-style: normal;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 3.5rem;
    text-align: center;
    text-transform: uppercase;
  }
`;
