import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const { MetaTags } = require("react-meta-tags");

import { withTranslation } from "react-i18next";

import Header from "./components/Header/Header";
import Footer from "../../components/VerticalLayout/Footer";

import {
  Container,
  Display,
  Form,
  Label,
  Fields,
  AlignRadio,
  Aligninput,
} from "./signUpSuccess.styles";
import UsagePolicy from "./components/UsagePolicy/UsagePolicy";

type Profile = {
  email: string;
  fullname: string;
  phone: string;
  contact: "email" | "phone" | "whatsapp";
};

interface SignUp {
  t: Function;
  profile: Profile;
}

function SignUpSucess({ t }: SignUp) {
  const [profile, setProfile] = useState<Profile>();
  const [onFocusNameField, setOnFocusNameField] = useState<boolean>(false);
  const [onFocusEmailFieldm, setOnFocusEmailField] = useState<boolean>(false);
  const [onFocusPhoneFieldm, setOnFocusPhoneField] = useState<boolean>(false);
  const [onFocusContactFieldm, setOnFocusContactField] =
    useState<boolean>(false);

  return (
    <>
      <MetaTags>
        <title>{t("Register | Spott | Manage your recharge points")}</title>
      </MetaTags>

      <Container className="custom-bg">
        <Header />

        <Display>
          <h1>{t("Estamos quase la")}</h1>
          <h2>{t("SignUpSucessText")}</h2>

          <Fields>
            <Link to="/login" style={{ width: "100%" }}>
              <button type="button">{t("Back to Login")}</button>
            </Link>
          </Fields>
        </Display>
      </Container>
      <Footer loginFooter={true} />
    </>
  );
}

export default withTranslation()(SignUpSucess);
