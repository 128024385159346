import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type ComponentProps = {
  lowerView?: boolean
};

export const Component = styled.section<ComponentProps>`
  width: fit-content;
  height: -webkit-fill-available;
  margin-top: 31px;
`;

export const Wrapper = styled.div`
  background-color: ${lighten(0.042, "#e7e8ea")};
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

export const Header = styled.header`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0.24rem;

  display: flex;
  align-items: center;
  gap: 0.6rem;
  /* padding: 20px 0 0 20px; */

  p {
    color: ${darken(0.64, "#e7e8ea")};
    margin: 0;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1.24rem;

  p {
    color: #3970f3;
    /* color: ${darken(0.64, "#e7e8ea")}; */
    padding: 0;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const DisplayEmpty = styled.div`
  width: 100%;
  height: 30.8rem;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: ${darken(0.64, "#e7e8ea")};
    padding: 0;
    font-style: normal;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    font-weight: 500;
    text-decoration: none;
  }
`;

export const TestAligment = styled.div`
  width: 100%;
  background-color: red;
  padding: 1rem;
`;
