import "./WidgetDashboard.scss";
import React from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

const WidgetDashboard = ({ data, t, siteInfo }) => {
  if (data.loading === true) {
    return (
      <div className="pb-4 h-100">
        <Card
          className={`mini-stat bg-primary text-white text-center h-100 card-widget-consumer`}
        >
          <CardBody className="d-flex align-items-center justify-content-center">
            <Spinner>Loading...</Spinner>
          </CardBody>
        </Card>
      </div>
    );
  }

  const imgFolder = process.env.PUBLIC_URL + "/images/";
  const processTagType = (value) => {
    if (!value) return;
    if (value >= 0) return "success";
    if (value < 0) return "danger";
  };
  const processTagValue = (value) => {
    if (!value) return;
    const pre = value >= 0 ? "+ " : "- ";
    const pos = " %";
    return pre + value + pos;
  };
  const processArrow = (value) => {
    if (!value) return;
    if (value >= 0) return "mdi mdi-arrow-up text-success ";
    if (value < 0) return "mdi mdi-arrow-down text-danger";
  };

  return (
    <div className="pb-4 h-100">
      <Card
        className={`mini-stat bg-${
          data.widgetType ? data.widgetType : "primary"
        } text-white h-100 card-widget-consumer`}
      >
        <CardBody>
          <div className="mb-4 container-top">
            <div className="float-start mini-stat-img me-4">
              <img
                src={data.icon ? imgFolder + data.icon : ""}
                alt={data.title ? data.title : ""}
              />
            </div>
            <p className="text-white-50 widget-title">
              {data.title ? t(data.title.toUpperCase()) : ""}
            </p>
            <h4>
              {data.textResult ? data.textResult : ""}{" "}
              <i className={`${processArrow(data.tagValue)} ms-2`}></i>
            </h4>
            <div
              className={`mini-stat-label bg-${processTagType(data.tagValue)}`}
            >
              <p className="mb-0">
                {data.tagValue ? processTagValue(data.tagValue) : ""}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between align-items-end">
            <div className="tx-footer">
              <p className="text-white-50 mb-0 mt-1">
                {data.textFooter ? t(data.textFooter) : ""}
              </p>
            </div>
            {data?.csId !== "" ? (
              <div className="tx-link">
                <Link
                  to={data.link ? data.link + data?.csId : ""}
                  className="text-white-50 stretched-link"
                >
                  <i className="mdi mdi-arrow-right h5"></i>
                </Link>
              </div>
            ) : (
              ""
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(WidgetDashboard);
