import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Header = styled.header`
  background-color: #2168d3;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0;

  img {
    padding: 1rem;
    margin: 0 auto;
    display: block;
  }
`;

export const Display = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  border: 0;

  overflow-y: scroll;

  h2 {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
  }
`;

export const FooterPage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;
