import React from "react";
import { withTranslation } from "react-i18next";

import ChargerOwner from "../ChargerOwner/ChargerOwner";
import ChargerPricification from "../ChargerPricification/ChargerPricification";
import ChargerExclusive from "../ChargerExclusive/ChargerExclusive";
import ChargerDetails from "../ChargerDetails/ChargerDetails";
import ChargerQRCode from "../ChargerQRCode/ChargerQRCode";
import ChargerOptions from "../ChargerOptions/ChargerOptions";

import { Charger, ChargerTypes } from "../../../interfaces/Chargers";

interface ChargerView {
  t: Function;
  charger: ChargerTypes;
  onChargerUpdate: () => void;
  refreshChargerList: () => void;
}

function ChargerView({
  t,
  charger,
  onChargerUpdate,
  refreshChargerList,
}: ChargerView) {
  return (
    <div>
      <p className="title mb-4" style={{ fontWeight: "500", margin: "0" }}>
        {t("Charger Details")}
      </p>

      <hr></hr>

      <ChargerPricification charger={charger} />

      <ChargerExclusive charger={charger} />

      <ChargerDetails charger={charger} onRefresh={onChargerUpdate} />

      <ChargerQRCode charger={charger} />

      <ChargerOptions charger={charger} forceRefreshList={refreshChargerList} />
    </div>
  );
}

export default withTranslation()(ChargerView);
