import React from "react";

const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} = require("reactstrap");

import Success from "./Success";

import { withTranslation } from "react-i18next";

interface SucessModalProps {
  t: Function;
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

function SuccessModal({
  t: translation,
  message,
  isOpen,
  onClose,
}: SucessModalProps) {

  React.useEffect( () => {
    const handleKeyPress = (event: any) => {
      if( event.keyCode === 27 ){
        onClose();
      }
    }

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, [])

  // React.useEffect(() => {
  //   if (onClose)
  //     setTimeout(() => {
  //       onClose();
  //     }, 2500);
  // }, []);

  return (
    <Modal isOpen={isOpen} centered fullscreen="sm">
      <ModalHeader>
        <span className="text-primary">
          {translation("global_SuccessModalTitle")}
        </span>
      </ModalHeader>
      <ModalBody>
        <Success message={message} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>
          {translation("global_SuccessModalButtonClose")}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withTranslation()(SuccessModal);
