import React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as ContextProvider from "../../../../context/ContextProvider";
import { AxiosApi } from "../../../../helpers/api";

import { CustomInput } from "../CustomInput";
import { CustomButton } from "../CustomButton";
import { CustomModal as Modal } from "../CustomModal";

import ErrorModal from "../../../../components/Alerts/ErrorModal";
import SucessModal from "../../../../components/Alerts/SuccessModal";

import { BanksList as banklist } from "../../../../common/data/BanksList";
import { Button } from "../../../../components/Button";
import { InputLabel } from "../../../../components/Inputs";

// Interfaces and Types
import { Exception } from "../../../../interfaces/Exception";
import {
  ChargerSiteData,
  BillingPlanData,
} from "../../../../interfaces/ChargerSite";

import {
  Container,
  AlignContainer,
  Header,
  Display,
  Title,
  EnterprisePlan,
  EnterprisePlanTax,
  CustomTextButton,
  Description,
  BankData,
  Form,
  EstablishmentData,
  ResponsibleData,
  ModalContainer,
} from "./bankinformation.styles";
import { DroplistLabel } from "../../../../components/Droplists";
import { DroplistTypes } from "../../../../interfaces/Droplist";

type BankInformationPropsType = {
  t: Function;
};

type AccountDataStateType = {
  csBankCode: string;
  csBankAgency: string;
  csBankAccount: string;
  csBankDv: string;
  csBankAccountType: string;
};

export const BankInformation = ({
  t: translation,
}: BankInformationPropsType) => {
  const { site_id }: any = RouterDom.useParams();
  const { state }: any = ContextProvider.useStateContext();

  const pageWidth = document.documentElement.scrollWidth;

  const droplistOptionsBank: DroplistTypes = banklist.map((bank) => {
    return {
      name: bank.Banco,
      value: bank.Codigo,
    };
  });

  const droplistOptionsAccountType: DroplistTypes = [
    {
      name: translation("finance_BankInfoBankDataAccountTypeIndividual"),
      value: "1",
    },
    {
      name: translation("finance_BankInfoBankDataAccountTypeCorporate"),
      value: "2",
    },
  ];

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const [enableBankInputFields, setEnableBankInputFields] =
    React.useState<boolean>(false);
  const [accountData, setAccountData] = React.useState<AccountDataStateType>({
    csBankCode: "",
    csBankAgency: "",
    csBankAccount: "",
    csBankDv: "",
    csBankAccountType: "",
  });

  const [APIData, setAPIData] = React.useState<ChargerSiteData>(
    {} as ChargerSiteData,
  );
  const [billingPlanData, setBillingPlanData] = React.useState<BillingPlanData>(
    {} as BillingPlanData,
  );

  // Functions
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const formatBankAgencyValue = (agency: number): string => {
    const maxFieldChars = 4;
    return String(agency).padStart(maxFieldChars, "0");
  };

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (handleError || handleSuccess) return;

    const mutableApiData = { ...APIData };

    mutableApiData.csBankCode = accountData.csBankCode;
    mutableApiData.csBankAgency = accountData.csBankAgency;
    mutableApiData.csBankAccount = accountData.csBankAccount;
    mutableApiData.csBankDv = accountData.csBankDv;
    mutableApiData.csBankAccountType = accountData.csBankAccountType;

    try {
      const { status } = await AxiosApi.put<ChargerSiteData>(
        `/ChargerSites/${site_id}`,
        mutableApiData,
      );

      if (status === 204) {
        setHandleSuccess(true);
      }
    } catch (err: any) {
      console.log(err);

      setHandleError({
        isOpen: true,
        message: err?.response?.data,
        status: err?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      getApiData();
      setEnableBankInputFields(false);
    }
  };

  const getApiData = async () => {
    try {
      const { data: apiData } = await AxiosApi.get<ChargerSiteData>(
        `/ChargerSites/${site_id}`,
      );

      const billingPlanId = apiData.csBillingPlanId;
      const { data: userPlan } = await AxiosApi.get<BillingPlanData>(
        `BillingPlans/${billingPlanId}`,
      );

      setAPIData(apiData);
      setBillingPlanData(userPlan);

      setAccountData((prev) => ({
        csBankCode: String(apiData.csBankCode),
        csBankAgency: formatBankAgencyValue(Number(apiData.csBankAgency)),
        csBankAccount: String(apiData.csBankAccount),
        csBankDv: String(apiData.csBankDv),
        csBankAccountType: String(apiData.csBankAccountType),
      }));
    } catch (error: unknown) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!site_id || !state.sites) return;

    if (site_id && state.sites) {
      const pageSite = state.sites.find((site: any) => site.csId === site_id);
    }

    getApiData();
    setEnableBankInputFields(false);
  }, [site_id, state.sites]);

  return (
    <>
      <Container>
        <AlignContainer>
          <Header>
            <p>{translation("finance_BankInfoContractsYourSpottContract")}</p>
            <p>{translation("finance_BankInfoContractsContractDetails")}</p>
          </Header>

          <Display>
            <Title>
              <p>{translation("finance_BankInfoContractsContractData")}</p>
            </Title>

            <EnterprisePlan>
              <h2>{translation(billingPlanData.billingPlanName)}</h2>
              <div>
                <ul>
                  <li>
                    {translation("finance_BankInfoContractsAddAnyOCPPCharger")}
                  </li>
                  <li>{translation("finance_BankInfoContractsSmartSpott")}</li>
                  <li>
                    {translation("finance_BankInfoContractsDashboardAccess")}
                  </li>
                  <li>
                    {translation("finance_BankInfoContractsUserManagement")}
                  </li>
                  <li>
                    {translation("finance_BankInfoContractsTimeInsurance")}
                  </li>
                  <li>
                    {translation(
                      "finance_BankInfoContractsWebMobileIntegration",
                    )}
                  </li>
                  <li>{translation("finance_BankInfoContractsSupport")}</li>
                </ul>

                {/* <CustomButton
                  buttonText={translation(
                    "finance_BankInfoContractsChangePlanButton",
                  )}
                  buttonWidth={21}
                  buttonColor={"blue"}
                  onClick={handleOpenModal}
                /> */}

                {/* <Button buttonColor={"blue"} onClick={handleOpenModal}>
                  {translation("finance_BankInfoContractsChangePlanButton")}
                </Button> */}
              </div>

              {/* <CustomTextButton
                type="button"
                onClick={() => console.log("Termos e condições")}
              >
                {translation("finance_BankInfoContractsTermsAndConditions")}
              </CustomTextButton> */}
            </EnterprisePlan>

            <EnterprisePlanTax>
              <div>
                <h2>{translation("finance_BankInfoContractsPlanFees")}</h2>
                <ul>
                  <li>
                    {translation("finance_BankInfoContractsSpottComissions")}:{" "}
                    <span>{billingPlanData.billingPlanSpottFeePercent}%</span>
                  </li>
                  <li>
                    {translation("finance_BankInfoContractsTransactionFees")}:{" "}
                    <span>{billingPlanData.billingPlanFinFeePercent}%</span>
                  </li>
                </ul>
              </div>

              <div>
                <h2>{translation("finance_BankInfoContractsBusinessModel")}</h2>
                <ul>
                  <li>
                    R$ {billingPlanData.billingPlanMonthlyFee}/
                    {translation("finance_BankInfoContractsMonthlyPoint")}
                  </li>
                </ul>
              </div>

              {/* <div>
                <h2>{translation("finance_BankInfoContractsFullContract")}</h2>

                <CustomTextButton
                  type="button"
                  onClick={() => console.log("Baixar meu contrato")}
                >
                  {translation("finance_BankInfoContractsDownloadContract")}
                </CustomTextButton>
              </div> */}
            </EnterprisePlanTax>

            <Description>
              {translation("finance_BankInfoContractsBillingMonthlyFee")}
            </Description>
          </Display>

          <Display>
            <Title>
              <p>{translation("finance_BankInfoBankData")}</p>
            </Title>

            <BankData>
              <Form onSubmit={handleSubmitForm}>

                <DroplistLabel
                  required
                  style={{ width: '100%'}}
                  disabled={!enableBankInputFields}
                  label={translation("finance_BankInfoBankDataBank")}
                  options={droplistOptionsBank}
                  enablePrimaryOption={true}
                  primaryOptionName={translation(
                    "global_DroplistOptionDefault",
                  )}
                  value={accountData?.csBankCode}
                  onChange={(event) =>
                    setAccountData((current) => ({
                      ...current,
                      csBankCode: event.target.value,
                    }))
                  }
                />

                <div className="inputs-aligment">
                  <InputLabel
                    required
                    inputAllowOnlyNumbers
                    label={translation("finance_BankInfoBankDataAgency")}
                    disabled={!enableBankInputFields}
                    type={"text"}
                    placeholder={"0001"}
                    maxLength={4}
                    value={accountData?.csBankAgency}
                    onChange={(event) =>
                      setAccountData((current) => ({
                        ...current,
                        csBankAgency: event.target.value,
                      }))
                    }
                  />
                  <InputLabel
                    required
                    inputAllowOnlyNumbers
                    label={translation("finance_BankInfoBankDataAccount")}
                    disabled={!enableBankInputFields}
                    type={"text"}
                    placeholder={"237865r-2"}
                    maxLength={10}
                    value={accountData?.csBankAccount}
                    onChange={(event) =>
                      setAccountData((current) => ({
                        ...current,
                        csBankAccount: event.target.value,
                      }))
                    }
                  />

                  <InputLabel
                    required
                    inputAllowOnlyNumbers
                    label={translation("finance_BankInfoBankDataAccountDigit")}
                    disabled={!enableBankInputFields}
                    type={"text"}
                    placeholder={"0"}
                    maxLength={1}
                    value={accountData?.csBankDv}
                    onChange={(event) =>
                      setAccountData((current) => ({
                        ...current,
                        csBankDv: event.target.value,
                      }))
                    }
                  />
                </div>

                <DroplistLabel
                  required
                  disabled={!enableBankInputFields}
                  style={{width: '100%'}}
                  label={translation("finance_BankInfoBankDataAccountType")}
                  options={droplistOptionsAccountType}
                  enablePrimaryOption={true}
                  primaryOptionName={translation(
                    "global_DroplistOptionDefault",
                  )}
                  value={accountData?.csBankAccountType}
                  onChange={(event) =>
                    setAccountData((current) => ({
                      ...current,
                      csBankAccountType: event.target.value,
                    }))
                  }
                />

                <div className="buttons-aligment">
                  {!enableBankInputFields && (
                    <Button
                      buttonColor="blue"
                      type={"button"}
                      onClick={() => setEnableBankInputFields(true)}
                    >
                      {translation("finance_BankInfoBankDataChangeButton")}
                    </Button>
                  )}

                  {enableBankInputFields && (
                    <Button
                      buttonColor="blue"
                      type={"submit"}
                      onClick={() => setEnableBankInputFields(true)}
                    >
                      {translation("finance_BankInfoBankDataUpdateButton")}
                    </Button>
                  )}
                </div>
              </Form>
            </BankData>
          </Display>

          <Display>
            <Title>
              <p>{translation("finance_BankInfoEstablishmentData")}</p>
            </Title>

            <EstablishmentData>
              <InputLabel
                disabled
                type={"text"}
                label={translation(
                  "finance_BankInfoEstablishmentDataCorporateReason",
                )}
                placeholder={APIData.csCorporateName}
              />

              <div>
                <InputLabel
                  disabled
                  type={"text"}
                  label={"CNPJ"}
                  placeholder={String(APIData.csDocumentType)}
                />

                <InputLabel
                  disabled
                  type={"text"}
                  label={translation(
                    "finance_BankInfoEstablishmentDataBusinessType",
                  )}
                  placeholder={String(APIData.csBusinessType)}
                />
              </div>
            </EstablishmentData>
          </Display>

          <Display>
            <Title>
              <p>{translation("finance_BankInfoResponsibleData")}</p>
            </Title>

            <ResponsibleData>
              <div>
                <InputLabel
                  style={{ width: "34rem" }}
                  disabled
                  type={"text"}
                  label={translation("finance_BankInfoResponsibleDataFullName")}
                  placeholder={APIData.csContactName}
                />
              </div>

              <div className="div-more-informations">
                <InputLabel
                  disabled
                  type={"text"}
                  label={translation("finance_BankInfoResponsibleDataPhone")}
                  placeholder={APIData.csContactPhoneNumber}
                />

                <InputLabel
                  disabled
                  type={"text"}
                  label={"CPF"}
                  placeholder={APIData.csContactDocument}
                />

                <InputLabel
                  disabled
                  type={"text"}
                  label={"E-mail"}
                  placeholder={APIData.csContactEmail}
                />
              </div>
            </ResponsibleData>
          </Display>
        </AlignContainer>
      </Container>

      {/*Modals*/}
      <>
        {isModalOpen && (
          <Modal isModalOpen={isModalOpen} onRequestClose={handleCloseModal}>
            <ModalContainer>
              <h2>{translation("finance_BankInfoModalChangePlanTitle")}</h2>
              <p>{translation("finance_BankInfoModalChangePlanText")}</p>

              <div>
                <h3>{translation("finance_BankInfoModalChangePlanAdvisor")}</h3>
                <ul>
                  <li>Ricardo Legname</li>
                  <li>
                    <a href="tel:+5511989796288">
                      {translation("finance_BankInfoModalChangePlanPhone")} (11)
                      98979-6288
                    </a>
                  </li>
                  <li>
                    <a href="mailto:rica@spott.eco">rica@spott.eco</a>
                  </li>
                </ul>
              </div>
            </ModalContainer>
          </Modal>
        )}

        {handleError && <ErrorModal {...handleError} />}

        {handleSuccess && (
          <SucessModal
            isOpen={handleSuccess}
            onClose={() => setHandleSuccess(false)}
            message={translation("global_SuccessModalMessage")}
          />
        )}
      </>
    </>
  );
};
export default Translator.withTranslation()(BankInformation);
