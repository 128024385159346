import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;

  small {
    color: ${lighten(0.24, "#666666")};

    position: absolute;
    top: 0.6rem;
    right: 1rem;

    font-style: normal;
    font-weight: 400;
    font-size: 1.16rem;
    line-height: 1.16rem;
    text-align: right;

    cursor: pointer;
    transition: color 300ms;

    &:hover {
      color: #666666;
    }
  }

  strong {
    font-weight: 700;
    text-decoration: underline;
    text-underline-position: under;
  }
`;
