import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";
const { AvField, AvForm } = require("availity-reactstrap-validation");

type ModalButtonsPropsType = {
  activeColor: "grey" | "blue" | "red";
};

const ButtonColor = {
  grey: "#666",
  blue: "#3970F3",
  red: "#d10000",
};

export const ModalFormField = styled(AvForm)`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  display: grid;
  grid-gap: 1rem;
`;

export const ModalFormHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;
  display: grid;
  grid-gap: 1rem;

  h1 {
    color: #3970f3;

    font-style: normal;
    font-weight: 700;
    font-size: 1.48rem;
    line-height: 1.48rem;
    text-align: left;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.24rem;
    text-align: left;
  }
`;

export const ModalFormContent = styled.div`
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-gap: 1rem;
`;

export const ModalFormInputField = styled(AvField)`
  color: #666;
  width: 100%;
  height: 3.5rem;
  border-color: #edeeef;
  border-radius: 0.5rem;

  &::placeholder {
    color: #666;
  }
`;

export const ModalFormFooter = styled.footer`
  width: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const BaseButton = styled.button<ModalButtonsPropsType>`
  background-color: ${({ activeColor }) => ButtonColor[activeColor]};
  color: white;
  width: 13.5rem;
  padding: 1rem;
  margin-top: 2rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);

  font-style: normal;
  font-weight: 500;
  font-size: 0.88rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ModalSubmitButton = styled(BaseButton)``;
export const ModalCloseButton = styled(BaseButton)``;
