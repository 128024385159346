import styled from "styled-components";
import { darken, lighten, rgba } from "polished";

interface CardProps {
  isHovering: boolean;
}

export const Container = styled.section`
  width: 100%;
  height: 80vh;
  margin: 1.32rem auto;
  padding: 1.32rem;

  h1 {
    color: #8E8E8E;
    /* filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.18)); */
    font-family: "Gilroy", sans-serif;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: -4px;

  }

  p {
    font-family: "Gilroy", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
    color: #8E8E8E;
  }
`;

export const Card = styled.div<CardProps>`
  background-color: white;
  width: 32rem;
  height: fit-content;
  margin-top: 1rem;
  padding: 1rem;

  /* border: 1px solid rgba(0, 0, 0, 0.08); */
  border-radius: 0.5rem;
  position: relative;

  transition: all 300ms;

  /* &:hover {
    background-color: ${darken(0.035, "white")};
    border: 1px solid ${lighten(0.28, "#3970f3")};
  } */

  p {
    font-family: "Gilroy", sans-serif;
    font-size: 23px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
    color: #262729;

  }

  svg {
    color: ${({ isHovering }) =>
      isHovering ? lighten(0.08, "#3970f3") : rgba(0, 0, 0, 0.28)};
    width: 1.5rem;
    height: 1.5rem;

    position: absolute;
    bottom: 1rem;
    right: 1rem;

    transition: all 300ms;
  }

  div {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    h3 {
      font-family: "Gilroy", sans-serif;
      font-weight: 500;
      letter-spacing: 0px;
      text-align: left;
      font-size: 16px;

      b{
        font-weight: 600;
      }
    }
    p {
      font-family: "Gilroy", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 5px;
      letter-spacing: 0px;
      text-align: left;
      color: #3970f3;
      margin: 20px 0 0 0;
    }
    a {
      font-family: "Gilroy", sans-serif;
      font-size: 16px;
      font-weight: 500;
      line-height: 40px;
      letter-spacing: 0px;
      text-align: left;
      color: #3970f3;
    }
  }
`;
