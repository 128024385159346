import React from "react";

import { Wrapper, Display } from "./development.styles";

export const Development = () => {
  const [state, setState] = React.useState<any>();

  return (
    <>
      <Wrapper>
        <Display>
          <h1>Here things starts to grow!</h1>
        </Display>
      </Wrapper>
    </>
  );
};
