import styled from "styled-components";
import { lighten, rgba, transparentize } from "polished";

interface LabelProps {
  onInputFocus: boolean;
}

interface DisplayProps {
  isErrored?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100vh;

  margin: 0 auto;
  position: relative;
`;

export const Display = styled.div<DisplayProps>`
  background: white;
  width: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '26rem' : '29rem'};
  margin: 0 auto;
  margin-top: -1rem;
  padding: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '1.2rem' : '1.5rem'};
  z-index: 1;

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;

  p {
    color: #3970f3;
    font-family: "Gilroy", sans-serif;
    font-size: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '22px' : '29px'};
    font-weight: 500;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? 'center' : 'left'};
  }

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
`;

export const Label = styled.label<LabelProps>`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  &:first-child {
    margin-top: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '' : '1rem'};
  }

  span {
    margin: 0.5rem 0;
  }

  input {
    background: white;
    color: #6c757d !important;
    width: 92%;
    height: 2.5rem;
    padding-left: 1rem;

    border: 1px solid
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};
    border-radius: 0.25rem;
    box-shadow: 0 0 8px 0
      ${({ onInputFocus }) =>
        onInputFocus ? lighten(0.18, "#3970f3") : rgba(0, 0, 0, 0.1)};

    transition: all 300ms;
  }

  footer {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;

    Link{
      a{
      margin-top: 20px;
      text-decoration: none;
      font-family: "Gilroy", sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: right;
      color: #262729;
      }
    }
  }
`;

export const Fields = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;

  button {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    width: 100%;
    height: 42px;
    padding: 0.6rem;

    border: 0;
    border-radius: 0.5rem;

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 500;

    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

    transition: all 300ms;
    &:hover {
      filter: brightness(1.1);
    }
  }

  button[type="submit"] {
    background-color: #3970f3;

    &:hover {
      box-shadow: 0 0 8px 0 #3970f3;
    }
    a{
      font-family: "Gilroy", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
  button[type="button"] {
    background-color: #6C6C6C;
    margin-top: 0.5rem;

    &:hover {
      box-shadow: 0 0 8px 0 #6C6C6C;
    }
    a{
      font-family: "Gilroy", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
`;

export const ErrorBoundary = styled.div`
  background-color: ${transparentize(0.85, "red")};
  width: 100%;
  height: 4rem;
  margin: 0 auto;
  margin-top: 1rem;
  display: block;
  border: 1px solid ${transparentize(0.78, "red")};
  border-radius: 0.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: #5b626b;
    margin: 0;
    padding: 0;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 500;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height:  ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? 'fit-content' : '100vh'};
  padding-bottom:  ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? '10vh' : ''};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: -70px;
`;


export const AlignInput = styled.div`
  width: 100%;
  display: ${document.documentElement.scrollWidth <= 1366 && document.documentElement.scrollWidth >= 769 ? 'block' : ''};
  flex-direction: row;

  img{
    cursor: pointer;
  }
`;

export const LoginWithView = styled.div`
  height: 20px;
  margin-top: 2rem;
  display  : flex ;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const LineView = styled.div`
  /* border-bottom-color: #979797; */
  border-bottom-color: #000000;
  border-bottom-width: 1px;
  flex: 1;
  /* margin-top: 0.5rem; */
  width: 104px;
  height: 1px;
  background-color: #979797;
`;

export const SignText = styled.h1`
  font-family: "Gilroy", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: center;
  color: #262729;
  margin-left: 12px;
  margin-right: 12px;
`;

export const SocialAlign = styled.div`
  display: flex;
  margin-top: 12px;
  margin-left: 28px;
  margin-right: 28px;
  margin-bottom: 20px;
  flex-direction: row;
  justify-content: center;
  grid-gap: 20px;
`;

export const SocialButton = styled.button`
  background-color: #FFFFFF !important;
  border: none !important;

`;