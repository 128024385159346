import * as React from "react";
import * as Translator from "react-i18next";

type TogglerIconPropsType = {
  t: Function;
  icon: "Off" | "On";
};

const TogglerIcon = ({
  t: translation,
  icon = "Off",
}: TogglerIconPropsType) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 12,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {translation(`global_Symbol${icon}`)}
    </div>
  );
};

export default Translator.withTranslation()(TogglerIcon);
