import * as React from "react";
import * as Translator from "react-i18next";

import { Component, Background } from "./chargerstatus.styles";

type ChargerStatusPropsTypes = {
  t: Function;
  chargerStatus: string;
};

export type ChargerStatusTypes = "offline" | "online";

enum EChargerStatus {
  DEFAULT = "offline",
  OFFLINE = "offline",
  ONLINE = "online",
}

const ChargerStatus = ({
  t: translation,
  chargerStatus,
}: ChargerStatusPropsTypes) => {
  const chargerStatusDimp = String(chargerStatus).toLocaleLowerCase();
  const [status, setStatus] = React.useState<ChargerStatusTypes>("offline");

  React.useEffect(() => {
    if (chargerStatus === null) {
      setStatus("offline");
      return;
    }

    if (
      chargerStatusDimp === EChargerStatus.DEFAULT ||
      chargerStatusDimp === EChargerStatus.OFFLINE
    ) {
      setStatus("offline");
      return;
    }

    setStatus("online");
  }, [chargerStatus]);

  return (
    <>
      <Component>
        <Background chargerStatus={status} lowerView={true}>
          <span>{status}</span>
        </Background>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerStatus);
