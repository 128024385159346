import React from "react";
import { withTranslation } from "react-i18next";

const { AvForm, AvField } = require("availity-reactstrap-validation");

const { Modal, ModalBody } = require("reactstrap");

import { Charger } from "../../../interfaces/Chargers";
import { BasicModal } from "../../../interfaces/Modal";

import {
  ModalContainer,
  ModalHeader,
  ModalCloseButton,
} from "./chargerlog.styles";

interface ChargerLogBox extends BasicModal {
  t: Function;
  charger: Charger;
}

function ChargerLogBox({
  t: translator,
  charger,
  isOpen,
  onClose,
}: ChargerLogBox) {
  return (
    <Modal isOpen={isOpen} centered fullscreen="sm" size={"lg"}>
      <ModalContainer>
        <ModalHeader className="border-0">
          <span className="text-primary">
            {translator("chargers_ModalChargerLogInformationInv")}
          </span>
          <ModalCloseButton onClick={onClose}>X</ModalCloseButton>
        </ModalHeader>

        <ModalBody>
          <AvForm className="form-horizontal">
            <div className="mb-3">
              <textarea
                name="logBox"
                className="form-control"
                placeholder={translator(
                  "chargers_ModalChargerLogNoLogsToDisplay",
                )}
                rows={10}
                disabled
              />
            </div>
          </AvForm>
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
}

export default withTranslation()(ChargerLogBox);
