import "./UserProfile.scss";
import React, { useState, useEffect, useRef } from "react";
import CustomBreadCrumb from "../../components/BreadCrumb";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Button,
  Card,
  CardBody,
  Label,
  FormGroup,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { AxiosApi } from "../../helpers/api";
import { ModalComponent } from "../../components/Modal";
import { FormatDateHelper } from "../../helpers/Helpers";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import SuccessModal from "../../components/Alerts/SuccessModal";

import * as Provider from '../../context/ContextProvider'
//import Tabs from 'components/Tabs';

const Password = (props) => {

  const { state } = Provider.useStateContext();

  const [userLanguage, setUserLanguage] = React.useState("");
  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  const [userData, setUserData] = useState({
    oldpass: "",
    newpass: "",
    newpassconfirm: "",
  });
  const [modalConfig, setModalConfig] = useState({ open: false });
  const formRef = useRef();

  const inputHandleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const saveProfileHandler = (e) => {
    e.preventDefault();
    const payload = {
      oldpassword: userData.oldpass,
      newpassword: userData.newpass,
    };
    console.log(payload);
    if (
      userData.oldpass === "" ||
      userData.newpass === "" ||
      userData.newpassconfirm === ""
    )
      return;
    const resp = AxiosApi.post(`/ChangePassword`, payload)
      .then((response) => {
        setHandleSuccess(true);
      })
      .catch((error) => {
        openModalError();
      });
    setUserData({ oldpass: "", newpass: "", newpassconfirm: "" });
    formRef.current.reset();
  };

  const modalCloseHandler = () => setModalConfig({ open: false });

  const openModalSuccess = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Password change successfully"),
      body: props.t(`Your password has been changed successfully.`),
      btOkText: "Ok",
      btOkStyle: "success",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  const openModalError = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Error creating user"),
      body: props.t(
        `There was an error trying to change your password, please try again.`,
      ),
      btOkText: "Ok",
      btOkStyle: "danger",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  const visible = require("../../assets/images/visible.png");
  const nonVisible = require("../../assets/images/visible_off.png");

  const [handleSuccess, setHandleSuccess] = React.useState(false);

  const [passwordData, setPasswordData] = useState({
    isShowLastPassword: false,
    isShowNewPassword: false,
    isShowRepeatNewPassword: false,
  })

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb
                siteName={props.t("Settings")}
                title={props.t("Password")}
                // subtitle={`${props.t("Last update")}: ${FormatDateHelper(
                //   Date.now(),
                // )}`}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <div className="w-100">
            <ul className="nav nav-tabs w-100">
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/UserProfile/`, state: {} }}
                >
                  {props.t("User Profile")}
                </Link>
              </li>
              <li className="nav-item actived">
                <span className={`nav-link active`}>{props.t("Password")}</span>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/Avatar/`, state: {} }}
                >
                  {props.t("Add photo")}
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </Container>

      <Row>
        <Col xl={12} className="p-3 pt-0">
          <p className="info-text">{props.t("settings_UserProfileText")}</p>
          <AvForm ref={formRef}>
            <Card>
              <CardBody className="">
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col xl={12} className="p-3 d-flex align-items-center">
                        <div style={{width: '100%', display: 'grid', gridTemplateColumns: 'auto 30px', alignItems: 'center'}}>
                          <AvField
                            name="oldpass"
                            label={props.t("Your password")}
                            className="form-control"
                            placeholder={props.t("Insert your password")}
                            type={passwordData.isShowLastPassword ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your password",
                                ),
                              },
                            }}
                            value={userData.oldpass}
                            onChange={(e) => inputHandleChange(e)}
                          />
                          <img
                            width={'18px'} height={passwordData.isShowLastPassword ? '15px' : '13px'}
                            src={passwordData.isShowLastPassword ? nonVisible : visible}
                            alt="eye"
                            style={{ marginLeft: 10, marginTop: 30, cursor: 'pointer' }}
                            onClick={() => {
                              setPasswordData({...passwordData, isShowLastPassword: !passwordData.isShowLastPassword});
                            }}
                          />  
                        </div>
                        
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} className="p-3 d-flex align-items-center">
                      <div style={{width: '100%', display: 'grid', gridTemplateColumns: 'auto 30px', alignItems: 'center'}}>
                        <AvField
                            name="newpass"
                            label={props.t("New password")}
                            className="form-control"
                            placeholder={props.t("Insert your new password")}
                            type={passwordData.isShowNewPassword ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform your new password",
                                ),
                              },
                              minLength: {
                                value: 6,
                                errorMessage: props.t(
                                  "You must use 6 or more characters",
                                ),
                              },
                            }}
                            value={userData.newpass}
                            onChange={(e) => inputHandleChange(e)}
                          />
                          <img
                            width={'18px'} height={passwordData.isShowNewPassword ? '15px' : '13px'}
                            src={passwordData.isShowNewPassword ? nonVisible : visible}
                            alt="eye"
                            style={{ marginLeft: 10, marginTop: 30, cursor: 'pointer' }}
                            onClick={() => {
                              setPasswordData({...passwordData, isShowNewPassword: !passwordData.isShowNewPassword});
                            }}
                          />  
                      </div>
                        
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} className="p-3 d-flex align-items-center">
                        <div style={{width: '100%', display: 'grid', gridTemplateColumns: 'auto 30px', alignItems: 'center'}}>
                          <AvField
                            name="newpassconfirm"
                            label={props.t("Confirm your new password")}
                            className="form-control"
                            placeholder={props.t("Insert again your new password")}
                            type={passwordData.isShowRepeatNewPassword ? "text" : "password"}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Your must inform confirm your new password",
                                ),
                              },
                              match: {
                                value: "newpass",
                                errorMessage: props.t("Password do not mach"),
                              },
                            }}
                            value={userData.newpassconfirm}
                            onChange={(e) => inputHandleChange(e)}
                          />
                          <img
                            width={'18px'} height={passwordData.isShowRepeatNewPassword ? '15px' : '13px'}
                            src={passwordData.isShowRepeatNewPassword ? nonVisible : visible}
                            alt="eye"
                            style={{ marginLeft: 10, marginTop: 30, cursor: 'pointer' }}
                            onClick={() => {
                              setPasswordData({...passwordData, isShowRepeatNewPassword: !passwordData.isShowRepeatNewPassword});
                            }}
                          />  
                        </div>
                        
                      </Col>
                    </Row>
                    <div className="text-end mt-4">
                      <Button
                        type="submit"
                        id="save-profile"
                        onClick={(e) => saveProfileHandler(e)}
                        color="primary"
                        className="ms-3"
                      >
                        {props.t("CHANGE PASSWORD")}
                      </Button>
                    </div>
                  </Col>

                  <Col xl={4} className="p-3">
                    <div>
                      <h4>{props.t("Choosing a Password")}</h4>
                      <p>
                        {props.t("A good passaword consist of:")}
                        <br />• {props.t("6 or more characters")}
                        <br />• {props.t("Mixture os letters and numbers")}
                        <br />• {props.t("Mixture of upper and lowcase")}
                        <br />• {props.t("Special characters")}
                        <br />• {props.t("Non-dictionary words")}
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </AvForm>
        </Col>
        {handleSuccess && 
          <SuccessModal
            isOpen={handleSuccess}
            onClose={() => setHandleSuccess(false)}
            message={userLanguage === 'pt-BR' ? 'Dados atualizados com sucesso' : 'Data updated successfully'}
          />
        }
        <ModalComponent config={modalConfig} />
      </Row>
    </div>
  );
};

export default withTranslation()(Password);
