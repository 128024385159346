import React from "react";
const { Modal } = require("reactstrap");
import { withTranslation } from "react-i18next";

import { formatChargerLabel } from "../../../../../utils/format";

import { Charger } from "../../../../../interfaces/Chargers";

import {
  ModalContainer,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
} from "./chargerdetailed.styles";

type ChargerDetailedPropsType = {
  t: Function;
  charger: Charger;
  isOpen: boolean;
  onHandleClose: () => void;
};

export const ChargerDetailed = ({
  t: translator,
  charger,
  isOpen,
  onHandleClose,
}: ChargerDetailedPropsType) => {
  const renderedDetails = (cpBootData: any) => {
    if (!cpBootData) return;

    const keys = Object.keys(JSON.parse(charger.cpBootData));
    const values = Object.values(JSON.parse(charger.cpBootData));

    let content: JSX.Element[] = [];

    keys.forEach((key, index) => {
      const newKeyValue =
        key && formatChargerLabel(key).trim().split(" ").join("");

      content.push(
        <p>
          {translator(`chargers_ChargerInfo${newKeyValue}`)}:
          <span>
            {values[index] ? values[index] : translator("Uninformed")}
          </span>
        </p>,
      );
    });

    return content;
  };

  return (
    <>
      <Modal isOpen={isOpen} size="lg" centered fullscreen="sm">
        <ModalContainer>
          <ModalHeader>
            <h1>
              {translator("chargers_ChargerInfoTitle")} {`${charger.cpCode}`}
            </h1>
            <ModalCloseButton onClick={onHandleClose}>X</ModalCloseButton>
          </ModalHeader>

          <ModalContent>
            <p>
              ID:{" "}
              <span>
                {charger.chargePointId
                  ? charger.chargePointId
                  : translator("Uninformed")}
              </span>
            </p>

            <p>
              {translator("chargers_ChargerInfoName")}:{" "}
              <span>
                {charger.name ? charger.name : translator("Uninformed")}
              </span>
            </p>

            <p>
              {translator("chargers_ChargerInfoComment")}:{" "}
              <span>
                {charger.comment ? charger.comment : translator("Uninformed")}
              </span>
            </p>

            {renderedDetails(charger.cpBootData)}
          </ModalContent>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default withTranslation()(ChargerDetailed);
