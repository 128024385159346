import React from "react";
import { withTranslation } from "react-i18next";

const { Button } = require("reactstrap");

import ChargerUpdate from "./components/ChargerUpdate";
import ChargerDetailed from "./components/ChargerDetailed/ChargerDetailed";

import { Charger } from "../../../interfaces/Chargers";
import { formatChargerLabel } from "../../../utils/format";

import {
  Container,
  DisplayDetails,
  ButtonsAligment,
} from "./chargerdetails.styles";

interface ChargerDetails {
  t: Function;
  charger: Charger;
  onRefresh: () => void;
}

function ChargerDetails({ t: translator, charger, onRefresh }: ChargerDetails) {
  const [update, setUpdate] = React.useState<boolean>(false);
  const [showChargerDetailed, setShowChargerDetailed] =
    React.useState<boolean>(false);

  return (
    <>
      <Container>
        <DisplayDetails>
          <p>
            {translator("chargers_ChargerInfoName")}:{" "}
            <span>
              {charger.name ? charger.name : translator("Uninformed")}
            </span>
          </p>

          <p>
            {translator("chargers_ChargerInfoComment")}:{" "}
            <span>
              {charger.comment ? charger.comment : translator("Uninformed")}
            </span>
          </p>

          <p>
            {translator("chargers_ChargerInfoModel")}:{" "}
            <span>
              {charger.cpCm.cmName &&  charger.cpCm.cmName !== 'Unknown'
                ? charger.cpCm.cmName
                : translator("Uninformed")}
            </span>
          </p>

          <p>
            {translator("chargers_ChargerInfoPower")}:{" "}
            <span>
              {charger.cpCm.cmMaxPower
                ? charger.cpCm.cmMaxPower + " kW"
                : translator("Uninformed")}
            </span>
          </p>
        </DisplayDetails>

        <ButtonsAligment>
          <Button
            color="primary"
            className="mt-2 px-5 btn-sm rounded-button"
            onClick={() => setShowChargerDetailed(true)}
          >
            <span>+ {translator("chargers_ChargerInfoMoreDetails")}</span>
          </Button>

          <Button
            color="primary"
            className="mt-2 px-5 btn-sm rounded-button"
            onClick={() => setUpdate(true)}
          >
            <span>{translator("chargers_ChargerInfoChangeInfo")}</span>
          </Button>
        </ButtonsAligment>
      </Container>

      {showChargerDetailed && (
        <ChargerDetailed
          charger={charger}
          isOpen={showChargerDetailed}
          onHandleClose={() => setShowChargerDetailed(false)}
        />
      )}

      {update && (
        <ChargerUpdate
          isOpen={update}
          charger={charger}
          onClose={() => setUpdate(false)}
          onRefresh={() => onRefresh()}
        />
      )}
    </>
  );
}

export default withTranslation()(ChargerDetails);
