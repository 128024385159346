import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = ({ loginFooter }) => {
  const attClass = loginFooter ? ' footer__login' : null;

  return (
    <React.Fragment>
      <footer className={'footer' + attClass}>
        <Container fluid={true}>
          <Row>
            <div className="col-12">
              Copyright © 2022 Spott All rights reserved.
            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
