import * as React from "react";
import * as Translator from "react-i18next";

import { Module } from "../Module/Module";
import ModalQRCode from "./components/ModalQRCode/ModalQRCode";

import { ChargerPointTypes } from "../../../../../../interfaces/Chargers";

import { Component, Wrapper, Content, List } from "./chargerconnectors.styles";

type ChargerConnectorsPropsTypes = {
  t: Function;
  selectedCharger: ChargerPointTypes;
};

type ModalQRCodeTypes = {
  modalIsOpen: boolean;
  modalCpCode: string;
  modalOnRequestClose: () => void;
};

const ChargerConnectors = ({
  t: translation,
  selectedCharger,
}: ChargerConnectorsPropsTypes) => {
  const charger: ChargerPointTypes = selectedCharger;

  const [modalQRCode, setModalQRCode] = React.useState<ModalQRCodeTypes>({
    modalIsOpen: false,
    modalCpCode: "",
    modalOnRequestClose: (): void => {},
  });

  // Functions
  const prepareQRCodeModal = (settings: ModalQRCodeTypes) => {
    setModalQRCode({ ...settings });
  };
  const onRequestCloseQRCodeModal = () => {
    setModalQRCode((prev) => ({
      ...prev,
      modalIsOpen: false,
    }));
  };

  return (
    <>
      <Component>
        <Module>
          <Wrapper>
            <Content>
              <div>
                <p>
                  <strong>
                    {translation("chargers_ChargerQRCodeConnectorsTextStrong")}
                  </strong>{" "}
                  {translation("chargers_ChargerQRCodeConnectorsText")}
                </p>
              </div>

              <List>
                {!charger.cpCm.chargerModelsConnectors.length && (
                  <li>
                    <i className="mdi mdi-qrcode-scan font-size-35"></i>
                    <div>
                      <h2></h2>
                      <p>{translation("global_NoDataToDisplay")}</p>
                    </div>
                  </li>
                )}

                {!!charger.cpCm.chargerModelsConnectors.length && (
                  <>
                    {charger.cpCm.chargerModelsConnectors.map((connector) => {
                      return (
                        <li
                          key={`${charger.cpCode}-${connector.connectorId}`}
                          onClick={() => {
                            const settings: ModalQRCodeTypes = {
                              modalIsOpen: true,
                              modalCpCode: `${charger.cpCode}-${connector.connectorId}`,
                              modalOnRequestClose: onRequestCloseQRCodeModal,
                            };

                            prepareQRCodeModal(settings);
                          }}
                        >
                          <i className="mdi mdi-qrcode-scan font-size-35"></i>
                          <div>
                            <h2>
                              {charger.cpCode}-{connector.connectorId}
                            </h2>
                            <p>{connector.cct.cctName}</p>
                          </div>
                        </li>
                      );
                    })}
                  </>
                )}
              </List>
            </Content>
          </Wrapper>
        </Module>

        {modalQRCode.modalIsOpen && <ModalQRCode {...modalQRCode} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerConnectors);
