import "./AlertsDashboard.scss";
import React from "react";
import { Card, CardBody, Spinner, Row } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { withTranslation } from "react-i18next";

const AlertsDashboard = ({ data, title, t }) => {
  if (!Array.isArray(data)) {
    return (
      <div className="pb-4 h-100">
        <Card className="alerts-dashboard mb-0">
          <CardBody>
            <p className="card-title">{t("Alerts")}</p>
            <Row>
              <div className="mt-2 mb-3">{t("Error loading data.")}</div>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (data.length === 0) {
    return (
      <div className="pb-4 h-100">
        <Card className="h-100">
          <CardBody >
            {/* <Spinner>{t("Loading...")}</Spinner> */}
            <p className="card-title">{t("NoAlert")}</p>
          </CardBody>
        </Card>
      </div>
    );
  }

  const dataFormat = (datastring) => {
    const datajs = new Date(datastring);
    const processDay = (day) => {
      const hoje = new Date();
      if (day.getDate() === hoje.getDate()) return "Hoje";
      if (day.getDate() === hoje.getDate() - 1) return "Ontem";
      return day.getDate() + "/" + day.getMonth();
    };

    const result = {
      dia: processDay(datajs),
      hora: datajs.getHours(),
      min: datajs.getMinutes() === 0 ? "" : data.js.getMinutes(),
    };
    return `${result.dia} - ${result.hora}h${result.min}`;
  };

  return (
    <div className="pb-4 h-100">
      <Card className="alerts-dashboard mb-0">
        <CardBody>
          {title ? <p className="mb-4 mt-0 card-title">{title}</p> : null}
          <div className={`mb-4 card-content-scroll`}>
            <SimpleBar className="h-100">
              <ol className="activity-feed">
                {/* {!data && <div>No data to display</div>} */}

                {data.map((item, idx) => {
                  return (
                    <li className="feed-item" key={idx}>
                      <div
                        className={`feed-item-list ${
                          item.isNew ? "isNew" : null
                        }`}
                      >
                        <span className="date">
                          {dataFormat(item.alertDate)}
                        </span>
                        <span className="activity-text">
                          {item.message} <br />
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ol>
            </SimpleBar>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(AlertsDashboard);
