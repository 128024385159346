import * as React from "react";

import { Component } from "./input.styles";

type InputPropsTypes = {
  inputAllowOnlyNumbers?: boolean;
  inputPreventPasting?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const Input = ({
  inputAllowOnlyNumbers = false,
  inputPreventPasting = false,
  ...rest
}: InputPropsTypes) => {
  const checkPressedKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (!/[0-9]/.test(event.key) && inputAllowOnlyNumbers) {
      event.preventDefault();
    }
  };

  const checkPasted = (event: React.ClipboardEvent<HTMLInputElement>) => {
    if (inputPreventPasting) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <>
      <Component>
        <input {...rest} onKeyPress={checkPressedKey} onPaste={checkPasted} />
      </Component>
    </>
  );
};
