import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";

export const ModalComponent = ({ config }) => {
  return (
    <Modal isOpen={config.open} centered fullscreen="sm">
      {config.title ? (
        config.btCancelText !== "" ? (
          <ModalHeader toggle={() => config.closeHandler()}>
            <span className={`text-${config.btOkStyle}`}>{config.title}</span>
          </ModalHeader>
        ) : (
          <ModalHeader>
            <span className={`text-${config.btOkStyle}`}>{config.title}</span>
          </ModalHeader>
        )
      ) : null}
      <ModalBody>{config.body ? config.body : ""}</ModalBody>
      <ModalFooter>
        <Button
          color={config.btOkStyle}
          onClick={() => {
            config.functionHandler(config.value);
          }}
        >
          {config.btOkText}
        </Button>{" "}
        {config.btCancelText !== "" ? (
          <Button onClick={() => config.closeHandler()}>
            {config.btCancelText}
          </Button>
        ) : (
          ""
        )}
      </ModalFooter>
    </Modal>
  );
};
