import { withTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import appStoreImg from "../../assets/images/apple-store.png";
import googlePlayImg from "../../assets/images/google-play.png";
import appImg from "../../assets/images/mvp-app.png";
import "./BannerDashboard.scss";

const BannerDashboard = ({ data, t }) => {
  return (
    <div className="pb-4 h-100">
      <Card className="text-center banner-dashboard card-container">
        {/* <CardBody> */}
        <div className="card-content">
          <header>
            <h2 className="banner-title">{t(`Download our new App!`)}</h2>
          </header>

          <div className="store-links">
            <a
              href="https://www.apple.com/br/app-store/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={appStoreImg} alt="app-store" />
            </a>

            <a
              href="https://play.google.com/store/games?hl=pt_BR&gl=US"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlayImg} alt="play-store" />
            </a>
          </div>

          <img src={appImg} alt="our-app" className="app-image" />
        </div>
        {/* </CardBody> */}
      </Card>
    </div>
  );
};

export default withTranslation()(BannerDashboard);
