import * as React from "react";

import { ModaActionlTypes } from "../../../interfaces/Modal";

import { ModalWrapper } from "./modalaction.styles";

const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} = require("reactstrap");

export const ModalAction = ({
  modalIsOpen,
  modalTitle,
  modalInfo,
  modalButtonConfirmText,
  modalButtonCancelText,
  modalLoadingRequisition = false,
  modalOnConfirmAction,
  modalOnRequestClose,
}: ModaActionlTypes) => {
  return (
    <>
      <Modal isOpen={modalIsOpen} centered fullscreen="sm">
        <ModalWrapper>
          {!modalLoadingRequisition && (
            <small onClick={modalOnRequestClose}>x</small>
          )}

          {modalTitle && (
            <ModalHeader>
              <span className={"text-danger"}>{modalTitle}</span>
            </ModalHeader>
          )}

          {modalInfo && <ModalBody>{modalInfo}</ModalBody>}

          <ModalFooter>
            {modalLoadingRequisition && (
              <>
                <Spinner size="sm" className="text-primary"></Spinner>
              </>
            )}

            {!modalLoadingRequisition && (
              <>
                <Button color={"danger"} onClick={modalOnConfirmAction}>
                  {modalButtonConfirmText}
                </Button>

                <Button onClick={modalOnRequestClose}>
                  {modalButtonCancelText}
                </Button>
              </>
            )}
          </ModalFooter>
        </ModalWrapper>
      </Modal>
    </>
  );
};
