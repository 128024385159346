import * as React from "react";
import { Container, Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import * as Provider from "../../context/ContextProvider";
import {
  FormatDateHelper,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import { URL_USERSITES, AxiosApi } from "../../helpers/api";

import TabSite from "../../components/TabSite";
import CustomBreadCrumb from "../../components/BreadCrumb";
// import AlertsDashboardOld from "../../components/Dashboard/AlertsDashboard";
import AlertsDashboard from "../../components/Dashboard/AlertsDashboard/AlertsDashboard.tsx";

import "./Style.scss";

const Alerts = (props) => {
  const { site_id } = useParams();
  const { context, state } = Provider.useStateContext();

  const [userLanguage, setUserLanguage] = React.useState("");
  const [siteInfo, setSiteInfo] = React.useState();
  const [sites, setSites] = React.useState([]);
  const [alertsDashboardState, setAlertsDashboardState] = React.useState([]);

  const [fetchingData, setFetchingData] = React.useState(false);

  const pageAlerts = [
    {
      alertDate: new Date(),
      isNew: true,
      message: "Something",
    },
  ];

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  // React.useEffect(() => {
  //   const effects = async () => {
  //     if (state.sites) {
  //       setSites(state.sites);

  //       if (!site_id) setAlertsDashboardState(state.alerts);

  //       if (site_id) {
  //         const alertsApi = await AxiosApi.get("UserAlerts/" + site_id);
  //         setAlertsDashboardState(alertsApi.data);
  //       }
  //       maskAsRead(site_id ? site_id : null);
  //     }
  //   };
  //   effects();
  // }, [site_id, state.sites]);

  React.useEffect(() => {
    const fetchAPIData = async () => {
      try {
        setFetchingData(true);
        const { data } = await AxiosApi.get("UserAlerts/" + site_id);

        if (state.sites) {
          setSites(state.sites);

          if (!site_id && state.alerts !== null)
            setAlertsDashboardState(state.alerts);

          if (site_id && data.length > 0 && data !== null) {
            setAlertsDashboardState(data);
          }

          maskAsRead(site_id && site_id);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setFetchingData(false);
      }
    };
    fetchAPIData();
  }, [state.sites, site_id]);

  const maskAsRead = (siteId) => {
    //call api to mark isNew = false;
  };

  const getSiteName = (id) => {
    if (sites.length === 0) return;
    const resp = sites.map((site) => {
      if (site.csId === id) return site.csName;
    });
    return resp;
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={12}>
              <CustomBreadCrumb
                siteName={props.t("Alerts")}
                title={site_id ? getSiteName(site_id) : props.t("All")}
                // subtitle={`${props.t(
                //   "Last update",
                // )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
              />
            </Col>
          </Row>
        </div>
      </Container>

      <TabSite tabs={sites} pathname="Alerts" className={"Tabs__Alerts"}>
        <Row>
          <Col xl={12}>
            <AlertsDashboard
              data={alertsDashboardState}
              isLoadingData={fetchingData}
            />
          </Col>
        </Row>
      </TabSite>
    </div>
  );
};

export default withTranslation()(Alerts);
