import styled from 'styled-components';

export const Titlediv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h1{
    font-family: "Gilroy", sans-serif;
    color: #3970F3;
    font-size: 22px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
  }
  a{
    color: #666666;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
  }
`;

export const BodyDiv = styled.div`

  p{
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    color: #484552;
    a{
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    color: #484552;
    }
  }
`;

export const ButtonDiv = styled.div`

  button[type="submit"]{
    margin-top: 2rem;
    height: 52px;
    width: 455px;
    border-radius: 12px;
    background-color: #FFFFFF;
    border: 1px solid #979797;

    a{
      font-size: 17px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.4px;
      text-align: center;
      color: #262729;
    }
  }
  button[type="back"]{
    margin-top: 2rem;
    height: 44px;
    width: 455px;
    border-radius: 8px;
    background-color: #6F7173;
    a{
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: center;
      text-transform: uppercase;
      color: #FFFFFF;
    }
  }
`;


