import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

 export function usersPdfReport(titlePdf:string, dataTableColumns:any, dataTableRows:any, fileName:string){

  pdfMake.vfs = pdfFonts.pdfMake.vfs
  
  const titleTable = dataTableColumns.map((res:any) => {
    return {text:res.name, style:'tableHeader', fontSize: 8}
  })
 

  const dataTable = dataTableRows.map((res:any) => {
    return [
      {text:res.rowName, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowUserName, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowComplement, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowChargers, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowTransactions, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowKwh, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowDuration, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
      {text:res.rowAmount, style:'tableHeader', fontSize: 8, margin: [0,2,0,2]},
    ]
  })

  const reportData = [
    {
      table: {
        headerRows: 1,
        widths: [ 80, 80, 80, 50, 50, 40, 40, 40 ],
        body: [
          titleTable,
          ...dataTable
          
        ],
        layout: "headerLineOnly"
      }
    }
  ]

  const documentDefinitions:any = {
    pageMargins: 25 ,
    content: [
      {text:`${titlePdf}`, style: 'header', alignment: 'center',},
      reportData,
      // Defina o nome do arquivo desejado
  
    ],
    footer: {
      alignment: 'justify',
      columns: [
        {text: '' },
        {text: 'Spott', alignment: 'center', },
        {text: '' },
      ]
    },
    
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        margin:10
      },
    },
  }
  


  pdfMake.createPdf(documentDefinitions).download(fileName);

  return
}

