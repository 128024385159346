import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";
import { formatFullDateAndTimeHelper } from "../../helpers/Helpers";

import DataTable, { TableColumn } from "react-data-table-component";
import DisplayNoData from "../../components/Displays/DisplayNoData/DisplayNoData";
import { DisplayLoading } from "../../components/Displays/DisplayLoading/DisplayLoading";

import { AuthUserTypes } from "../../interfaces/AuthUser";
import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { RowTypes } from "../../interfaces/DataTable";
import ConnectorStatus from "./components/ConnectorStatus/ConnectorStatus";
import {
  SiteConnectorsMonitoringConnectorsTypes,
  SiteConnectorsMonitoringTypes,
} from "../../interfaces/SiteConnectors";

import {
  Component,
  Wrapper,
  Header,
  Content,
  GreenBallDiv,
  GreenBall,
} from "./monitoring.styles";
import { customSortStatusMonitoring } from "../../utils/filters-datatable";

type MonitoringPropsTypes = {
  t: Function;
};

type DataTableTypes = {
  tableColumns: TableColumn<DataRowTypes>[];
  tableRows: Array<DataRowTypes>;
};

type DataRowTypes = {
  rowAvailability: RowTypes;
  rowCpcode: RowTypes;
  rowLaststatus: RowTypes;
  rowName: RowTypes;
  rowChargerid: RowTypes;
  rowStatus: RowTypes;
};

export const Monitoring = ({ t: translation }: MonitoringPropsTypes) => {
  const currentUser: AuthUserTypes = JSON.parse(
    localStorage.getItem("authUser")!,
  );
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { state }: any = Provider.useStateContext();
  const emptyData: string = translation("global_NoDataToDisplay");

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );

  const [loadingData, setLoadingData] = React.useState<boolean>(true);
  const [siteConnectorsData, setSiteConnectorsData] = React.useState<
    SiteConnectorsMonitoringTypes[]
  >([]);

  const [dataTableSettings, setDataTableSettings] = React.useState<DataTableTypes>({} as DataTableTypes);

  

  const dataTableColumns: TableColumn<DataRowTypes>[] = 
    site_id ? [
    {
      name: translation("monitoring_TableHeaderCpCode"),
      selector: (row: any) => row.rowCpcode,
      sortable: true,
    },
    {
      name: translation("monitoring_TableHeaderChargerId"),
      selector: (row: any) => row.rowChargerid,
      sortable: true,
    },
    {
      name: translation("monitoring_TableHeaderChargerName"),
      selector: (row: any) => row.rowName,
      sortable: true,
    },
    {
      name: translation("monitoring_TableHeaderStatus"),
      selector: (row: any) => row.rowStatus,
      sortable: true,
      sortFunction: customSortStatusMonitoring,
    },
    {
      name: translation("monitoring_TableHeaderLastStatus"),
      selector: (row: any) => row.rowLaststatus,
      sortable: true,
    },
    {
      name: translation("monitoring_TableHeaderAvailability"),
      selector: (row: any) => row.rowAvailability,
      sortable: false,
    }
    ]
    :
    [
      {
        name: translation("monitoring_TableHeaderSite"),
        selector: (row: any) => row.csName,
        sortable: true,
      },
      {
        name: translation("monitoring_TableHeaderCpCode"),
        selector: (row: any) => row.rowCpcode,
        width: "110px",
        sortable: true,
      },
      {
        name: translation("monitoring_TableHeaderChargerId"),
        selector: (row: any) => row.rowChargerid,
        sortable: true,
      },
      {
        name: translation("monitoring_TableHeaderChargerName"),
        selector: (row: any) => row.rowName,
        sortable: true,
      },
      {
        name: translation("monitoring_TableHeaderStatus"),
        selector: (row: any) => row.rowStatus,
        width: "160px",
        sortable: true,
        sortFunction: customSortStatusMonitoring,
      },
      {
        name: translation("monitoring_TableHeaderLastStatus"),
        selector: (row: any) => row.rowLaststatus,
        sortable: true,
      },
      {
        name: translation("monitoring_TableHeaderAvailability"),
        selector: (row: any) => row.rowAvailability,
        sortable: false,
      },
    ];
  const paginationSettings = {
    rowsPerPageText: translation("global_TableFooterPaginationItem"),
    rangeSeparatorText: translation("global_TableFooterSeparator"),
    selectAllRowsItem: true,
    selectAllRowsItemText: translation("global_TableFooterAll"),
  };

  // Functions
  const RenderAvailability = (value:any): JSX.Element => {
    const elements = []
    for (let count = 1; count <= 7; count++) {
      elements.push(Math.trunc((Number(value.avaibility) * 7.4) / 100) >= count && <GreenBall />)
    }
    return (
      <GreenBallDiv>
        {elements}
        {Number(value.avaibility) + "%"}
      </GreenBallDiv>
    );
  };

  const buildDataTable = () => {
    const mutableSiteConnectorsData: SiteConnectorsMonitoringTypes[] =
      structuredClone(siteConnectorsData);

    
    const dataTableRows: DataRowTypes[] = mutableSiteConnectorsData.map(
      (charger: SiteConnectorsMonitoringTypes) => {
        const chargerCpCode = charger.cpCode ?? emptyData;
        const chargerLastStatus: JSX.Element[] = charger.connectors.map((
          chargerConnector: SiteConnectorsMonitoringConnectorsTypes,
          index,
        ) => {
            return (
              <div
                key={`${charger.cpCode}-${index}`}
                style={{ width: "100%", height: "100%", margin: "1rem auto" }}
              >
                {formatFullDateAndTimeHelper(
                  chargerConnector.statusTimestamp,
                  state?.userData?.language,
                ) ?? emptyData}
              </div>
            );
          },
        );
        const chargerName = charger.name ?? emptyData;
        const chargerPointId = charger.chargePointId ?? emptyData;
        const chargerConnectorStatus: JSX.Element[] = charger.connectors.map(
        (
          chargerConnector: SiteConnectorsMonitoringConnectorsTypes,
          index,
        ) => {
            return (
              <div
                key={`${charger.cpCode}-${index}`}
                style={{ marginTop: "0.16rem" }}
              >
                <ConnectorStatus
                  chargerStatus={charger.cpStatus}
                  connectorStatus={chargerConnector.connectorStatus}
                />
              </div>
            );
        });

        const chargerAvailability = charger.cpAvailability ?? emptyData;
        const csName = charger.csName;

        return {
          rowAvailability: <RenderAvailability avaibility={chargerAvailability}  />,
          csName: csName,
          rowCpcode: chargerCpCode,
          rowLaststatus: chargerLastStatus,
          rowName: chargerName,
          rowChargerid: chargerPointId,
          rowStatus: chargerConnectorStatus,
        };
      },
    );

    setDataTableSettings((prev) => ({
      ...prev,
      tableColumns: dataTableColumns,
      tableRows: dataTableRows,
    }));
  };

  // API data
  const getSiteConnectorsData = async () => {
    try {
      const { data } = await api.get<SiteConnectorsMonitoringTypes[]>(
        `/SiteConnectorsMonitoring/${site_id ? site_id : ''}`,
      );

      const mutableData: SiteConnectorsMonitoringTypes[] =
        structuredClone(data);
      const sortedData: SiteConnectorsMonitoringTypes[] = mutableData.sort(
        (dataA, dataB) => {
          if (dataA.cpCode > dataB.cpCode) return 1;
          if (dataA.cpCode < dataB.cpCode) return -1;

          return 0;
        },
      );

      buildDataTable();
      setSiteConnectorsData(sortedData);
    } catch (error: unknown) {
      console.log(error);
    } finally {
      setLoadingData(false);
    }
  };

  React.useEffect( () => {

    setLoadingData(true)

    setTimeout( () => {
      setLoadingData(false);
    }, 100)
    
    getSiteConnectorsData();
  }, [site_id]);

  // Life cycle
  React.useMemo(() => {
    if ( site_id && !state.sites.length) return;
    if ( site_id && !site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
  }, [state.sites, site_id]);

  React.useMemo(() => {
    if (!siteConnectorsData.length) return;

    buildDataTable();
    // setLoadingData(false);
  }, [siteConnectorsData, state?.userData?.language]);

  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              {site_id ? 
                <span>
                  {siteAccess?.csName} <span>/</span>
                </span>
                :
                " "
              }
              {translation("monitoring_PageTitle")}
            </h1>
          </Header>

          <Content>
            <p>{translation("monitoring_Info")}</p>

            <div className={"data-table"}>
              {loadingData === false && dataTableSettings.tableRows &&
              <DataTable
                columns={dataTableSettings.tableColumns}
                data={dataTableSettings.tableRows}
                defaultSortFieldId={site_id ? 4 : 5}
                defaultSortAsc={true}
                progressPending={loadingData}
                noDataComponent={<DisplayNoData/>}
                progressComponent={<DisplayLoading />}
                selectableRows={false}
                selectableRowsVisibleOnly={false}
                selectableRowsHighlight={false}
                dense={false}
                responsive
                striped
                highlightOnHover
                // pointerOnHover
                fixedHeader
                persistTableHead
                pagination
                paginationDefaultPage={1}
                paginationComponentOptions={paginationSettings}
              />
}
            </div>
          </Content>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Monitoring);
