import "./Style.scss";
import { IoIosMail } from "react-icons/io";
import CustomBreadCrumb from "../../components/BreadCrumb";
import Tabs from "../../components/Tabs";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardBody,
} from "reactstrap";
import { FormatDateHelper } from "../../helpers/Helpers";
import { withTranslation } from "react-i18next";

const Support = (props) => {
  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row>
            <Col md={8}>
              <CustomBreadCrumb
                siteName={props.t("Support")}
                siteNameStyle={{ color: "#8E8E8E", fontSize: "25px" }}
                title={""}
                // subtitle={`${props.t("Last update")}: ${FormatDateHelper(
                //   Date.now(),
                // )}`}
                className="breadcrumb-component"
              />
            </Col>
          </Row>
        </div>
      </Container>

      <Card className="widget-suport" style={{ width: "40rem" }}>
        <CardBody>
          <header>
            <h1 className="h1-support-page-title">
              {props.t("Precisa de ajuda?")}
            </h1>
          </header>

          <p className="content-section">
            {props.t(
              "If you have questions about management your chargers or how to our system, talk to us!",
            )}
          </p>

          <footer className="support-footer">
            <IoIosMail />
            <a href="mailto:evspott@evspott.com?subject=Olá Spott!">
              <span className="content-section">spott@spott.eco</span>
            </a>
          </footer>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(Support);
