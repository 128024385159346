import React, { useState, useEffect } from "react";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { Container, Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";

import { AxiosApi } from "../../helpers/api";
import {
  FormatDateHelper,
  formatCurrency,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import { useStateContext } from "../../context/ContextProvider";

//Components
import WidgetDashboard from "../../components/Dashboard/WidgetDashboard";
import StatusChargers from "../../components/Dashboard/StatusChargers";
import MotlyPreferencesUse from "../../components/Dashboard/MotlyPreferencesUse";
import ChargersUsageLoad from "../../components/Dashboard/ChargersUsageLoad";
import AlertsDashboard from "../../components/Dashboard/AlertsDashboard";
import SitesRevenueGraph from "../../components/Dashboard/SitesRevenueGraph";
import BannerDashboard from "../../components/Dashboard/BannerDashboard";
import WidgetSupport from "../../components/Dashboard/WidgetSupport";
import SpottTips from "../../components/Dashboard/SpottTips";
import { ModalComponent } from "../../components/Modal";
import CustomTitlePage from "../../components/CustomTitlePage";
import DropdownMenuRight from "../../components/DropdownMenuRight";

//data
import { WidgetSupportData } from "../../common/data/DashboardData";

const Dashboard = (props) => {
  const history = useHistory();
  const { site_id } = useParams();
  const { context, state } = useStateContext();

  const [confirmationConfig, setConfirmationConfig] = useState({ open: false });
  const [userLanguage, setUserLanguage] = useState("");

  const [pageTitle, setPageTitle] = useState("");
  const [pageInfo, setPageInfo] = useState({});
  const [siteInfo, setSiteInfo] = useState({});

  // Dashboard states
  const [cosumersWidget, setConsumersWidget] = useState({
    loading: true,
  });
  const [revenueWidget, setRevenueWidget] = useState({ loading: true });
  const [energyConsumptionWidget, setEnergyConsumptionWidget] = useState({
    loading: true,
  });
  const [transactionsWidget, setTransactionsWidget] = useState({
    loading: true,
  });
  const [chargerStatusBoard, setChargerStatusBoard] = useState({
    loading: true,
  });
  const [allDataLoaded, setAllDataLoaded] = useState(true);
  const [userPreferenceBoard, setUserPreferenceBoard] = useState(null);
  const [usageLoadBoard, setUsageLoadBoard] = useState(null);
  const [revenueBoard, setRevenueBoard] = useState(null);
  const [alertsBoard, setAlertsBoard] = useState([]);

  const [revenueData, setRevenueData] = React.useState({
    value: 0,
    variation: 0,
  });

  const [energyConsumptionData, setEnergyConsumptionData] = React.useState({
    value: 0,
    variation: 0,
  });

  // Life cycle
  useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  useEffect(() => {
    !site_id && setSiteInfo({ csName: "", csId: "" });

    if (site_id && state.sites) {
      const getDashboardSiteByID = state.sites.find(
        (targetSite) => targetSite.csId === site_id,
      );

      !getDashboardSiteByID && history.push("/");
      getDashboardSiteByID && setSiteInfo({ ...getDashboardSiteByID });
    }

    // if (site_id && state.sites.length > 0) {
    //   const siteInfoFilter = state.sites.filter(
    //     (site) => site.csId === site_id,
    //   );
    //   if (siteInfoFilter.length === 0) history.push("/"); //site_id não encontrado
    //   setSiteInfo(siteInfoFilter[0]);
    // }
    // }, [site_id, state.sites]);
  }, [site_id]);

  useEffect(() => {
    setPageTitle(() => {
      if (siteInfo?.csName) {
        return props.t("Total sites information (Last 30 days)");
      }
      return `${siteInfo.csName} ${props.t("(Last 30 days)")}`;
    });

    setPageInfo({
      submenu: {
        active: true,
        buttom: { label: "Site settings", color: "primary" },
        items: [
          { label: "Edit site info", link: `/EditSite/${siteInfo?.csId}` },
          { label: "", link: "" },
          {
            label: "Disable site",
            link: disableSiteConfirm,
            color: "danger",
            linkType: "function",
          },
        ],
      },
    });

    const getApiData = async () => {
      if (siteInfo?.csId !== undefined) {
        const asyncFunctions = [
          getRevenueWidget(),
          getEnergyConsumptionWidget(),
          getConsumersTotalWidget(),
          getTransactionsWidget(),
          getChargerStatusBoard(),
          getUsePreferencesBoard(),
          getUsageLoadBoard(),
          getRevenueBoard(),
          getAlertsBoard(),
        ];

        await Promise.all(asyncFunctions);
      }
      setAllDataLoaded(false);
    };

    getApiData();
  }, [siteInfo]);

  React.useEffect(() => {
    const mutableRevenueData = { ...revenueData };

    setRevenueWidget({
      icon: "widgetRevenueIco.png",
      title: "REVENUE",
      textResult: formatCurrency(mutableRevenueData.value, userLanguage),
      tagValue: mutableRevenueData.variation,
      loading: mutableRevenueData.value === 0 ? true : false,
      textFooter: "Last 30 days",
      link: "/performance/",
      csId: siteInfo?.csId,
    });
  }, [revenueData, userLanguage]);

  React.useEffect(() => {
    const mutableEnergyData = { ...energyConsumptionData };

    const values = Number(mutableEnergyData.value).toFixed(2);
    const strValues = String(values);

    const replacedPT = strValues.replace(".", ",");
    const replacedUS = strValues.replace(",", ".");

    setEnergyConsumptionWidget({
      icon: "widgetEnergyConsumptionIco.png",
      title: "Energy Consumption",
      textResult: (userLanguage === "pt-BR" ? replacedPT : replacedUS) + " kWh",
      loading: mutableEnergyData.value === 0 ? true : false,
      tagValue: mutableEnergyData.variation,
      textFooter: "Last 30 days",
      link: "/energy-consumption/",
      csId: siteInfo?.csId,
    });
  }, [energyConsumptionData, userLanguage]);

  // Functions => Get API Data
  const getEnergyConsumptionWidget = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/EnergyConsumptionTotal/${siteInfo?.csId}`,
      );

      setEnergyConsumptionData(data);

      // setEnergyConsumptionWidget({
      //   icon: "widgetEnergyConsumptionIco.png",
      //   title: "Energy Consumption",
      //   textResult: data.value.toLocaleString(userLanguage, {
      //     minimumFractionDigits: 1,
      //     maximumFractionDigits: 1,
      //   }),
      //   tagValue: data.variation,
      //   textFooter: "Last 30 days",
      //   link: "/energy-consumption/",
      //   csId: siteInfo?.csId,
      // });
    } catch {
      setEnergyConsumptionWidget({
        icon: "widgetEnergyConsumptionIco.png",
        title: "Energy Consumption",
        textResult: 0,
        tagValue: 0,
        textFooter: "Error loading data",
      });
    } finally {
      setEnergyConsumptionWidget((prev) => ({ ...prev, loading: allDataLoaded }));
    }

    // const data = await AxiosApi.get(
    //   `metrics/EnergyConsumptionTotal/${siteInfo?.csId}`,
    // )
    //   .then((response) => {
    //     setEnergyConsumptionWidget({
    //       icon: "widgetEnergyConsumptionIco.png",
    //       title: "Energy Consumption",
    //       textResult: response.data.value.toLocaleString(userLanguage, {
    //         minimumFractionDigits: 1,
    //         maximumFractionDigits: 1,
    //       }),
    //       tagValue: response.data.variation,
    //       textFooter: "Last 30 days",
    //       link: "/energy-consumption/",
    //       csId: siteInfo?.csId,
    //     });
    //   })
    //   .catch((error) => {
    //     setEnergyConsumptionWidget({
    //       icon: "widgetEnergyConsumptionIco.png",
    //       title: "Energy Consumption",
    //       textResult: 0,
    //       tagValue: 0,
    //       textFooter: "Error loading data",
    //     });
    //   });
  };

  const getRevenueWidget = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/RevenueTotal/${siteInfo?.csId}`,
      );

      setRevenueData(data);
    } catch {
      setRevenueWidget({
        icon: "widgetRevenueIco.png",
        title: "REVENUE",
        textResult: 0,
        tagValue: 0,
        textFooter: "Last 30 days",
      });
    } finally {
      setRevenueWidget((prev) => ({ ...prev, loading: allDataLoaded }));
    }
  };

  const getConsumersTotalWidget = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/ConsumersTotal/${siteInfo?.csId}`,
      );

      setConsumersWidget({
        icon: "widgetConsumerIco.png",
        title: "Performance",
        textResult: data.value.toFixed(0),
        tagValue: data.variation,
        textFooter: "Last 30 days",
        link: "/performance/",
        csId: siteInfo?.csId,
      });
    } catch (err) {
      setConsumersWidget({
        icon: "widgetConsumerIco.png",
        title: "Performance",
        textResult: 0,
        tagValue: 0,
        textFooter: "Last 30 days",
      });
    } finally {
      setConsumersWidget((prev) => ({ ...prev, loading: allDataLoaded }));
    }

    // const data = await AxiosApi.get(`metrics/ConsumersTotal/${siteInfo?.csId}`)
    //   .then((response) => {
    //     setConsumersWidget({
    //       icon: "widgetConsumerIco.png",
    //       title: "Consumers",
    //       textResult: response.data.value.toFixed(0),
    //       tagValue: response.data.variation,
    //       textFooter: "Last 30 days",
    //       link: "/ConsumersList/",
    //       csId: siteInfo?.csId,
    //     });
    //   })
    //   .catch((error) => {
    //     setConsumersWidget({
    //       icon: "widgetConsumerIco.png",
    //       title: "Consumers",
    //       textResult: 0,
    //       tagValue: 0,
    //       textFooter: "Last 30 days",
    //     });
    //   });
  };

  const getTransactionsWidget = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/TransactionsTotal/${siteInfo?.csId}`,
      );

      setTransactionsWidget({
        icon: "widgetTrnasactionsIco.png",
        title: "Transactions",
        textResult: data.value.toFixed(0),
        tagValue: data.variation,
        textFooter: "Last 30 days",
        link: "/transactions/",
        csId: siteInfo?.csId,
      });
    } catch {
      setTransactionsWidget({
        icon: "widgetTrnasactionsIco.png",
        title: "Transactions",
        textResult: 0,
        tagValue: 0,
        textFooter: "Last 30 days",
      });
    } finally {
      setTransactionsWidget((prev) => ({ ...prev, loading: allDataLoaded }));
    }

    // const data = await AxiosApi.get(
    //   `metrics/TransactionsTotal/${siteInfo?.csId}`,
    // )
    //   .then((response) => {
    //     setTransactionsWidget({
    //       icon: "widgetTrnasactionsIco.png",
    //       title: "Transactions",
    //       textResult: response.data.value.toFixed(0),
    //       tagValue: response.data.variation,
    //       textFooter: "Last 30 days",
    //       link: "/ConsumersList/",
    //       csId: siteInfo?.csId,
    //     });
    //   })
    //   .catch((error) => {
    //     setTransactionsWidget({
    //       icon: "widgetTrnasactionsIco.png",
    //       title: "Transactions",
    //       textResult: 0,
    //       tagValue: 0,
    //       textFooter: "Last 30 days",
    //     });
    //   });
  };

  const getChargerStatusBoard = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/ChargerStatus/${siteInfo?.csId}`,
      );
      setChargerStatusBoard(data);
    } catch {
      setChargerStatusBoard({ error: true });
    } finally {
      setChargerStatusBoard((prev) => ({ ...prev, loading: allDataLoaded }));
    }

    // const data = await AxiosApi.get(`metrics/ChargerStatus/${siteInfo?.csId}`)
    //   .then((response) => {
    //     setChargerStatusBoard(response.data);
    //   })
    //   .catch((error) => {
    //     setChargerStatusBoard({ error: true });
    //   });
  };

  const getUsePreferencesBoard = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/UsePeference/${siteInfo?.csId}`,
      );
      setUserPreferenceBoard(data);
    } catch {
      setUserPreferenceBoard([]);
    }

    // const data = await AxiosApi.get(`metrics/UsePeference/${siteInfo?.csId}`)
    //   .then((response) => {
    //     setUserPreferenceBoard(response.data);
    //   })
    //   .catch((error) => {
    //     setUserPreferenceBoard([]);
    //   });
  };

  const getUsageLoadBoard = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/ChargersUsageLoadPeriod/${siteInfo?.csId}`,
      );
      setUsageLoadBoard(data.sort((x, y) => x.timeStart - y.timeStart));
    } catch {
      setUsageLoadBoard([]);
    }

    // const data = await AxiosApi.get(
    //   `metrics/ChargersUsageLoadPeriod/${siteInfo?.csId}`,
    // )
    //   .then((response) => {
    //     response.data.sort((x, y) => x.timeStart - y.timeStart);
    //     setUsageLoadBoard(response.data);
    //   })
    //   .catch((error) => {
    //     setUsageLoadBoard([]);
    //   });
  };

  const getRevenueBoard = async () => {
    try {
      const { data } = await AxiosApi.get(
        `metrics/RevenuePeriodDistribution/${siteInfo?.csId}`,
      );
      setRevenueBoard(data.sort((x, y) => x.timeStart - y.timeStart));
    } catch {
      setRevenueBoard([]);
    }

    // const data = await AxiosApi.get(
    //   `metrics/RevenuePeriodDistribution/${siteInfo?.csId}`,
    // )
    //   .then((response) => {
    //     response.data.sort((x, y) => x.timeStart - y.timeStart);
    //     setRevenueBoard(response.data);
    //   })
    //   .catch((error) => {
    //     setRevenueBoard([]);
    //   });
  };

  const getAlertsBoard = async () => {
    try {
      const { data } = await AxiosApi.get(`UserAlerts/${siteInfo?.csId}`);
      setAlertsBoard(data);
    } catch {
      setAlertsBoard([]);
    }

    // const data = await AxiosApi.get(`UserAlerts/${siteInfo?.csId}`).then(
    //   (response) => {
    //     setAlertsBoard(response.data);
    //   },
    // );
  };

  // Functions
  const modalCloseHandler = () => {
    setConfirmationConfig({ open: false });
  };

  const disableSite = () => {
    const resp = AxiosApi.put(`SiteDisabledStatus/${site_id}`, {
      isDisabled: true,
      siteId: site_id,
    })
      .then((response) => {
        context.sitesUpdate();
        setTimeout(() => history.push("/DisabledSites"), 1000);
      })
      .catch((error) => {});
    modalCloseHandler();
  };

  const disableSiteConfirm = () => {
    setConfirmationConfig({
      open: true,
      value: "",
      title: props.t("Disable site"),
      body: props.t("Are you sure to disable site ") + siteInfo.csName + " ?",
      btOkText: props.t("Yes"),
      btOkStyle: "danger",
      btCancelText: props.t("No"),
      functionHandler: disableSite,
      closeHandler: modalCloseHandler,
    });
  };

  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>
            {props.t("Dashboard")} | Spott |{" "}
            {props.t("Manage your recharge points")}
          </title>
        </MetaTags>

        <Container fluid>
          <div className="page-title-box pb-0">
            <Row className="align-items-center">
              <Col md={8}>
                <CustomTitlePage
                  title={
                    siteInfo?.csName === ""
                      ? props.t("Local")
                      : siteInfo.csName + " " + props.t("(Last 30 days)")
                  }
                  // subtitle={`${props.t(
                  //   "Last update",
                  // )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
                />
              </Col>

              {/*site settings button*/}
              {/* <Col md="4">
                <div className="float-end d-none d-md-block">
                  {siteInfo.csId && siteInfo.csId !== "" ? (
                    <DropdownMenuRight settings={pageInfo?.submenu} />
                  ) : null}
                </div>
              </Col> */}
            </Row>
          </div>

          <Row>
            <Col xl={3} lg={6}>
              <WidgetDashboard data={cosumersWidget} />
            </Col>

            <Col xl={3} lg={6}>
              <WidgetDashboard data={revenueWidget} />
            </Col>

            <Col xl={3} lg={6}>
              <WidgetDashboard data={energyConsumptionWidget} />
            </Col>

            <Col xl={3} lg={6}>
              <WidgetDashboard data={transactionsWidget} />
            </Col>
          </Row>
          <Row>
            <Col xl={3}>
              <StatusChargers data={chargerStatusBoard} />
            </Col>

            <Col xl={5}>
              <div className="pb-4 h-100">
                <MotlyPreferencesUse data={userPreferenceBoard} />
              </div>
            </Col>

            <Col xl={4}>
              <div className="pb-4 h-100">
                <ChargersUsageLoad data={usageLoadBoard} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={3}>
              <div className="pb-4 h-100">
                <SitesRevenueGraph data={revenueBoard} />
              </div>
            </Col>

            <Col xl={4}>
              <AlertsDashboard data={alertsBoard} title={props.t("Alerts")} />
            </Col>

            <Col xl={5}>
              <Row className="h-100">
                <Col md={7}>
                  <BannerDashboard data={{ title: "Banners" }} />
                </Col>

                <Col md={5}>
                  <WidgetSupport />
                </Col>

                <Col md={13}>
                  <SpottTips data={{ title: props.t("Spott Tips") }} />
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalComponent config={confirmationConfig} />
        </Container>
      </div>
    </>
  );
};

Dashboard.propTypes = { t: PropTypes.any };

export default withTranslation()(Dashboard);
