import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;

  font-weight: 500;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  word-wrap: break-word;

  small {
    color: ${darken(0.24, "#e7e8ea")};
    font-size: 1.16rem;
    line-height: 1.16rem;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.9);
    }
  }

  p {
    color: #3970f3;
    font-weight: 600;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  margin: 0 auto;

  textarea {
    width: 100%;
    padding: 0.32rem;
    border: 1px solid #e7e8ea;
    border-radius: 0.24rem;
  }
`;
