import * as React from "react";
import ReactApexChart from "react-apexcharts";

import { formatValuesByLanguage } from "../../../helpers/Helpers";
import * as Provider from "../../../context/ContextProvider";

const Salesdonut = (props) => {
  const { state } = Provider.useStateContext();

  const labelsGrafico = props.data
    ? props.data.map((item, key) => {
        return item.periodName;
      })
    : [];
  const valoresGrafico = props.data
    ? props.data.map((item, key) => {
        return item.value;
      })
    : [];
  // const valoresGrafico = props.data ? props.data.map((item, key) => { return item.value }) : [];
  const graphData = {
    options: {
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
          },
        },
      },
      colors: ["#626ed4", "#02a499", "#f8b425", "gray"],
    },
    series: valoresGrafico,
    chartOptions: {
      labels: labelsGrafico,
    },
  };

  return (
    <React.Fragment>
      <ReactApexChart
        options={graphData.options}
        series={graphData.series}
        type="donut"
        height="250"
      />
    </React.Fragment>
  );
};

export default Salesdonut;
