import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { AxiosApi } from "../../helpers/api";
import MetaTags from "react-meta-tags";
import { Row, Col, CardBody, Card, Alert, Input } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { withTranslation } from "react-i18next";
// import { Container, Display, Form, Label, Fields } from "./CreatePass.styles";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";

// import Header from "./components/Header";
// import Footer from "../../components/VerticalLayout/Footer";
import Header from "./components/Header/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { Container , Display, Form, Label, Fields, AlignInput } from "./createpass.styles";
import { ModalComponent } from "./components/ChangePassModal";

const CreatePass = (props) => {
  const url = new URL(window.location.href);
  const hashParam = url.searchParams.get("culture");

  const token = props.match.params.t;
  const [erro, setErro] = useState({ erro: false, message: "" });
  const [confirmationConfig, setConfirmationConfig] = useState({ open: false });
  const history = useHistory();
  const visible  = require("../../assets/images/visible.png");
  const nonVisible  = require("../../assets/images/visible_off.png");
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [isShowPassword1, setIsShowPassword1] = useState(false)

  const handleValidSubmit = (event, values) => {
    if (values.pass !== values.passConfirm) {
      setErro({ erro: true, message: props.t("cratePassError1") });
      return;
    }

    const resp = {
      Password: values.pass,
      Token: token,
    };
    passCreatedSuccess();
    createPasswordAPI(resp);
  };

  const createPasswordAPI = (payload) => {
    const resp = AxiosApi.post(`/SetPassword`, payload)
      .then((response) => {
        passCreatedSuccess();
      })
      .catch((error) => {
        const msg = JSON.parse(error.request.response).message;
        if (error.response.status === 500) {
          setErro({ erro: true, message: props.t("cratePassError2") });
        } else {
          setErro({ erro: true, message: msg });
        }
      });
  };

  const passCreatedSuccess = () => {
    setConfirmationConfig({
      open: true,
      value: "",
      title: props.t("cratePassModalTitle"),
      body: props.t("cratePassModalMsg"),
      bt2Function: redirectToLogin,
      bt2: props.t("Fazer o login"),
    });
  };

  const redirectToLogin = () => history.push("/login");

  return (
    <>
    <MetaTags>
      <title>{props.t("Login | Spott | Manage your recharge points")}</title>
    </MetaTags>

    <Container className="custom-bg">
      <Header />

      <Display>
        <p>
        {props.t("Crie uma nova senha")} <br/>

        </p>

        {erro.erro && (
          <Row className="mt-3">
            <Col sm={12} className="text-start">
              <Alert
                isOpen={erro.message !== null}
                color="danger"
                className="mb-0"
              >
                {erro.message}
              </Alert>
            </Col>
          </Row>
        )}
      <AvForm
          className="form-horizontal mt-4"
          onValidSubmit={(e, v) => {
            handleValidSubmit(e, v);
          }}
        >
          <AlignInput>
          <div>
            <AvField
              name="pass"
              // label={props.t("Password")}
              value=""
              className="form-control"
              placeholder={props.t("Nova Senha")}
              type={isShowPassword1 ? 'text' :'password'}
              required
            />
          </div>
          <img src={isShowPassword1? nonVisible : visible} alt="eye" onClick={()=>{setIsShowPassword1(!isShowPassword1)}}/>
          </AlignInput>
            <a>{props.t("Change Tip")}</a>
          <AlignInput style={{marginTop:25}}>
            <div>
            <AvField
              name="passConfirm"
              // label={props.t("authPasswordConfirmation")}
              value=""
              className="form-control"
              placeholder={props.t("Confirme sua nova senha")}
              type={isShowPassword ? 'text' :'password'}
              required
            />
            </div>
          <img src={isShowPassword? nonVisible : visible} alt="eye"  onClick={()=>{setIsShowPassword(!isShowPassword)}}/>
          </AlignInput>
          <Row className="mb-1">
            <Col sm={12} className="text-end">
              <button
                type="submit"
                className="btn mt-4 btn-primary w-full waves-effect waves-light"
              >
                {props.t("authSetPassword")}
              </button>
            </Col>
          </Row>
      </AvForm>

      </Display>

      <ModalComponent config={confirmationConfig} />
    </Container>

    <Footer
    loginFooter={true}
    setLanguageByExternalResource={hashParam || "pt-BR"}
    />
  </>
  );
};

export default withRouter(connect(null, {})(withTranslation()(CreatePass)));
