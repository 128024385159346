import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type TabButtonPropsType = {
  isSelected: boolean;
};

export const Container = styled.section`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
`;

export const Header = styled.header`
  width: 100%;
  margin: 0 auto;

  h1 {
    color: #3970f3;
    font-weight: 500;
    font-size: 25px;
    display: flex;

    span{
      color: #8c8c8c;
      margin-right: 0.6rem;
      display: flex;
      align-items: center;

      span{
        margin: auto auto auto 10px;
        font-size: 14px;
      }
    }
  }

  p {
    color: #8e8e8e;
    margin: 0;
    padding: 0;
    font-size: 1.8rem;
    font-weight: 500;
  }
`;

export const TabBar = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  border: 1px solid transparent;
  border-bottom-color: #ced4da;
  padding-bottom: -1px;
`;

export const TabButton = styled.button<TabButtonPropsType>`
  background-color: transparent;
  color: #495057;
  margin: 0;
  padding: 8px 16px;
  border: 1px solid ${({ isSelected }) => (isSelected ? `#ced4da` : `transparent`)};
  border-bottom-color: transparent;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5rem;

  &:hover{
    border-color: ${({ isSelected }) => (isSelected ? `#ced4da` : `#e9ecef`)};
    border-bottom: none;
    color: ${({ isSelected }) => (isSelected ? `#495057` : `#2e5acf`)};
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 1.2rem;
`;
