import styled from "styled-components";

const { AvField, AvForm } = require("availity-reactstrap-validation");

type ModalButtonsPropsType = {
  activeColor: "grey" | "blue" | "red";
};

const ButtonColor = {
  grey: "#6C6C6C",
  blue: "#3970F3",
  red: "#d10000",
};

export const ModalFormField = styled(AvForm)`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;

  display: grid;
  grid-gap: 2rem;
`;

export const ModalFormHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;
  display: grid;
  grid-gap: 1rem;

  h1 {
    color: #3970f3;

    font-style: normal;
    font-weight: 700;
    font-size: 1.48rem;
    line-height: 1.48rem;
    text-align: left;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.24rem;
    text-align: left;
  }
`;

export const ModalFormTitleField = styled(AvField)`
  width: 100%;
  height: 3.5rem;

  &::placeholder {
    color: #ada7c0;

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
  }
`;

export const ModalFormContent = styled.div`
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-gap: 1rem;

  p {
    margin: 0;
    color: #353f47;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }

  span {
    color: #637280;

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
  }

  .aligment-content {
    display: flex;
    align-items: center;
    gap: 0.6rem;
  }
`;

export const ModalFormInputField = styled(AvField)`
  width: 6rem;
`;

export const ModalFormFooter = styled.footer`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  button {
    width: 10rem;
  }
`;

export const BaseButton = styled.button<ModalButtonsPropsType>`
  background-color: ${({ activeColor }) => ButtonColor[activeColor]};
  color: white;
  width: 13.5rem;
  padding: 1rem;
  margin-top: 2rem;

  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);

  font-style: normal;
  font-weight: 500;
  font-size: 0.88rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ModalFormButton = styled(BaseButton)``;
