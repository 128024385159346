import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";
import Flatpickr from "react-flatpickr";

type WidgetButtonsPropsTypes = {
  isSelected?: boolean;
};

type buttonColor = {
  isPdf: boolean;
};

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
  display: grid;
  grid-gap: 1.32rem;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  p {
    color: #484552;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }

  .data-table {
    background-color: white;
    width: 100%;
    margin: 0 auto;
    padding: 0.32rem;
    border: 1px solid #edeeef;
    border-radius: 0.32rem;
    overflow: hidden;
    display: grid;
    grid-gap: 0.32rem;
  }
`;

export const DatePickerField = styled(Flatpickr)`
  background-color: none;
  width: 34rem;
  display: flex;
  gap: 0.64rem;
  z-index: 100;

  input {
    background-color: white;
    color: ${darken(0.48, "#edeeef")};
    width: 100%;
    padding: 0.6rem;
    border: 1px solid #edeeef;
    border-radius: 0.24rem;

    font-style: normal;
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 0.8rem;
    text-align: center;
    text-transform: none;

    transition: filter 300ms;
    &:hover {
      filter: brightness(0.96);
    }

    &::placeholder {
      text-decoration: underline;
      text-underline-position: under;
    }
  }

  button {
    width: 10rem;
  }
`;

const ButtonType = styled.button`
  background: #3970f3;
  color: white;
  width: 8rem;
  padding: 0.8rem;

  border: 0;
  border-radius: 0.24rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  text-transform: uppercase;

  transition: all 300ms;
  &:disabled {
    background-color: ${darken(0.48, "#edeeef")};
    opacity: 0.4;
  }
  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonClearDate = styled(ButtonType)`
  width: 20rem;
`;

export const ButtonExport = styled(ButtonType)<buttonColor>``;

export const Footer = styled.footer`
  width: 100%;
  margin: 0 auto;

  .buttons-aligment {
    width: 100%;
    margin: 1rem auto;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;

    span {
      color: #484552;
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 1.8rem;
      text-align: center;
      text-transform: none;
      user-select: none;
    }
  }
`;

export const WidgetButton = styled.button<WidgetButtonsPropsTypes>`
  background-color: transparent;
  color: #484552;
  border: 0;

  font-style: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  text-underline-position: under;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
  text-decoration: ${({ isSelected }) => (isSelected ? "underline" : "none")};
  // css-text-model-properties

  transition: all 300ms;
  &:hover {
    text-decoration: underline;
  }
`;
