import * as React from "react";
import * as Translator from "react-i18next";
import { BiCheck } from "react-icons/bi";

import { DisplaySettings } from "../../Chargers";

import { Component, View } from "./display.styles";

type DisplayPropsTypes = {
  t: Function;
} & DisplaySettings;

export const Display = ({
  t: translation,
  displayInfo = "",
  displayShow = false,
  displayClipped = false,
  displayCordinates = { x: 0, y: 0 },
}: DisplayPropsTypes) => {
  return (
    <>
      <Component>
        <View
          show={displayShow}
          clipped={displayClipped}
          coordinates={displayCordinates}
        >
          {displayClipped && (
            <div>
              <BiCheck />
              {translation("chargers_DisplayCopied")}
            </div>
          )}

          {!displayClipped && (
            <p>
              {translation("chargers_DisplayCopy", {
                chargerid: displayInfo,
              })}
            </p>
          )}
        </View>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(Display);
