import React from "react";
import { withTranslation } from "react-i18next";

const {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Spinner,
} = require("reactstrap");

import Error from "../../Alerts/Error";
import Success from "../../Alerts/Success";

import { Charger } from "../../../interfaces/Chargers";
import { Exception } from "../../../interfaces/Exception";
import { BasicModal } from "../../../interfaces/Modal";
import { AxiosApi } from "../../../helpers/api";

interface ChargerResetProps extends BasicModal {
  t: Function;
  charger: Charger;
  forceRefreshList: () => void;
}

function ChargerHardReset({
  t,
  charger,
  isOpen,
  onClose,
  forceRefreshList,
}: ChargerResetProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<boolean>(false);

  const dispatchResetCharger = async () => {
    try {
      setLoading(true);

      const { data } = await AxiosApi.post(
        `/ChargerReset/${charger.chargePointId}`,
      );

      if (data.status === "Accepted") {
        setHandleSuccess(true);

        forceRefreshList();

        setTimeout(() => {
          onClose();
        }, 2500);
      } else {
        setHandleError({
          isOpen: true,
          status: 400,
          message: { error: "REQUEST_FAILURE" },
          onClose: () => setHandleError(undefined),
        });
      }
    } catch (err: any) {
      setHandleError({
        isOpen: true,
        message: err?.response?.data,
        status: err?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} centered fullscreen="sm">
      <ModalHeader toggle={onClose}>
        <span className="text-primary">{t("Charger Reset")}</span>
      </ModalHeader>

      {handleError && <Error {...handleError} />}
      {handleSuccess && <Success message={t("Charger successfully reset!")} />}

      {!handleError && !handleSuccess && (
        <>
          <ModalBody>
            <span>{t("Are you sure you want to restart this charger?")}</span>
          </ModalBody>

          <ModalFooter>
            {loading ? (
              <Spinner size="sm" className="text-primary">
                {t("Loading...")}
              </Spinner>
            ) : (
              <>
                <Button color="danger" onClick={dispatchResetCharger}>
                  {t("Reset Charger")}
                </Button>
                <Button onClick={onClose}>{t("Cancel")}</Button>
              </>
            )}
          </ModalFooter>
        </>
      )}
    </Modal>
  );
}

export default withTranslation()(ChargerHardReset);
