import React, { Component } from 'react';
import ChartistGraph from 'react-chartist';

class lineareachart extends Component {
  render() {
    var lineChartData = {
      labels: ["Dom", "seg", "ter", "qua", "qui", "sex", "sab"],
      series: [[7, 4, 3, 4, 5, 6, 7]],
    };
    var lineChartOptions = {
      low: 0,
      showArea: true,
      height: '300px',
    };
    return (
      <React.Fragment>
        <ChartistGraph
          data={lineChartData}
          className="grafico01 earning ct-golden-section "
          options={lineChartOptions}
          type={'Bar'}
        />
      </React.Fragment>
    );
  }
}

export default lineareachart;
