import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

import { ChargerStatusTypes } from "./ChargerStatus";

type BackgroundPropsTypes = {
  chargerStatus: ChargerStatusTypes;
  lowerView?: boolean
};

const ChargerStatusColor = {
  offline: "#c5c5cb",
  online: "#00da71",
};

export const Component = styled.section``;

export const Background = styled.div<BackgroundPropsTypes>`
  background-color: ${({ chargerStatus }) =>
    ChargerStatusColor[chargerStatus ?? "offline"]};
  border: 1px solid
    ${({ chargerStatus }) =>
      darken(0.088, ChargerStatusColor[chargerStatus ?? "offline"])};
  color: white;
  width: ${({lowerView}) => lowerView ? '6rem' : '8rem'};
  margin: 0.16rem;
  margin-left: 0;
  padding: 0.8rem;
  border-radius: 0.24rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.88rem;
  line-height: 0.88rem;
  text-align: center;
  text-transform: capitalize;

  user-select: none;
  transition: filter 300ms;
  &:hover {
    filter: brightness(0.9);
  }
`;
