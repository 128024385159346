import * as React from "react";

import { Input } from "../../Inputs";

import { Component } from "./inputlabel.styles";

type InputlabelPropsTypes = {
  label: string;
  inputAllowOnlyNumbers?: boolean;
  inputPreventPasting?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const InputLabel = ({
  label = "",
  inputAllowOnlyNumbers = false,
  inputPreventPasting = false,
  ...rest
}: InputlabelPropsTypes) => {
  return (
    <>
      <Component>
        <label htmlFor={label}>
          <p>{label}</p>

          <Input
            inputAllowOnlyNumbers={inputAllowOnlyNumbers}
            inputPreventPasting={inputPreventPasting}
            {...rest}
          />
        </label>
      </Component>
    </>
  );
};
