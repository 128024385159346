import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const ModalHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
  padding-bottom: 0;
  position: relative;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 1.24rem;
    line-height: 1.5rem;
    /* text-transform: capitalize; */
  }
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #666666;
  border: 0;

  position: absolute;
  top: 1rem;
  right: 1rem;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
`;
