import "./DropdownMenuRight.scss";
import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Link } from "react-router-dom";

// settings = {
//     buttom: {
//       label: 'Settings',
//       color: 'primary',
//       classList: '',
//     },
//     items: [
//       { label: 'Action', link: '#' },
//       { label: 'Another action', link: '#' },
//       { label: 'Something else here', link: '#' },
//       { label: '', link: '' },
//       { label: 'Separated link', link: '#', color: 'danger' },
//     ]
//   }

const DropdownMenuRight = ({ settings }) => {
  const [menu, setMenu] = useState(false);
  const classList = `btn btn-${
    settings?.buttom.color ? settings?.buttom.color : "primary"
  } dropdown-toggle waves-effect waves-light ${settings?.buttom.classList}`;
  const icon = `mdi mdi-cog me-2`;
  const toggle = () => {
    setMenu(!menu);
  };

  if (settings.active === false) return "";

  return (
    <Dropdown isOpen={menu} toggle={toggle}>
      <DropdownToggle
        color={settings?.buttom.color ? settings?.buttom.color : "primary"}
        className={classList}
      >
        <i className={icon}></i> {settings?.buttom.label}
      </DropdownToggle>
      <DropdownMenu right>
        {settings.items.map((item, key) => {
          if (item.label === "") {
            return <DropdownItem key={key} divider />;
          } else {
            return !item?.linkType ? (
              <DropdownItem key={key}>
                <Link to={{ pathname: item?.link, state: {} }}>
                  <span
                    className={
                      item?.color ? "text-" + item?.color : "text-primary"
                    }
                  >
                    {item?.label}
                  </span>
                </Link>
              </DropdownItem>
            ) : (
              <DropdownItem key={key}>
                <span
                  className={
                    item?.color ? "text-" + item?.color : "text-primary"
                  }
                  onClick={() => item?.link()}
                >
                  {item?.label}
                </span>
              </DropdownItem>
            );
          }
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownMenuRight;
