import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.div`
  p {
    font-weight: 500;
    font-style: normal;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
    word-wrap: break-word;
    text-underline-position: under;
    text-decoration: none;
  }
`;
