import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type DisplayPropsTypes = {
  show: boolean;
  clipped: boolean;
  coordinates: {
    x: number;
    y: number;
  };
};

const dimizedCoordinates = {
  x: 256,
  y: 180,
};

export const Component = styled.section``;

export const View = styled.div<DisplayPropsTypes>`
  background: rgba(0, 0, 0, 0.88);
  color: white;
  width: 12rem;
  height: 4rem;
  padding: 0.6rem;
  border: 1px solid transparent;
  border-radius: 0.32rem;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 300ms;

  // Text properties
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1.24rem;
  word-wrap: break-word;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  text-underline-position: under;
  user-select: none;

  position: absolute;
  top: ${({ coordinates }) => coordinates.y - dimizedCoordinates.y}px;
  left: ${({ coordinates }) => coordinates.x - dimizedCoordinates.x}px;
  z-index: 100;

  opacity: ${({ show }) => (show ? "1" : "0")};
  pointer-events: ${({ show }) => (show ? "all" : "none")};
  background-color: ${({ clipped }) => clipped && "#028b82"};

  div {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
    padding: 0;
  }

  svg {
    color: white;
    width: 1.24rem;
    height: 1.24rem;
  }

  span {
    text-decoration: underline;
    text-underline-position: below;
  }
`;
