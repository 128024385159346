import "./forgotPasswordApp.scss";
import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Header from "../App/components/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { AxiosApi } from "../../helpers/api";
import { withRouter, useHistory, useParams } from "react-router-dom";
import { withTranslation } from "react-i18next";

import { RiEyeCloseLine, RiEyeLine } from "react-icons/ri";

const ForgotPassApp = (props) => {
  const { t } = useParams();
  const [erro, setErro] = useState({ erro: false, message: "" });
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [passwordConfirmVisibility, setPasswordConfirmVisibility] =
    useState(false);

  const handleValidSubmit = (event, values) => {
    if (values.pass !== values.passConfirm) {
      setErro({ erro: true, message: props.t("appForgotPasswordError1") });
      return;
    }

    if (!t) {
      setErro({ erro: true, message: props.t("appForgotPasswordError2") });
      return;
    }

    const resp = {
      Password: values.pass,
      Token: t,
    };
    createPasswordAPI(resp);
  };

  const createPasswordAPI = (payload) => {
    const resp = AxiosApi.post(`/SetPassword`, payload)
      .then((response) => {
        setSuccess(true);
      })
      .catch((error) => {
        const msg = JSON.parse(error.request.response).message;
        if (error.response.status === 500) {
          setErro({ erro: true, message: props.t("appForgotPasswordError3") });
        } else {
          setErro({ erro: true, message: msg });
        }
      });
  };

  if (success) {
    return (
      <>
        <MetaTags>
          <title>{props.t("appForgotPasswordPageTitle")}</title>
        </MetaTags>
        <Header />
        <div className="account-pages pt-5" style={{ marginTop: "-40px" }}>
          <Container>
            <Row className="justify-content-center">
              <Col sm={9} md={7} lg={6} xl={4} xxl={3}>
                <Card className="overflow-hidden">
                  <CardBody className="p-4">
                    <p className="font-size-29 text-info mb-0">
                      {props.t("appForgotPasswordTitleSuccess")}
                    </p>
                    <p className="font-size-12 mb-0">
                      {props.t("appForgotPasswordMsgSuccess")}
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer loginFooter={true} />
      </>
    );
  }

  return (
    <>
      <MetaTags>
        <title>{props.t("appForgotPasswordPageTitle")}</title>
      </MetaTags>
      <Header />
      <div className="account-pages pt-5" style={{ marginTop: "-40px" }}>
        <Container>
          <Row className="justify-content-center">
            <Col sm={9} md={7} lg={6} xl={4} xxl={3} className="collum-input">
              <div className="toggle-pass-visibility">
                {passwordVisibility ? (
                  <RiEyeLine onClick={() => setPasswordVisibility(false)} />
                ) : (
                  <RiEyeCloseLine onClick={() => setPasswordVisibility(true)} />
                )}
              </div>

              <div className="toggle-pass-confirm-visibility">
                {passwordConfirmVisibility ? (
                  <RiEyeLine
                    onClick={() => setPasswordConfirmVisibility(false)}
                  />
                ) : (
                  <RiEyeCloseLine
                    onClick={() => setPasswordConfirmVisibility(true)}
                  />
                )}
              </div>

              <Card className="overflow-hidden">
                <CardBody className="p-4">
                  <p className="font-size-29 text-info mb-0">
                    {props.t("appForgotPasswordTitle")}
                  </p>

                  {erro.erro ? (
                    <Row className="mt-3">
                      <Col sm={12} className="text-start">
                        <Alert
                          isOpen={erro.message !== null}
                          color="danger"
                          className="mb-0"
                        >
                          {erro.message}
                        </Alert>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  <AvForm
                    className="form-horizontal mt-4 form-field"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <div className="mb-4">
                      <AvField
                        name="pass"
                        label={props.t("appForgotPasswordInput1")}
                        value=""
                        className="form-control pass-input form-field-input"
                        placeholder={props.t("appForgotPasswordInput1")}
                        type={passwordVisibility ? "text" : "password"}
                        required
                      />
                    </div>

                    <div className="mb-4">
                      <AvField
                        name="passConfirm"
                        label={props.t("appForgotPasswordInput2")}
                        value=""
                        className="form-control pass-input form-field-input"
                        placeholder={props.t("appForgotPasswordInput2")}
                        type={passwordConfirmVisibility ? "text" : "password"}
                        required
                      />
                    </div>

                    <Row className="mb-1">
                      <Col sm={12} className="text-end">
                        <button
                          type="submit"
                          className="btn mt-4 btn-primary w-full waves-effect waves-light"
                        >
                          {props.t("appForgotPasswordButtom")}
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer loginFooter={true} />
    </>
  );
};

export default withRouter(withTranslation()(ForgotPassApp));
