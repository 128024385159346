import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  font-style: normal;
  text-align: left;
  text-transform: none;
  word-wrap: break-word;
  text-underline-position: under;
  font-weight: 500;
  text-decoration: none;

  h1 {
    color: ${darken(0.48, "#e7e8ea")};
    font-size: 1.16rem;
    line-height: 1.16rem;
  }

  p {
    color: ${darken(0.32, "#e7e8ea")};
    font-size: 0.88rem;
    line-height: 0.88rem;
  }
`;

export const IdGenerator = styled.div`
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
  gap: 0.32rem;

  font-style: normal;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  text-underline-position: under;
  font-weight: 500;
  text-decoration: none;

  input {
    width: 24rem;
  }

  button {
    width: 4.88rem;
  }
`;
