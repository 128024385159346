import "./ChargersUsageLoad.scss";
import React from "react";
import ProgressBar from "../ProgressBar";
import { Card, CardBody, Spinner } from "reactstrap";
import { withTranslation } from "react-i18next";

const ChargersUsageLoad = ({ data, t }) => {
  if (!data) {
    return (
      <Card className="charger-usage-load">
        <CardBody className="d-flex align-items-center justify-content-center">
          <Spinner>Loading...</Spinner>
        </CardBody>
      </Card>
    );
  }

  if (data.length === 0) {
    return (
      <Card className="charger-usage-load">
        <CardBody className="">
          <p className="card-title">{t("Chargers usage load")}</p>
          <div className="mb-2 d-flex h-100 flex-row align-itens-center justify-content-center">
            <p className="card-title align-self-center">
              {t("No data to display")}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  }

  const setColor = (usageLoad) => {
    if (usageLoad >= 90) return "red";
    if (usageLoad < 90 && usageLoad >= 70) return "orange";
    if (usageLoad < 70 && usageLoad >= 50) return "yellow";
    if (usageLoad < 50) return "green";
  };

  return (
    <Card className="charger-usage-load">
      <CardBody>
        <p className="card-title">{t("Chargers usage load")}</p>
        {data.map((item) => {
          return (
            <div className="mb-2" key={item.periodId}>
              <ProgressBar
                key={item.periodId}
                percent={item.value}
                label={`${item.timeStart}h ${t("global_To")} ${item.timeEnd}h`}
              />
            </div>
          );
        })}
      </CardBody>
    </Card>
  );
};

export default withTranslation()(ChargersUsageLoad);
