import React from "react";
import { withTranslation } from "react-i18next";

import ChargerReset from "./ChargerHardReset";
import ChargerLogBox from "../ChargerLogs/ChargerLogBox";
import ChargerFirmware from "../ChargerFirmware/ChargerFirmware";

import { Charger } from "../../../interfaces/Chargers";

import { Container, Content, CustomButton } from "./chargeroptions.styles";

interface ChargerOptions {
  t: Function;
  charger: Charger;
  forceRefreshList: () => void;
}

function ChargerOptions({
  t: translator,
  charger,
  forceRefreshList,
}: ChargerOptions) {
  const [chagerLogs, setChargerLogs] = React.useState<boolean>(false);
  const [resetCharger, setResetCharger] = React.useState<boolean>(false);
  const [updateFirmware, setUpdateFirmware] = React.useState<boolean>(false);

  return (
    <>
      <Container>
        <Content>
          <div>
            <CustomButton
              buttonDefaultColor={"blue"}
              buttonWidth={8}
              onClick={() => setResetCharger(true)}
            >
              {translator("chargers_ConectorButtonRestart")}
            </CustomButton>

            <CustomButton
              buttonDefaultColor={"blue"}
              buttonWidth={12}
              onClick={() => setUpdateFirmware(true)}
            >
              {translator("chargers_ConectorButtonUpdateFirmware")}
            </CustomButton>
          </div>

          <CustomButton
            buttonDefaultColor={"grey"}
            buttonWidth={12}
            onClick={() => setChargerLogs(true)}
          >
            {translator("chargers_ConectorButtonLogInformation")}
          </CustomButton>
        </Content>
      </Container>

      <hr />

      {chagerLogs && (
        <ChargerLogBox
          charger={charger}
          isOpen={chagerLogs}
          onClose={() => setChargerLogs(false)}
        />
      )}

      {updateFirmware && (
        <ChargerFirmware
          charger={charger}
          isOpen={updateFirmware}
          onClose={() => setUpdateFirmware(false)}
        />
      )}

      {resetCharger && (
        <ChargerReset
          charger={charger}
          isOpen={resetCharger}
          onClose={() => setResetCharger(false)}
          forceRefreshList={() => forceRefreshList()}
        />
      )}
    </>
  );
}

export default withTranslation()(ChargerOptions);
