import { Container } from "./privacypolicycontent.styles";

export const PrivacePolicyContent = () => {
  return (
    <>
      <Container>
        <p>
          Nós da SPOTT TECNOLOGIA LTDA. <b>(“SPOTT”)</b> estamos constantemente
          preocupados em respeitar a privacidade dos nossos Usuários e
          demonstrar como seus dados pessoais são tratados dentro da nossa
          Plataforma.
        </p>
        <p>
          Escrevemos esse documento para te explicar como tratamos seus dados
          pessoais, mas se você ainda tiver alguma dúvida, pode entrar em
          contato conosco através do e-mail{" "}
          <a href="mailto:privacidade@spott.eco">privacidade@spott.eco</a>.
        </p>
        <p>
          Leia atentamente a presente Política de Privacidade, preferencialmente
          em conjunto com os Termos e Condições de Uso, pois é importante que
          você (“Usuário”) saiba o que esperar ao usar os serviços do Aplicativo
          e o que a SPOTT espera do Usuário.
        </p>
        <h3>1. Definições</h3>
        <p>
          Em primeiro lugar, vamos te mostrar as definições de alguns termos que
          aparecerão ao longo desse documento.
        </p>
        <h4>1.1 - Dado Pessoal:</h4>
        <p>
          informação relacionada a uma pessoa física que a torne diretamente
          identificada ou identificável;
        </p>
        <h4>1.2 - Dado Pessoal Sensível:</h4>
        <p>
          informação relacionada a uma pessoa física que a torne diretamente
          identificada ou identificável;
        </p>
        <h4>1.3 - TITULAR:</h4>
        <p>
          pessoa física a quem se referem os dados pessoais que são objeto de
          tratamento;
        </p>
        <h4>1.4 - TRATAMENTO:</h4>
        <p>
          toda operação realizada com dados pessoais, como as que se referem a
          coleta, produção, recepção, classificação, utilização, acesso,
          reprodução, transmissão, distribuição, processamento, arquivamento,
          armazenamento, eliminação, avaliação ou controle da informação,
          modificação, comunicação, transferência, difusão ou extração;
        </p>
        <h4>1.5 - ANONIMIZAÇÃO:</h4>
        <p>
          utilização de meios técnicos razoáveis e disponíveis no momento do
          tratamento, por meio dos quais um dado perde a possibilidade de
          associação, direta ou indireta, a um indivíduo;
        </p>
        <h4>1.6 - CONSENTIMENTO:</h4>
        <p>
          manifestação livre, informada e inequívoca pela qual o titular
          concorda com o tratamento de seus dados pessoais para uma finalidade
          determinada;
        </p>
        <h3>2. INFORMAÇÕES QUE COLETAMOS</h3>
        <p>
          Agora que você já sabe sobre o que estamos falando, vamos explicar
          para você quais são seus dados pessoais que coletamos,{" "}
          <b>
            lembrando que somente trabalhamos com aquelas informações essenciais
            para as finalidades da nossa Plataforma
          </b>
          , sendo certo que outras informações podem ser coletadas e tratadas
          diante da imposição de normas que sejam se apliquem aos nossos
          serviços.
        </p>
        <h4>2.1 - Dados Fornecidos pelo(a) Usuário(a):</h4>
        <p>
          <b>a)</b> Dados de Cadastro na Plataforma: nome completo, data de
          nascimento, e-mail, cidade, país, marca, modelo e cor do veículo e
          número de telefone. Para comprovação dessas informações fornecidas
          diretamente por você, podemos solicitar o envio de documentos;
        </p>
        <h4>2.2 - Dados Coletados pela Plataforma:</h4>
        <p>
          <b>a)</b> Dados de Localização: Para melhorar a sua experiência em
          nossa Plataforma, nós coletamos os dados de localização, conforme
          preferência autorizada diretamente no aparelho celular pelo Usuário.
        </p>
        <p>
          <b>b)</b> Câmera e acesso às fotos: Toda vez que você realizar uma
          carga no seu veículo, será necessário scaner um QR CODE, momento em
          que a SPOTT, através do App, poderá ter acesso a sua câmera celular,
          conforme preferências escolhidas por você, Usuário, no seu dispositivo
          celular. Ainda, a SPOTT poderá ter acesso a sua câmera ou a sua
          biblioteca de fotos, conforme preferências escolhidas por você,
          Usuário, no seu dispositivo celular, caso você queira inserir uma foto
          sua na página de cadastro.
        </p>
        <p>
          <b>c)</b> Perfil de Usuário: Quando o App SPOTT estiver sendo
          utilizado por você, somente coletaremos informações sobre como você
          usa nossa Plataforma, tais como datas e horários de acesso, falhas no
          aplicativo e demais informações relacionadas ao seu uso, quando você
          realiza alguma transação no App. Essas informações serão coletadas com
          a finalidade de garantir a constante qualidade das funcionalidades
          existentes na plataforma.
        </p>
        <p>
          <b>d)</b> Dados de Transações: As transações de pagamento pelos
          Usuários serão realizadas via empresas de meios de pagamento parceiras
          e a SPOTT não possui qualquer responsabilidade quanto à transação,
          tampouco coleta dados dessa natureza.
        </p>
        <p>
          <b>e)</b> Dados do Dispositivo Eletrônico: Ao utilizar o App SPOTT,
          serão coletados dados mínimos, de acordo com a legislação brasileira,
          tais como endereço de IP, data e horário. Essas informações serão
          coletadas com a finalidade de cumprir com a Lei n. 12.965/2014,
          conhecida como Marco Civil da Internet.
        </p>
        <p>
          <b>f)</b> Google Analytics: Nossa Plataforma utiliza o Google
          Analytics para a coleta e processamento de Dados, conforme políticas
          do Google. São de responsabilidade única e exclusiva do Google os usos
          dos dados realizados por este ou seus parceiros, não tendo a SPOTT
          responsabilidades resultantes de tal uso.
        </p>
        <h3>3. UTILIZAÇÃO DOS DADOS PESSOAIS</h3>
        <p>
          A LGPD dispõe que podemos tratar informações pessoais quando tivermos
          uma razão aceitável para fazê-lo, com fundamento nas bases legais
          trazidas pela Lei. Nessa sessão, você entenderá como utilizamos suas
          informações coletadas.
        </p>
        <p>
          <b>Usamos as informações que coletamos sobre você para fins de:</b>
        </p>
        <p>
          <b>a)</b> Cumprir nossas obrigações ou exercer nossos direitos sob
          nosso contrato com você;
        </p>
        <p>
          <b>b)</b> Cumprir obrigações legais ou regulatórias a que estamos
          sujeitos;
        </p>
        <p>
          <b>c)</b> Promover os nossos produtos e avisar sobre novas
          funcionalidades, serviços e promoções;
        </p>
        <p>
          <b>d)</b> Avaliar a eficácia da publicidade que veiculamos;{" "}
        </p>
        <p>
          <b>e)</b> Gerenciar riscos e detectar, prevenir e/ou remediar fraudes
          ou outras atividades potencialmente ilegais ou proibidas, além de
          violações de políticas, contratos ou termos de uso aplicáveis;
        </p>
        <p>
          <b>f)</b> Exercer e defender direitos em processos judiciais,
          administrativos ou arbitrais.
        </p>
        <h3>4. COMPARTILHAMENTO DE DADOS</h3>
        <p>
          Como você já sabe quais tipos de informações coletamos, agora você
          pode ter uma pergunta: “a SPOTT vai compartilhar meus dados com alguma
          outra pessoa e/ou empresa?”. E nessa sessão vamos responder a essa
          pergunta.
        </p>
        <p>
          Como falamos no começo, a privacidade dos nossos Usuários é uma
          preocupação constante. Assim, nós não vamos compartilhar seus dados
          com terceiros, exceto nas hipóteses abaixo.
        </p>
        <p>Hipóteses de compartilhamento de dados:</p>
        <p>
          <b>a)</b> Compartilhamento mediante consentimento do titular: seus
          dados serão compartilhados sempre que você expressamente nos solicitar
          e/ou autorizar a fazer essa operação;
        </p>
        <p>
          <b>b)</b> Compartilhamento com órgãos públicos e entidades públicas:
          poderemos compartilhar seus dados com órgãos públicos e entidades
          públicas para atender eventuais obrigações legais e/ou regulatórios
          que sejam impostas aos serviços que prestamos, bem como para o
          cumprimento de ordens judiciais e administrativas eventualmente
          impostas;
        </p>
        <p>
          <b>c)</b> Compartilhamento com autoridades policiais: poderemos
          compartilhar seus dados pessoais e demais informações que julgarmos
          necessárias caso, a critério da SPOTT, houver suspeita do cometimento
          de qualquer ato considerado crime ou contravenção penal, nos termos da
          legislação. Nesse caso, estará expressamente dispensada a existência
          de obrigação legal e/ou regulatória, bem como de ordem judicial; e
        </p>
        <p>
          <b>d)</b> Compartilhamento com prestadores de serviço e parceiros
          comerciais: poderemos compartilhar seus dados pessoais com prestadores
          de serviço e parceiros comerciais, tais como empresas que funcionem
          como meios de pagamento, seguradoras e serviços em nuvem.
        </p>
        <h3>5. PROTEÇÃO E SEGURANÇA</h3>
        <p>
          Nós utilizamos os mais modernos sistemas de segurança da informação em
          todos os nossos sistemas e dispositivos, o que inclui o uso de
          ferramentas tecnológicas como criptografias, controles de acesso,
          firewalls, registros de criação e acesso de contas, dentre outras.
        </p>
        <p>
          É importante lembrar que somente aplicamos essas camadas de segurança
          a partir do momento que suas informações são recebidas pela SPOTT e
          enquanto forem mantidas conosco. Assim, não podemos garantir a
          segurança do dispositivo eletrônico ou das redes que você está
          utilizado para acessar nossa Plataforma.
        </p>
        <p>
          Mas apesar de utilizarmos nossos maiores esforços para proteger os
          dados dos nossos usuários, sabemos que incidentes podem acontecer.
          Dessa forma, caso quaisquer de seus dados pessoais sejam expostos de
          alguma forma, lhe enviaremos um comunicado dentro de um prazo razoável
          com as seguintes informações: (i) descrição da natureza dos dados
          pessoais afetados; (ii) indicação das medidas técnicas e de segurança
          utilizadas para a proteção dos dados; (iii) riscos relacionados ao
          incidente; (iv) motivos da demora, no caso de a comunicação não ter
          sido imediata; e (v) as medidas que foram ou que serão adotadas para
          reverter ou mitigar os efeitos do prejuízo.
        </p>
        <h3>6. DIREITOS DOS TITULARES DOS DADOS PESSOAIS</h3>
        <p>
          Como dono(a) de seus próprios dados pessoais, você possui diversos
          direitos que podem ser exercidos a qualquer tempo e mediante
          requisição direta direcionada para a SPOTT. Abaixo, vamos explicar
          cada um desses direitos, demonstrando nosso compromisso com a
          transparência:
        </p>
        <p>
          <b>a)</b> Confirmação da existência de tratamento: você pode nos
          encaminhar uma solicitação para confirmar se estamos realizando
          qualquer tipo de tratamento relacionado aos seus dados pessoais, bem
          como quais dados possuímos a seu respeito;
        </p>
        <p>
          <b>b)</b> Acesso aos dados: além de confirmar se estamos tratando seus
          dados pessoais, você também pode solicitar acesso a todos os dados que
          possuímos a seu respeito;
        </p>
        <p>
          <b>c)</b> Correção de dados incompletos, inexatos ou desatualizados:
          caso você verifique que seus dados estão incompletos, errados ou
          desatualizados em nossa Plataforma, você poderá nos solicitar a
          correção das informações;
        </p>
        <p>
          <b>d)</b> Anonimização, bloqueio ou eliminação de dados
          desnecessários, excessivos ou tratados em desconformidade com a lei:
          caso você entenda que estamos tratando dados de forma excessiva a seu
          respeito, você poderá solicitar que realizemos a anonimização,
          bloqueio ou eliminação dessas informações. Sobre esse direito, é
          importante lembrar que primeiramente realizaremos uma consulta para
          verificar se realmente não precisamos dos dados que foram coletados;
        </p>
        <p>
          <b>e)</b> Portabilidade dos dados a outro fornecedor de serviço ou
          produto: caso você opte por utilizar alguma plataforma concorrente da
          SPOTT, você poderá nos solicitar o envio de todas as suas informações
          que possuímos conosco diretamente para essa nova plataforma.
          Entretanto, preservaremos os segredos comerciais e industriais que
          eventualmente estejam envolvidos nas operações;
        </p>
        <p>
          <b>f)</b> Eliminação dos dados pessoais tratados com o consentimento
          do titular: caso você tenha nos fornecido seu consentimento para o
          tratamento de seus dados pessoais, poderá solicitar a eliminação
          desses mesmos dados, exceto quando tivermos que manter essas
          informações, nos termos da lei, oportunidade na qual lhe informaremos
          sobre isso;
        </p>
        <p>
          <b>g)</b> Informação das entidades públicas e privadas com as quais
          compartilhamos seus dados: você poderá nos solicitar o detalhamento
          sobre quais órgãos públicos e/ou empresas compartilhamos seus dados
          pessoais;
        </p>
        <p>
          <b>h)</b> Informação sobre a possibilidade de não fornecer
          consentimento e sobre as consequências da negativa: sempre que
          precisarmos do seu consentimento para o tratamento dos seus dados
          pessoais, informaremos a você sobre a possibilidade de não nos
          fornecer essa autorização, bem como o que isso pode acarretar; e
        </p>
        <p>
          <b>i)</b> Revogação do consentimento: você poderá revogar o
          consentimento que nos forneceu para tratar seus dados pessoais.
          Entretanto, poderemos manter informações para o cumprimento de leis
          e/ou regulamentos, além de outras hipóteses expressamente previstas na
          lei.
        </p>

        <h3>7. MANUTENÇÃO E EXCLUSÃO DOS DADOS PESSOAIS</h3>
        <p>
          Manteremos suas informações conosco enquanto você mantiver sua conta
          em nossa Plataforma. Caso você opte por encerrar sua conta, poderá
          solicitar a exclusão dos seus dados pessoais diretamente em nossos
          canais de comunicação, lembrando que a desinstalação do aplicativo não
          basta para que suas informações sejam deletadas.
        </p>

        <p>
          Lembramos também que, mesmo diante da sua solicitação de exclusão dos
          dados, poderemos manter algumas informações conosco, nos termos da
          legislação em vigor. Nesse caso, lhe informaremos a esse respeito.
          Ainda, poderemos manter seus dados conosco de forma anonimizada e para
          uso exclusivo da SPOTT.
        </p>

        <h3>8. ATUALIZAÇÕES DAS POLÍTICAS DE PRIVACIDADE</h3>
        <p>
          Publicaremos a versão atualizada em nosso Aplicativo sempre que
          realizarmos algum tipo de atualização e/ou alteração nessas Políticas
          de Privacidade e, caso seja necessário seu consentimento,
          solicitaremos imediatamente através do seu e-mail cadastrado conosco.
        </p>

        <p>
          Você reconhece que o aceite na presente Política de Privacidade será
          registrado nos nosso banco de dados e poderá ser utilizado como prova
          do consentimento, independentemente de outra formalidade.
        </p>
      </Container>
    </>
  );
};
