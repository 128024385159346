import React from "react";
import * as RouterDom from "react-router-dom";
import { AxiosApi } from "../../../../../helpers/api";
import { BiCheck } from "react-icons/bi";
const { Modal, Spinner } = require("reactstrap");

import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { withTranslation } from "react-i18next";

import Error from "../../../../Alerts/Error";
import { ChargerQRCodeInterface } from "../../ChargerQRCode";

import { Exception } from "../../../../../interfaces/Exception";
import { Connectors } from "../../../../../interfaces/Chargers";

const spottTecLogo = require("../../../../../assets/images/spott-tec-logo.png");

import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  QRCodeSection,
  QRCodeImage,
  QRCodeButtons,
  ModalLink,
  ModalFooter,
  FloatingDisplay,
  CustomButton,
  ClipboardButton,
  CloseButton,
  QRCodePDFToPrint,
} from "./viewchargerqrcode.styles";

interface ViewQRCode extends ChargerQRCodeInterface {
  isOpen: boolean;
  onClose: () => void;
  connector: Connectors;
}

enum FileType {
  PNG = "png",
  JPG = "jpg",
}

function ViewChargerQRCode({
  t: translator,
  isOpen,
  onClose,
  charger,
  connector,
}: ViewQRCode) {
  const { cpCode } = charger;
  const { connectorId } = connector;
  const { site_id }: any = RouterDom.useParams();

  const QRCodeRef = React.useRef<HTMLDivElement>(null);

  const [PNGLoading, setPNGLoading] = React.useState<boolean>(false);
  const [JPGLoading, setJPGLoading] = React.useState<boolean>(false);
  const [printLoading, setPrintLoading] = React.useState<boolean>(false);
  const [handleError, setHandleError] = React.useState<Exception | undefined>();

  const [showFloatingDisplay, setShowFloatingDisplay] =
    React.useState<boolean>(false);

  const [imageLogo, setImageLogo] = React.useState<any>();

  const getPointAccessLogo = async () => {
    const { data } = await AxiosApi.get(`ChargerSites/${site_id}`);
    const logoID: any = data?.csLogoFileId;
    const LogoImg: any = await AxiosApi.get(`/Files/${logoID}`);

    setImageLogo(LogoImg?.data?.fileData);
  };

  const handleDownloadQRCodeImage = React.useCallback(
    async (type: FileType) => {
      if (QRCodeRef.current === null) return;

      try {
        if (type === FileType.JPG) {
          setJPGLoading(true);
        } else {
          setPNGLoading(true);
        }

        const canvas = await html2canvas(QRCodeRef.current, {
          allowTaint: true,
          logging: true,
          useCORS: true,
        });
        const dataURL = canvas.toDataURL(
          type === FileType.JPG ? "image/jpeg" : "image/png",
        );

        downloadjs(
          dataURL,
          `spott-qrcode-charger-${cpCode}-${connectorId}.${
            type === FileType.JPG ? "jpg" : "png"
          }`,
          `image/${type === FileType.JPG ? "jpeg" : "png"}`,
        );
      } catch (err: unknown) {
        console.log(err);

        setHandleError({
          isOpen: true,
          message: err,
          status: 400,
          onClose: () => setHandleError(undefined),
        });
      } finally {
        setPNGLoading(false);
        setJPGLoading(false);
      }
    },
    [QRCodeRef],
  );

  const QRCodePrintError = (err: any) => {
    setPrintLoading(false);

    setHandleError({
      isOpen: true,
      message: err,
      status: 400,
      onClose: () => setHandleError(undefined),
    });
  };

  const handleCopyContentToClipboard = () => {
    navigator.clipboard.writeText(
      `https://app.spott.eco/ch/${cpCode}-${connectorId}`,
    );
    setShowFloatingDisplay(true);
  };

  React.useEffect(() => {
    const timerDelay: number = 1500;

    if (!showFloatingDisplay) return;
    window.setTimeout(() => setShowFloatingDisplay(false), timerDelay);
  }, [showFloatingDisplay]);

  React.useEffect(() => {
    getPointAccessLogo();
  }, []);

  return (
    <>
      <QRCodePDFToPrint ref={QRCodeRef}>
        <img
          src={imageLogo}
          alt="charge-point-logo"
          style={{ width: "16rem", marginTop: "4rem" }}
        />
        <QRCode
          size={250}
          logoWidth={120}
          logoHeight={120}
          logoOpacity={1}
          fgColor="#5b626b"
          logoImage={require("../../../../../assets/images/logo-sm.png")}
          value={`https://app.spott.eco/ch/${cpCode}-${connectorId}`}
        />
        <p>{`${cpCode}-${connectorId}`}</p>
        <img
          src={spottTecLogo}
          alt="spott-tec-logo"
          style={{ width: "13rem" }}
        />
      </QRCodePDFToPrint>

      <Modal isOpen={isOpen} size="lg" centered fullscreen="sm">
        {handleError && <Error {...handleError} />}

        {!handleError && (
          <>
            <ModalContainer>
              <ModalHeader>
                <h1>{translator("chargers_QRCodeTitle")}</h1>
                <p>{translator("chargers_QRCodeSubTitle")}</p>
              </ModalHeader>

              <ModalContent>
                <QRCodeSection>
                  <QRCodeImage>
                    <QRCode
                      size={250}
                      logoWidth={120}
                      logoHeight={120}
                      logoOpacity={1}
                      fgColor="#5b626b"
                      logoImage={require("../../../../../assets/images/logo-sm.png")}
                      value={`https://app.spott.eco/ch/${cpCode}-${connectorId}`}
                    />
                    <p>
                      {translator("chargers_QRCodeEnumCode")}:{" "}
                      {`${cpCode}-${connectorId}`}
                    </p>
                  </QRCodeImage>

                  <QRCodeButtons>
                    <CustomButton
                      buttonDefaultColor={"blue"}
                      buttonWidth={12}
                      onClick={() => handleDownloadQRCodeImage(FileType.PNG)}
                    >
                      {!PNGLoading && translator("chargers_QRCodeDownloadPNG")}
                      {PNGLoading && (
                        <Spinner size="sm" className="text-white">
                          {translator("chargers_QRCodeLoading")}
                        </Spinner>
                      )}
                    </CustomButton>

                    <CustomButton
                      buttonDefaultColor={"blue"}
                      buttonWidth={12}
                      onClick={() => handleDownloadQRCodeImage(FileType.JPG)}
                    >
                      {!JPGLoading && translator("chargers_QRCodeDownloadJPG")}
                      {JPGLoading && (
                        <Spinner size="sm" className="text-white">
                          {translator("chargers_QRCodeLoading")}
                        </Spinner>
                      )}
                    </CustomButton>

                    <ReactToPrint
                      onBeforePrint={() => setPrintLoading(true)}
                      onAfterPrint={() => setPrintLoading(false)}
                      trigger={() => {
                        return (
                          <CustomButton
                            buttonDefaultColor={"blue"}
                            buttonWidth={12}
                          >
                            {!printLoading &&
                              translator("chargers_QRCodePrint")}
                            {printLoading && (
                              <Spinner size="sm" className="text-white">
                                {translator("chargers_QRCodeLoading")}
                              </Spinner>
                            )}
                          </CustomButton>
                        );
                      }}
                      content={() => QRCodeRef?.current}
                      onPrintError={(err) => QRCodePrintError(err)}
                    />
                  </QRCodeButtons>
                </QRCodeSection>

                <ModalLink>
                  <div>
                    <h3>URL</h3>
                    <a
                      href={`https://app.spott.eco/ch/${cpCode}-${connectorId}`}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      {`https://app.spott.eco/ch/${cpCode}-${connectorId}`}
                    </a>
                  </div>

                  <ClipboardButton
                    buttonDefaultColor={"blue"}
                    buttonWidth={10}
                    onClick={handleCopyContentToClipboard}
                    isContentCopied={showFloatingDisplay}
                    disabled={showFloatingDisplay}
                  >
                    {translator(
                      showFloatingDisplay
                        ? "chargers_QRCodeCopied"
                        : "chargers_QRCodeCopy",
                    )}
                  </ClipboardButton>
                </ModalLink>
              </ModalContent>

              <ModalFooter>
                <CloseButton
                  buttonDefaultColor={"grey"}
                  buttonWidth={8}
                  onClick={onClose}
                >
                  {translator("chargers_QRCodeClose")}
                </CloseButton>
              </ModalFooter>

              <FloatingDisplay showFloatingDisplay={showFloatingDisplay}>
                <BiCheck />
                <span>Copied to clipboard!</span>
              </FloatingDisplay>
            </ModalContainer>
          </>
        )}
      </Modal>
    </>
  );
}

export default withTranslation()(ViewChargerQRCode);
