import React from "react";

interface SuccessProps {
  message: string;
  onClose?: () => void;
}

export default function Success({ message, onClose }: SuccessProps) {
  React.useEffect(() => {
    if (onClose)
      setTimeout(() => {
        onClose();
      }, 2500);
  }, []);

  return (
    <div className="mt-3 mb-3">
      <div
        className="swal2-icon swal2-success swal2-animate-success-icon"
        style={{ display: "flex" }}
      >
        <div
          className="swal2-success-circular-line-left"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div>
        <div
          className="swal2-success-fix"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
        <div
          className="swal2-success-circular-line-right"
          style={{ backgroundColor: "rgb(255, 255, 255)" }}
        ></div>
      </div>
      <p className="text-grey text-center mt-1 mb-1 mx-auto w-100">{message}</p>
    </div>
  );
}
