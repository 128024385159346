import styled from "styled-components";

const { AvField, AvForm } = require("availity-reactstrap-validation");

export const Container = styled.div`
  background-color: white;
  width: 100%;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 1rem;
`;

export const FormField = styled(AvForm)`
  width: 100%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;

  p {
    margin: 0;
    padding: 0;
    padding-right: ${document.documentElement.scrollWidth <= 1366 &&
    document.documentElement.scrollWidth >= 1120
      ? "45px"
      : ""};
    color: #353f47;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    word-wrap: break-word;
  }

  svg {
    width: 1.4rem;
    height: 1.4rem;
    margin: 0.6rem;
    cursor: pointer;

    transition: all 300ms;
    &:hover {
      color: #3970f3;
    }

    & + svg:hover {
      color: #d10000;
    }
  }
`;

export const InputsAligment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  flex: 1;

  span {
    color: #637280;

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
  }
`;

export const ButtonsAligment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 0.64rem;

  button[type="button"] {
    background-color: transparent;
    width: 4rem;
  }
  button[type="submit"] {
    width: 10rem;
  }

  /* button[type="button"] {
    background-color: transparent;
    color: #8b8b8b;

    margin-right: 0.8rem;
    border: 0;
    cursor: pointer;

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
    text-align: center;
    text-transform: capitalize;
  }

  button[type="submit"] {
    background-color: #3970f3;
    color: white;
    width: 14rem;
    padding: 0.8rem;
    border: 0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: uppercase;
  } */
`;

export const FormInputField = styled(AvField)`
  width: 6rem;
`;

export const InputField = styled.input`
  width: 6rem;
  padding: 6px 12px;
  border-radius: 3px;
  border: 1px solid transparent;
  border-color: #ced4da;
  color: #495057;
  transition: .3s all;

  &:focus{
    border-color: #3970f3;
  }
`;

