import { DataRowTypes } from "../pages/ChargersNew/components/DataTable/DataTable"
import * as Provider from "../context/ContextProvider";

export function customNumberFilterDataTable(elementA:any, elementB:any){
  const cpCodeA = Number(elementA.rowCpCode.props.data)
  const cpCodeB = Number(elementB.rowCpCode.props.data)

  if(cpCodeA > cpCodeB) return -1
  if(cpCodeA < cpCodeB) return 1
  return 0
}

export function customSortStatusChargers(elementA: any, elementB: any){
  const chargerStatus = elementA.rowStatus.props.chargerStatus
  
  if(chargerStatus === "Offline" || !chargerStatus) return 1
  if(chargerStatus === "Online" || !chargerStatus) return -1

  return 0
};

export function customSortStatusMonitoring(elementA: any, elementB: any){
  
  const chargerStatusA   = elementA.rowStatus[0].props.children.props.chargerStatus;
  const chargerStatusB   = elementB.rowStatus[0].props.children.props.chargerStatus;
  const connectorStatusA = elementA.rowStatus[0].props.children.props.connectorStatus;
  const connectorStatusB = elementB.rowStatus[0].props.children.props.connectorStatus;

  const connectorStatusOrder = [
    'Available',
    'Preparing',
    'Charging',
    'Reserved',
    'Suspended',
    'SuspendedEV',
    'Finishing',
    'Unavailable',
    'Faulted',
    'Waiting',
    'available-offilne',
  ];

  const resConnectorA = connectorStatusA && chargerStatusA === "Offline" ? 'available-offilne' : connectorStatusA ? connectorStatusA : 'Waiting'
  const resConnectorB = connectorStatusB && chargerStatusB === "Offline" ? 'available-offilne' : connectorStatusB ? connectorStatusB : 'Waiting'

  const connectorStatusAIndex = connectorStatusOrder.indexOf(resConnectorA);
  const connectorStatusBIndex = connectorStatusOrder.indexOf(resConnectorB);

  if(connectorStatusAIndex > connectorStatusBIndex) {
    return 1
  }
  if(connectorStatusAIndex < connectorStatusBIndex) {
   return -1
  }

  return 0
};

export function customSortDatesChargers(elementA: DataRowTypes, elementB: DataRowTypes){

  console.log(elementA, 'elementA')
  
  enum SystemLanguage {
    US = "en-US",
    BR = "pt-BR",
  }

  const { state }: any = Provider.useStateContext();
  const [month_dayA, day_monthA, year_timeA] = String(
    elementA.rowIncludedDate.date,
  ).split("/");
  const [yearA, timeA] = year_timeA.split("-");

  const [month_dayB, day_monthB, year_timeB] = String(
    elementB.rowIncludedDate.date,
  ).split("/");
  const [yearB, timeB] = year_timeB.split("-");

  const dateA_US = `${yearA}-${month_dayA}-${day_monthA}`; // 2023-05-22
  const dateB_US = `${yearB}-${month_dayB}-${day_monthB}`;

  const dateA_BR = `${yearA}-${day_monthA}-${month_dayA}`; // 2023-05-22 (in pt-BR day becomes month - month becomes day)
  const dateB_BR = `${yearB}-${day_monthB}-${month_dayB}`;

  if (state?.userData?.language === SystemLanguage.US) {
    if (dateA_US > dateB_US) return 1;
    if (dateA_US < dateB_US) return -1;
  }

  if (state?.userData?.language === SystemLanguage.BR) {
    if (dateA_BR > dateB_BR) return 1;
    if (dateA_BR < dateB_BR) return -1;
  }

  return 0;
};

export function customSortIdChargers(elementA: any, elementB: any){

  const IdElementA = elementA.rowChargerId.props.data;
  const IdElementB = elementB.rowChargerId.props.data;

  return IdElementA.localeCompare(IdElementB);
};

export function customSortExclusiveUseChargers(elementA: DataRowTypes){
  
  const exclusiveUse = elementA.rowExclusiveAccess;

  if(exclusiveUse === false){
    return 1;
  } else{
    return -1;
  }
};