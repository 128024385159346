import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import './NotificationDropdown.scss';

//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = (props) => {
  const [menu, setMenu] = useState(false);

  const dataFormat = (datastring) => {
    const datajs = new Date(datastring);
    const processDay = (day) => {
      const hoje = new Date();
      if (day.getDate() === hoje.getDate()) return "Hoje";
      if (day.getDate() === hoje.getDate() - 1) return "Ontem";
      return day.getDate() + "/" + day.getMonth();
    }

    const result = {
      'dia': processDay(datajs),
      'hora': datajs.getHours(),
      'min': datajs.getMinutes() === 0 ? "" : data.js.getMinutes(),
    }
    return `${result.dia} - ${result.hora}h${result.min}`;
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="mdi mdi-bell-outline"></i>
          {props.data.length !== 0 ? <span className="badge bg-danger rounded-pill">{props.data.length}</span> : null}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0 font-size-16">
                  {" "}
                  {props.t("Alerts")} {props.data.length !== 0 ? "(" + props.data.length + ")" : null}
                </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {/* <Link to="" className="text-reset notification-item">
              <div className="d-flex">
                <div className="avatar-xs me-3">
                  <span className="avatar-title bg-success rounded-circle font-size-16">
                    <i className="mdi mdi-cart-outline"></i>
                  </span>
                </div>
                <div className="flex-1">
                  <h6 className="mt-0 mb-1">Your order is placed</h6>
                  <div className="font-size-12 text-muted">
                    <p className="mb-1">
                      Dummy text of the printing and typesetting industry.
                    </p>
                  </div>
                </div>
              </div>
            </Link> */}
            {props.data.length === 0 ? 
            <span className="span-No-Alerts">
              <p>{props.t("No Alerts")}</p>
            </span>
            :
            ''
            }
            {props.data.map((item, index) => {
              return (
                <div to="" className="text-reset notification-item" key={index}>
                  <div className="d-flex">
                    <div className="flex-1">
                      <h6 className="mt-0 mb-1">{dataFormat(item.alertDate)}</h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">
                          {item.message} <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </SimpleBar>

          <div className="p-2 border-top d-grid">
            <Link to="/alerts" className="btn btn-sm btn-link font-size-14 btn-block text-center">
              <i className="mdi mdi-arrow-right-circle me-1"></i>{" "}
              {props.t("View all")}{" "}
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any,
};
