import React from "react";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
const { Modal } = require("reactstrap");
import { withTranslation } from "react-i18next";

import {
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalInfo,
  ModalFooter,
  CustomButton,
} from "./modaldeletecharger.styles";

type ChargerType = {
  isOpen: boolean;
  chargerID: number;
  onHandleDeleteCharger: (id: number) => void;
};

type ModalDeleteChargerPropsType = {
  t: Function;
  Charger: ChargerType;
  onHandleCloseModal: () => void;
};

export const ModalDeleteCharger = ({
  t: translator,
  Charger,
  onHandleCloseModal,
}: ModalDeleteChargerPropsType) => {
  const [checkElement, setCheckElement] = React.useState<boolean>(false);

  const resetCheckElementState = () => setCheckElement(false);

  return (
    <>
      <Modal isOpen={Charger.isOpen} size="lg" fullscreen="sm" centered>
        <ModalContainer>
          <ModalHeader>
            <h1>
              {translator(`chargers_ModalDeleteTitle`)} {Charger.chargerID}
            </h1>
          </ModalHeader>

          <ModalContent>
            <p>{translator(`chargers_ModalDeleteQuestion`)}</p>
            <p>{translator(`chargers_ModalDeleteInfo`)}</p>
          </ModalContent>

          <ModalInfo isElementChecked={checkElement}>
            <span onClick={() => setCheckElement((prev) => !prev)}>
              {checkElement ? <ImCheckboxChecked /> : <ImCheckboxUnchecked />}
            </span>
            <p>{translator(`chargers_ModalDeleteCheckBox`)}</p>
          </ModalInfo>

          <ModalFooter>
            <CustomButton
              buttonColor={"grey"}
              onClick={() => {
                onHandleCloseModal();
                resetCheckElementState();
              }}
            >
              {translator(`chargers_ModalDeleteCancelButton`)}
            </CustomButton>

            <CustomButton
              buttonColor={"red"}
              disabled={!checkElement}
              onClick={() => {
                resetCheckElementState();
                Charger.onHandleDeleteCharger(Charger.chargerID);
              }}
            >
              {translator(`chargers_ModalDeleteConfirmButton`)}
            </CustomButton>
          </ModalFooter>
        </ModalContainer>
      </Modal>
    </>
  );
};

export default withTranslation()(ModalDeleteCharger);
