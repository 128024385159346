import "./StatusChargers.scss";
import React from "react";
import { Row, Card, CardBody, Spinner } from "reactstrap";
import { withTranslation, useTranslation } from "react-i18next";

const StatusChargers = ({ data, t }) => {
  if (data.loading === true) {
    return (
      <div className="pb-4 h-100">
        <Card className="status-chargers">
          <CardBody className="d-flex align-items-center justify-content-center">
            <Spinner>Loading...</Spinner>
          </CardBody>
        </Card>
      </div>
    );
  }

  if (data.error === true) {
    return (
      <div className="pb-4 h-100">
        <Card className="status-chargers">
          <CardBody className="align-items-center justify-content-center">
            <p className="card-title">{t("Status Chargers")}</p>
            <div className="mb-2 d-flex h-100 flex-row align-itens-center justify-content-center">
              <p className="card-title align-self-center">
                {t("No data to display")}
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  const icons = {
    chargerGreen: process.env.PUBLIC_URL + "/images/charger-green.png",
    chargerBlue: process.env.PUBLIC_URL + "/images/charger-blue.png",
    chargerRed: process.env.PUBLIC_URL + "/images/charger-red.png",
  };
  return (
    <div className="pb-4 h-100">
      <Card className="status-chargers">
        <CardBody>
          <p className="card-title">{t("Conector Status")}</p>
          <div className="status-chargers-container-flex">
            <div className="w-100">
              <div className="card-status-charges">
                <p className="card-status-charges-title green">{t("Disponivel")}</p>
                <img
                  src={icons.chargerGreen}
                  className="image-card-status-charges"
                />
                <h4 className="card-status-charges-number green">
                  {data.available}
                </h4>
              </div>
            </div>

            <div className="w-100">
              <div className="card-status-charges">
                <p className="card-status-charges-title red">{t("Offline")}</p>
                <img
                  src={icons.chargerRed}
                  className="image-card-status-charges"
                />
                <h4 className="card-status-charges-number red">
                  {data.offLine}
                </h4>
              </div>
            </div>

            <div className="w-100">
              <div className="card-status-charges no-border">
                <p className="card-status-charges-title blue">{t("In Use")}</p>
                <img
                  src={icons.chargerBlue}
                  className="image-card-status-charges"
                />
                <h4 className="card-status-charges-number blue">
                  {data.inUse}
                </h4>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default withTranslation()(StatusChargers);
