import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;

  border-bottom: 1px solid black;
  border-color: #dadbdc;
`;

export const LogList = styled.div``;

export const DisplayDetails = styled.div`
  color: #000000;
  width: 100%;
  margin: 0 auto;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
  }

  span {
    margin-left: 0.24rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
  }
`;

export const ButtonsAligment = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 1rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
    text-transform: uppercase;
  }
`;
