import "./MotlyPreferencesUse.scss";
import React from "react";
import { Row, Card, CardBody, Spinner } from "reactstrap";
import ChartistGraph from "react-chartist";
import { withTranslation } from "react-i18next";

const MotlyPreferencesUse = ({ text, data, t }) => {
  if (!Array.isArray(data) && data !== null) {
    return (
      <Card className="motly-pref-use">
        <CardBody>
          <p className="card-title">{t("Weekly use preference")}</p>
          <div className="mb-2 d-flex h-100 flex-row align-itens-center justify-content-center">
            <p className="card-title align-self-center">
              {t("Error loading data.")}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  }

  if (!data) {
    return (
      <Card className="h-100">
        <CardBody className="d-flex align-items-center justify-content-center">
          <Spinner>{t("Loading...")}</Spinner>
        </CardBody>
      </Card>
    );
  }

  if (data.length === 0) {
    return (
      <Card className="motly-pref-use">
        <CardBody>
          <p className="card-title">{text + " " + t("Por Dia")}</p>
          <Row>
            <div className="mt-2 mb-3">{t("No data to display")}</div>
          </Row>
        </CardBody>
      </Card>
    );
  }

  const values = data.map((item) => item.value);
  const lineChartData = {
    labels: [
      t("Sun"),
      t("Mon"),
      t("Tue"),
      t("Wed"),
      t("Thu"),
      t("Fri"),
      t("Sat"),
    ],
    series: [values],
  };
  const lineChartOptions = {
    showArea: true,
    height: "300px",
  };

  return (
    <Card className="motly-pref-use">
      <CardBody>
        {text ? (
          <p className="textgraph">{text + " " + t("Por Dia")}</p>
        ) : (
          <p className="card-title">{t("Weekly use preference")}</p>
        )}
        <Row>
          <div className="mt-2 mb-3">
            <ChartistGraph
              data={lineChartData}
              className="grafico01 earning ct-golden-section"
              options={lineChartOptions}
              type={"Bar"}
            />
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(MotlyPreferencesUse);
