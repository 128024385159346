import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Container = styled.div`
  width: 100%;
`;

export const Display = styled.div`
  width: 100%;
  height: 66vh;
  margin: 0 auto;
  overflow: hidden;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  z-index: 1;
`;

export const DisplayContentDataRefer = styled.div`
  color: #979797;
  width: 100%;
  height: 100%;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 1px solid #979797;
  border-right: none;
  border-radius: 0.24rem;

  display: flex;
  flex-direction: column;

  svg {
    color: #666666;
    width: 2rem;
    height: 2rem;
  }

  p {
    margin: 1rem;
    color: #666666;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: none;
  }
`;

export const NoCardData = styled.div`
  background-color: white;
  width: 100%;
  height: 8.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    margin: 1rem;
    color: #666666;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.4rem;
    text-align: center;
    text-transform: none;
  }
`;
