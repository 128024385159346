import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

type DropListPropsType = {
  dropListWidth: number;
};

export const Wrapper = styled.section`
  width: 100%;
`;

export const Content = styled.div`
  width: 100%;

  p {
    color: #000000;
    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 0.88rem;
  }

  strong {
    font-weight: 700;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.8rem;
  }

  span {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 1rem;
    text-transform: uppercase;
  }
`;

export const DropList = styled.label<DropListPropsType>`
  width: ${({ dropListWidth }) => dropListWidth || 32}rem;
  position: relative;
  color: #484552;

  select {
    background-color: #eaeaea;
    width: 100%;
    height: 2.45rem;
    margin-top: 0.4rem;
    padding-left: 0.5rem;

    border-radius: 0.5rem;
    border: 1px solid #dadbdc;
    cursor: pointer;

    option {
      color: #5b626b;
    }
  }
`;
