import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";
import { transparentize, darken, lighten, rgba } from "polished";

import * as Provider from "../../context/ContextProvider";
import { AxiosApi as api } from "../../helpers/api";

import Switch from "react-switch";
import { Button } from "../../components/Button";
import { InputLabel } from "../../components/Inputs";
import { DroplistLabel } from "../../components/Droplists";
import TogglerIcon from "../../components/Renders/TogglerIcon/TogglerIcon";
import ErrorModal from "../../components/Alerts/ErrorModal";
import SuccessModal from "../../components/Alerts/SuccessModal";

import { RouterDomTypes } from "../../interfaces/RouterDom";
import { SiteAccessTypes } from "../../interfaces/SiteUsers";
import { DroplistTypes } from "../../interfaces/Droplist";
import { SmartSpottTypes } from "../../interfaces/SmartSpott";
import { Exception, ResponseSuccess } from "../../interfaces/Exception";

import {
  Component,
  Wrapper,
  Header,
  Content,
  SmartSettings,
  GraphFrame,
} from "./smartspott.styles";

type SmartSpottPropsTypes = {
  t: Function;
};

export const SmartSpott = ({ t: translation }: SmartSpottPropsTypes) => {
  const { REACT_APP_GRAFANA_HOST } = process.env;
  const { site_id } = RouterDom.useParams<RouterDomTypes>();
  const { context, state }: any = Provider.useStateContext();

  const droplistOptions: DroplistTypes = [
    {
      value: "220",
      name: "220 V",
    },
    {
      value: "380",
      name: "380 V",
    },
    {
      value: "440",
      name: "440 V",
    },
  ];

  const [siteAccess, setSiteAccess] = React.useState<SiteAccessTypes>(
    {} as SiteAccessTypes,
  );

  const [smartSpottdata, setSmartSpottData] = React.useState<SmartSpottTypes>({
    csDlmEnabled: false,
    csVoltage: "",
    csDlmMaxLoadAmp: "",
    csMaxLoadAmp: "",
    csSmartMaxLoadAmp: "",
  });

  const [handleError, setHandleError] = React.useState<Exception | undefined>();
  const [handleSuccess, setHandleSuccess] = React.useState<
    ResponseSuccess | undefined
  >();

  // Api fetch
  const getSmartSpottData = async () => {
    try {
      const { data } = await api.get<SmartSpottTypes>(
        `SmartSpottSettings/${site_id}`,
      );

      const mutableData = structuredClone(data);
      const smartSpottDataModed: SmartSpottTypes = {
        csDlmEnabled: false,
        csVoltage: mutableData.csVoltage,
        csDlmMaxLoadAmp: `${mutableData.csDlmMaxLoadAmp} A`,
        csMaxLoadAmp: `${mutableData.csMaxLoadAmp} A`,
        csSmartMaxLoadAmp: `${mutableData.csSmartMaxLoadAmp} A`,
      };

      setSmartSpottData(smartSpottDataModed);
    } catch (error: unknown) {
      console.log(error);
    }
  };
  const dispatchSmartSettings = async () => {
    try {
      const settings: SmartSpottTypes = structuredClone(smartSpottdata);

      const payload = {
        csDlmEnabled: settings.csDlmEnabled,
        csVoltage: settings.csVoltage,
        csDlmMaxLoadAmp: Number(
          settings.csDlmMaxLoadAmp.replace(" A", "").trim(),
        ),
        csMaxLoadAmp: Number(settings.csMaxLoadAmp.replace(" A", "").trim()),
        csSmartMaxLoadAmp: Number(
          settings.csSmartMaxLoadAmp.replace(" A", "").trim(),
        ),
      };

      const response = await api.put<SmartSpottTypes>(
        `SmartSpottSettings/${site_id}`,
        payload,
      );

      if (response.status === 200) {
        setHandleSuccess(() => ({
          isOpen: true,
          message: translation("global_SuccessModalResponse"),
          onClose: () => setHandleSuccess(undefined),
        }));
      }
    } catch (error: any) {
      setHandleError({
        isOpen: true,
        message: translation("global_ErrorModalMessage"),
        status: error?.response?.status,
        onClose: () => setHandleError(undefined),
      });
    }
  };

  // Life cycle
  React.useMemo(() => {
    if (!state.sites.length) return;
    if (!site_id.length) return;

    const pageSite: SiteAccessTypes = state.sites.find(
      (site: any) => site.csId === site_id,
    );

    if (typeof pageSite === "undefined") return;

    setSiteAccess(pageSite);
    getSmartSpottData();
  }, [state.sites, site_id]);

  // Render
  return (
    <>
      <Component>
        <Wrapper>
          <Header>
            <h1>
              <span>
                {siteAccess?.csName} <span>/</span>
              </span>
              Smart Spott
            </h1>
          </Header>

          <Content>
            <SmartSettings>
              <section>
                <h2>{translation("smartspott_AddTechnicalInformation")}</h2>
                <InputLabel
                  name={"max-load"}
                  type={"text"}
                  label={translation("smartspott_LabelMaximumLoad")}
                  placeholder={"400 A"}
                  inputAllowOnlyNumbers
                  value={smartSpottdata.csMaxLoadAmp}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csMaxLoadAmp: event.target.value,
                    }));
                  }}
                  onFocus={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = event.target.value
                      .replace(" A", "")
                      .trim();

                    setSmartSpottData((prev) => ({
                      ...prev,
                      csMaxLoadAmp: eventValue,
                    }));
                  }}
                  onBlur={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = `${Number(event.target.value) ?? 0} A`;
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csMaxLoadAmp: eventValue,
                    }));
                  }}
                />

                <DroplistLabel
                  required
                  label={translation("smartspott_LabelPower")}
                  primaryOptionName={translation(
                    "global_DroplistOptionDefault",
                  )}
                  enablePrimaryOption={true}
                  options={droplistOptions}
                  value={smartSpottdata.csVoltage}
                  onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csVoltage: event.target.value,
                    }));
                  }}
                />

                <InputLabel
                  name={"max-load-amp"}
                  type={"text"}
                  label={translation("smartspott_LabelMaximumCurrentLoad")}
                  placeholder={"50 A"}
                  inputAllowOnlyNumbers
                  value={smartSpottdata.csSmartMaxLoadAmp}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csSmartMaxLoadAmp: event.target.value,
                    }));
                  }}
                  onFocus={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = event.target.value
                      .replace(" A", "")
                      .trim();

                    setSmartSpottData((prev) => ({
                      ...prev,
                      csSmartMaxLoadAmp: eventValue,
                    }));
                  }}
                  onBlur={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = `${Number(event.target.value) ?? 0} A`;
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csSmartMaxLoadAmp: eventValue,
                    }));
                  }}
                />
              </section>

              <section>
                <h2>{translation("smartspott_OptimizeUsageLoad")}</h2>

                <div className="switcher">
                  <Switch
                    id="update-switch"
                    className="react-switch"
                    onColor={"#028b82"}
                    offColor={`${darken(0.48, "#e7e8ea")}`}
                    uncheckedIcon={<TogglerIcon icon={"Off"} />}
                    checkedIcon={<TogglerIcon icon={"On"} />}
                    checked={smartSpottdata.csDlmEnabled}
                    onChange={(event: boolean) => {
                      setSmartSpottData((prev) => ({
                        ...prev,
                        csDlmEnabled: event,
                      }));
                    }}
                  />

                  <span>
                    {translation("smartspott_LabelEnableLoadBalancing")}
                  </span>
                </div>

                <InputLabel
                  disabled={!smartSpottdata.csDlmEnabled}
                  name={"max-dyn-amp"}
                  type={"text"}
                  label={translation("smartspott_LabelMaximumDynamicLoad")}
                  placeholder={"100 A"}
                  inputAllowOnlyNumbers
                  value={smartSpottdata.csDlmMaxLoadAmp}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csDlmMaxLoadAmp: event.target.value,
                    }));
                  }}
                  onFocus={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = event.target.value
                      .replace(" A", "")
                      .trim();

                    setSmartSpottData((prev) => ({
                      ...prev,
                      csDlmMaxLoadAmp: eventValue,
                    }));
                  }}
                  onBlur={(
                    event: React.FocusEvent<HTMLInputElement, Element>,
                  ) => {
                    const eventValue = `${Number(event.target.value) ?? 0} A`;
                    setSmartSpottData((prev) => ({
                      ...prev,
                      csDlmMaxLoadAmp: eventValue,
                    }));
                  }}
                />

                <Button
                  buttonColor={"blue"}
                  type={"button"}
                  onClick={dispatchSmartSettings}
                >
                  {translation("smartspott_ButtonSave")}
                </Button>
              </section>
            </SmartSettings>

            <GraphFrame>
              <header>{translation("Site Load")}</header>
              <iframe
                id="player"
                width="640"
                height="360"
                src={`${REACT_APP_GRAFANA_HOST}/grafana/d-solo/dD-CtX3nz/site-load?orgId=2&var-site=${site_id}&refresh=10s&from=now-30m&to=now&theme=light&panelId=2`}
              >
                <span>{translation("global_NoDataToDisplay")}</span>
              </iframe>
            </GraphFrame>
          </Content>
        </Wrapper>

        {handleError && <ErrorModal {...handleError} />}
        {handleSuccess && <SuccessModal {...handleSuccess} />}
      </Component>
    </>
  );
};

export default Translator.withTranslation()(SmartSpott);
