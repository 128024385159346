import * as React from "react";

import { Component, Wrapper } from "./module.styles";

type ModulePropsTypes = {
  children: React.ReactNode;
};

export const Module = ({ children }: ModulePropsTypes) => {
  return (
    <>
      <Component>
        <Wrapper>
          <hr />
          {children}
          <hr />
        </Wrapper>
      </Component>
    </>
  );
};
