import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
  display: grid;
  grid-gap: 1.32rem;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  background-color: white;
  width: 100%;
  margin: 0 auto;
  padding: 0.32rem;
  border: 1px solid #edeeef;
  border-radius: 0.32rem;
  overflow: hidden;

  button {
    width: 10rem;

    // Place button right aligned
    display: block;
    margin-left: auto;
    margin-right: 0;
  }
`;

export const ButtonAddUser = styled.button`
  background-color: #3970f3;
  color: white;
  width: 12rem;
  padding: 0.8rem;
  border: 0;
  border-radius: 0.5rem;

  // Place button right aligned
  display: block;
  margin-left: auto;
  margin-right: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1rem;
  text-align: center;
  text-transform: uppercase;

  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  transition: filter 300ms;

  &:hover {
    filter: brightness(0.9);
  }
`;
