import * as React from "react";
import * as Translator from "react-i18next";

import { Background } from "./connectorstatus.styles";

type ConnectorStatusPropsTypes = {
  t: Function;
  chargerStatus: string;
  connectorStatus: string;
};

enum EConnectorStatus {
  DEFAULT = "offline",
  OFFLINE = "offline",
  AVAILABLE = "available",
  PREPARING = "preparing",
  CHARGING = "charging",
  RESERVED = "reserved",
  SUSPENDEDEV = "suspendedev",
  SUSPENDEDEVSE = "suspendedevse",
  FINISHING = "finishing",
  UNAVAILABLE = "unavailable",
  FAULTED = "faulted",
}

enum EChargerStatus {
  DEFAULT = "offline",
  OFFLINE = "offline",
  AVAILABLE = "available",
  ONLINE = "online",
}

export const ConnectorStatus = ({
  t: translation,
  chargerStatus,
  connectorStatus,
}: ConnectorStatusPropsTypes) => {
  const chargerStatusDimp = String(chargerStatus).toLocaleLowerCase();
  const connectorStatusDimp = String(connectorStatus).toLocaleLowerCase();

  const [statusType, setStatusType] = React.useState<
    "offline" | "available" | "using" | "unavailable"
  >("offline");

  // Life cycle
  React.useEffect(() => {
    if (
      (chargerStatusDimp === EChargerStatus.DEFAULT ||
        chargerStatusDimp === EChargerStatus.OFFLINE) &&
      (connectorStatusDimp === EConnectorStatus.DEFAULT ||
        connectorStatusDimp === EConnectorStatus.OFFLINE)
    ) {
      setStatusType("offline");
      return;
    }

    if (
      chargerStatusDimp === EChargerStatus.ONLINE &&
      connectorStatusDimp === EConnectorStatus.AVAILABLE
    ) {
      setStatusType("available");
      return;
    }

    // if (connectorStatusDimp === EConnectorStatus.AVAILABLE) {
    //   setStatusType("available");
    //   return;
    // }

    if (
      connectorStatusDimp === EConnectorStatus.UNAVAILABLE ||
      connectorStatusDimp === EConnectorStatus.FAULTED
    ) {
      setStatusType("unavailable");
      return;
    }

    if (
      connectorStatusDimp === EConnectorStatus.PREPARING ||
      connectorStatusDimp === EConnectorStatus.CHARGING ||
      connectorStatusDimp === EConnectorStatus.RESERVED ||
      connectorStatusDimp === EConnectorStatus.SUSPENDEDEV ||
      connectorStatusDimp === EConnectorStatus.SUSPENDEDEVSE ||
      connectorStatusDimp === EConnectorStatus.FINISHING
    ) {
      setStatusType("using");
      return;
    }

    return;
  }, [chargerStatus, connectorStatus]);

  return (
    <>
      <Background connectorStatus={statusType}>
        <span>
          {translation(
            `monitoring_Status${
              (chargerStatusDimp === EChargerStatus.OFFLINE ||
                chargerStatus === null) &&
              (connectorStatusDimp === EConnectorStatus.AVAILABLE ||
                connectorStatus === undefined ||
                connectorStatus === null)
                ? (connectorStatus = "Offline")
                : chargerStatusDimp === EChargerStatus.ONLINE &&
                  (connectorStatus === undefined || connectorStatus === null)
                ? (connectorStatus = "Offline")
                : connectorStatus
            }`,
          )}
        </span>
      </Background>
    </>
  );
};

export default Translator.withTranslation()(ConnectorStatus);
