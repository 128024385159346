import React from "react";
const { Button } = require("reactstrap");
import { withTranslation } from "react-i18next";

import RegisterUserModal from "./components/RegisterUserModal/RegisterUserModal";

import { Charger } from "../../../interfaces/Chargers";

const avatar = require("../../../assets/images/icon_owner_big.png");

import { Container, Content } from "./chargerexclusive.styles";

type ChargerExclusivePropsType = {
  t: Function;
  charger: Charger;
};

export const ChargerExclusive = ({
  t: translator,
  charger,
}: ChargerExclusivePropsType) => {
  const [openRegisterUserModal, setOpenRegisterUserModal] =
    React.useState<boolean>(false);

  const onHandleOpenModal = () => setOpenRegisterUserModal(true);
  const onHandleCloseModal = () => setOpenRegisterUserModal(false);

  return (
    <>
      <Container>
        <Content>
          <img src={avatar} alt="icon_owner" />

          <div>
            <h4>{translator("chargers_ExclusiveChargerExclusiveCharger")}</h4>
            <p>{translator("chargers_ExclusiveChargerText")}</p>
            <Button
              color="primary"
              className="mt-2 px-5 btn-sm rounded-button"
              onClick={onHandleOpenModal}
            >
              <span>
                {translator("chargers_ExclusiveChargerDefineUsersButton")}
              </span>
            </Button>
          </div>
        </Content>
      </Container>

      <hr />

      {/* Modals */}
      <>
        {openRegisterUserModal && (
          <RegisterUserModal
            charger={charger}
            modalIsOpen={openRegisterUserModal}
            modalHandleClose={onHandleCloseModal}
          />
        )}
      </>
    </>
  );
};

export default withTranslation()(ChargerExclusive);
