import React, { useEffect, useState } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, } from "reactstrap";
import { get, map } from "lodash";
import { withTranslation } from "react-i18next";
import { useStateContext } from "../../../context/ContextProvider";

//i18n
import i18n, { getLanguage } from "../../../i18n";
import languages from "../../../common/languages";
import { AxiosApi } from "../../../helpers/api";

const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const [selectedLang, setSelectedLang] = useState("");
  const [menu, setMenu] = useState(false);
  const { context, state } = useStateContext();
  const navLang = (navigator.language === "pt-BR" || navigator.language === "en-US") ? navigator.language : "en-US";

  useEffect(() => {
    const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
    setSelectedLang(currentLanguage);
  }, []);

  useEffect(() => {
    if (state?.userData?.language) {
      const langUser = state.userData.language.substring(0, 2).toLowerCase();
      changeLanguageAction(langUser);
    }
  }, [state.userData?.language]);

  const changeLanguageAction = (lang) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("I18N_LANGUAGE", lang);
    setSelectedLang(lang);
    if (state?.userData !== null && state?.userData?.language.substring(0, 2).toLowerCase() !== lang) {
      updateLanguageState(lang)
    };
  };

  const updateLanguageState = (lang) => {
    const value = (lang === "en") ? "en-US" : "pt-BR";
    context.changeLanguage(value);
  }

  const toggle = () => {
    setMenu(!menu);
  };

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-none d-md-block ms-2"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <img
            src={get(languages, `${selectedLang}.flag`)}
            alt="Veltrix"
            height="16"
            className="me-2"
          />{" "}
          {get(languages, `${selectedLang}.label`)}{" "}
          <span className="mdi mdi-chevron-down"></span>
        </DropdownToggle>
        <DropdownMenu className="language-switch dropdown-menu-end">
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Veltrix"
                className="me-2"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default withTranslation()(LanguageDropdown);
