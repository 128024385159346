import * as React from "react";
import * as Translator from "react-i18next";
import { BsExclamationDiamond } from "react-icons/bs";

import { Component, Wrapper } from "./displaynodata.styles";

type DisplayNoDataPropsTypes = {
  t: Function;
};

export const DisplayNoData = ({ t: translation }: DisplayNoDataPropsTypes) => {
  return (
    <>
      <Component>
        <Wrapper>
          <BsExclamationDiamond />
          <p>{translation("global_NoDataToDisplay")}</p>
        </Wrapper>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(DisplayNoData);
