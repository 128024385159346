import * as React from "react";
import * as Translator from "react-i18next";
import { BsExclamationDiamond } from "react-icons/bs";
const { Modal } = require("reactstrap");

import { AxiosApi } from "../../../../../helpers/api";

import { ModalAction } from "../../../../../components/Modal/ModalAction/ModalAction";

import { Charger, ChargerPoint } from "../../../../../interfaces/Chargers";

const UserAvatarIcon = require("../../../../../assets/images/icon_owner_big.png");

import * as ContextProvider from "../../../../../context/ContextProvider";

import {
  ModalWrapper,
  ModalHeader,
  ModalCloseIcon,
  ModalFormField,
  ModalFormInputField,
  ModalUsersList,
  ModalUsersListDisplay,
  ModalDisplayItem,
  EmptyList,
  ButtonSubmit,
  ButtonDelete,
  TextValidation,
} from "./registerusermodal.styles";

type RegisterUserModalPropsType = {
  t: Function;
  charger: Charger;
  modalIsOpen: boolean;
  modalHandleClose: () => void;
};

type FormSubmitValuesType = {
  email: string;
};

export const RegisterUserModal = ({
  t: translation,
  charger,
  modalIsOpen,
  modalHandleClose,
}: RegisterUserModalPropsType) => {
  const { context, state }: any = ContextProvider.useStateContext();
  const { chargePointId }: Charger = charger;

  const [apiData, setApiData] = React.useState<ChargerPoint[]>([]);
  const [validation, setValidation] = React.useState(0);
  console.log("verification datas", apiData);
  const [userDeletion, setUserDeletion] = React.useState({
    id: null,
    userEmail: "",
    openDeletionModal: false,
  });

  const handleOpenDeletionModal = (id: any, userEmail: string) => {
    setUserDeletion((prev) => ({
      id,
      userEmail,
      openDeletionModal: true,
    }));
  };
  const handleCloseDeletionModal = () => {
    setUserDeletion((prev) => ({
      ...prev,
      openDeletionModal: false,
    }));
  };

  const handleSubmitForm = async (
    event: React.FormEvent<HTMLFormElement>,
    values: FormSubmitValuesType,
  ) => {
    event.preventDefault();

    if (!values || values === null) return;

    try {
      const payload = {
        ChargepointId: chargePointId,
        UserName: values.email,
      };
      const verificationUserSubscribe = apiData.find(
        (res) => res.email === payload.UserName,
      );

      if (verificationUserSubscribe) {
        setValidation(1);
        return;
      } else {
        setValidation(0);
      }

      await AxiosApi.post(`/UserChargePointPermissions`, payload)
        .then((res) => {
          res.data;
          context.getAuthorizationReload(true);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            setValidation(2);
          }
        });
    } catch (error) {
      console.log("teste", JSON.stringify(error));
      console.log(status);
    } finally {
      getApiData();
    }
  };

  const handleDeleteUser = async () => {
    try {
      const mutableApiData = [...apiData];

      const selectedUser = mutableApiData.find(
        (current) => current.ucpId === userDeletion.id,
      );

      const { status } = await AxiosApi.delete(
        `/UserChargePointPermissions/${selectedUser?.ucpId}`,
      );
      context.getAuthorizationReload(true);
      if (status === 204) {
        console.log("User deleted successfully");
      }
    } catch (error: unknown) {
      console.log(error);
    } finally {
      getApiData();
      handleCloseDeletionModal();
    }
  };

  const getApiData = async () => {
    try {
      const { data } = await AxiosApi.get(
        `/UserChargePointPermissions?ChargePointId=${chargePointId}`,
      );

      setApiData(data);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getApiData();
  }, []);

  const Avatar = (): JSX.Element => {
    return <img src={UserAvatarIcon} alt="user-avatar" />;
  };

  return (
    <>
      <Modal isOpen={modalIsOpen} size="lg" centered={false} fullscreen="sm">
        <ModalWrapper>
          <ModalHeader>
            <ModalCloseIcon onClick={modalHandleClose}>x</ModalCloseIcon>
            <h1>{translation("chargers_ModalExclusiveChargerTitle")}</h1>
            <p>{translation("chargers_ModalExclusiveChargerText")}</p>
          </ModalHeader>

          <ModalFormField onValidSubmit={handleSubmitForm}>
            <ModalFormInputField
              required
              id={"email"}
              name={"email"}
              type={"email"}
              placeholder={translation(
                "chargers_ModalExclusiveChargerInputPlaceholder",
              )}
            />
            {validation === 1 ? (
              <TextValidation>
                {translation(
                  "chargers_ModalExclusiveChargerValidationRegistered",
                )}
              </TextValidation>
            ) : validation === 2 ? (
              <TextValidation>
                {" "}
                {translation(
                  "chargers_ModalExclusiveChargerValidationNonexistent",
                )}
              </TextValidation>
            ) : (
              <></>
            )}

            <ButtonSubmit type="submit">
              {translation("chargers_ModalExclusiveChargerButtonAdd")}
            </ButtonSubmit>
          </ModalFormField>

          <ModalUsersList>
            <p>
              {translation("chargers_ModalExclusiveChargerRegisteredUsers")}
            </p>
            <hr></hr>

            <ModalUsersListDisplay>
              {!apiData.length && (
                <EmptyList>
                  <BsExclamationDiamond />
                  <small>
                    {translation("chargers_ModalExclusiveChargerNoneUsers")}
                  </small>
                </EmptyList>
              )}

              {!!apiData.length &&
                apiData.map((user) => {
                  return (
                    <ModalDisplayItem key={user.ucpId}>
                      <Avatar />
                      <div>
                        <p>{user.email}</p>
                      </div>
                      <ButtonDelete
                        type="button"
                        onClick={() =>
                          handleOpenDeletionModal(user.ucpId, user.email)
                        }
                      >
                        {translation(
                          "chargers_ModalExclusiveChargerButtonDelete",
                        )}
                      </ButtonDelete>
                    </ModalDisplayItem>
                  );
                })}

              {!!apiData.length && userDeletion.openDeletionModal && (
                <ModalAction
                  modalIsOpen={userDeletion.openDeletionModal}
                  modalTitle={translation(
                    "chargers_ModalExclusiveChargerModalRemoveUserTitle",
                  )}
                  modalInfo={translation(
                    "chargers_ModalExclusiveChargerModalRemoveUserText",
                    {
                      user: userDeletion.userEmail,
                    },
                  )}
                  modalButtonConfirmText={translation("Yes")}
                  modalButtonCancelText={translation("No")}
                  modalOnConfirmAction={handleDeleteUser}
                  modalOnRequestClose={handleCloseDeletionModal}
                />
              )}
            </ModalUsersListDisplay>
          </ModalUsersList>
        </ModalWrapper>
      </Modal>
    </>
  );
};

export default Translator.withTranslation()(RegisterUserModal);
