import "./SitesRevenueGraph.scss";
import React from "react";
import { Card, CardBody, Spinner } from "reactstrap";
import Salesdonut from "../../pages/AllCharts/apex/salesdonut";
import { withTranslation } from "react-i18next";

import { useStateContext } from "../../context/ContextProvider";

const SitesRevenueGraph = ({ text, data, t }) => {
  const { context, state } = useStateContext();
  const [userLanguage, setUserLanguage] = React.useState("");

  const formatValuesByLanguageHere = (value, language) => {
    const converted = Number(value);
    const portuguese = "pt-BR";
    const english = "en-US";
    const locale = language === portuguese ? portuguese : english;

    const newValue = converted.toFixed(2);

    if (locale === portuguese) {
      const USCurrency = String(newValue).replace(".", ",");
      return USCurrency;
    }

    if (locale === english) {
      const PTCurrency = String(newValue).replace(",", ".");
      return PTCurrency;
    }
  };

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  if (!data) {
    return (
      <Card className="h-100">
        <CardBody className="d-flex align-items-center justify-content-center">
          <Spinner>{t("Loading...")}</Spinner>
        </CardBody>
      </Card>
    );
  }

  if (data.length === 0) {
    return (
      <div className="pb-4 h-100">
        <Card className="h-100">
          <CardBody className="">
            {/* <p className="mb-0 mt-0 card-title">{t("Total sites revenue")}</p> */}
            <div className="mb-2 d-flex h-100 flex-row align-itens-center justify-content-center">
              <p className="card-title align-self-center">
                {t("No data to display")}
              </p>
            </div>
          </CardBody>
        </Card>
      </div>
    );
  }

  return (
    <Card className="h-100">
      <CardBody>
        {text ? (
          <p className="textgraph">{text + " " + t("Por Carregador")}</p>
        ) : (
          <p className="mb-0 mt-0 card-title">{t("Total sites revenue")}</p>
        )}
        <div id="ct-donut" className="ct-chart wid pt-4">
          <Salesdonut data={data} />
        </div>

        <div className="mt-4">
          <table className="table mb-0">
            <tbody>
              {data.map((item, key) => {
                return (
                  <tr key={item.periodId}>
                    <td>
                      <span className={`badge ${item.periodName}`}>
                        {t(item.periodName)}
                      </span>
                    </td>
                    <td>{`${item.timeStart.toString().padStart(2, "0")}h ${t(
                      "global_To",
                    )} ${item.timeEnd.toString().padStart(2, "0")}h`}</td>

                    <td className="text-end">
                      {formatValuesByLanguageHere(item.value, userLanguage)}%
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default withTranslation()(SitesRevenueGraph);
