import * as React from "react";
import * as RouterDom from "react-router-dom";
import * as Translator from "react-i18next";
import downloadjs from "downloadjs";
import html2canvas from "html2canvas";
import ReactToPrint from "react-to-print";

import { AxiosApi as api } from "../../../../../../../../helpers/api";

const { Modal } = require("reactstrap");
import { Button } from "../../../../../../../../components/Button";

import { FilesTypes } from "../../../../../../../../interfaces/Files";
import { RouterDomTypes } from "../../../../../../../../interfaces/RouterDom";
import { ChargerSitesTypes } from "../../../../../../../../interfaces/ChargerSite";

import {
  Component,
  ModalWrapper,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalQRCodeDispose,
  ModalURLDispose,
  ModalQRCodeConfig,
} from "./modalqrcode.styles";
import { QRCode } from "./components/QRCode/QRCode";

type ModalQRCodePropsTypes = {
  t: Function;
  modalIsOpen: boolean;
  modalCpCode: string;
  modalOnRequestClose: () => void;
};

enum FileType {
  PNG = "png",
  JPG = "jpg",
}

export const ModalQRCode = ({
  t: translation,
  modalIsOpen = false,
  modalCpCode = "",
  modalOnRequestClose,
}: ModalQRCodePropsTypes) => {
  const { site_id } = RouterDom.useParams<RouterDomTypes>();

  const QRCodeRef = React.useRef<HTMLDivElement>(null);

  const [clippedLink, setClippedLink] = React.useState(false);
  const [createImage, setCreateImage] = React.useState<string>("");
  const [imageLogo, setImageLogo] = React.useState<FilesTypes>();
  const [createPDF, setCreatePDF] = React.useState<boolean>(false);

  // Functions
  const clipContentURL = () => {
    const clipped = navigator.clipboard.writeText(
      `https://app.spott.eco/ch/${modalCpCode}`,
    );

    setClippedLink(true);
  };

  // Callbacks
  const handleDownloadQRCodeImage = React.useCallback(
    async (fileType: FileType) => {
      if (QRCodeRef.current === null) return;

      try {
        setCreateImage(fileType);

        const canvas = await html2canvas(QRCodeRef.current, {
          allowTaint: true,
          logging: true,
          useCORS: true,
        });
        const dataURL = canvas.toDataURL(
          fileType === FileType.JPG ? "image/jpeg" : "image/png",
        );

        downloadjs(
          dataURL,
          `spott-qrcode-charger-${modalCpCode}.${
            fileType === FileType.JPG ? "jpg" : "png"
          }`,
          `image/${fileType === FileType.JPG ? "jpeg" : "png"}`,
        );
      } catch (error: any) {
        console.log(error);
      } finally {
        setCreateImage("");
      }
    },
    [QRCodeRef],
  );

  // Life cycle
  React.useEffect(() => {
    if (!clippedLink) return;
    const timerDelay: number = 800;

    const timer = setTimeout(() => setClippedLink(false), timerDelay);
    return () => clearTimeout(timer);
  }, [clippedLink]);

  React.useMemo(() => {
    (async () => {
      try {
        const { data } = await api.get<ChargerSitesTypes>(
          `ChargerSites/${site_id}`,
        );
        const { data: imageData } = await api.get<FilesTypes>(
          `Files/${data.csLogoFileId}`,
        );

        if (data !== null && imageData !== null) {
          setImageLogo(imageData);
        }
      } catch (error: unknown) {
        console.log(error);
      }
    })();
  }, []);

  // Render
  return (
    <>
      <Component>
        <Modal
          isOpen={modalIsOpen}
          fullscreen={"sm"}
          size={"md"}
          centered={true}
        >
          <ModalWrapper>
            <ModalQRCodeConfig ref={QRCodeRef}>
              <img
                src={imageLogo?.fileData}
                alt={"charger-point-logo"}
                style={{ width: "16rem" }}
              />
              <QRCode cpCode={modalCpCode} />
              <h2>{modalCpCode}</h2>
              <img
                src={require("../../../../../../../../assets/images/spott-tec-logo.png")}
                alt="spott-tech-logo"
              />
            </ModalQRCodeConfig>

            <ModalHeader>
              <p>{translation("chargers_ChargerQRCodeModalTitle")}</p>
              <p>{translation("chargers_ChargerQRCodeModalText")}</p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            <hr />

            <ModalContent>
              <ModalQRCodeDispose>
                <div>
                  <QRCode cpCode={modalCpCode} />
                  <h2>CP-code: {modalCpCode}</h2>
                </div>

                <div>
                  <Button
                    buttonColor={"blue"}
                    onClick={() => handleDownloadQRCodeImage(FileType.PNG)}
                    loadingRequisiton={createImage === FileType.PNG}
                  >
                    {translation("chargers_ChargerQRCodeModalButtonPNG")}
                  </Button>

                  <Button
                    buttonColor={"blue"}
                    onClick={() => handleDownloadQRCodeImage(FileType.JPG)}
                    loadingRequisiton={createImage === FileType.JPG}
                  >
                    {translation("chargers_ChargerQRCodeModalButtonJPG")}
                  </Button>

                  <ReactToPrint
                    onBeforePrint={() => setCreatePDF(true)}
                    onAfterPrint={() => setCreatePDF(false)}
                    content={() => QRCodeRef?.current}
                    trigger={() => {
                      return (
                        <Button
                          buttonColor={"blue"}
                          loadingRequisiton={createPDF}
                        >
                          {translation(
                            "chargers_ChargerQRCodeModalButtonPrint",
                          )}
                        </Button>
                      );
                    }}
                  />
                </div>
              </ModalQRCodeDispose>

              <ModalURLDispose>
                <div>
                  <h2>URL</h2>
                  <a
                    href={`https://app.spott.eco/ch/${modalCpCode}`}
                    target={"_blank"}
                    rel={"noopener noreferrer"}
                  >
                    {`https://app.spott.eco/ch/${modalCpCode}`}
                  </a>
                </div>

                <Button
                  buttonColor={clippedLink ? "green" : "blue"}
                  onClick={clipContentURL}
                >
                  {clippedLink
                    ? translation("chargers_ChargerQRCodeModalButtonCopied")
                    : translation("chargers_ChargerQRCodeModalButtonCopy")}
                </Button>
              </ModalURLDispose>
            </ModalContent>

            <hr />

            <ModalFooter>
              <Button buttonColor={"dark"} onClick={modalOnRequestClose}>
                {translation("chargers_ChargerQRCodeModalButtonClose")}
              </Button>
            </ModalFooter>
          </ModalWrapper>
        </Modal>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalQRCode);
