import "./NewSite.scss";
import React, { useState, useEffect } from "react";
import { AxiosApi } from "../../helpers/api";
import CustomBreadCrumb from "../../components/BreadCrumb";
import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  CardBody,
  Button,
  Alert,
  Input,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { ModalComponent } from "../../components/Modal";
import { withRouter, Link } from "react-router-dom";
import { FormatDateHelper, validaEmail } from "../../helpers/Helpers";

import SubsctiptionBullets from "./SubsctiptionBullets";
import SubscriptionCard from "./SubscriptionCard";
import CardTableHeader from "./CardTableHeader";
import BoltIcon from "./../../assets/images/bolt_icon.png";
import CheckIcon from "../../assets/images/ckeck_icon.png";

import { SubscriptionSettings, Cards } from "../../common/data/Subscription";

const PaymentInformation = (props) => {
  const [menu, setMenu] = useState(false);
  const [SubscriptionCards, setSubscriptionCards] = useState(Cards);
  //const siteInfo = props.history.location.state.site;
  const idUsuarioLocal = localStorage.getItem("authUser")
    ? JSON.parse(localStorage.getItem("authUser")).user.id
    : "";

  const toggle = () => {
    setMenu(!menu);
  };

  // const getUsers = () => {
  //     const siteId = props.match.params.site_id;
  //     const resp = AxiosApi.get(`/SiteUsers/${siteId}`, {})
  //         .then((response) => {
  //             setUsersList(response.data);
  //         })
  //         .catch(error => error);
  // };

  const getSiteData = {
    readOnly: false,
    name: "Name Test",
    email: "email@email.com",
    cpf: "001.002.003-04",
    birthdate: "01/01/2001",
    mobile: "55 21 98877-5547",
    adress: "Endereço de teste",
    number: "123",
    complement: "201 fundos",
    cep: "22222-222",
    city: "Rio de Janeiro",
    state: "Rio de Janeiro",
    country: "Brasil",
  };

  const [siteData, setSiteData] = useState(getSiteData);

  const inputHandleChange = (e) => {
    setSiteData({ ...siteData, [e.target.name]: e.target.value });
  };

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb
                siteName={"Register a new site"}
                title={"New site"}
                subtitle={`Last update: ${FormatDateHelper(Date.now())}`}
              />
            </Col>

            <Col md="4">
              <div className="float-end d-none d-md-block">
                <Dropdown isOpen={menu} toggle={toggle}>
                  <DropdownToggle
                    color="primary"
                    className="btn btn-primary dropdown-toggle waves-effect waves-light"
                  >
                    <i className="mdi mdi-cog me-2"></i> Settings
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="#">
                      Action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Another action
                    </DropdownItem>
                    <DropdownItem tag="a" href="#">
                      Something else here
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" href="#">
                      Separated link
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <Col xl={12} className="p-3">
            <p>Insert the credit cars for payment information below.</p>
            <Card className="payment-info">
              <CardBody className="">
                <AvForm
                  className=""
                  onValidSubmit={(e, v) => {
                    submitHandler(e, v);
                  }}
                >
                  <Row>
                    <Col xl={5} className="p-3">
                      <FormGroup>
                        <AvField
                          name="cardNumber"
                          label="Card number"
                          className="form-control"
                          placeholder="Card number"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                "Your must inform a credit card number",
                            },
                          }}
                          //validate={{ myValidation: null }}
                          value={siteData.cardNumber}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} className="p-3">
                      <FormGroup>
                        <AvField
                          name="cardExpiration"
                          label="Expiration Date"
                          className="form-control"
                          placeholder=""
                          type="date"
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                "Your must inform the card expiration date",
                            },
                          }}
                          //validate={{ myValidation: null }}
                          value={siteData.cardExpiration}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={5} className="p-3">
                      <FormGroup>
                        <AvField
                          name="cardName"
                          label="Name on card"
                          className="form-control"
                          placeholder="Type the name how is it printed on the card"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage: "Your must inform a name",
                            },
                          }}
                          //validate={{ myValidation: null }}
                          value={siteData.cardName}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl={3} className="p-3">
                      <FormGroup>
                        <AvField
                          name="cardSecurityCod"
                          label="Security number"
                          className="form-control"
                          placeholder="CVV"
                          type="text"
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                "Your must inform a card security number",
                            },
                          }}
                          //validate={{ myValidation: null }}
                          value={siteData.cardSecurityCod}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} className="p-3">
                      <div className="form-check form-switch">
                        <AvField
                          type="checkbox"
                          name="cardDefault"
                          label="Set this card as default payment method"
                          trueValue="true"
                          className="form-control form-check-input"
                          value={siteData.cardDefault}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </div>
                    </Col>
                    <Col xl={4} className="p-3">
                      <div className="form-check form-switch">
                        <AvField
                          type="checkbox"
                          name="agreeTermConditions"
                          label="Terms and conditions for payment . Read here"
                          trueValue="true"
                          className="form-control form-check-input"
                          validate={{
                            required: {
                              value: true,
                              errorMessage:
                                "Your must agree with terms and conditions to proceed",
                            },
                          }}
                          value={siteData.agreeTermConditions}
                          onChange={(e) => inputHandleChange(e)}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} className="pt-3">
                      <p>Review your order</p>
                      <hr />
                    </Col>
                    <Col xl={2} className="">
                      <p className="review-order-plan">Medium</p>
                      <p className="review-order-price">R$ 23 / port</p>
                      <p className="review-order-priod">per month</p>
                    </Col>
                    <Col xl={10} className="">
                      <p>
                        Connect any OCPP charger
                        <br />
                        5% transaction fee
                        <br />
                        Enhanced platform access
                        <br />
                        Maxi 9 ports
                      </p>
                    </Col>
                    <Col xl={12} className="pb-3">
                      <hr />
                    </Col>
                  </Row>
                  <div className="text-end mt-4">
                    <Button
                      id="previous"
                      onClick={(e) => previousHandler(e)}
                      color="light"
                    >
                      PREVIOUS
                    </Button>
                    <Button
                      type="submit"
                      id="save-profile"
                      color="primary"
                      className="ms-3"
                    >
                      CREATE SITE
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(PaymentInformation);
