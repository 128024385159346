import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
  display: grid;
  grid-gap: 0.88rem;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 0.88rem;
`;

export const SmartSettings = styled.div`
  section {
    display: grid;
    grid-gap: 0.8rem;

    h2 {
      color: ${darken(0.48, "#edeeef")};
      font-size: 1.16rem;
      line-height: 1.16rem;
      font-weight: 500;
      font-style: normal;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;
    }

    & + section {
      margin-top: 0.88rem;
    }
  }

  .switcher {
    display: flex;
    align-items: center;
    gap: 0.48rem;
    margin-bottom: 0.48rem;
  }
`;

export const GraphFrame = styled.div`
  width: 100%;
  height: 48rem;
  margin: 0 auto;
  border: 1px solid #edeeef;
  border-radius: 0.24rem;
  overflow: hidden;
  flex: 1;

  header {
    background-color: ${darken(0.24, "#edeeef")};
    color: #edeeef;
    padding: 0.64rem;

    font-weight: 500;
    font-style: normal;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: center;
    text-transform: capitalize;
    text-decoration: none;
    text-underline-position: under;
    word-wrap: break-word;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  span {
    color: ${darken(0.24, "#edeeef")};
  }
`;
