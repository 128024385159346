import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 1.32rem auto;
  padding: 1.32rem;
  display: grid;
  grid-gap: 1.32rem;
  position: relative;
`;

export const Header = styled.header`
  color: #3970f3;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  line-height: 2.5rem;

  h1 {
    font-size: 25px;
    font-weight: 500;
    display: flex;
  }

  span {
    color: #8c8c8c;
    margin-right: 0.6rem;
    display: flex;
    align-items: center;

    span {
      margin: auto auto auto 10px;
      font-size: 14px;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 0.32rem;
  overflow: hidden;
  position: relative;

  display: grid;
  grid-gap: 2rem;
`;

export const Panels = styled.div`
  width: 100%;
  display: flex;
  // grid-template-columns: auto minmax(300px, 500px);
  align-items: flex-start;
  flex-direction: row;
`;
