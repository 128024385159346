export function formatDocument(document, type) {
  const types = {
    cpf: "999.999.999-99",
    cnpj: "99.999.999/9999-99",
  };
  if (document) {
    const documentOnlyNumbers = document.replace(/[^0-9]/g, "");

    if (!type) {
      return formatToPattern(
        documentOnlyNumbers,
        documentOnlyNumbers.length <= 11 ? types.cpf : types.cnpj,
      );
    }
    return formatToPattern(documentOnlyNumbers, types[type] || types.cpf);
  }
  return "";
}

export function formatPhoneNumber(phoneNumber, useDDD = false) {
  if (!phoneNumber) {
    return null;
  }

  const phoneOnlyNumbers = formatToNumber(phoneNumber);
  let format = "";
  if (phoneOnlyNumbers.length === 9) {
    format = `${useDDD ? "(xx)" : "(99)"} 9 9999-9999`;
  } else {
    format = "(99) 9 9999-9999";
  }

  return formatToPattern(phoneOnlyNumbers, format);
}

export function formatChargerLabel(oldLabel) {
  let newLabel =
    oldLabel
      .replace(/([A-Z])/g, " $1")
      .charAt(0)
      .toUpperCase() + oldLabel.replace(/([A-Z])/g, " $1").slice(1);

  newLabel = newLabel.replace("Charge Point", "");

  return newLabel;
}
