import React from "react";
import { withTranslation } from "react-i18next";

import Footer from "../../../components/VerticalLayout/Footer";
import { TermsAndConditionsContentApp } from "./components/TermsAndConditionsContentApp/TermsAndConditionsContentApp";

const logoImg = require("../../../assets/images/logo.png");
import {
  Container,
  Display,
  FooterPage,
  Header,
} from "./termsandconditionsapp.styles";

interface TermsAndConditionsAppProps {
  t: Function;
}

export const TermsAndConditionsApp = ({
  t: translator,
}: TermsAndConditionsAppProps) => {
  return (
    <>
      <Container>
        <Header>
          <img src={logoImg} width={'200px'} alt="logo" />
        </Header>

        <Display>
          <h2>{translator("Privacy Policy")}</h2>
          <TermsAndConditionsContentApp />
        </Display>

        <FooterPage>
          <Footer darkText={true} loginFooter={true} tintText hasBackground />
        </FooterPage>
      </Container>
    </>
  );
};

export default withTranslation()(TermsAndConditionsApp);
