import * as React from "react";
import * as Translator from "react-i18next";
import ErrorModal from "../../../../components/Alerts/ErrorModal";
import { ErrorMessage } from "../ChargerDetails/components/ChargerExclusive/components/ModalUsersAccess/modalusersaccess.styles";

import { AxiosApi as api } from "../../../../helpers/api";

import { Exception } from "../../../../interfaces/Exception";
import { IdGeneratorTypes } from "../../../../interfaces/IdGenerator";
import { SiteAccessTypes } from "../../../../interfaces/SiteUsers";
import { Button } from "../../../../components/Button";

import {
  Component,
  Wrapper,
  Content,
  IdGenerator,
} from "./chargergeneratorid.styles";
import { Input } from "../../../../components/Inputs";

type ChargerGeneratorIdPropsTypes = {
  t: Function;
  siteAccess: SiteAccessTypes;
  refreshChargersList: () => void;
};

export const ChargerGeneratorId = ({
  t: translation,
  siteAccess,
  refreshChargersList,
}: ChargerGeneratorIdPropsTypes) => {
  const [handleError, setHandleError] = React.useState<Exception | undefined>();

  const [idData, setIdData] = React.useState<IdGeneratorTypes>(
    {} as IdGeneratorTypes,
  );

  const [errorMessage, setErrorMessage] = React.useState(false);

  // Functions
  const handleClearInput = () => {
    setIdData((prev) => ({
      ...prev,
      generatedDateTime: "",
      generatedId: "",
    }));
    setErrorMessage(false);
  };

  // Api data
  const getGenerateChargerId = async () => {
    setErrorMessage(false);

    try {
      const { data } = await api.get<IdGeneratorTypes>("/genChargerId");

      setIdData(data);
    } catch (error: unknown) {
      console.log(error);
    }
  };

  const dispatchGeneratedChargerId = async () => {
    if (idData.generatedId === undefined || idData.generatedId.length === 0) {
      return setErrorMessage(true);
    }

    try {
      const newChargerId = idData.generatedId.trim();

      const payload = {
        chargePointId: newChargerId,
        cpCsId: siteAccess.csId,
      };

      const response = await api.post("/ChargePoints", payload);

      if (response.status === 201) {
        refreshChargersList();
        handleClearInput();
      }
    } catch (error: any) {
      if (error?.response?.data) {
        setHandleError({
          isOpen: true,
          message: error?.response?.data,
          status: error?.response?.status,
          onClose: () => setHandleError(undefined),
        });
      }
    } finally {
      handleClearInput();
    }
  };

  return (
    <>
      <Component>
        <Wrapper>
          <Content>
            <h1>{translation("chargers_PageText")}</h1>

            <p>{translation("chargers_PageInfo")}</p>

            <IdGenerator>
              <div style={{ display: "grid" }}>
                <Input
                  type={"text"}
                  placeholder={translation("chargers_InputPlaceholder")}
                  value={idData.generatedId}
                  onFocus={() => setErrorMessage(false)}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setIdData((prev: IdGeneratorTypes) => ({
                      ...prev,
                      generatedId: event.target.value,
                    }))
                  }
                />
                {errorMessage && (
                  <ErrorMessage>
                    {translation("charger_GenerateIdFirst")}
                  </ErrorMessage>
                )}
              </div>

              <Button
                buttonColor={"green"}
                type={"submit"}
                onClick={dispatchGeneratedChargerId}
              >
                {translation("chargers_ButtonAdd")}
              </Button>
              <Button
                buttonColor={"blue"}
                type={"button"}
                onClick={getGenerateChargerId}
              >
                {translation("chargers_ButtonGenerate")}
              </Button>
              <Button
                buttonColor={"blue"}
                type={"button"}
                onClick={handleClearInput}
              >
                {translation("chargers_ButtonClear")}
              </Button>
            </IdGenerator>
          </Content>
        </Wrapper>

        {/* Modals */}
        <>{handleError && <ErrorModal {...handleError} />}</>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ChargerGeneratorId);
