import { useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";

import Header from "./components/Header/Header";
import Footer from "../../components/VerticalLayout/Footer";
import { connect } from "react-redux";
import {
  loginUser,
  apiError,
  clearAPIError,
  googleAuth,
  facebookAuth,
} from "../../store/actions";
import {
  Display,
  Form,
  Label,
  Fields,
  ErrorBoundary,
  Wrapper,
  AlignInput,
  LoginWithView,
  LineView,
  SignText,
  SocialAlign,
  SocialButton,
} from "./login.styles";
import {Spinner} from 'reactstrap';
import "./styles.scss";
import { useGoogleLogin } from "@react-oauth/google";
import AppleLogin from "react-apple-login";
import FacebookLogin from "react-facebook-login";

const Login = (props) => {
  const visible = require("../../assets/images/visible.png");
  const nonVisible = require("../../assets/images/visible_off.png");

  const pageWidth = document.documentElement.scrollWidth;

  const [onFocus, setOnFocus] = useState({
    email: false,
    password: false
  })
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    isShowPassword: false
  })

  const [isLoading, setIsLoading] = useState(false);

  const handleValidSubmit = (event) => {
    event.preventDefault();

    const loginFieldValues = { email: loginData.email, password: loginData.password };
    // const loginFieldValues = { email: emailInput, password: passwordInput };
    props.loginUser(loginFieldValues, props.history);

    setIsLoading(true)

    setTimeout( () => {
      setIsLoading(false)
    }, 1000)
  };

  async function handlegoogleSignIn(tokenResponse) {
    console.log(tokenResponse);
    const payload = { access_token: tokenResponse.access_token };
    props.googleAuth(payload, props.history);
  }

  async function responseFacebook(tokenResponse) {
    console.log(tokenResponse);
    if (tokenResponse.accessToken) {
      const payload = { access_token: tokenResponse.accessToken };
      props.facebookAuth(payload, props.history);
    }
  }

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      handlegoogleSignIn(tokenResponse);
    },
    onError: (tokenResponse) => console.log(tokenResponse),
  });

  return (
    <div className="div-login-background" style={{heigth: pageWidth <= 1366 && pageWidth >= 769 ? '' : 'inherit'}}>
      <MetaTags>
        <title>{props.t("Login | Spott | Manage your recharge points")}</title>
      </MetaTags>

      <Wrapper>
        <Header />

        <Display isErrored={props.error}>
          <p>{props.t("Por favor digite os seus dados")}</p>

          <Form onSubmit={handleValidSubmit}>
            <Label htmlFor="email" onInputFocus={onFocus.email}>
              <span>{props.t("E-mail")}</span>

              <input
                type="email"
                required
                placeholder={props.t("Insert your email")}
                value={loginData.email}
                onChange={(event) => setLoginData({...loginData, email: event.target.value})}
                onFocus={() => {
                  props.clearAPIError();
                  setOnFocus({...onFocus, email: true});
                }}
                onBlur={() => setOnFocus({...onFocus, email: false})}
              />
            </Label>

            <Label htmlFor="password" onInputFocus={onFocus.password}>
              <span>{props.t("Password")}</span>
              <AlignInput>
                <input
                  type={loginData.isShowPassword ? "text" : "password"}
                  required
                  placeholder={props.t("Insert your password")}
                  value={loginData.password}
                  onChange={(event) => setLoginData({...loginData, password: event.target.value})}
                  onFocus={() => {
                    props.clearAPIError();
                    setOnFocus({...onFocus, password: true});
                  }}
                  onBlur={() => setOnFocus({...onFocus, password: false})}
                />
                <img
                  src={loginData.isShowPassword ? nonVisible : visible}
                  alt="eye"
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    setLoginData({...loginData, isShowPassword: !loginData.isShowPassword});
                  }}
                />
              </AlignInput>
              <footer>
                <Link
                  to="/forgot-password"
                  className="forgot-password mb-3 d-block"
                  style={{
                    textDecoration: "none",
                    color: "#262729",
                    marginTop: 10,
                  }}
                >
                  <a> {props.t("Forgot your password?")}</a>
                </Link>
              </footer>
            </Label>

            <Fields>
              <button type="submit">
                <a>
                  { isLoading ?
                  <span style={{width: 'fit-content', display: 'flex', gridGap: '10px', margin: 'auto'}}>
                    <Spinner style={{ width: '15px', height: '15px' }}/>
                    {props.t("Loading")}
                  </span>
                  :
                  props.t("Log in")
                  }
                </a>
              </button>
              <a href="https://spott.eco/planos-spott/" style={{ width: "100%" }}>
                <button type="button">
                  <a>{props.t("Create an account")}</a>
                </button>
              </a>
            </Fields>

            {props.error && (

              props.error === 'Erro: usuário ou senha inválido.' ?
              <ErrorBoundary>
                <p>{props.t("Invalid e-mail or password")}</p>
              </ErrorBoundary>
              :
              props.error === 'Erro: usuário não possui acesso.' ?
              <ErrorBoundary>
                <p>{props.t("The user don't have access")}</p>
              </ErrorBoundary>
              :
              <ErrorBoundary>
                <p>{props.t("Intern error")}</p>
              </ErrorBoundary>
            )}
          </Form>
          <LoginWithView>
            <LineView />
            <SignText>{props.t("or enter with")}</SignText>
            <LineView />
          </LoginWithView>
          <SocialAlign>
            <SocialButton
              onClick={() => {
                googleLogin();
              }}
            >
              <img
                width={pageWidth <= 1366 && pageWidth >= 769 ? "60px" : ""}
                src={require("../../assets/images/singgoogle.png")}
              />
            </SocialButton>
            <FacebookLogin
              // appId="851508656270165"
              appId="582505193680933"
              fields="name,email"
              callback={responseFacebook}
              cssClass="my-facebook-button-class"
              textButton=""
              // onClick={responseFacebook}
              icon={
                <SocialButton>
                  <img
                    width={pageWidth <= 1366 && pageWidth >= 769 ? "60px" : ""}
                    src={require("../../assets/images/SignFacebook.png")}
                  />
                </SocialButton>
              }
            />
          </SocialAlign>
        </Display>
      </Wrapper>

      <Footer loginFooter={true} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, {
    facebookAuth,
    googleAuth,
    loginUser,
    apiError,
    clearAPIError,
  })(withTranslation()(Login)),
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  googleAuth: PropTypes.func,
  facebookAuth: PropTypes.func,
  clearAPIError: PropTypes.func,
};
