import React, { useState } from 'react';
import CustomBreadCrumb from '../../components/BreadCrumb';
import Tabs from '../../components/Tabs';
import BoltIcon from './../../assets/images/bolt_icon.png';
import './styles.scss';
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownItem, DropdownMenu, } from 'reactstrap';

import SubsctiptionBullets from '../../components/Subscription/SubsctiptionBullets';
import SubscriptionCard from '../../components/Subscription/SubscriptionCard';
import { SubscriptionSettings, Cards } from '../../common/data/Subscription';
import CardTableHeader from '../../components/Subscription/CardTableHeader';

import CheckIcon from '../../assets/images/ckeck_icon.png';
import { forEach } from 'lodash';

export default function Payment() {
  const [menu, setMenu] = useState(false);
  const [SubscriptionCards, setSubscriptionCards] = useState(Cards);
  const toggle = () => { setMenu(!menu); };

  let tableContent = Cards[0].attributes;
  if (tableContent[0].length < Cards.length + 1) {
    for (let i = 1; i < Cards.length; i++) {
      for (let z = 0; z < Cards[i].attributes.length; z++) {
        tableContent[z].push(Cards[i].attributes[z][1]);
      }
    }
  }

  const drawTr = (tr, index) => {
    return (
      <tr key={index}>{tr.map(drawTd)}</tr>
    )
  }

  const drawTd = (td, index) => {
    if (index === 0) return <td key={Math.random() * Math.random()} className="label">{td}</td>
    return <td key={index}>{td ? <img src={CheckIcon} alt="Yes" /> : ""}</td>
  }

  return (
    <div className="page-content">
      <Container fluid>

        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb siteName={'Site 01'} title={'Payment'} subtitle={'Last update 21/02.21 - 22:30h'} />
            </Col>

            <Col md="4">
              <div className="float-end d-none d-md-block">
                <Dropdown isOpen={menu} toggle={toggle}>
                  <DropdownToggle color="primary" className="btn btn-primary dropdown-toggle waves-effect waves-light">
                    <i className="mdi mdi-cog me-2"></i> Settings
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag="a" href="#">Action</DropdownItem>
                    <DropdownItem tag="a" href="#">Another action</DropdownItem>
                    <DropdownItem tag="a" href="#">Something else here</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem tag="a" href="#">Separated link</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </div>

        <Row>
          <div className="tabs align-itens-center d-flex mb-3">
            <span className="tabs__defaultItem btn active">Our Plan</span>
            <div className="d-flex">
              <span className="tabs__separator"></span>
              <span className="tabs__name btn">Your Card</span>
            </div>
          </div>
        </Row>

        <Row>
          <p className="font-size-15 py-3">Review the credit cars for payment information below.</p>
        </Row>

      </Container>

    </div>
  );
}
