import React, { useState } from 'react';
import Salesdonut from '../AllCharts/apex/salesdonut';
import LineAreaChart from '../AllCharts/apex/lineareachart';
import ProgressBar from '../../components/ProgressBar';
import CustomTitlePage from '../../components/CustomTitlePage';
import CustomBreadCrumb from '../../components/BreadCrumb';
import Tabs from '../../components/Tabs'
import EnergyIcon from './../../assets/images/energy.png';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from 'reactstrap';

export default function EnergyConsumption() {
  const [menu, setMenu] = useState(false);
  const tabItems = [
    { name: 'Charger 01' },
    { name: 'Charger 02' },
    { name: 'Charger 03' }
  ]
  const toggle = () => {
    setMenu(!menu);
  };
  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={8}>
              <CustomBreadCrumb siteName={'Welcome'} title={'Create a new site'} subtitle={'Last update 21/02.21 - 22:30h'} />
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}