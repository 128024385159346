import styled from "styled-components";
import { transparentize, darken, lighten, rgba } from "polished";
const { AvField, AvForm } = require("availity-reactstrap-validation");

export const ModalWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  word-wrap: break-word;

  h1 {
    color: #3970f3;
    font-style: normal;
    font-weight: 700;
    font-size: 1.32rem;
    line-height: 1.32rem;
  }

  p {
    margin: 1.6rem 0;
    color: #666666;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
  }
`;

export const ModalCloseIcon = styled.button`
  background-color: transparent;
  color: ${lighten(0.24, "#666666")};
  margin: 0;
  padding: 0;
  border: 0;

  display: block;
  margin-left: auto;
  margin-right: 0;

  font-style: normal;
  font-weight: 400;
  font-size: 1.24rem;
  line-height: 1.24rem;
  text-align: right;

  transition: all 300ms;

  &:hover {
    color: #666666;
  }
`;

export const ModalFormField = styled(AvForm)`
  width: 100%;
  margin: 0 auto;

  display: grid;
  grid-gap: 1.24rem;
`;

export const ModalFormInputField = styled(AvField)`
  width: 100%;
  height: 3.5rem;
  border-color: #edeeef;
  border-radius: 0.5rem;

  &::placeholder {
    color: ${lighten(0.24, "#666666")};

    font-style: normal;
    font-weight: 500;
    font-size: 0.88rem;
    line-height: 0.88rem;
    text-align: left;
    text-transform: none;
  }
`;

export const ModalUsersList = styled.section`
  width: 100%;
  margin: 0 auto;

  p {
    color: #666666;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1rem;
    text-align: left;
    text-transform: none;
  }
`;

export const ModalUsersListDisplay = styled.div`
  width: 100%;
  height: 24rem;
  margin: 0 auto;
  position: relative;
  overflow-y: scroll;
`;

export const ModalDisplayItem = styled.div`
  width: 100%;
  height: 5.5rem;
  margin: 0;
  padding: 1rem;
  border: 1px solid #edeeef;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.8rem;

  transition: background-color 300ms;

  & + & {
    border-top-color: transparent;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.032);
  }

  img {
    width: 1.64rem;
    height: 1.64rem;
  }

  div {
    flex: 1;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    p {
      color: #666666;
      margin: 0.24rem 0;
      padding: 0;
      word-wrap: break-word;

      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.24rem;
      text-align: left;
      text-transform: none;
    }
  }
`;

export const EmptyList = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;

  border: 1px solid #edeeef;
  border-radius: 0.16rem;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1rem;

  svg {
    color: #666666;
    width: 2rem;
    height: 2rem;
  }

  small {
    display: block;
    margin: 0 auto;

    color: #666666;
    word-wrap: break-word;

    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    text-align: center;
    text-transform: none;
  }
`;

export const BaseButton = styled.button`
  background-color: #666;
  color: white;
  width: 12rem;
  padding: 1rem;

  border: 0;
  border-radius: 0.5rem;

  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  text-transform: uppercase;

  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
  transition: filter 300ms;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonSubmit = styled(BaseButton)`
  background-color: #3970f3;
  display: block;
  margin-left: auto;
  margin-right: 0;
`;

export const ButtonDelete = styled(BaseButton)`
  background-color: #d10000;
  width: 10rem;
  padding: 0.8rem;
`;

export const TextValidation = styled.span`
  color: red;
  font-size: 0.7rem;
  line-height: 0.1px;
  margin-left: 0.2rem;
`;
