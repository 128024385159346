import './styles.scss';
import React from 'react';
import PropTypes from 'prop-types';

const Progress = props => {
  const defineStepActive = step => {
    if (props.step >= step) {
      return 'active';
    }

    return null;
  };

  return (
    <div className="login-step d-none d-sm-flex align-items-center justify-content-between">
      <div className="line"></div>
      <div className={`step-number d-flex align-items-center justify-content-center ${defineStepActive(1,)}`}>
        <span>1</span>
      </div>
      <div className={`step-number d-flex align-items-center justify-content-center ${defineStepActive(2,)}`}>
        <span>2</span>
      </div>
      <div className={`step-number d-flex align-items-center justify-content-center ${defineStepActive(3,)}`}>
        <span>3</span>
      </div>
    </div>
  );
};

Progress.propTypes = {
  step: PropTypes.number,
};

export default Progress;