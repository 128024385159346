import styled from "styled-components";

export const ModalContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
`;

export const ModalHeader = styled.header`
  width: 100%;
  margin: 0 auto;
  position: relative;

  h1 {
    color: #3970f3;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
`;

export const ModalCloseButton = styled.button`
  background-color: transparent;
  color: #666666;
  border: 0;

  position: absolute;
  top: 0;
  right: 1rem;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
`;

export const ModalContent = styled.div`
  color: #000000;
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  padding: 1rem;

  border: 1px solid #979797;

  p {
    text-transform: capitalize;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  span {
    text-transform: none;
    margin-left: 0.24rem;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;
