import React, { useState, useEffect } from "react";
import * as Translator from "react-i18next";

import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { withTranslation } from "react-i18next";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {
  Container,
  Row,
  Col,
  Input,
  Button,
  Card,
  CardBody,
  Spinner,
} from "reactstrap";

import SuccessModal from "../../components/Alerts/SuccessModal";

import {
  FormatDateHelper,
  formatFullDateAndTimeHelper,
} from "../../helpers/Helpers";
import { AxiosApi } from "../../helpers/api";
import * as Provider from "../../context/ContextProvider";

import { ModalComponent } from "../../components/Modal";
import CustomBreadCrumb from "../../components/BreadCrumb";

import "./UserProfile.scss";

const UserProfile = (props) => {
  const { state } = Provider.useStateContext();

  const [userData, setUserData] = useState({ readOnly: true, loading: true });
  const [modalConfig, setModalConfig] = useState({ open: false });
  const [userLanguage, setUserLanguage] = React.useState("");

  useEffect(() => {
    loadUserData();
  }, []);

  React.useEffect(() => {
    setUserLanguage(state?.userData?.language);
  }, [state?.userData?.language]);

  const inputHandleChange = (e) =>
    setUserData({ ...userData, [e.target.name]: e.target.value });
  const changeProfileHandler = (e) =>
    setUserData({ ...userData, readOnly: false });
  const cancelEditProfileHandler = (e) => loadUserData();
  const saveProfileHandler = (e, values) => saveUserData();

  const saveUserData = () => {
    setUserData({ ...userData, readOnly: true, loading: true });

    const userDataSend = userData;
    for (let prop in userDataSend) {
      if (Array.isArray(userDataSend[prop])) {
        delete userDataSend[prop];
      }
    }
    delete userDataSend.avatar;

    const resp = AxiosApi.put(`/userprofile`, userDataSend)
      .then((response) => {
        setHandleSuccess(true);
        setUserData({ ...userDataSend, readOnly: true, loading: false });
        //atualizar o topo
      })
      .catch((error) => {
        setUserData({ ...userDataSend, readOnly: false, loading: false });
        openModalError();
      });
  };

  const loadUserData = () => {
    const resp = AxiosApi.get(`/userprofile`, {})
      .then((response) => {
        setUserData({ ...response.data, readOnly: true, loading: false });
      })
      .catch((error) => {
        console.warn("RESPOSTA DA API [erro]: " + JSON.stringify(error));
      });
  };

  const openModalSuccess = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Profile updated successfully"),
      body: props.t(`Your profile has been updated successfully.`),
      btOkText: "Ok",
      btOkStyle: "success",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  const openModalError = () => {
    setModalConfig({
      open: true,
      value: "",
      title: props.t("Error updating profile"),
      body: props.t(
        `There was an error trying to updating your profile, please try again.`,
      ),
      btOkText: "Ok",
      btOkStyle: "danger",
      btCancelText: "",
      functionHandler: modalCloseHandler,
      closeHandler: "",
    });
  };

  const modalCloseHandler = () => setModalConfig({ open: false });

  const [handleSuccess, setHandleSuccess] = React.useState(false);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-title-box">
          <Row className="align-items-center">
            <Col md={12}>
              <CustomBreadCrumb
                siteName={props.t("Settings")}
                title={props.t("User Profile")}
                // subtitle={`${props.t(
                //   "Last update",
                // )}: ${formatFullDateAndTimeHelper(Date.now(), userLanguage)}`}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <div className="w-100">
            <ul className="nav nav-tabs w-100">
              <li className="nav-item actived">
                <span className={`nav-link active`}>
                  {props.t("User Profile")}
                </span>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/Password/`, state: {} }}
                >
                  {props.t("Password")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link`}
                  to={{ pathname: `/Settings/Avatar/`, state: {} }}
                >
                  {props.t("Add photo")}
                </Link>
              </li>
            </ul>
          </div>
        </Row>
      </Container>

      <Row>
        <Col xl={12} className="p-3 pt-0">
          <p className="info-text">{props.t("settings_UserProfileText")}</p>

          <Card>
            <CardBody className="">
              <AvForm
                className="mt-0"
                onValidSubmit={(e, v) => saveProfileHandler(e, v)}
              >
                <Row>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="name"
                      label={props.t("Name")}
                      className="form-control"
                      placeholder={props.t("Insert your full name")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform your name"),
                        },
                      }}
                      value={userData.name}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="email"
                      label={props.t("E-mail")}
                      className="form-control"
                      placeholder={props.t("Insert your e-mail")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform your e-mail"),
                        },
                      }}
                      value={userData.email}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="cpf"
                      label={props.t("CPF")}
                      type="text"
                      placeholder={props.t("Insert your document")}
                      mask="999.999.999-99"
                      tag={[Input, InputMask]}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform a CPF"),
                        },
                        pattern: {
                          value:
                            "/^([0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}|[0-9]{2}.?[0-9]{3}.?[0-9]{3}/?[0-9]{4}-?[0-9]{2})$/",
                          errorMessage: "Your must inform a valid CPF",
                        },
                      }}
                      value={userData.cpf}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="birthdate"
                      label={props.t("Birth date")}
                      type="date"
                      placeholder={props.t("Insert your birth date")}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t(
                            "Your must inform your birth date",
                          ),
                        },
                      }}
                      value={userData.birthdate}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="phoneNumber"
                      label={props.t("Mobile")}
                      type="text"
                      placeholder="(99) 99999-9999"
                      mask="(99) 99999-9999"
                      tag={[Input, InputMask]}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t(
                            "Your must inform your mobile number",
                          ),
                        },
                      }}
                      value={userData.phoneNumber}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="cep"
                      label={props.t("CEP")}
                      type="text"
                      mask="99999-999"
                      placeholder="99999-999"
                      tag={[Input, InputMask]}
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform a CEP"),
                        },
                        pattern: {
                          value: "/^[0-9]{5}-[0-9]{3}$/",
                          errorMessage: props.t("Your must inform a valid CEP"),
                        },
                      }}
                      value={userData.cep}
                      onChange={(e) => inputHandleChange(e)}
                      //onBlur={e => cepOnBlurHandler(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={8} className="p-3">
                    <AvField
                      name="address"
                      label={props.t("Address")}
                      className="form-control"
                      placeholder={props.t("Insert your address")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform a address"),
                        },
                      }}
                      value={userData.address}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={2} className="p-3">
                    <AvField
                      name="number"
                      label={props.t("Number")}
                      className="form-control"
                      placeholder={props.t("Insert number")}
                      type="number"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t(
                            "Your must inform a address number",
                          ),
                        },
                      }}
                      value={userData.number}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={2} className="p-3">
                    <AvField
                      name="complement"
                      label={props.t("Complement")}
                      type="text"
                      placeholder={props.t("Insert your complement")}
                      value={userData.complement}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="country"
                      label={props.t("Country")}
                      className="form-control"
                      placeholder={props.t("Country")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform a country"),
                        },
                      }}
                      value={userData.country}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="state"
                      label={props.t("State")}
                      className="form-control"
                      placeholder={props.t("State")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform your state"),
                        },
                      }}
                      value={userData.state}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                  <Col xl={4} className="p-3">
                    <AvField
                      name="city"
                      label={props.t("City")}
                      className="form-control"
                      placeholder={props.t("City")}
                      type="text"
                      validate={{
                        required: {
                          value: true,
                          errorMessage: props.t("Your must inform your city"),
                        },
                      }}
                      value={userData.city}
                      onChange={(e) => inputHandleChange(e)}
                      readOnly={userData.readOnly}
                    />
                  </Col>
                </Row>

                {userData?.loading ? (
                  <div className="text-end mt-2">
                    <Spinner>Loading ...</Spinner>
                  </div>
                ) : userData.readOnly ? (
                  <div className="text-end mt-2">
                    <Button
                      id="edit"
                      onClick={(e) => changeProfileHandler(e)}
                      color="primary"
                    >
                      {props.t("CHANGE YOUR PROFILE")}
                    </Button>
                  </div>
                ) : (
                  <div className="text-end mt-2">
                    <Button
                      id="cancel"
                      onClick={(e) => cancelEditProfileHandler(e)}
                      color="light"
                    >
                      {props.t("CANCEL")}
                    </Button>
                    <Button
                      type="submit"
                      id="save-profile"
                      color="primary"
                      className="ms-3"
                    >
                      {props.t("SAVE")}
                    </Button>
                  </div>
                )}
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {handleSuccess && 
        <SuccessModal
          isOpen={handleSuccess}
          onClose={() => setHandleSuccess(false)}
          message={userLanguage === 'pt-BR' ? 'Dados atualizados com sucesso' : 'Data updated successfully'}
        />
      }
      <ModalComponent config={modalConfig} />
    </div>
  );
};

export default Translator.withTranslation()(UserProfile);
