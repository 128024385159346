import usFlag from "../assets/images/flags/us.jpg";
import ptFlag from "../assets/images/flags/pt.jpg";
// import spain from "../assets/images/flags/spain.jpg";
// import germany from "../assets/images/flags/germany.jpg";
// import italy from "../assets/images/flags/italy.jpg";
// import russia from "../assets/images/flags/russia.jpg";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  pt: {
    label: "Português",
    flag: ptFlag,
  },
};

export default languages;
