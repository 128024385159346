import './styles.scss';
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu, Card, CardBody, Spinner } from 'reactstrap';
import ArrowUp from './../../assets/images/arrow-up.png';
import ArrowDown from './../../assets/images/arrow-down.png';
import { withTranslation } from 'react-i18next';
//import { set } from 'lodash';

const MenuChargers = ({ tabs, pathname, siteInfo, t }) => {
  const [menu, setMenu] = useState(false);
  const toggle = () => { setMenu(!menu); };
  const { site_id } = useParams();
  const [visibleTabs, setVisibleTabs] = useState([]);
  const selectedSite = (site_id ? tabs.find(tab => tab.csId === site_id) : undefined);
  let q = 3; //visible tabs

  useEffect(() => {
    if (visibleTabs && !tabs.find(tab => tab.csId === visibleTabs[0].csId)) setVisibleTabs([]);
  }, [tabs]);

  // tabs = {
  //   'csId': site id,
  //   'csName': site name,
  // }

  const listTabs = () => {
    let arr = visibleTabs;

    //nao ha tabs visiveis, adiciona algumas
    if (arr.length === 0) {
      tabs.map((tab, index) => {
        if (index < q) return arr.push(tab);
      });
      setVisibleTabs(arr);
    }

    //checando se a tab selecionada esta entre as visiveis, se nao, coloca ela em primeiro.
    if (selectedSite && !arr.find(tab => tab.csId === selectedSite.csId)) {
      arr.unshift(selectedSite);
      arr.pop();
      setVisibleTabs(arr);
    }

    //prepara a lista de tabs pronta
    const result = arr.map((tab, index) => {
      if (index < q) {
        return (
          <li key={index} className="nav-item">
            <Link
              className={`nav-link ${selectedSite && tab.csId === selectedSite.csId ? "active" : ""}`}
              to={{
                pathname: `/${pathname}/${tab.csId}`,
                state: { site: { ...tab.siteInfo, 'chargerActiveName': tab.csName } }
              }}
            >
              {tab.csName}
            </Link>
          </li >
        );
      };
    });

    return result;
  };

  const listMore = () => {
    let arr = visibleTabs;
    if (tabs.length <= arr.length) return; //se ha menos que tabs visiveis ele para aqui

    return ( //retorna a aba More preenchida
      <li className="nav-item dropdown">
        <Dropdown tag="div" isOpen={menu} toggle={toggle} nav>
          <DropdownToggle caret nav className="nav-link dropdown-toggle">{t('More...')}</DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            {tabs.map((tab, index) => {
              if (arr.find(tabVisible => tabVisible.csId === tab.csId)) return;
              return (
                <DropdownItem key={index}>
                  <Link
                    to={{
                      pathname: `/${pathname}/${tab.csId}`,
                      state: { site: { ...tab.siteInfo, 'chargerActiveName': tab.csName } }
                    }}
                  >
                    {tab.csName}
                  </Link>
                </DropdownItem>
              );
            })};
          </DropdownMenu>
        </Dropdown>
      </li>
    );
  };

  return (

    <ul className="nav nav-tabs w-100">

      {/* ALL TAB */}
      <li className="nav-item">
        <Link
          className={`nav-link ${selectedSite ? "" : "active"}`}
          to={{
            pathname: `/${pathname}`,
            state: { site: { ...siteInfo, 'chargerActiveName': '' } }
          }}
        >
          {t('All chargers')}
        </Link>
      </li>

      {/* 3 itens */}
      {listTabs()}

      {/* MORE TAB */}
      {listMore()}

    </ul>
  );
};
const MenuChargersTranslation = withTranslation()(MenuChargers);
export { MenuChargersTranslation };

const Tabs = ({ tabs, children, siteInfoProps, pathname }) => {
  const [siteInfo, setSiteInfo] = useState(siteInfoProps);
  const { site_id } = useParams();

  useEffect(() => {
    setSiteInfo(siteInfoProps);
  }, [siteInfoProps]);

  const loader = <div className="tabsContent__header p-4">
    <div className="d-flex text-white align-items-center justify-content-center">
      <Spinner>Loading...</Spinner>
    </div>
  </div>

  return (
    <div className="container-fluid">
      {tabs.length > 0 ?
        <div className="tabs">
          <MenuChargersTranslation tabs={tabs} siteInfo={siteInfo} site_id={site_id} pathname={pathname} />
        </div>
        : ""}

      <div className="tabsContent">
        {children}
      </div>
    </div>
  );
};

export default withTranslation()(Tabs);
