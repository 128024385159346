import styled from "styled-components";
import { transparentize, lighten, darken, rgba } from "polished";

export const Component = styled.section`
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  div {
    p {
      color: ${darken(0.32, "#e7e8ea")};
      font-size: 0.88rem;
      line-height: 0.88rem;
      font-weight: 500;
      font-style: normal;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;

      strong {
        font-weight: 600;
      }
    }
  }
`;

export const List = styled.ul`
  width: 100%;
  margin: 0 auto;
  padding: 0;

  display: grid;
  grid-gap: 0.24rem;

  li {
    list-style-type: none;
    width: 100%;
    margin: 0;
    padding: 0.24rem;
    border: 2px solid transparent;
    border-radius: 0.24rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.64rem;
    cursor: pointer;

    transition: all 300ms;
    &:hover {
      background-color: ${darken(0.024, "#e7e8ea")};
      border-color: ${transparentize(0.64, "#3970F3")};
    }
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 0.24rem;

    h2 {
      color: ${darken(0.48, "#e7e8ea")};
      margin: 0;
      padding: 0;
      font-weight: 600;
      font-style: normal;
      font-size: 1.24rem;
      line-height: 1.24rem;
      text-align: left;
      text-transform: uppercase;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;
    }

    p {
      color: ${darken(0.32, "#e7e8ea")};
      margin: 0;
      padding: 0;
      font-weight: 500;
      font-style: normal;
      font-size: 1rem;
      line-height: 1rem;
      text-align: left;
      text-transform: none;
      text-decoration: none;
      text-underline-position: under;
      word-wrap: break-word;
    }
  }
`;
