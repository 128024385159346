import * as React from "react";
import * as Translator from "react-i18next";

const { Modal } = require("reactstrap");

import {
  Component,
  ModalWrapper,
  ModalHeader,
  ModalContent,
} from "./modalloginformation.styles";

type ModalLogInformationPropsTypes = {
  t: Function;
  modalIsOpen: boolean;
  modalOnRequestClose: () => void;
};

export const ModalLogInformation = ({
  t: translation,
  modalIsOpen = false,
  modalOnRequestClose,
}: ModalLogInformationPropsTypes) => {
  return (
    <>
      <Component>
        <Modal isOpen={modalIsOpen} fullscreen={"sm"} size={"lg"} centered>
          <ModalWrapper>
            <ModalHeader>
              <p>{translation("charger_ChargerCommandsModalLogInfoTitle")}</p>
              <small onClick={modalOnRequestClose}>x</small>
            </ModalHeader>

            <hr />

            <ModalContent>
              <textarea
                name="logInformation"
                placeholder={translation(
                  "charger_ChargerCommandsModalLogInfoPlaceholder",
                )}
                rows={16}
                disabled
              />
            </ModalContent>
          </ModalWrapper>
        </Modal>
      </Component>
    </>
  );
};

export default Translator.withTranslation()(ModalLogInformation);
